import { useTranslation } from 'react-i18next';

import "./SearchBarAges.scss";

const SearchBarAges = ({startAge, setStartAge, endAge, setEndAge }) => {

  const {t} = useTranslation(['translation']);

  return (
    <div className="SBA_container">
         
         <div className="SBA_box">
         {/* <p>{t("from", { ns: "translation" })}</p> */}

     <input 
     className="SBA_input"
     type="number" 
     name="startAge"
     placeholder='18'
     value={startAge}
     onChange={(e)=>{setStartAge(e.target.value.slice(0, 2))}}
     />

     <p> - </p>

     <input 
     className="SBA_input"
     type="number" 
     name="endAge"
     value={endAge}
     onChange={(e)=>setEndAge(e.target.value.slice(0, 2))}
     />

    <h3>{t("years", { ns: "translation" })}</h3>


      </div>
    </div>
  );
};

export default SearchBarAges;
