import React, { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import BigEmoji from "./BigEmoji";
import Msg from "./Msg";

import './Messenger.scss'
const MessageBox = ({jwtUser, chatUsers, headerUser, headerTour, messages}) => {

    const scrollRef = useRef()
    const {t} = useTranslation(['tr']);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' })
    }, [messages])

    const regex_emoji = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;

  return (
    <div className="MP_MsgBox">
      {!headerUser && !headerTour && (
        <h1> &larr; {t("msngr_chooseChat", { ns: "tr" })}</h1>
      )}

      <div className="MP_msg_empty"></div>

      {messages?.map((msg, index) => (
        <div key={index} ref={scrollRef}>
          {regex_emoji.test(msg?.text) && msg?.text.length === 2 ? (
            // {/* Если это одна эмоджа то  */}
            <BigEmoji jwtUser={jwtUser} chatUsers={chatUsers} msg={msg} />
          ) : (
            <Msg jwtUser={jwtUser} chatUsers={chatUsers} msg={msg} />
          )}
        </div>
      ))}
    </div>
  );
};

export default MessageBox;
