import React, { useEffect } from "react";
import Spinner from "./Spinner";

import "./Loading.scss";

const LoadingScreen = () => {

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  return (
    <div className="LoadingScreen_container">
      <Spinner />
    </div>
  );
};

export default LoadingScreen;
