import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as api from '../../utils/api/api.utils'
import { handleError } from '../../utils/handleError';

import './Buttons.scss'
const FollowTourBtn = ({jwtUser, setJwtUser, tour}) => {

    const doIFollow = jwtUser?.data?.user?.i_follow_tour?.includes(tour?._id)

    const [pressed, setPressed] = useState(doIFollow);
    const [clickable, setClickable] = useState(true);

    const {t} = useTranslation(['tr']);

    const navigate = useNavigate();
    const myId = jwtUser?.data?.user?._id

    const followTour= async()=>{
    if(!jwtUser) navigate('/login')

    try {
      setPressed(true);
      setClickable(false);
      const res = await api.followTourById(myId, {followID: tour?._id})

      if(res?.status===200){
        jwtUser.data.user.i_follow_tour = res.data.user?.i_follow_tour;
        setJwtUser(jwtUser)
        setClickable(true);
        // console.log("Follow button pressed")

      }else{
        setPressed(false);
        setClickable(true);
        throw res
      }
    } catch (error) {
      handleError(error)
    }
}

const unFollowTour = async()=>{
  if(!jwtUser)navigate('/login')

  try {
    setPressed(false)
    setClickable(false);
    const res = await api.unFollowTourById(myId, {unFollowID: tour?._id})

    if(res?.status===200){
      jwtUser.data.user.i_follow_tour = res.data.i_follow_tour;
      setJwtUser(jwtUser)
      setClickable(true);
      // console.log("UnFollow button pressed")
      }else{
        setPressed(true)
        setClickable(true);
        throw res
      }
  } catch (error) {
    handleError(error)
  }
}

  return (
    <div className={clickable? 'FollowBtn_link': "FollowBtn_text"}
        onClick={clickable? (pressed? unFollowTour: followTour): ()=>{}}>
        {t(pressed? "up_unFollow": "up_follow",{ns:"tr"})}
    </div>
  )
}

export default FollowTourBtn