import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import * as api from "../../utils/api/api.utils";
import { useTranslation } from 'react-i18next';

import Spinner from "../../components/Spinner/Spinner";
import BigReview from "../../components/Review/BigReview";
import { handleError } from "../../utils/handleError";

import './MyReviews.scss'
import TabsMenu from "../../components/Menu/TabsMenu";
import EmptyHere from "../../components/PageItems/EmptyHere";
const MyReviews = () => {
  const { user } = useOutletContext();
  const [joinedTours, setJoinedTours] = useState();
  const [createdTours, setCreatedTours] = useState();
  const [loading, setLoading] = useState(true);
  const [menuActive, setMenuActive] = useState(1)

    useEffect(() => {
      api.findToursByIdArray(user?.joined_to_tour).then((res) => {
        if (res?.status === 200) {
          setJoinedTours(res.data)
          setLoading(false);
        }else{
          handleError(res)
        }
      });

      api.findToursByIdArray(user?.created_tours).then((res) => {
        if (res?.status === 200) {
          setCreatedTours(res.data)
          setLoading(false);
        }else{
          handleError(res)
        }
      });

    }, []);

    const {t} = useTranslation(['tr']);


    const joinedToursFinished = joinedTours?.filter(tour=>{
      if(!tour?.noEndDate && (new Date() > new Date(tour?.endDate))){
        return true
      }else{
        return false
      }
    })

    const createdToursFinished = createdTours?.filter(tour=>{
      if(!tour?.noEndDate && (new Date() > new Date(tour?.endDate))){
        return true
      }else{
        return false
      }
    })

    const joinedAmnt = joinedToursFinished ? joinedToursFinished?.length : "" ;
    const createdAmnt = createdToursFinished ? createdToursFinished?.length : "" ;

    useEffect(() => {
      if(joinedAmnt > createdAmnt) {
        setMenuActive(1)
      }else{
        setMenuActive(2)
      }
    }, [joinedAmnt, createdAmnt])


    if (loading) {
      return <Spinner />;
    }

    if(!loading && (createdToursFinished?.length===0) && (joinedToursFinished?.length===0)){
      return (
        <EmptyHere
          text={t("emptyHere",{ns:"tr"})}
          subText={t("upRw_emptyText",{ns:"tr"})}
        />
      )
    }

  return (
    <div className="MyReviews_wrapper">

      <div className="MRs_Title">
        <p><b>{t("upRw_reviewsAfterTours",{ns:"tr"})}</b></p>
      </div>

      <TabsMenu
        menuActive={menuActive}
        setMenuActive={setMenuActive}
        title1={`${t("upRw_iTookPart",{ns:"tr"})}: ${joinedAmnt}`}
        title2={`${t("upRw_iOrganized",{ns:"tr"})}: ${createdAmnt}`}
      />

      {menuActive===2 &&
        <div className="MRs_container"
          // style={{visibility: menuActive===2 ? "visible" : "hidden"}}
          >
          {createdToursFinished?.map((tour, index)=>(
            <BigReview
              key={index}
              tour={tour}
              user={user}
              org={true}
            />
          ))
          }
        </div>
      }
      {menuActive===1 &&
        <div className="MRs_container"
          // style={{visibility: menuActive===1 ? "visible" : "hidden"}}
          >
          {joinedToursFinished?.map((tour, index)=>(
            <BigReview
              key={index}
              tour={tour}
              user={user}
            />
          ))}
        </div>
      }
    </div>
    )
}

export default MyReviews