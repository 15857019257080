import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';

import { thumb } from "../../utils/thumb";

import Verified from "../../assets/verified.svg";
import daysAmnt from "../../assets/sortMenu/amount.svg";
import MsgBtnSml from "../Buttons/MsgBtnSml";
import PostFavBtn from "../Buttons/PostFavBtn";
import Modal from "../Modal/Modal";
import { config } from "../../utils/config";
import { showDate } from "../../utils/showDate";
import SendMsgWin from "../Window/SendMsgWin";

import { FaSearch } from "react-icons/fa";
import { IoLanguageSharp } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";


import "./MPost.scss";
import { showLangs } from "../../utils/showLangs";
import { showGender } from "../../utils/showGender";
import { showAges } from "../../utils/showAges";
import TimeStatusText from "../Chips/TimeStatusText";
import LikesChip from "../Chips/LikesChip";
const MPost = ({ post }) => {
  const { jwtUser, setJwtUser } = useContext(UserContext);
  const [like, setLike] = useState(0);

  const [msgModalOpen, setMsgModalOpen] = useState(false);

  const {t} = useTranslation(['translation', 'countries', 'int', 'langs']);

  const navigate = useNavigate();

  const {date, year} = showDate(post);
  const langs = showLangs(post)
  const gender = showGender(post, "post")
  const ages = showAges(post)

  const sendMessage =()=>{
    if(!jwtUser) navigate('/login')
    setMsgModalOpen(true)
  }

  return (

    <div className="Mpost_Big_wrapper">
      <div className="Mpost_countryLine">
        <p><b>{t(post.country,{ns:"countries"})},</b> {post?.city}</p>
        {/* <span><b>{date}</b> {year}</span> */}
        <span><b>{date}</b></span>
      </div>

      <div className="Mpost_wrapper">
        <div className="Mpost_top">

        <div className="Mpost_left_side">

          <div className="Mpost_pic_area" onClick={() => navigate(`/user/${post?.creator?.username}`)} >
            <img className="Mpost_ibg"
              src={thumb(post?.creator?.photos[0], config.thumb_width)} alt="" loading="lazy"/>
          </div>

        </div>

        <div className="Mpost_right_side">

            <div className="Mpost_line">
              {post?.creator?.verified ?
                <img src={Verified} alt="" />
                :
                <FaRegUserCircle size={16} color="#87589C" />
              }
              <p><b>{post?.creator?.fName}</b> </p>
              <p> {post?.org_age} </p>
              <img className="Mpost_flag" src={`/assets/flags/small/${post?.depart?.toLowerCase()}.jpg`} alt="" />
            </div>


            <div className="MPost_btns">

              <PostFavBtn
                  post={post}
                  jwtUser={jwtUser}
                  setLike={setLike}
                  setJwtUser={setJwtUser}
              />

              {post?.creator?._id!==jwtUser?.data?.user?._id&&
                <div className="MPost_MsgBtn" onClick={sendMessage} >
                  <MsgBtnSml />
                </div>
              }
            </div>

              <div className="Mpost_line">
                <FaSearch size={14} color="#87589C" />
                <p><b>{gender}</b></p>
                <p>{ages}</p>
              </div>

              <div className="Mpost_line">
                {post?.daysAmount > 0 && <>
                  <img src={daysAmnt} alt="travel dates" />
                    <p> <b>{t("Duration", { ns: "tr" })}:</b> </p>
                    <p>{post?.daysAmount}</p>
                    <p>{t("days", { ns: "tr" })}</p>
                </>}
              </div>

              <div className="Mpost_line">
                <IoLanguageSharp size={18} color="#87589C" />
                <p>{langs}</p>
              </div>

        </div>
        </div>


        <div className={`Mpost_right_btm ${post?.org_gender==='male'? 'Mpost_M': 'Mpost_F'}`}>
          <div dangerouslySetInnerHTML={{ __html: post.text }} />
        </div>

        <div className="MPost_bottomLine">
          <TimeStatusText item={post}/>
          <LikesChip item={post} like={like} size={15}/>
        </div>

  <Modal open={msgModalOpen} close={()=>setMsgModalOpen(false)}>

  <SendMsgWin
  jwtUser={jwtUser}
  setJwtUser={setJwtUser}
  user={post?.creator}
  close={()=>{
    document.body.classList.remove("active-modal");
    setMsgModalOpen(false)}}
  />

  </Modal>


      </div>
    </div>
  );
};

export default MPost;
