import { useRef, useEffect, useCallback } from "react";
import { config } from "../../utils/config";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { useTranslation } from 'react-i18next';

import close_icon from "../../assets/close.svg";
import Upload_img from "../../assets/upload_img.svg";

import "./AddPhoto.scss";
import { thumb } from "../../utils/thumb";

const AddPhoto = ({
  selectedFiles,
  setSelectedFiles,
  initialFiles,
  formik_errors,
  formik_touched,
}) => {


  useEffect(() => {
    //--- Add Initial Files ----
    if(initialFiles){
      initialFiles.forEach((file) => {
      const linkImgObj={ link: file }
      setSelectedFiles((curr) => [...curr, linkImgObj]);
    });
  }
  }, []);

  //--- Image File Resizer ----------
  const resizeFile = (file) =>

  new Promise((resolve) => {
      Resizer.imageFileResizer( file,
        config.uploadImage_maxWidth,
        config.uploadImage_maxHeight,
        config.uploadImage_format,
        config.uploadImage_quality,
         0, (uri) => { resolve(uri) }, "file" )
        })

  //----------------
  //----------------
        // console.log(initialFiles);

        const {t} = useTranslation(['tr']);



  //--- Drop Zone -----
  const onDrop = useCallback((acceptedFiles) => {

    acceptedFiles.forEach((file) => {

      resizeFile(file).then((resizedImage)=>{

        const resizedImgObj={ source: resizedImage, preview: URL.createObjectURL(resizedImage) }

        setSelectedFiles((curr) => [...curr, resizedImgObj]);
      });
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop,
    accept: {
    'image/*': [],
  }});

  //-------------------

  //---- Drag n Drop Sorting (очень круто) --------

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleDragSort = (e) => {
    let _selectedFiles = [...selectedFiles];
    //сюда сохраняем елемент который подняли
    const draggedImage = _selectedFiles.splice(dragItem.current, 1)[0];
    // тут вставляем поднятый элемент в место над которым висим
    _selectedFiles.splice(dragOverItem.current, 0, draggedImage);
    // сбрасываем значения чтобы каждый раз они были пустые
    dragItem.current = null;
    dragOverItem.current = null;
    setSelectedFiles(_selectedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.outline = "4px solid #A681B6";
  };

  const handleDragLeave = (e) => {
    e.target.style.outline = "none";
  };

  const handleDrop = (e) => {
    e.target.style.outline = "none";
  };

  //--------------------------------------

  const handleDeleteFile = (photo, index) => {
    const afterDelete = selectedFiles.filter((file, i) => i !== index);
    setSelectedFiles(afterDelete);
  };

  return (
    <div className="AddPhoto_wrapper">
      <div className="AddP_photos_block">
        <div className="AddP_title_area">
          <p>{t("UploadYourPhotos",{ns:"tr"})}</p>
          <span>{t("UploadYourPhotos_text",{ns:"tr"})}</span>
        </div>
        <div className="AddP_photos_img_area">
          <div className="AddP_dragndrop_box" {...getRootProps()}>
            <img src={Upload_img} alt="" />
            <p>{t("dragNdropPhotos",{ns:"tr"})}</p>

            <input
              {...getInputProps()}
              //   onChange={handleFile}
              multiple
              type="file"
              name="image"
            />

            <span>{t("uploadMaximum",{ns:"tr"})} {config.uploadImage_maxAmount} {t("images",{ns:"tr"})}</span>
          </div>

        </div>
      </div>

      <div className="errorBox">
              {formik_errors.image && formik_touched.image
                ? formik_errors.image
                : null}
      </div>

      {selectedFiles.length !== 0 && (
        <h6>{t("movePhotos",{ns:"tr"})}. </h6>
      )}

      <div className="AddP_Show_images_block">
        {selectedFiles && selectedFiles.map((file, index) => (
            <div className="AddP_show_one_img"
              key={index}
              draggable
              onDragStart={(e) => (dragItem.current = index)}
              onDragEnter={(e) => (dragOverItem.current = index)}
              onDragEnd={(e) => handleDragSort(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDragLeave={(e) => handleDragLeave(e)}
              onDrop={(e) => handleDrop(e)}
            >
              <div className="AddP_over_img_line">
                <p>{index + 1}</p>
                <div className="AddP_img_deleteBtn" onClick={(file) => handleDeleteFile(file, index)} >
                  <img className="AddP_img_close_btn" src={close_icon} alt="close" />
                </div>
              </div>
              <img className="AddP_photo" src={file.link ? thumb(file.link, config.thumb_width)
                : file.preview} alt="for upload" loading="lazy"/>
            </div>
          ))}

      </div>
    </div>
  );
};

export default AddPhoto;
