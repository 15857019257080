import { useTranslation } from 'react-i18next';

import TooltipPurp from '../Tooltip/TooltipPurp'
import './AddHotel.scss'

const AddHotel = () => {
  const {t} = useTranslation(['tr']);

  return (
    <div className="AddHotel_wrapper">
      <div className="ADDH_title">
        <div className="ADDH_title_line">
            <p>{t("Hotels",{ns:"tr"})}</p> 
            <TooltipPurp text={t("Hotels_tt",{ns:"tr"})}/>
        </div>
        <span>{t("Hotels_text",{ns:"tr"})}</span>
        <p><b> {t("underDevSection",{ns:"tr"})}</b></p>
        </div>

        <div className="ADDH_btn"> + {t("addHotel",{ns:"tr"})} </div>


    </div>
  )
}

export default AddHotel