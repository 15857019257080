import {useEffect, useState } from "react";
import * as api from '../../utils/api/api.utils'

import Spinner from "../../components/Spinner/Spinner";
import FeedBackLine from "../../components/FeedBack/FeedBackLine";

import './FeedBackPage.scss'
import { handleError } from "../../utils/handleError";
const FeedbackPage = () => {
  const [loading, setLoading] = useState(true);
  const [fbs, setFbs] = useState();

  useEffect(() => {
    api.getFeedbacks().then(res=> {
      if(res?.status===200){
          setFbs(res.data)
      }else{
        handleError(res)
      }
    })
    setLoading(false)
  }, []);

  if(loading){
    return <Spinner/>
  }

    return (
      <div className="FeedbackPage_wrapper">

      <div className="FBP_Header">
        <div></div>
        <p>Date</p>

        <div>
        <p>Username</p>
        <p>e-mail</p>
        </div>

        <div className="FBP_text"> <p>Text</p> </div>
        <p>Country, City</p>
        <p>Url</p>
        <div></div>
      </div>

      <div className="FBP_container">
      {fbs && fbs.map((fb) => (
            <div key={fb._id}>
              <FeedBackLine fb={fb} />
            </div>
          ))}

      </div>
    </div>
  )
}

export default FeedbackPage