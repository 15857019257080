import { useTranslation } from 'react-i18next';

import './Lang.scss'

const Lang = ({icon, code, title, onClick, pointer}) => {
  const {t} = useTranslation(['langs']);

  return (
    <div 
    className="Language"
    onClick={onClick}
    style={{ cursor: pointer ? 'pointer' : ''}}
    
    >
    {icon&&
      <img src={icon} alt="flag" />}
      
      <p>{code ? t(code,{ns:"langs"}) : title}</p>
    </div>
  );
};

export default Lang;
