import { useContext, useState } from 'react';
import { UserContext } from '../../context/user.context';
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';

import User from './User'

import './VUser.scss'

const VUser = ({user}) => {
  const { jwtUser} = useContext(UserContext);

  const [reason, setReason] = useState();
  const [done, setDone] = useState(false);
  const [verified, setVerified] = useState(false);
  const [denied, setDenied] = useState(false);
  const [error, setError] = useState("");

  const {t} = useTranslation(['tr']);

const deny=()=>{
    if(!reason) return setError(t("DenyReason_err",{ns:"tr"}))
    setError("")
    try {
        jwtUser.denyReason=reason;

        api.adminDenyUser(user._id, jwtUser)
        .then(res=> {
            if(res.status===200){
                setDone(true)
                setDenied(true)
            }
        }) 
    } catch (error) {
    }
}

const verify=()=>{
    try {
        api.adminVerifyUser(user._id, jwtUser)
        .then(res=> {
            if(res.status===200){
                setDone(true)
                setVerified(true)
            }
            console.log(res);
        }) 
    } catch (error) {
        console.log(error);
    }
}

  return (

    <div className="VUser_wrapper">

    <div className="VU_top">
        <div className="VU_User">
            <User user={user}/>
        </div>
        <div className="VU_infoBlocks">
            <div className="VU_InfoBlock1">
                <span><b>{t("firstName",{ns:"translation"})}:</b> {user?.v_fName}</span>
                <span><b>{t("lastName",{ns:"translation"})}:</b> {user?.v_lName}</span>
                <span><b>{t("userGender",{ns:"translation"})}:</b> {user?.v_gender==="male"? t("Male",{ns:"translation"}): 
                t("Female",{ns:"translation"})}</span>
                <span><b>{t("birthDate",{ns:"translation"})}: </b> 
                { new Date(user?.v_birthDate)
                .toLocaleString('en-us',{year:'numeric', day:'numeric', month:'long'})}
                </span>
                <span><b>{t("ID_number",{ns:"translation"})}:</b> {user?.v_passport}</span>
            </div>
            <div className="VU_InfoBlock2">
                <span><b>{t("country",{ns:"translation"})}:</b> {user?.v_country}</span>
                <span><b>{t("city",{ns:"translation"})}:</b> {user?.v_city}</span>
                <span><b>{t("Address",{ns:"translation"})}:</b> {user?.v_address}</span>
                <span><b>{t("Phone",{ns:"translation"})}:</b> {user?.v_phone}</span>

            </div>
        </div>
    </div>
    <div className="VU_bottom">
        <div className="VU_doc_photos">

        {user?.doc_files && user?.doc_files.map((file) => (
            <div key={file} className="VU_doc_photo">
              <img onClick={()=> window.open(`${file}`, "_blank")} 
              src={file} alt="" />
            </div>
          ))}
            <div className="VU_doc_photo"></div>
        </div>
        <div className="VU_btn_area">

   {!done? 
    <>

    {error&&
    <h3>{error}</h3>
    }
        <input 
        type="text" 
        name="reason" 
        value={reason} 
        placeholder={t("DenyReason_ph",{ns:"tr"})}
        onChange={(e)=>setReason(e.target.value)}

        />
        
    <div className="VU_buttons">
    <div onClick={deny} className="VU_success_BTN VU_fail_BTN">
    {t("Deny",{ns:"tr"})}</div>
    <div onClick={verify} className="VU_success_BTN">
    {t("Verify",{ns:"tr"})}</div>
    </div>
    </> 
    :
    <div className="VU_verified">
    {verified && <p>  {t("Verified",{ns:"tr"})}</p>}
    {denied && <h3> {t("Denied",{ns:"tr"})} </h3>}
    </div>
    }

    </div>
        </div>
    


    </div>

    )
}

export default VUser