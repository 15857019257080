import { useTranslation } from 'react-i18next';
import Rating from './Rating';

import './TourReview.scss'

const TourReview = ({tourReview, setTourReview}) => {

  const {t} = useTranslation(['tr']);

  return (
   <div className="TourReview_wrapper">
    <h2>{t("Rate_tour",{ns:"tr"})}</h2>

    <div className="TR_marks">
      <Rating size={32}
      rating={tourReview?.rating}
      setRating={(rating)=>setTourReview({...tourReview, rating: rating})}
      />
    </div>

    {/* <div className="TR_marks">
        <div onClick={()=>setTourReview({...tourReview, rating: 1})} className={tourReview?.rating===1 ? "TR_mark TR_mark_active":"TR_mark"}> 1 </div>
        <div onClick={()=>setTourReview({...tourReview, rating: 2})} className={tourReview?.rating===2 ? "TR_mark TR_mark_active":"TR_mark"}> 2 </div>
        <div onClick={()=>setTourReview({...tourReview, rating: 3})} className={tourReview?.rating===3 ? "TR_mark TR_mark_active":"TR_mark"}> 3 </div>
        <div onClick={()=>setTourReview({...tourReview, rating: 4})} className={tourReview?.rating===4 ? "TR_mark TR_mark_active":"TR_mark"}> 4 </div>
        <div onClick={()=>setTourReview({...tourReview, rating: 5})} className={tourReview?.rating===5 ? "TR_mark TR_mark_active":"TR_mark"}> 5 </div>
    </div> */}

    <textarea
    className='TR_input'
    name="text"
    value={tourReview?.text}
    onChange={e=>setTourReview({...tourReview,  text: e.target.value})}
    placeholder={t("WriteReview_ph",{ns:"tr"})}/>

   </div>
  )
}

export default TourReview