import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';

import ppl from '../../assets/ConTour/confirmed.svg'
import confirmed from '../../assets/ConTour/thumb.svg'
import requests from '../../assets/ConTour/requests.svg'
import followers from '../../assets/ConTour/binocular.svg'
import rejects from '../../assets/ConTour/reject.svg'
import blocked from '../../assets/ConTour/blocked.svg'

import { config } from '../../utils/config';
import { thumb } from '../../utils/thumb';
import EyeBtn from '../Buttons/EyeBtn';
import EditBtn from '../Buttons/EditBtn';
import Modal from '../Modal/Modal';
import ConfirmWin from '../Window/ConfirmWin';

import LiftUpBtn from '../Buttons/LiftUpBtn';
import { showDate } from '../../utils/showDate';
import DeleteBtn from '../Buttons/DeleteBtn';
import PublishedText from '../Chips/PublishedText';

import { AiFillEye, AiTwotoneHeart } from "react-icons/ai";


import './ConTourM.scss'
import { handleError } from '../../utils/handleError';
const ConTourMob = ({tour, jwtUser, setUpdated}) => {
    const [isPublic, setIsPublic] = useState(tour?.isPublic);
    const [modalOpen, setModalOpen] = useState(false);

    const navigate = useNavigate();
    const {t} = useTranslation(['translation', 'countries']);

    const {date, daysBefore, isFinished, inProgress} = showDate(tour);

    const deleteTour =()=>{
        api.deleteTour(tour?._id).then(res=> {
          if(res.status===200){
            setUpdated(res.data)
            document.body.classList.remove("active-modal");
            setModalOpen(false)
          }else{
            handleError(res)
          }
        })

    }

  return (

  <div className="ConTourM_BigWrapper">

    <div className="ContM_Contry_Dates_line">
      <p><b>{t(tour?.country, { ns: "countries" })}</b>, {tour?.city}</p>
      <p> {date} </p>
    </div>

  <div className="ConTourM_wrapper">
    {/* <p className="ConTM_title">{tour?.title}</p> */}

    <div className="ConTourM_content_box">

    <div className="ConTourM__left_side">

    <img className='Tour_img' src={thumb(tour?.titleImages[0], config.image_width)}
      alt="country" onClick={() => navigate(`/tour/${tour?._id}/`)} loading="lazy"/>

        <div className="CTMTB_textLine">

          <div className="ConTM_stat">
              <AiTwotoneHeart size={15} color="#87589C" />
              <p>{tour?.user_liked_me?.length}</p>
          </div>

          <div className="ConTM_stat">
              <AiFillEye size={16} color="black" />
              <p>{tour?.visitorsUUID?.length}</p>
          </div>

          <p><b>{tour?.daysAmount}</b> {t("days",{ ns:"tr"})}</p>

          <p><b>$ {tour?.tourPrice}</b></p>

        </div>

      </div>



      <div className="ConTourM_rightside">

      <div className="ConTM_control_Btns_line">

            <div className="ConTM_miniBtns">
              <LiftUpBtn small tooltip={t("raiseViews",{ns:"tr"})} />
            </div>

            <div className="ConTM_miniBtns">
                <EyeBtn
                small
                tooltip={t("Publish_tt",{ns:"tr"})}
                // post={post}
                tour={tour}
                isPublic={isPublic}
                setIsPublic={setIsPublic}
                />
            </div>
            <div onClick={()=>navigate(`/edittour/${tour?._id}`)}
                className="ConTM_miniBtns">
                <EditBtn small tooltip={t("Edit",{ns:"tr"})} />
            </div>
            <div onClick={()=> {
              if(tour?.joined_users?.length>0){
                alert(t("cantDelete",{ns:"tr"}))
              }else{
                setModalOpen(true)
              }
            }}>
              <DeleteBtn small tooltip={t("Delete",{ns:"tr"})} />
            </div>
        </div>

          <div className="ConTourM_rs_left">

          {tour?.joined_users?.length>0 &&
            <div className="CounTM_item_line">
                <img src={ppl} alt="" />
                <p>{t("AlreadyJoined",{ns:"tr"})}: <b>{tour?.joined_users?.length}</b> </p>
            </div>
          }
          {tour?.confirmed_users?.length>0 &&
            <div className="CounTM_item_line">
                <img src={confirmed} alt="" />
                <p>{t("ConfirmedToJoin",{ns:"tr"})}: <b>{tour?.confirmed_users?.length}</b></p>
            </div>
          }

          {tour?.join_requests?.length>0 &&
            <div className="CounTM_item_line">
                <img src={requests} alt="" />
                <p><b>{t("RequestToJoin",{ns:"tr"})}: </b> </p>
                {tour?.join_requests?.length===0 ?
                <p>&nbsp;  0</p>
                :
                <h2 onClick={()=>navigate(`/tour/${tour?._id}/people`)}>
                +{tour?.join_requests?.length}</h2>
                }
            </div>
          }
          {tour?.rejected_users?.length>0 &&
            <div className="CounTM_item_line">
                <img src={rejects} alt="" />
                <p>{t("RejectedToJoin",{ns:"tr"})}: <b>{tour?.rejected_users?.length}</b></p>
            </div>
          }
          {tour?.following_users?.length >0 &&
            <div className="CounTM_item_line">
                <img src={followers} alt="" />
                <p>{t("tripFollowers",{ns:"tr"})}: <b>{tour?.following_users?.length}</b></p>
            </div>
          }
          {tour?.blocked_users?.length>0 &&
            <div className="CounTM_item_line">
                <img src={blocked} alt="" />
                <p>{t("BlockedToTrip",{ns:"tr"})}: <b>{tour?.blocked_users?.length}</b></p>
            </div>
          }

          </div>

      </div>

    </div>
{tour?.v_deny_reason&& <>
<h6>{t("TourIsNotPublished",{ns:"tr"})}"{tour?.v_deny_reason}"</h6>
<h5>{t("makeChangesAndTryAgain",{ns:"tr"})}</h5>
</>
}

  <div className='CTMTB_textLine2'>
    {tour?.isTest && <div className='testChipText'>{t("testTrip2",{ns:"tr"})}</div> }
  </div>

<div className='ContM_finished_line'>

  <PublishedText
    isPublic={isPublic}
    isVerifying={tour?.isVerifying}
  />

  {isFinished && <div className='finishChipText'>{t("tourFinished",{ns:"tr"})}</div>}
  {inProgress && <div className='finishChipText'>{t("inProgress",{ns:"tr"})}</div>}

  {daysBefore >=0 &&
      <p>{t("BeforTrip",{ns:"tr"})}: <b>{daysBefore}&nbsp;{t("days",{ns:"tr"})}</b></p>
  }
</div>


<Modal open={modalOpen} close={() => setModalOpen(false)} NoCloseBtn={true} >
      <ConfirmWin
      close={()=> {
        document.body.classList.remove("active-modal");
        setModalOpen(false)}}

      doSmth={deleteTour}
      title={t("AreYouSureToDeleteTour",{ns:"tr"})}
      info={`<b>${t("AllBeDeleted",{ns:"tr"})}</b>
      <br/>  ${t("NoWayBack",{ns:"tr"})}`}
      />
</Modal>


    </div>
</div>


    )
}

export default ConTourMob