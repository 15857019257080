import TooltipPurp from "../Tooltip/TooltipPurp";
import "./PriceInput.scss";

const PriceInput = ({tooltip, title, subtitle, description, value,...otherProps }) => {
  return (
    <div className="PriceInput_wrapper">
      <div className="PI_title_line">
        <p>{title}</p>
        <div className="PI_price_side">
          <div className="PI_price_input">
            <h1>$</h1>
            <input type="number" value={value} {...otherProps} />
          </div>
          {tooltip && <TooltipPurp text={tooltip} />}
        </div>
      </div>
      <h2>{subtitle}</h2>
      <h3>{description}</h3>
    </div>
  );
};

export default PriceInput;
