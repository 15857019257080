import { MdDeleteForever } from "react-icons/md";

import "./MiniBtns.scss";
const DeleteBtn = ({tooltip, small, onClick}) => {
  return (
    <div className="Btns_Tooltip_wrapper" onClick={onClick}>

      <div className={`CloseIcon  ${small? "Btns_small": null}`}>
        <MdDeleteForever size={26} color="black" />
      </div>
      <div className="Btns_TT_tip" dangerouslySetInnerHTML={{ __html: tooltip }} />
    </div>
  );
};

export default DeleteBtn;
