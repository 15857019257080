import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import YouTubeBtn from '../../components/Buttons/YouTubeBtn';
import PageHeader from '../../components/PageHeader/PageHeader';
import TooltipPurp from '../../components/Tooltip/TooltipPurp';
import { UserContext } from "../../context/user.context";

import './BalancePage.scss'

const BalancePage = () => {
    const { jwtUser} = useContext(UserContext);

    if(!jwtUser){
        return <Navigate to='/login' />
    }

  return (
    <div className='BalancePage_wrapper'>

<PageHeader
    icon="balance"
    title="Баланс" 
    tooltip="Тут отображен ваш финансовый баланс на Tripico. Вы можете увидеть подробный отчет о пополнении средств на счет, так же как и заработанные средства на сайте. Заработанные средства можно вывести в любой момент."
    text="Средства на вашем счету."
    btn="none" />

    <div className="BP_content_box">

        <h1> Баланс </h1>
        <div className="BP_page_item">
            <div className="BP_left_part">
                <div className="BP_item_line">
                    <p>На вашем счете:</p>
                    <TooltipPurp text='Остаток всех средств на вашем счету.'/>
                    <h2>$ {jwtUser?.data?.user?.balance}</h2>
                </div>
                <h3>Сумма внесенных и заработанных средств</h3>
            </div>
            <span>История транзакций</span>
        </div>

        <div className="BP_page_item">
            <div className="BP_left_part">
                <div className="BP_item_line">
                    <p>Вы заработали:</p>
                    <TooltipPurp text='Сумма заработанных средств за организацию и проведение туров.'/>
                    <h2>$ {jwtUser?.data?.user?.earned}</h2>
                </div>
                <h3>Средства полученные за организацию и проведение туров за весь период.</h3>
            </div>
            <span>Вывод средств</span>
        </div>

        <div className="BP_adv_block">
            <h4>Хотите узнать как заработать на Tripico?</h4>
            <div className="BP_read_article">
                <span>Прочитать статью</span>
                <YouTubeBtn
                    title='Видео инструкция'
                    link='https://youtu.be/g2yhOPsfIlI'
                />
            </div>
        </div>
        

        <h1> Услуги </h1>

        <div className="BP_service">
            <div className="BP_left_part">
                <div className="BP_service_line">
                    <p>VIP аккаунт</p>
                    <TooltipPurp text='Добавляет вам расширенные возможности пользования сайтом'/>
                    <h2>$ 100 / мес.</h2>
                </div>
                <ul> Расширяет список ваших возможностей:
                <li>Создать "Тур-Невидимку"</li>
                <li>Скрыть свой аккаунт в поиске</li>
                <li>Создавать до 10 туров одновременно</li>
                </ul>

            </div>
            <span>Подключить услугу</span>
        </div>

        <div className="BP_service">
            <div className="BP_left_part">
                <div className="BP_service_line">
                    <p>Бизнес аккаунт</p>
                    <TooltipPurp text='Этот тариф подходит для тур-агентств и собстенникам отелей'/>
                    <h2>$ 1000 / мес.</h2>
                </div>
                <ul> Добавляет бизнес возможности:
                <li>Создание до 100 туров одновременно</li>
                <li>Автоматическое создание регулярных туров</li>
                <li>Услуга продвижения туров</li>
                <li>Приоритет при поисковых запросах</li>
                <li>В 10 раз больше показов</li>
                <li>Выделение туров жирным контуром</li>
                </ul>

            </div>
            <span>Подключить услугу</span>
        </div>

    </div>


    </div>
  )
}

export default BalancePage