import doc_icon from '../../assets/create_account/doc.svg'
import TooltipPurp from "../Tooltip/TooltipPurp";

import "./TextField.scss";
const TextField = ({ title, icon, tooltip, value, maxLength, ...otherProps }) => {

    let img;

    if(icon==="doc") {img = doc_icon};


 

  return (
    <div className="TextField_container">
      <div className="TF_title_line">

      <div className="TF_title_sign">
        <img src={img} alt="" />
        <p>{title}</p>
      </div>

        <div className="TF_tl_right_side">

        <span>{value?.length || 0} / {maxLength} </span>

        {tooltip && <TooltipPurp text={tooltip}/> }
        </div>
      </div>
      
      <textarea 
      value={value}
      maxLength={maxLength}
      {...otherProps} />

    </div>
  );
};

export default TextField;
