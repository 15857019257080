import { FiEdit } from "react-icons/fi";

import "./MiniBtns.scss";
const EditBtn = ({ tooltip, small, noShadow, onClick }) => {
  return (
    <div className="Btns_Tooltip_wrapper" onClick={onClick}>
      <div className={`CloseIcon EditIcon ${small? "Btns_small": null} ${noShadow? "Btns_noShadow":null}`}>

        <FiEdit size={22} color="#6A2C85" />

      </div>
      <div className="Btns_TT_tip" dangerouslySetInnerHTML={{ __html: tooltip }} />
    </div>
  );
};

export default EditBtn;
