import { useState } from 'react';
import * as api from '../../utils/api/api.utils'
import EyeOpenIcon from '../../assets/eye_open.svg'
import EyeCloseIcon from '../../assets/eye_hide.svg'

import './MiniBtns.scss'
import { handleError } from '../../utils/handleError';

const EyeBtn = ({post, tour, isPublic, setIsPublic, tooltip, small}) => {
  const [pressed, setPressed] = useState(isPublic);

  const pressAction = async()=>{
    if(pressed){
      //-- UnPress
      setPressed(false)
      setIsPublic(false)

      if(post){
        api.unPublicPost(post._id).then(res=> {
          if(res.status!==200){
            setIsPublic(true)
            setPressed(true)
            handleError(res)
          }
        })
      }

      if(tour){
        api.unPublicTour(tour?._id).then(res=> {
          if(res.status!==200){
            setIsPublic(true)
            setPressed(true)
            handleError(res)
          }
        })
      }

    }else{
      //-- Press
      setIsPublic(true)
      setPressed(true);

    if(post){
      api.publicPost(post._id).then(res=> {
        if(res.status!==200){
            setIsPublic(false)
            setPressed(false)
            handleError(res)
          }
      })
    }

      if(tour){
        api.publicTour(tour?._id).then(res=> {
          if(res.status!==200){
            setIsPublic(false)
            setPressed(false)
            handleError(res)
          }
        })
      }

    }
  }


  return (
    <div className='Btns_Tooltip_wrapper'>
          <div onClick={pressAction} className={`CloseIcon EyeOpenIcon ${small? "Btns_small": null}`}>
              {pressed ?
              <img src={EyeOpenIcon} alt="close" />
              :
              <img src={EyeCloseIcon} alt="close" />
              }
          </div>
      <div className="Btns_TT_tip" dangerouslySetInnerHTML={{ __html: tooltip }} />
    </div>
  )
}

export default EyeBtn