import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import './SelectLang.scss'

const SelectLang = ({lang, setLang}) => {
  const { mobile, mobileBig, isTablet, isDesktop } = useMatchMedia();
  
    const {t, i18n} = useTranslation(['langs']);

    const langArr = [
      { 
        value: "ru",  
        label: mobile? "RU": "Русский" ,
        // label: "Русский",
        icon: <img className='SL_flag' src={`/assets/flags/rus.jpg`} alt="" />
      },
      { 
        value: "en",  
        label: mobile? "EN": "English" ,
        // label: "English",
        icon: <img className='SL_flag' src={`/assets/flags/eng.jpg`} alt="" />
      }
    ]
    //lang detection
    const currLang = i18n.language || window.localStorage.i18nextLng
    //setting value based on detection
    const selectValue = (langArr, lang)=>{
      return langArr? langArr.find(o=>o.value===currLang):null
    }

    return (
        <div className="SelectLang_wrapper">
      <Select 
      options={langArr} 
      value={selectValue(langArr, lang)} 
      onChange={(v)=>setLang(v.value)}
      components={{ IndicatorSeparator: () => null }}
      isSearchable={ false }
      getOptionValue={o => o.label}
      getOptionLabel={e=>(
      <div className='SL_option'> 
        {e.icon} 
        <span className='SL_Label'>{e.label}</span> 
      </div>)}

      styles={{
          control: (provided, state) => ({
            ...provided,
            border: "1px solid #e3e3e3"
          }),
        }}

      theme={(theme) => ({...theme, borderRadius: 8,
      colors: {
        ...theme.colors,
        primary25: '#eee3f4',
        primary: '#87589C',
      },})} />
        </div>
  )
}

export default SelectLang