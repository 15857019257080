import i18n from "./i18next";

export const showGender = (item, type ) => {
  // type: "post", "tour"
  const {t} = i18n;

  let gender;
  if(item.gender ==="any"){
    gender = t("any",{ns:"translation"})
  }else{
    if(item.gender==="men"){
      gender = t(type==="post"? "l4men": "men" ,{ns:"translation"})
    }else{
      gender = t(type==="post"? "l4women": "women",{ns:"translation"})
    }
  }
    return gender
  }