import { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { UserContext } from "../../context/user.context";
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';


import checkIcon from '../../assets/check.svg'
import Step_0 from '../../assets/verification/id_card.jpg'
import Step_1 from '../../assets/join/Step_1.jpg'
import Step_2 from '../../assets/join/Step_2.jpg'
import Step_3 from '../../assets/join/Step_3.jpg'

import "./Join.scss";
import { handleError } from "../../utils/handleError";

const Join = () => {
  // const [inJoined, setInJoined] = useState(false);
  // const [inConfirmed, setInConfirmed] = useState(false);
  // const [inRequests, setInRequests] = useState(false);
  // const [inRejected, setInRejected] = useState(false);
  // const [inBlocked, setInBlocked] = useState(false);

    const { jwtUser, setJwtUser} = useContext(UserContext);

    const {tour, setTour} = useOutletContext();
    const navigate = useNavigate();
    const { id } = useParams();
    const myId = jwtUser?.data?.user?._id
    const {t} = useTranslation(['tr']);

    const inJoined = tour?.joined_users?.some(user => user._id === myId)
    const inConfirmed = tour?.confirmed_users?.includes(myId)
    const inRequests = tour?.join_requests?.includes(myId)
    const inRejected = tour?.rejected_users?.includes(myId)
    const inBlocked = tour?.blocked_users?.includes(myId)

//     useEffect(() => {
//       if(amIinJoinedList){ setInJoined(true)
//       }else{ setInJoined(false)}
//
//       if(amIinConfirmedList){ setInConfirmed(true)
//       }else{ setInConfirmed(false)}
//
//       if(amIinRequestsList){ setInRequests(true)
//       }else{ setInRequests(false)}
//
//       if(amIinRejectedList){ setInRejected(true)
//       }else{ setInRejected(false)}
//
//       if(amIinBlockedList){ setInBlocked(true)
//       }else{ setInBlocked(false)}
//
//
//     }, [tour]);

    const sendRequest= async ()=>{
      if(!jwtUser) navigate('/login')

      try {
        const res = await api.sendRequest(tour?._id)

        if(res?.status===200){
          jwtUser.data.user.i_sent_request_to = res.data.user.i_sent_request_to;
          setJwtUser(jwtUser)

          api.getTourById(id).then(res=> {
            if(res?.status===200){
              setTour(res.data)
            }else{
              handleError(res)
            }
          })
        }else{
          throw res
        }
      } catch (error) {
        handleError(error)
      }
    }


  return (
    <div className="TP_Join_wrapper">
      <div className="TP_Join_Title">
      {tour?.isTest&&
      <h3>{t("thisIsNotRealTrip",{ns:"tr"})}!
      <br/>{t("onlyForLearningPurpose",{ns:"tr"})}.
      <br/>{t("joinItForTesting",{ns:"tr"})}. </h3>
      }
        <p>{t("joiningToTrip",{ns:"tr"})}</p>
        {(inConfirmed || inJoined||inRejected || inBlocked)&&
        <>
          <div className="TP_header_status">
            <p>{t("yourStatus",{ns:"tr"})}: </p>
              {inBlocked&& <h5>{t("youBlocked",{ns:"tr"})}</h5>}
              {inRejected&& <h5>{t("youRejected",{ns:"tr"})}</h5>}
              {inConfirmed&& <h3>{t("youConfirmed",{ns:"tr"})}!</h3>}
              {inJoined&& <h3>{t("youJoined",{ns:"tr"})}!</h3>}
          </div>
            {inConfirmed&& <span><b>{t("Congrats",{ns:"tr"})}!</b> {t("confirmed_text",{ns:"tr"})}! </span>}

            {inJoined&& <span><b>{t("Congrats",{ns:"tr"})}!</b> {t("joined_text",{ns:"tr"})}.
            <br/>{t("toAdventures",{ns:"tr"})}!</span>}
        </>
        }
      {!(inJoined || inRejected || inBlocked)&&
        <span> <b> {t("stepsToJoin",{ns:"tr"})}:</b> </span>}

      </div>


{!tour?.isTest && !jwtUser?.data?.user?.verified && <>

      <div className="TP_Join_Step">
        <div className="TP_Join_Step_title">
          <p>{t("Step",{ns:"tr"})} 0: </p>
          <div className="Step_Line"></div>
        </div>

      <div className="TP_Join_MOB_block">
        <p> {t("needVerification",{ns:"tr"})}</p>
        <img src={Step_0} alt="step_1" />
        <span>{t("needVerification_descr",{ns:"tr"})}! </span>
          <button onClick={()=>navigate(`/verification`)} type="button"
            className="Step_Action_Btn Step_VerifyBtn">
            {t("verification",{ns:"tr"})}
          </button>
      </div>


        <div className="TP_Join_Step_block">

          <div className="TP_Join_Step_box">
            <p>{t("needVerification",{ns:"tr"})}</p>
            <span>{t("needVerification_descr",{ns:"tr"})}! </span>
          </div>
          <div className="TP_Join_Step_action">
            <img src={Step_0} alt="step_1" />
            <button onClick={()=>navigate(`/verification`)} type="button"
            className="Step_Action_Btn Step_VerifyBtn">
            {t("verification",{ns:"tr"})}</button>
          </div>
        </div>
      </div>
</> }

      <div className="TP_Join_Step">
        <div className="TP_Join_Step_title">
        {(inRequests || inConfirmed || inJoined) &&
        <img src={checkIcon} alt="" />
        }
          <p>{t("Step",{ns:"tr"})} 1: </p> <div className="Step_Line"></div>
        </div>

        <div className="TP_Join_MOB_block">
          <p> {t("sendRequestToOrg",{ns:"tr"})} </p>
          <img src={Step_1} alt="step_1" />
          <div className="TP_Join_Step_status">
              <p>{t("Status",{ns:"tr"})}: </p>
               {inBlocked&& <h5>{t("youBlocked",{ns:"tr"})}</h5>}
               {inRejected&& <h5>{t("youRejected",{ns:"tr"})}</h5>}
               {inRequests&& <h3> {t("youRequested",{ns:"tr"})}</h3>}
               {inConfirmed&& <h3>{t("youConfirmed",{ns:"tr"})}!</h3>}
               {inJoined&& <h3>{t("youJoined",{ns:"tr"})}!</h3>}
               {(!inRequests&&!inConfirmed&&!inJoined&&!inRejected && !inBlocked)&& <span> {t("notSent",{ns:"tr"})}</span>}
            </div>
            {inRequests&&
            <span> {t("requested_descr",{ns:"tr"})}! </span>}

            {inRejected&&
            <span> {t("rejected_descr",{ns:"tr"})}! </span>}

            <span>{t("sendRequest_descr",{ns:"tr"})}</span>

            <button
             type="button"
             disabled={(inRequests||inConfirmed||inJoined || inRejected || inBlocked|| (!tour?.isTest && !jwtUser?.data?.user?.verified))}
             onClick={sendRequest}
             className="Step_Action_Btn">
                {(inRequests||inConfirmed||inJoined || inRejected || inBlocked)?
                t("youRequested",{ns:"tr"}) :
                t("sendRequest",{ns:"tr"})}
            </button>

        </div>



        <div className="TP_Join_Step_block">
          <div className="TP_Join_Step_box">
            <p> {t("sendRequestToOrg",{ns:"tr"})} </p>
            <span>{t("sendRequest_descr",{ns:"tr"})}</span>
            <div className="TP_Join_Step_status">
              <p>{t("Status",{ns:"tr"})}: </p>
               {inBlocked&& <h5>{t("youBlocked",{ns:"tr"})}</h5>}
               {inRejected&& <h5>{t("youRejected",{ns:"tr"})}</h5>}
               {inRequests&& <h3> {t("youRequested",{ns:"tr"})}</h3>}
               {inConfirmed&& <h3>{t("youConfirmed",{ns:"tr"})}!</h3>}
               {inJoined&& <h3>{t("youJoined",{ns:"tr"})}!</h3>}
               {(!inRequests&&!inConfirmed&&!inJoined&&!inRejected && !inBlocked)&& <span> {t("notSent",{ns:"tr"})}</span>}
            </div>

            {inRequests&&
            <span> {t("requested_descr",{ns:"tr"})}! </span>}

            {inRejected&&
            <span> {t("rejected_descr",{ns:"tr"})}! </span>}

          </div>

          <div className="TP_Join_Step_action">
            <img src={Step_1} alt="step_1" />

            <button
             type="button"
             disabled={(inRequests||inConfirmed||inJoined || inRejected || inBlocked|| (!tour?.isTest && !jwtUser?.data?.user?.verified))}
             onClick={sendRequest}
             className="Step_Action_Btn">
                {(inRequests||inConfirmed||inJoined || inRejected || inBlocked)?
                t("youRequested",{ns:"tr"}) :
                t("sendRequest",{ns:"tr"})}
            </button>
          </div>
        </div>
      </div>

{!tour?.isTest&& (tour?.tourFee > 0) &&
      <div className="TP_Join_Step">
        <div className="TP_Join_Step_title">
          <p>{t("Step",{ns:"tr"})} 2</p> <div className="Step_Line"></div>
        </div>

        <div className="TP_Join_MOB_block">
          <p> {t("payTourFee",{ns:"tr"})}: $ {tour?.tourFee}</p>
          <img src={Step_2} alt="step_1" />
          <div className="TP_Join_Step_status">
              <p>{t("Status",{ns:"tr"})}:</p>
              <span>{t("notPayed",{ns:"tr"})}</span>
          </div>
          <span>{t("payTourFee_descr",{ns:"tr"})}! </span>

          <button
          type="button"
              disabled={(!jwtUser?.data?.user?.verified)}
            className="Step_Action_Btn">
            {t("payTourFee",{ns:"tr"})}
            </button>

        </div>


        <div className="TP_Join_Step_block">
          <div className="TP_Join_Step_box">
            <p> {t("payTourFee",{ns:"tr"})}: $ {tour?.tourFee}</p>
            <span>{t("payTourFee_descr",{ns:"tr"})}! </span>

            <div className="TP_Join_Step_status">
              <p>{t("Status",{ns:"tr"})}:</p>
              <span>{t("notPayed",{ns:"tr"})}</span>
            </div>
          </div>

          <div className="TP_Join_Step_action">
            <img src={Step_2} alt="step_1" />

            <button
            type="button"
              disabled={(!jwtUser?.data?.user?.verified)}
            className="Step_Action_Btn">
            {t("payTourFee",{ns:"tr"})}
            </button>
          </div>
        </div>
      </div>
}

{!tour?.isTest&&
      <div className="TP_Join_Step">
        <div className="TP_Join_Step_title">
          <p>{t("Step",{ns:"tr"})} 3</p> <div className="Step_Line"></div>
        </div>

        <div className="TP_Join_MOB_block">
          <p> {t("bookHotelsTickets",{ns:"tr"})} </p>
          <img src={Step_3} alt="step_1" />
          <div className="TP_Join_Step_status">
              <p>{t("Status",{ns:"tr"})}:</p>
              <span>{t("notBooked",{ns:"tr"})}</span>
          </div>
          <span>{t("bookHotelsTickets_descr",{ns:"tr"})}! </span>

          <div className="Step_Action_Link">
            {t("seeHotels",{ns:"tr"})}
          </div>

        </div>


        <div className="TP_Join_Step_block">
          <div className="TP_Join_Step_box">
            <p> {t("bookHotelsTickets",{ns:"tr"})} </p>
            <span>{t("bookHotelsTickets_descr",{ns:"tr"})}! </span>
            <div className="TP_Join_Step_status">
              <p>{t("Status",{ns:"tr"})}:</p>
              <span>{t("notBooked",{ns:"tr"})}</span>
            </div>
          </div>

          <div className="TP_Join_Step_action">
            <img src={Step_3} alt="step_1" />

            <div className="Step_Action_Link">
            {t("seeHotels",{ns:"tr"})}
            </div>
          </div>
        </div>
      </div>
}


    </div>
  );
};

export default Join;
