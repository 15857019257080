import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import ConUser from "../../components/User/ConUser";
import User from "../../components/User/User";
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';

import { ReactComponent as JoinIcon } from '../../assets/user_con_menu/confirm.svg'
import { ReactComponent as ConfirmIcon } from '../../assets/user_con_menu/thumb.svg'
import { ReactComponent as RequestIcon } from '../../assets/user_con_menu/requests.svg'
import { ReactComponent as FollowersIcon } from '../../assets/user_con_menu/binocular.svg'
import { ReactComponent as BlockIcon } from '../../assets/user_con_menu/block.svg'
import { ReactComponent as RejectIcon } from '../../assets/user_con_menu/reject.svg'


import { handleError } from "../../utils/handleError";
import OrgBlock from "../../components/PageItems/OrgBlock";

import './People.scss'
const People = () => {
  const {tour, setTour, isMyTour, isFinished} = useOutletContext();

  const [joinedUsers, setJoinedUsers] = useState();
  const [confirmedUsers, setConfirmedUsers] = useState();
  const [rejectedUsers, setRejectedUsers] = useState();
  const [joinRequests, setJoinRequests] = useState();
  const [followingUsers, setFollowingUsers] = useState();
  const [blockedUsers, setBlockedUsers] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.findUsersByIdArray(tour?.joined_users).then(res=> {
      if(res.status===200){
        setJoinedUsers(res.data)
      }else{
        handleError(res)
      }
    })

    api.findUsersByIdArray(tour?.confirmed_users).then(res=> {
      if(res.status===200){
        setConfirmedUsers(res.data)
      }else{
        handleError(res)
      }
    })

    api.findUsersByIdArray(tour?.rejected_users).then(res=> {
      if(res.status===200){
        setRejectedUsers(res.data)
      }else{
        handleError(res)
      }
    })

    api.findUsersByIdArray(tour?.join_requests).then(res=> {
      if(res.status===200){
        setJoinRequests(res.data)
      }else{
        handleError(res)
      }
    })

    api.findUsersByIdArray(tour?.following_users).then(res=> {
      if(res.status===200){
        setFollowingUsers(res.data)
      }else{
        handleError(res)
      }
    })

    api.findUsersByIdArray(tour?.blocked_users).then(res=> {
      if(res.status===200){
        setBlockedUsers(res.data)
      }else{
        handleError(res)
      }
    })

      setLoading(false)
  }, [tour]);

  const navigate = useNavigate();
  const {t} = useTranslation(['tr']);

  if(loading){
    return <Spinner/>
  }

  return (
    <div className="TP_People_container">

      <div className="TP_People_orgBlock">
        <div className="TP_People_orgBlock_title">
          <p>{t("Organizator",{ns:"tr"})}</p>
        </div>
        <OrgBlock tour={tour} isMyTour={isMyTour} />
      </div>

    {(joinedUsers?.length >0) ?
    <div className="TP_People_joined">
      <div className="TP_People_header">
        <div className="TP_People_header_line">
          <JoinIcon className="TPP_menuIcon"/>
          <p><b>{t("AlreadyJoined",{ns:"tr"})}:  {joinedUsers?.length+1} </b> / {tour?.amount}</p>
        </div>
        <span>{t("AlreadyJoined_descr",{ns:"tr"})}</span>
      </div>
      <div className="TP_People_users ">
        {joinedUsers && joinedUsers.map((user) => (
          <div key={user._id}>
            {isMyTour?
              <ConUser
                user={user}
                setTour={setTour}
                tour={tour}
                menu="joined"
                />
              :
              <User user={user} />
            }
          </div>
        ))}
      </div>

    </div>
    :
    (!isFinished&&
    <div className="TP_People_joined">
        <h4>{t("collectingPeople",{ns:"tr"})}!</h4>
        <h3 onClick={()=>navigate(`/tour/${tour?._id}/join`)}>
        {t("Join",{ns:"tr"})}</h3>
    </div>
    )
    }

    {(confirmedUsers?.length >0) &&
    <div className="TP_People_confirmed">
    <div className="TP_People_header">
    <div className="TP_People_header_line">
      <ConfirmIcon className="TPP_menuIcon"/>
          <p><b>{t("ConfirmedToJoin",{ns:"tr"})}:</b> {confirmedUsers && confirmedUsers?.length} </p>
      </div>
          <span>{t("ConfirmedToJoin_descr",{ns:"tr"})}</span>
      </div>
      <div className="TP_People_users ">

    {confirmedUsers && confirmedUsers.map((user) => (
      <div key={user._id}>

      {isMyTour?
      <ConUser
        user={user}
        setTour={setTour}
        tour={tour}
        menu="confirm"/>
        :
        <User user={user} />
      }
      </div>
    ))}
      </div>

    </div>
    }

    {(joinRequests?.length >0) && isMyTour &&
    <div className="TP_People_requests">
    <div className="TP_People_header">
    <div className="TP_People_header_line">
      <RequestIcon className="TPP_menuIcon"/>
          <p><b>{t("RequestToJoin",{ns:"tr"})}:</b> {joinRequests && joinRequests?.length}</p>
      </div>
          <span>{t("RequestToJoin_descr",{ns:"tr"})}</span>
      </div>
      <div className="TP_People_users ">

    {joinRequests && joinRequests.map((user) => (
      <div key={user._id}>
        <ConUser
        user={user}
        setTour={setTour}
        tour={tour}
        menu="requests"/>
      </div>
    ))}
      </div>

    </div>}

    {(rejectedUsers?.length >0) && isMyTour &&
    <div className="TP_People_blocked">
    <div className="TP_People_header">
    <div className="TP_People_header_line">
      <RejectIcon className="TPP_menuIcon"/>
          <p><b>{t("RejectedToJoin",{ns:"tr"})}:</b> {rejectedUsers && rejectedUsers?.length}</p>
      </div>
          <span>{t("RejectedToJoin_descr",{ns:"tr"})}</span>
      </div>
      <div className="TP_People_users ">

    {rejectedUsers && rejectedUsers.map((user) => (
      <div key={user._id}>
        <ConUser
        user={user}
        setTour={setTour}
        tour={tour}
        menu="rejected"/>
      </div>
    ))}
      </div>

    </div>}

    {(followingUsers?.length >0) &&
    <div className="TP_People_following">
    <div className="TP_People_header">
    <div className="TP_People_header_line">
      <FollowersIcon className="TPP_menuIcon"/>
          <p><b>{t("tripFollowers",{ns:"tr"})}:</b> {followingUsers && followingUsers?.length}</p>
      </div>
          <span>{t("tripFollowers_descr",{ns:"tr"})}</span>
      </div>
      <div className="TP_People_users ">

    {followingUsers && followingUsers.map((user) => (
      <div key={user._id}>
      {isMyTour?
      <ConUser
        user={user}
        setTour={setTour}
        tour={tour}
        menu="follow"/>
        :
        <User user={user} />
      }
      </div>
    ))}
      </div>

    </div>}

    {(blockedUsers?.length >0) &&  isMyTour &&
    <div className="TP_People_blocked">
    <div className="TP_People_header">
    <div className="TP_People_header_line">
      <BlockIcon className="TPP_menuIcon"/>
          <p><b>{t("BlockedToTrip",{ns:"tr"})}:</b> {blockedUsers && blockedUsers?.length}</p>
      </div>
          <span>{t("BlockedToTrip_descr",{ns:"tr"})}</span>
      </div>
      <div className="TP_People_users ">

    {blockedUsers && blockedUsers.map((user) => (
      <div key={user._id}>
      <ConUser
        user={user}
        setTour={setTour}
        tour={tour}
        menu="blocked"/>
      </div>
    ))}
      </div>

    </div>}

    </div>
  )
}

export default People