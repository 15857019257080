import { useNavigate } from "react-router-dom";
import { config } from "../../utils/config";
import { thumb } from "../../utils/thumb";
import { useTranslation } from 'react-i18next';

import { showDate } from "../../utils/showDate";

import "./TourForReview.scss";
const TourForReview = ({ tour }) => {

  const navigate = useNavigate();
  const {t} = useTranslation(['translation', 'countries']);

  const {date, year} = showDate(tour);

  return (
    <div className="MTFR_wrapperBig" >

        <div className="MTFR_title">
          <p onClick={() => navigate(`/tour/${tour?._id}`)}>{tour?.title}</p>
        </div>

      <div className="MTFR_wrapper" >
        <img className="MTFR_img" src={thumb(tour?.titleImages[0], config.thumb_width)}
          onClick={() => navigate(`/tour/${tour?._id}`)} alt="my tour" loading="lazy"/>

      <div className="MTFR_text_area">
        <div className="MTFR_countrySide">
          <h5><b>{t(tour?.country, { ns: "countries" })},</b> {tour?.city}</h5>
          <img className="MTFR_flag" src={`/assets/flags/small/${tour?.country.toLowerCase()}.jpg`} alt="" />
        </div>
        <span><b>{date}</b> {year}</span>
      </div>

      </div>

    </div>
  );
};

export default TourForReview;
