import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import TooltipPurp from "../../components/Tooltip/TooltipPurp";
import { agreement_p1, agreement_p2  } from "../../utils/agreement";
import { en_agreement_p1, en_agreement_p2  } from "../../utils/en_agreement";

import { useTranslation } from 'react-i18next';

import "./EditTourPages.scss";

const EditTourPage4 = ({ formik, jwtUser }) => {
  const {t, i18n} = useTranslation(['tr']);

  let orgAgreement_p1;
  let orgAgreement_p2;

  if(i18n.language==="ru"){
    orgAgreement_p1 = agreement_p1;
    orgAgreement_p2 = agreement_p2;
  }

  if(i18n.language==="en"){
    orgAgreement_p1 = en_agreement_p1;
    orgAgreement_p2 = en_agreement_p2;
  }



var toolbarOptions = [
  [{ font: [] }],
  ["emoji"],
  ["bold", "italic", "underline", "strike"],
  [{ align: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],
  // [{ 'direction': 'rtl' }],
  // [{ 'size': ['small', false, 'large', 'huge'] }],
  [{ color: [] }, { background: [] }],
  ["link", "image"],
  // ['blockquote', 'code-block'],
  // ['clean']
];

  return (
    <div className="EditTour_wrapper">

      <div className="ET_content_box">




        <div className="ET_title_area">
        <div className="ET_Title_with_tooltip">
        <p>{t("RulesAndPublishing",{ns:"tr"})}</p>
            <TooltipPurp text={t("RulesAndPublishing_tt",{ns:"tr"})}/>
          </div>
          <h3>{t("RulesAndPublishing_text",{ns:"tr"})}
          <br/> {t("RulesAndPublishing_text2",{ns:"tr"})}
          </h3>
        </div>


        <div className="ET_title_area ET_title_area_no_underline">
          <div className="ET_Title_black_with_tooltip">
          <p>{t("RulesForParticipants",{ns:"tr"})}</p>
          <TooltipPurp text={t("RulesForParticipants_tt",{ns:"tr"})}/>
          </div>
        </div>

      <div className="ET_Editor_box">

        <ReactQuill
          className="ET_Editor"
          theme="snow"
          value={formik.values.tourRules}
          onChange={(e) => formik.setFieldValue('tourRules', e)}
          modules={{
            toolbar: toolbarOptions,
            "emoji-toolbar": true,
            "emoji-shortname": true,
          }}
        />
      </div>

        {/* <div className="ET_dotted_line"></div> */}


        {/* <div className="ET_title_area ET_gap">
          <div className="ET_Title_black_with_tooltip">
          <p>{t("serviceAgreement",{ns:"tr"})}</p>
          <TooltipPurp text={t("serviceAgreement_tt",{ns:"tr"})}/>
          </div>
        </div> */}

        {/* <div className="ET_agreement" dangerouslySetInnerHTML={{ __html: orgAgreement_p1 + ` <strong> ${jwtUser?.data?.user?.fName} ${jwtUser?.data?.user?.lName} </strong>` + orgAgreement_p2 }} /> */}

      </div>
    </div>
  );
};

export default EditTourPage4;

