import { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from "../../context/user.context";
import { useFormik } from "formik";
import * as api from '../../utils/api/api.utils'
import { rules } from "../../utils/rules";
import { en_rules } from "../../utils/en_rules";
import { useTranslation } from 'react-i18next';
// import { useMatchMedia } from "../../hooks/use-match-media";

import { validationSchema } from "../../utils/validations/createTour_Yup";

// import PageBtn from '../../components/PageBtn/PageBtn';

import PageHeader from '../../components/PageHeader/PageHeader'
import CreateTourPage1 from '../CreateTourPages/CreateTourPage1';
import CreateTourPage2 from '../CreateTourPages/CreateTourPage2';
import CreateTourPage3 from '../CreateTourPages/CreateTourPage3';
import CreateTourPage4 from '../CreateTourPages/CreateTourPage4';

// import PreviewTour from '../../components/Tour/PreviewTour';
import Modal from '../../components/Modal/Modal';
import InfoWindow from '../../components/Window/InfoWindow';
import TourPreviewPage from '../TourPreviewPage/TourPreviewPage';

import { handleError } from '../../utils/handleError';
import LoadingScreen from '../../components/Spinner/LoadingScreen';
import MainBtn from '../../components/Buttons/MainBtn';

import './CreateTourPage.scss'
const CreateTourPage = () => {
  const { jwtUser, setJwtUser, location } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [serverError, setServerError] = useState();
  // const [errorsPage1, setErrorsPage1] = useState();
  // const [errorsPage2, setErrorsPage2] = useState();
  const [previewPage, setPreviewPage] = useState(false);
  // const [page, setPage] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);//-- Files to upload
  const [usersList, setUsersList] = useState([]); //-- Users Interests
  const [usersLangs, setUsersLangs] = useState([]); //-- Users Langs

  const [isTest, setIsTest] = useState(true);
  const [sending, setSending] = useState(false);

  // const { ipadSml } = useMatchMedia();
  const {t, i18n} = useTranslation(['tr']);
  const navigate = useNavigate();

  useEffect(() => {
      if(!isTest){
        handleSubmit()
      }
    }, [isTest]);

  useEffect(() => {
    formik.setFieldValue("image", selectedFiles);
    if(selectedFiles.length===0) {
    formik.setFieldValue("image", null);
    }
    }, [selectedFiles]);

  useEffect(() => {
    formik.setFieldValue("interests", usersList);
    }, [usersList]);

    useEffect(() => {
      formik.setFieldValue("langs", usersLangs);
      if(usersLangs.length===0) {
        formik.setFieldValue("langs", "");
      }
    }, [usersLangs]);

    useEffect(() => {
      if(location) {
          formik.setFieldValue("depart", location.country);
       }
  }, [location]);



  const formik = useFormik({initialValues: {
        depart: "",
        title: "",
        creator: jwtUser?.data?.user?._id,
        amount: "",
        startAge: 18,
        endAge: '',
        anyAge: false,
        text: "",
        country: "",
        city: "",
        interests: [],
        langs: [],
        gender: "any",

        startDate:"",
        endDate:"",
        noStartDate:false,
        noEndDate:false,
        anyDates:false,
        daysAmount:0,

        tourPrice:"",
        tourFee:0,
        pocketMoney:"",

        joined_users:[],
        confirmed_users:[],
        following_users:[],
        blocked_users:[],

        image:null,
        tourMap:[],

        tourPlan:"",
        tourRules: rules,

        isModerating:true,
        isPublic:false,

        isTest: true,
      },
      validationSchema: validationSchema ,
      onSubmit: (values) => {
          // console.log('Formik values', values);
      },
    });

    useEffect(() => {
        if(!formik.values.endDate || !formik.values.startDate || formik.values.noStartDate ||
            formik.values.noEndDate || (formik.values.endDate - formik.values.startDate)<0){
            formik.setFieldValue("daysAmount", 0);
          }else{
            const daysAmount = (Math.ceil((formik.values.endDate - formik.values.startDate) / (1000 * 3600 * 24)))+1;
            formik.setFieldValue("daysAmount", daysAmount);
        }
    }, [formik.values.endDate, formik.values.startDate, formik.values.noStartDate,
        formik.values.noEndDate ]);


    useEffect(() => {
        if(i18n.language==="ru"){
          formik.setFieldValue("tourRules", rules);
        }
        if(i18n.language==="en"){
          formik.setFieldValue("tourRules", en_rules);
        }
    }, [i18n.language]);


    if(!jwtUser){
        return <Navigate to={`/login`} />
    }

    const createRealTrip = ()=>{
        setIsTest(false)
    }

    const handleSubmit = async(e)=>{
      if(e) e.preventDefault();
      formik.handleSubmit();
      setServerError(null);

      try {
        const formData = new FormData();
        formData.append("title", formik.values.title);
        formData.append("creator", jwtUser.data.user._id);
        formData.append("amount", formik.values.amount);
        formData.append("gender", formik.values.gender);
        formData.append("startAge", formik.values.startAge);
        formData.append("endAge", formik.values.endAge);
        formData.append("anyAge", formik.values.anyAge);
        formData.append("text", formik.values.text);
        formData.append("depart", formik.values.depart);
        formData.append("country", formik.values.country);
        formData.append("city", formik.values.city);

        for (let i=0; i<usersLangs.length; i++) {
          formData.append("langs", usersLangs[i]);
        }

        for (let i=0; i<usersList.length; i++) {
          formData.append("interests", usersList[i]);
        }

        for (let i=0; i<selectedFiles.length; i++) {
          formData.append("titleImages", selectedFiles[i].source);
        }

        if(formik.values?.tourMap?.length>0){
        formData.append("tourMap", formik.values?.tourMap?.[0].source);
        }

        formData.append("startDate", formik.values.startDate);
        formData.append("endDate", formik.values.endDate);
        formData.append("noStartDate", formik.values.noStartDate);
        formData.append("noEndDate", formik.values.noEndDate);

        if(formik.values.noStartDate && formik.values.noEndDate){
          formData.append("anyDates", true);
        }else{
          formData.append("anyDates", false);
        }

        formData.append("daysAmount", formik.values.daysAmount);

        formData.append("tourPrice", +formik.values.tourPrice);
        formData.append("tourFee", +formik.values.tourFee);
        formData.append("pocketMoney", +formik.values.pocketMoney);

        formData.append("tourPlan", formik.values.tourPlan);
        formData.append("tourRules", formik.values.tourRules);

        if(isTest){
          formData.append("isVerifying", false);
          formData.append("isPublic", true);
        }else{
          formData.append("isVerifying", true);
          formData.append("isPublic", false);
        }

        formData.append("isTest", isTest)

        if(formik.isValid){
          setSending(true)

          const res = await api.createTour(formData)

          if (res?.status === 200) {
            setSending(false)
            jwtUser.data.user.created_tours = res.data.created_tours;
            setJwtUser(jwtUser)
            setModalOpen(true)
          }else{
            throw res
          }

        }else{
          console.log("Forms are not correct")
          setIsTest(true)
          setServerError(t("fillAllFields",{ns:"tr"}))
        }
      } catch (error) {
        handleError(error);
        setSending(false)
        setServerError(error.response.message)
      }
    }

    return (
        <div className="CreateTourPage_wrapper">

        {sending && <LoadingScreen/>}

    <PageHeader
      icon="createtour"
      title={t("creatingTour",{ns:"tr"})}
      text={t("creatingTour_text",{ns:"tr"})}
      tooltip={t("creatingTour_tooltip",{ns:"tr"})}

      />

  <form  onSubmit={handleSubmit}>

      <CreateTourPage1
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        usersLangs={usersLangs}
        setUsersLangs={setUsersLangs}
        usersList={usersList}
        setUsersList={setUsersList}
        formik={formik}
        jwtUser={jwtUser}
      />

      <CreateTourPage2 formik={formik} />

      <CreateTourPage3 formik={formik} />

      <CreateTourPage4 formik={formik} jwtUser={jwtUser} />

      <div className="CT_VerifyBtn">
        {serverError &&
            <div className="CT_notValid_error">
              <p>{serverError}</p>
            </div>
        }

        {!jwtUser?.data?.user?.verified &&
          <MainBtn
            color='white'
            title={t("getVerified",{ns:"tr"})}
            onClick={()=>navigate(`/verification`)}
          />
        }
      </div>

    <div className="CT_nextBtn_line">

        <MainBtn
          color='purp'
          title={t("CreateRealTour",{ns:"tr"})}
          tooltip={t("RealTourBtn_tooltip",{ns:"tr"})}
          disabled={(!serverError && formik.isSubmitting)|| !jwtUser?.data?.user?.verified}
          onClick={createRealTrip}
        />

        <MainBtn
          color='blue'
          title={t("CreateTestTour",{ns:"tr"})}
          tooltip={t("TestTourBtn_tooltip",{ns:"tr"})}
          disabled={!serverError && formik.isSubmitting}
          onClick={handleSubmit}
        />
    </div>

    </form>

<Modal open={modalOpen} close={() => setModalOpen(false)} NoCloseBtn={true} >

      {!isTest &&
        <InfoWindow
        close={()=> {
          document.body.classList.remove("active-modal");
          setModalOpen(false)
          navigate('/')
          }}
        title={t("TourIsOnCheck",{ns:"tr"})}
        info={t("TourIsOnCheck_info",{ns:"tr"})}
        />
      }

      {isTest &&
        <InfoWindow
        close={()=> {
          document.body.classList.remove("active-modal");
          setModalOpen(false)
          navigate('/')
          }}
        title={t("TesTourPublished",{ns:"tr"})}
        info={t("TesTourPublished_info",{ns:"tr"})}
        />
      }

</Modal>

    </div>
  )
}

export default CreateTourPage