import { useState } from "react";
import { useNavigate} from "react-router-dom";


import Modal from "../../components/Modal/Modal";
import OBPScreen1 from "../OnboardingPages/OBPScreen1";
import OBPScreen2 from "../OnboardingPages/OBPScreen2";
import OBPScreen3 from "../OnboardingPages/OBPScreen3";

import "./OnboardingPage.scss";
import '../../null.css'

const OnboardingPage = () => {
    const [modalOpen, setModalOpen] = useState(true);
    const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const onClose =()=>{
    document.body.classList.remove("active-modal");
    setModalOpen(false);
}
  const toRegister =()=>{
    document.body.classList.remove("active-modal");
    setModalOpen(false);
    navigate(`/register`)
}

  return (
    <div className="OBP_wrapper">
      <Modal NoCloseBtn={true} open={modalOpen} close={()=> onClose()}>
        <div className="OBP_Form">

        {page===0 &&
          <OBPScreen1
            next={()=> setPage(page+1)}
            close={()=> onClose()}
          />
        }

        {page===1 &&
          <OBPScreen2
            back={()=> setPage(page-1)}
            next={()=> setPage(page+1)}
            close={()=> onClose()}
          />
        }

        {page===2 &&
          <OBPScreen3
            back={()=> setPage(page-1)}
            next={()=>  toRegister()}
            close={()=> onClose()}
          />
        }

        </div>
      </Modal>
    </div>
  );
};

export default OnboardingPage;
