import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en_tr from '../data/locales/en/tr.json'
import en_translation from '../data/locales/en/translation.json'
import en_countries from '../data/locales/en/countries.json'
import en_int from '../data/locales/en/int.json'
import en_langs from '../data/locales/en/langs.json'
import en_err from '../data/locales/en/err.json'

import ru_tr from '../data/locales/ru/tr.json'
import ru_translation from '../data/locales/ru/translation.json'
import ru_countries from '../data/locales/ru/countries.json'
import ru_int from '../data/locales/ru/int.json'
import ru_langs from '../data/locales/ru/langs.json'
import ru_err from '../data/locales/ru/err.json'


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'RU',
    debug: true,
    detection: {
        order: ['localStorage', 'cookie','htmlTag', 'navigator', 'sessionStorage' ],
        caches: ['localStorage', 'cookie'],
    },

    resources: {
      en: {
       tr: en_tr,
       translation: en_translation,
       countries: en_countries,
       int: en_int,
       err: en_err,
       langs: en_langs
     },
     ru: {
       tr: ru_tr,
       translation: ru_translation,
       countries: ru_countries,
       int: ru_int,
       err: ru_err,
       langs: ru_langs
     },
   },

    useSuspense: false,

    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;


//  const { t, i18n, ready } = useTranslation('ns1', { useSuspense: false });