import { useState } from 'react';
import pass_icon from '../../assets/create_account/password.svg'
import conf_icon from '../../assets/create_account/confirm.svg'
import see from '../../assets/show_pwd.svg'
import hide from '../../assets/hide_pwd.svg'
import TooltipPurp from "../Tooltip/TooltipPurp";

import "./InputPassword.scss";
const InputPassword = ({ title, icon, value, tooltip,...otherProps }) => {
  const [show, setShow] = useState(false);

    let img;
    if(icon==="pass") {img = pass_icon};
    if(icon==="conf") {img = conf_icon};

  return (
    <div className="InputPassword_container">
      <div className="IP_title_line">
        <div className="IP_title_sign">
          <img src={img} alt="" />
          <p>{title}</p>
        </div>
        {tooltip && <TooltipPurp text={tooltip}/> }
      </div>

  <div className="IP_field">
    <input
      value={value}
      type={show? "text" :"password" }
     {...otherProps} />

     <img src={show? see: hide} onClick={()=>setShow(!show)} alt="" />
  </div>

    </div>
  );
};

export default InputPassword;
