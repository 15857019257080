import { BiSolidUserPlus } from "react-icons/bi";

import './MsgBtnSml.scss'
const JoinBtnSml = ({onClick}) => {
  return (
    <div className="MsgBtnUP" onClick={onClick}>
        <BiSolidUserPlus size={28} color="#fff" />
    </div>
  )
}

export default JoinBtnSml