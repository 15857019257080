import { useState, useEffect } from "react";
import Interest from "../Interest/Interest";
import OutsideClick from "../../utils/OutsideClick";
import { interests } from "../../data/interests";
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';


import arrow_down from '../../assets/arrow_down_grey.svg'
import int_icon from '../../assets/create_account/interest.svg'

import "./AddInterest.scss";
import TooltipPurp from "../Tooltip/TooltipPurp";
import { handleError } from "../../utils/handleError";
import MainBtn from "../Buttons/MainBtn";
const AddInterest = ({ usersList, setUsersList, title, tooltip, max=50 }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  //---- Interest states -----------
  const [interestInput, setInterestInput] = useState(''); //-- Input Field
  const [menuInterests, setMenuInterests] = useState([...interests]);//-- обрезанный список
  const [wholeList, setWholeList] = useState([...interests]); //-- полный

  useEffect(() => {
    api.getInterests().then((res) => {
      if(res.status===200){
        setMenuInterests([...interests, ...res.data])
        setWholeList([...interests, ...res.data])
      }else{
        handleError(res)
      }
    });
}, []);

const publicMenuInterests = menuInterests.filter(int=> int.isPublic===true)
  const {t} = useTranslation(['int']);


const createInterest=async()=>{
  try {
    const res = await api.createInterest({ rus_title: interestInput, isPublic: false })

    if(res.status===200){
      setWholeList([...wholeList,res.data ])
      setUsersList([...usersList, res.data._id ])
    }else{
      throw res
    }
  } catch (error) {
    handleError(error)
}
}

const addInterest=()=>{

  if(interestInput.length>=3){
  setInterestInput('')
  createInterest()
  }
  return
}

  //-- Закрытие на ESC клавишу --
  const onClose = () => {
    document.removeEventListener("keydown", escClose, false);
    setMenuOpen(!menuOpen);
  };
  const escClose = (event) => {
    if (event.key === "Escape") onClose();
  };
  if (menuOpen) {
    document.addEventListener("keydown", escClose, false);
  }
  //------------------------------

  const userInterestList = wholeList.filter(({ _id }) => usersList?.includes(_id));

  //-- Добавление в список пользователя и удаление из списка меню

  const selectInterest = (interest) => {
    if(usersList.length<max){
    const newList = menuInterests.filter((item) => item._id !== interest._id);
    setMenuInterests(newList);
    setUsersList([...usersList, interest._id]);
    }
  };

  //-- Удаление из списка пользователя и добавление в список меню
  const deleteInterest = (interest) => {
    const newUserList = usersList.filter((item) => item !== interest._id);
    setUsersList(newUserList);
    const addToMenuItem = wholeList.find((item) => item._id === interest._id);
    setMenuInterests([addToMenuItem, ...menuInterests]);
  };

  return (
    <div className="AI_wrapper">
    <div className="AI_Title_line">
        <img src={int_icon} alt="interest icon" />
        <p>{title}</p>
        {tooltip &&
        <div className="AI_Tooltip">
          <TooltipPurp text={tooltip} />
        </div>
        }
    </div>
    <div className="AI_content_box">
      <div className="AI_Btn_area">

        <div className="AI_BTN"
        onClick={() => setMenuOpen(!menuOpen)} >
        {t("Select",{ns:"tr"})}
        <img src={arrow_down} alt="" />
        </div>


        {menuOpen && (
          <OutsideClick doSmth={() => setMenuOpen(!menuOpen)}>
            <div className="AI_Interests_menu">
              {menuInterests && publicMenuInterests.map((int) => (

                  <Interest
                    key={int?._id}
                    int={int}
                    title={t(int.code, { ns: "int" })}
                    rus_title={int.rus_title}
                    icon={int?.icon}
                    pointer={true}
                    onClick={() => selectInterest(int)}
                  />
                ))}
            </div>
          </OutsideClick>
        )}
  <h5>{t("AddIfNo",{ns:"tr"})}</h5>

<div className="AI_addNew">
        <input
        className="AI_input"
        type="text"
        onChange={(e)=>setInterestInput(e.target.value)}
        name="createInterest"
        value={interestInput}
        placeholder={t("more3Char",{ns:"tr"})}
    />

  <MainBtn
      color='purp'
      title={t("AddInt",{ns:"tr"})}
      onClick={addInterest}
  />

</div>

      </div>
  <div className="AI_Int_box">
     <span> {t("clickToRemove",{ns:"tr"})}.</span>

      <div className="AI_Interests_area">
        {userInterestList && userInterestList.map((int) => (
            <Interest
              key={int._id}
              title={t(int.code, { ns: "int" })}
              rus_title={int.rus_title}
              icon={int.icon}
              pointer={true}
              onClick={() => deleteInterest(int)}
            />
          ))}
      </div>

  </div>
    </div>
    </div>
  );
};

export default AddInterest;
