import {useState } from "react";
import { useNavigate } from "react-router-dom";
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';
import star from "../../assets/star.svg";

import ppl from "../../assets/ppl.svg";
import Lang from "../../assets/lang.svg";

import { thumb } from "../../utils/thumb";
import { config } from "../../utils/config";

import "./Tour.scss";
import "./VerifyTour.scss";
import Modal from "../Modal/Modal";
import ConfirmWin from "../Window/ConfirmWin";
import DeleteBtn from "../Buttons/DeleteBtn";
import { showDate } from "../../utils/showDate";
import { showLangs } from "../../utils/showLangs";
import { handleError } from "../../utils/handleError";
const VerifyTour = ({tour, jwtUser, setUpdated}) => {
  const [reason, setReason] = useState();
  const [done, setDone] = useState(false);
  const [verified, setVerified] = useState(false);
  const [denied, setDenied] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);


  const MAX_LENGTH = 210;
  const navigate = useNavigate();
  const {t} = useTranslation(['translation', 'countries']);

  const {date, year} = showDate(tour);
  const langs = showLangs(tour)


  const navigateTo = (tour)=>{
      navigate(`/tour/${tour?._id}/`)
    };

    const navigateToUser = (user)=>{
      navigate(`/user/${user.username}/`)
    };


    const deleteTour=()=>{
      api.adminMoveTour2Trash(tour?._id, jwtUser).then(res=> {
        if(res.status===200){
            setDone(true)
            setDeleted(true)
            setModalOpen(false)
            setUpdated("")
        }else{
          handleError(res)
        }
      })
  }

    const deny=()=>{
      if(!reason) return setError(t("DenyReason_err",{ns:"tr"}))

      setError("")
      jwtUser.denyReason=reason;

      api.adminDenyTour(tour?._id, jwtUser).then(res=> {
          if(res.status===200){
              setDone(true)
              setDenied(true)
          }
      })
  }

  const verify=()=>{
    api.adminVerifyTour(tour?._id, jwtUser).then(res=> {
        if(res.status===200){
            setDone(true)
            setVerified(true)
        }else{
          handleError(res)
          console.log(res?.data?.message);
        }
    })
  }


  return (
    <div className="VfyTour_Tour_wrapper">
            <p className="VfyTour_title">{tour?.title}</p>

        {!deleted&&
          <div className="VfyTour_deleteBtn" onClick={()=> setModalOpen(true)}>
            <DeleteBtn tooltip={t("Delete",{ns:"tr"})}/>
          </div>
        }


      <div className="VfyTour_contentBox">

      <div className="VfyTour_left_side">
        <div className="Tour_country_part">
          <p className="Tour_country">{t(tour?.country, { ns: "countries" })},</p>
          <p className="Tour_city">{tour?.city}</p>
        </div>
        <div className="VfyTour_pic_place" onClick={() => navigateTo(tour)} >
          <img className="VfyTour_ibg" alt="country" loading="lazy"
            src={thumb(tour?.titleImages[0], config.thumb_width)} />

          <div className="new_dark_line">
            <h5><b>{date}</b></h5>
            <span>{year}</span>
          </div>

          <div className="price_days">
            <p>$ {tour?.tourPrice}</p>
            <span>{tour?.daysAmount} &nbsp; {t("days",{ns:"tr"})}</span>
          </div>

        </div>
      </div>
      <div className="Tour_rightside">
        <div className="VfyTour_rs_top">
        <div className="VfyTour_info">

        <div className="rs_org_area">
          <img onClick={() => navigateToUser(tour?.creator)}  className="rs_org_img" src={tour?.creator?.photos[0]} alt="organizator" />

          <div className="VfyTour_left_org_rating">
            <div className="lor_ratingLine">
              <img src={star} alt="" />
              {tour?.org_rating > 0 && <p>{tour?.org_rating}</p>}
            </div>
          <p>{tour?.creator?.fName}</p>
          <p>{tour?.creator?.lName}</p>

          </div>
        </div>
                <div className="top_mid_amount">
                  <img src={ppl} alt="" />
                  <p>{tour?.amount} &nbsp;{t("ppl",{ns:"tr"})}</p>

                  {tour?.anyAge ? <span>({t("anyAge",{ns:"translation"})})</span>
                  : <span>({tour?.startAge}-{tour?.endAge})</span>
                  }

                </div>
        <div className="top_mid_lang">
          <img src={Lang} alt="" />
          <span>{langs}</span>
        </div>

              </div>
        </div>
        <div className="Tour_rs_mid">

          {tour?.text?.length > MAX_LENGTH ?
        (
          <div dangerouslySetInnerHTML={{ __html: [`${tour?.text.substring(0, MAX_LENGTH)}...`, '<a href="#">Read more</a>'] }} />
        ) :
        <div dangerouslySetInnerHTML={{ __html: tour?.text }} />
      }
        </div>
      </div>
      </div>

      <div className="VT_btn_area">

{!done?
 <>

 {error&&
 <h3>{error}</h3>
 }
     <input
     type="text"
     name="reason"
     value={reason}
     placeholder={t("DenyReason_ph",{ns:"tr"})}
     onChange={(e)=>setReason(e.target.value)}

     />

 <div className="VT_buttons">
 <div onClick={deny} className="VT_success_BTN VT_fail_BTN">
 {t("Deny",{ns:"tr"})}</div>
 <div onClick={verify} className="VT_success_BTN">
 {t("Publish",{ns:"tr"})}</div>
 </div>
 </>
 :
 <div className="VT_verified">
 {verified && <p> {t("Published",{ns:"tr"})}</p>}
 {denied && <h3> {t("Denied",{ns:"tr"})} </h3>}
 {deleted && <h3> {t("Deleted",{ns:"tr"})} </h3>}
 </div>
 }

 </div>

 <Modal open={modalOpen} close={() => setModalOpen(false)} NoCloseBtn={true} >

      <ConfirmWin
        title={t("AreYouSureToDeleteTour",{ns:"tr"})}
        info={`<b>${t("AllBeDeleted",{ns:"tr"})}</b> <br/>  ${t("NoWayBack",{ns:"tr"})}`}
        close={()=> {
          document.body.classList.remove("active-modal");
          setModalOpen(false)}}
        doSmth={deleteTour}
      />

</Modal>


    </div>
  );
};

export default VerifyTour;
