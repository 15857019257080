import { useContext } from 'react'
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';

import PageHeader from '../../components/PageHeader/PageHeader'
import ChangePass from './ChangePass'
import ChangeUsername from './ChangeUsername'
import ConfirmEmail from './ConfirmEmail'
import DeleteAccount from './DeleteAccount'
import ResetPassBlock from './ResetPassBlock'

import './SettingsPage.scss'
const SettingsPage = () => {
  const { jwtUser, setJwtUser} = useContext(UserContext);
  const {t} = useTranslation(['tr']);


  return (

    <div className="SettingsPage_wrapper">

        <PageHeader
        icon="settings"
        title={t("mySettings",{ns:"tr"})}
        // tooltip="Введите ваш логин и пароль "
        text={t("mySettings_text",{ns:"tr"})}
        btn="none"
        />

        {!jwtUser?.data?.user?.isEmailActivated &&
        <ConfirmEmail jwtUser={jwtUser} setJwtUser={setJwtUser} />
        }

        <ChangeUsername jwtUser={jwtUser} setJwtUser={setJwtUser} />
        
        <ChangePass jwtUser={jwtUser} setJwtUser={setJwtUser} />

        <ResetPassBlock jwtUser={jwtUser} />

        <DeleteAccount jwtUser={jwtUser} setJwtUser={setJwtUser}/>


    </div>

    )
}

export default SettingsPage