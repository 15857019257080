import { useTranslation } from 'react-i18next';

import User from "../../components/User/User";

import "./FavoritesPages.scss";

const FavPeoplePage = ({users}) => {

  const show_amount = 55;
  const {t} = useTranslation(['tr']);

  return (
   
    <div className="FavPeoplePage_wrapper FavPage_wrapper">

      <h1>{t("fav_people",{ns:"tr"})}</h1>

      <div className="Fav_users_container">
      {users && users.map((user, index) => 
            index < show_amount &&  (
            <div key={user._id}>
              <User user={user} />
            </div>
          ))}

      </div>
    </div>

  );
};

export default FavPeoplePage;
