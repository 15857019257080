import { thumb } from "../../utils/thumb";
import { age } from "../../utils/age";
import { config } from "../../utils/config";
import { useTranslation } from 'react-i18next';

import { useState } from "react";
import { ReactComponent as Verified } from "../../assets/verified.svg";

import OutsideClick from "../../utils/OutsideClick";
import LoadingItem from "../Spinner/LoadingItem";

import UserConMenu from "../Menu/UserConMenu";

import './User.scss';
const ConUser = ({user, tour, setTour, menu}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const {t} = useTranslation(['translation', 'countries']);


  return (

    <div className="user_wrapper" onClick={() => setMenuOpen(true)} >

      {sending && <LoadingItem/>}

      <div className="user_hover">
        <div className="user_imgBG">
          <img src={thumb(user?.photos[0], config.thumb_width)} alt="user" loading="lazy" />
        </div>

        {user?.verified&&<Verified className='user_verified'/>}

        <div className="user_text">
          <div className="user_text_up">
            <p><b>{user?.fName},</b> {age(user?.birthDate)} </p>
          </div>
          <span className="user_country">{t(user?.country, { ns: "countries" })}, {user?.city}</span>
        </div>

      </div>

        <OutsideClick doSmth={() => setMenuOpen(false)}>

        <UserConMenu
          menu={menu}
          user={user}
          tour={tour}
          setTour={setTour}
          setSending={setSending}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />

        </OutsideClick>





    </div>

  )
}

export default ConUser