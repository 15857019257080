import RejectIcon from '../../assets/join/Reject.svg'


import './JoinedChip.scss'

const RejectChip = ({title, tooltip, small=false, mobile=false}) => {
  return (
    <div className='Chip_Tooltip_wrapper'>

    {!mobile ?
    <div className={small? "TP_Chip_wrapper Chip_small": "TP_Chip_wrapper"}>
    <img src={RejectIcon} alt="" />
    {!small &&
    <h2>{title}</h2>}
    </div>
    :
    <div className="TP_Chip_mob">
        <img src={RejectIcon} alt="" />
      </div>
    }
    <div className="Chip_tip" dangerouslySetInnerHTML={{ __html: tooltip }} />

    </div>
    )
}

export default RejectChip