import { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { UserContext } from "../../context/user.context";
import * as api from "../../utils/api/api.utils";
import OutsideClick from "../../utils/OutsideClick";

import bell from "../../assets/mob/bell.svg";
import add from "../../assets/mob/add.svg";
import burger from "../../assets/mob/burger.svg";
import heart from "../../assets/mob/heart.svg";
import msg from "../../assets/mob/msg.svg";

import { HiOutlineNewspaper } from "react-icons/hi2";

import MobMenu from "../Menu/MobMenu";
import MobAddMenu from "../Menu/MobAddMenu";


import "./MobFooter.scss";
const MobFooter = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const [eventsNum, setEventsNum] = useState();

  const { jwtUser, unreadMsg, socket } = useContext(UserContext);

  const navigate = useNavigate();
  const myId = jwtUser?.data?.user?._id

  const handleScroll=()=>{
    setMenuOpen(false)
    setAddMenuOpen(false)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, false)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if(jwtUser){
      api.getUnreadEventsAnmt().then((res) => setEventsNum(res.data));
    }
  }, [jwtUser]);

  useEffect(() => {
  socket && socket.on("event", e=>{
    // console.log(e.userId===myId);
    if(e.userId===myId){
      setEventsNum(prev => prev + 1)
    }

    })
}, [socket])


const notifications=()=>{
    navigate(`/notifications`)
    setEventsNum(null)
}

  return (
    <div className="MobFooter_wrapper">

      <div className="MobF_item" onClick={() => setMenuOpen(!menuOpen)}>
        <OutsideClick doSmth={() => setMenuOpen(false)}>
            <img src={burger} alt="" />
            <MobMenu
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
            />
        </OutsideClick>
      </div>

      <div className="MobF_item" onClick={notifications}>
        <div className="MobF_events">
          <img src={bell} alt="" />
          {eventsNum>0 && <div className="MobF_event_dot"></div>}
        </div>
      </div>

        <div className="MobF_item">
        <OutsideClick doSmth={() => setAddMenuOpen(false)}>
          <div onClick={() => setAddMenuOpen(!addMenuOpen)}>
            <img src={add} alt="" />
              <MobAddMenu
              addMenuOpen={addMenuOpen}
              setAddMenuOpen={setAddMenuOpen}
              />
          </div>
        </OutsideClick>
        </div>

      {/* <div className="MobF_item" onClick={()=> navigate(`/favorites`)}>
        <img src={heart} alt="" />
      </div> */}

      <div className="MobF_item" onClick={()=> navigate(`/news`)}>
        <HiOutlineNewspaper size={28} color="#767676"/>
      </div>

      <div className="MobF_item" onClick={()=>navigate(`/messenger`)}>
        <div className="MobF_events">
          <img src={msg} alt="" />
          {unreadMsg>0 && <div className="MobF_event_dot"></div>}
        </div>
      </div>

    </div>
  );
};

export default MobFooter;
