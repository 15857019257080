import { useOutletContext, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';

import User from "../../components/User/User";

import TimeIcon from "../../assets/time.svg";
import DepartIcon from "../../assets/create_tour/depart.svg";
import GroupIcon from "../../assets/group.svg";
import AgesIcon from "../../assets/ages.svg";
import DollarIcon from "../../assets/sortMenu/dollar.svg";
import TourFeeIcon from "../../assets/tour_fee.svg";
import { useContext, useEffect, useState } from "react";
import * as api from '../../utils/api/api.utils'


import Spinner from "../../components/Spinner/Spinner";
import Interest from "../../components/Interest/Interest";
import PocketMoneyIcon from "../../assets/pocket_money.svg";
import LangIcon from "../../assets/lang_2.svg";

import TooltipPurp from "../../components/Tooltip/TooltipPurp";
import { handleError } from "../../utils/handleError";
import { showGender } from "../../utils/showGender";
import { FaTransgenderAlt } from "react-icons/fa";
import { showLangs } from "../../utils/showLangs";
import { showDate } from "../../utils/showDate";
import OrgBlock from "../../components/PageItems/OrgBlock";

import "./Overview.scss";
import { showAges } from "../../utils/showAges";
const Overview = () => {
  const { jwtUser, setJwtUser} = useContext(UserContext);
  const { tour, tour_interests, isMyTour } = useOutletContext();

  const [loading, setLoading] = useState(true);
  const [joinedUsers, setJoinedUsers] = useState();

  const navigate = useNavigate();
  const {t} = useTranslation(['translation', 'countries', 'int', 'tr']);

  const {daysBefore, isStarted, isFinished} = showDate(tour);
  const gender = showGender(tour, "tour")
  const langs = showLangs(tour)
  const ages = showAges(tour)


  useEffect(() => {
    api.findUsersByIdArray(tour?.joined_users).then(res=> {
      if(res.status===200){
        setJoinedUsers(res.data)
      }else{
        handleError(res)
      }
    })
    setLoading(false)
  }, [tour]);

  const navigateToUser = (user)=>{
    navigate(`/user/${user.username}`)
  };

if(loading){
  return <Spinner/>
}

  return (
    <div className="TourPage_Outlet">
      <div className="TourPage_OverviewPage">
        <div className="TP_overview_text">
          <div className="TP_block_header TP_text_header">
            {tour?.title}
          </div>
          <div className="TP_overview_text_block">
            <div dangerouslySetInnerHTML={{ __html: tour?.text }} />
          </div>
        </div>
        <div className="TP_overview_org">

          <div className="TP_block_header">
            {t("Organizator",{ns:"tr"})}
            <span onClick={() => navigateToUser(tour?.creator)}>
              {t("viewProfile",{ns:"tr"})}
            </span>
          </div>

          <OrgBlock
            jwtUser={jwtUser}
            setJwtUser={setJwtUser}
            tour={tour}
            isMyTour={isMyTour}
          />

          <div className="TP_overview_info">
            <div className="TP_block_header">
            {t("TripInfo",{ns:"tr"})}:</div>
            <div className="TP_info_box">

              <div className="Tour_info_line">
                <img src={DepartIcon} alt="GroupIcon" />
                <p>{t("FromCountry",{ns:"tr"})}:</p>
                <img className="TP_fromFlag" src={`/assets/flags/small/${tour?.depart?.toLowerCase()}.jpg`} alt="" />
                <span>{t(tour?.depart, { ns: "countries" })}</span>
              </div>

            {!isFinished&& !isStarted&&
              <div className="Tour_info_line">
                <img src={TimeIcon} alt="GroupIcon" />
                <p>{t("BeforTrip",{ns:"tr"})}:</p>
                <span> <b>{daysBefore}</b>&nbsp;
                {t("days",{ns:"tr"})}</span>
              </div>
            }

              <div className="Tour_info_line">
                <img src={GroupIcon} alt="GroupIcon" />
                <p>{t("Group",{ns:"tr"})}:</p> &nbsp;
                <span>{t("upTo",{ns:"tr"})}&nbsp;<b>{tour?.amount}</b>
                &nbsp; {t("ppl",{ns:"tr"})}</span>
              </div>

              <div className="Tour_info_line">
                <img src={AgesIcon} alt="AgesIcon" />
                <p>{t("Ages",{ns:"tr"})}:</p>
                <span>{ages}</span>
              </div>

              <div className="Tour_info_line">
                <FaTransgenderAlt size={20} color="#87589C" />
                <p>{t("pplGender",{ns:"tr"})}:</p>
                <span>{gender}</span>
              </div>

              <div className="Tour_info_line bold_p">
              <img src={DollarIcon} alt="TourCost" />
                <p>{t("TourCost",{ns:"tr"})}:</p>
                <span>$ {tour?.tourPrice}</span>

                <div className="Tour_IL_Tooltip">
                  <TooltipPurp text={t("Hotels_tooltip",{ns:"tr"})} />
                </div>

              </div>
              <div className="Tour_info_line bold_p">
                <img src={TourFeeIcon} alt="TourFeeIcon" />
                <p>{t("TourFee",{ns:"tr"})}:</p> <span>$ {tour?.tourFee}</span>

                <div className="Tour_IL_Tooltip">
                  <TooltipPurp text={t("TourFee_tooltip",{ns:"tr"})}/>
                </div>

              </div>
              <div className="Tour_info_line bold_p">
                <img src={PocketMoneyIcon} alt="PocketMoneyIcon" />
                <p>{t("PocketMoney",{ns:"tr"})}:</p>
                <span>$ {tour?.pocketMoney}</span>

                <div className="Tour_IL_Tooltip">
                  <TooltipPurp text={t("PocketMoney_tooltip",{ns:"tr"})}/>
                </div>

              </div>

              <div className="Tour_info_line lang_p">
                <img src={LangIcon} alt="LangIcon" />
                <p>{t("Languages",{ns:"tr"})}:</p>
                <div className="lang_list_block">
                  <span>{langs}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {tour_interests?.length!==0 &&
        <div className="TP_Interests">
          <div className="TP_block_header">
            <p><b>{t("TripInterests",{ns:"tr"})}:</b></p>
          </div>

          <div className="TP_Interests_box">
            {tour_interests && tour_interests.map((int) => (
              <Interest
              key={int._id}
              title={t(int.code, { ns: "int" })}
              icon={int.icon}
              pointer={false}
            />
              ))}
          </div>
        </div>}

        {(joinedUsers?.length>0)&&
          <div className="TP_overview_users">
            <div className="TP_block_header TP_users_header">
            {t("AlreadyJoined",{ns:"tr"})}:
              <p><b>{joinedUsers?.length+1}</b>/{tour?.amount}</p>
            </div>
            <div className="TP_users_box ">
              {joinedUsers?.map((user) => (
                  <div key={user._id}>
                    <User user={user} />
                  </div>
                ))}
            </div>
          </div>
        }
        {(tour?.tourMap?.length>0) &&
        <div className="TP_overview_map">
          <div className="TP_block_header">
          {t("TravelMap",{ns:"tr"})}:
            <span>{t("seeMoreDetails",{ns:"tr"})}</span>
          </div>
          <img src={tour?.tourMap[0]} alt="Map" />

          {/* <video
          controls
          // autoPlay
          muted
          name="Video Map">
            <source src={tour?.video_path} />
          </video> */}
        </div>}

        {(tour?.tickets?.length>0)&&
        <div className="TP_overview_tickets">
          <div className="TP_block_header">
          {t("Tickets",{ns:"tr"})}:
            <span>{t("seeAllTickets",{ns:"tr"})}</span>
          </div>
        </div>
        }
        {(tour?.hotels?.length>0)&&
        <div className="TP_overview_hotels">
          <div className="TP_block_header">
          {t("Hotels",{ns:"tr"})}:
            <span>{t("seeAllHotels",{ns:"tr"})}</span>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default Overview;
