import date_icon from '../../assets/create_account/calendar.svg'

import TooltipPurp from "../Tooltip/TooltipPurp";
import "./InputField.scss";

const SearchDaysAmnt = ({ title, upTo, icon, value,anyDates, setAnyDates, tooltip, cb_title,cb_tooltip,...otherProps }) => {

    let img;
    if(icon==="date") {img = date_icon};
    if(icon==="daysAmount") {img = date_icon};

    return (
    <div className="InputField_container">
      <div className="IF_title_line">
        <div className="IF_title_sign">
          <img src={img} alt="" />
          <p>{title}</p>
        </div>
        {tooltip && <TooltipPurp text={tooltip}/> }
        </div>

      <div className="IF_amountLine">
        {upTo&&<p>{upTo}</p>}
        <input className='IF_amount'
        disabled={anyDates}
        value={value}
        {...otherProps} />
      </div>

      <div className="SDA_Dates_checkbox">
          <label className="SDA_checkbox_label">  {cb_title}
          <input
            type="checkbox"
            name="noStartDate"
            checked={anyDates}
            value={anyDates}
            onChange={()=>setAnyDates(!anyDates)}
          />
          <span className="SDA_checkmark"></span>
        </label>

        <TooltipPurp text={cb_tooltip} />
        </div>
  
    </div>
  );
};

export default SearchDaysAmnt;
