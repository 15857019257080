import ReactQuill from "react-quill";
import * as Emoji from "quill-emoji"; //-- не удалять !
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import TooltipPurp from "../../components/Tooltip/TooltipPurp";
import { useTranslation } from 'react-i18next';

import CheckBox from "../../components/CheckBox/CheckBox";

import "./EditTourPages.scss";

const EditTourPage3 = ({ formik }) => {
  const {t} = useTranslation(['tr']);
 

  var toolbarOptions = [
    [{ font: [] }],
    ["emoji"],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    // [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    // ['blockquote', 'code-block'],
    // ['clean']
  ];

  // console.log(formik.values);

  return (
    <div className="EditTour_wrapper">
      <div className="ET_content_box">
        <div className="ET_title_area">
        <p>{t("TourControl",{ns:"tr"})}</p>
          <h3>{t("TourControl_text",{ns:"tr"})}</h3>
        </div>

        <div className="ET_control_area">

          <CheckBox
            title={t("invisibleTour",{ns:"tr"})}
            subtitle={t("invisibleTour_st",{ns:"tr"})}
            tooltip={t("invisibleTour_tt",{ns:"tr"})}
            formik={formik}
            color="purp"
            disabled
            checked={formik.values.invisibleTour}
            name="invisibleTour"
          />

          <CheckBox
            title={t("regularTour",{ns:"tr"})}
            subtitle={t("regularTour_st",{ns:"tr"})}
            tooltip={t("regularTour_tt",{ns:"tr"})}
            formik={formik}
            color="purp"
            checked={formik.values.regularTour}
            disabled
            name="regularTour"
          />
        </div>

        <div className="ET_title_area ET_title_area_no_underline">
          <div className="ET_Title_with_tooltip">
            <p>{t("TravelPlan",{ns:"tr"})}</p>
            <TooltipPurp text={t("TravelPlan_tt",{ns:"tr"})}/>
          </div>
          <h3>{t("TravelPlan_descr",{ns:"tr"})} </h3>
        </div>


        <ReactQuill
          className="ET_Editor"
          theme="snow"
          placeholder={t("TravelPlan_descr",{ns:"tr"})}
          value={formik.values.tourPlan}
          onChange={(e) => formik.setFieldValue('tourPlan', e)}
          modules={{
            toolbar: toolbarOptions,
            "emoji-toolbar": true,
            "emoji-shortname": true,
          }}
        />

      </div>
    </div>
  );
};

export default EditTourPage3;
