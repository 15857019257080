import BackBtnIcon from '../../assets/back.svg'

import './BackBtn.scss'

const BackBtnEmpty = () => {
  return (
    <div className="BackBtnEmpty">
    <img src={BackBtnIcon} alt="share" />
</div>
  )
}

export default BackBtnEmpty