import { useContext, useState } from "react";
import { UserContext } from "../../context/user.context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMatchMedia } from "../../hooks/use-match-media";

import i18next from "i18next";

import UserXSml from "../User/UserXSml";
import NewsFavBtn from "../Buttons/NewsFavBtn";
import NewsAnswerBtn from "../Buttons/NewsAnswerBtn";
import NewsFooter from "./NewsFooter";
import MsgBtn from "../Buttons/MsgBtn";

import CreatedPostBlock from "./Blocks/CreatedPostBlock";
import TourBlock from "./Blocks/TourBlock";
import MainBtn from "../Buttons/MainBtn";

import TimeStatusText from "../Chips/TimeStatusText";
import { showDate } from "../../utils/showDate";
import UserTourPhotoBlock from "./Blocks/UserTourPhotoBlock";


import "./news.scss";
const News = ({ news }) => {
  const { jwtUser } = useContext(UserContext);

  const [comments, setComments] = useState(news?.comments);
  const [focus, setFocus] = useState(false);

  const {fName, lName, username} = news?.fromUser;

  const navigate = useNavigate();
  const { mobileBig  } = useMatchMedia();

  const { t } = useTranslation(["tr", "countries"]);

  const lang = i18next.language;
  const newsDate = new Date(news?.createdAt).toLocaleString(lang, {day: "numeric",month: "short",});

  const creatorId = news?.post?.creator;

  const navigateTo = (username) => {
    navigate(`/user/${username}/`);
  };

  let action;
  let content;
  let msgBtn;
  let status;
  let userTitle;

  if (news?.action === "createdPost") {
    action = <p>{t("createdAdvert", { ns: "tr" })}:</p>;
    content = <CreatedPostBlock post={news?.post}/>;
    status = <TimeStatusText item={news?.post}/>
    msgBtn = <MsgBtn userId={news?.post?.creator} />;

  }

  if (news?.action === "createdTour") {
    if(news?.tour?._id){
      const {isFinished} = showDate(news?.tour)

      action = <p>{t("createdTour", { ns: "tr" })}:</p>;
      content = <TourBlock tour={news?.tour}/>;
      status = <TimeStatusText item={news?.tour}/>;
      if(!isFinished){
          msgBtn = <MainBtn color="tort" title={t("Join",{ns:"tr"})} onClick={()=>navigate(`/tour/${news?.tour?._id}/join`)} />
      }
    }else{
      return
    }
  }

  if (news?.action === "joinedTour") {
    if(news?.tour?._id){
      const {isFinished} = showDate(news?.tour)
      action = <p>{t("joinedTour", { ns: "tr" })}:</p>;
      content = <TourBlock noText tour={news?.tour}/>;
      status = <TimeStatusText item={news?.tour}/>;
      if(!isFinished){
        msgBtn = <MainBtn color="tort" title={t("Join",{ns:"tr"})} onClick={()=>navigate(`/tour/${news?.tour?._id}/join`)} />
      }
    }else{
      return
    }
  }

  if (news?.action === "followedTour") {
    // если тур новости существует?...
    if(news?.tour?._id){
      const {isFinished} = showDate(news?.tour)
      action = <p>{t("followedTour", { ns: "tr" })}:</p>;
      content = <TourBlock noText tour={news?.tour}/>;
      status = <TimeStatusText item={news?.tour}/>
      if(!isFinished){
        msgBtn = <MainBtn color="tort" title={t("Join",{ns:"tr"})} onClick={()=>navigate(`/tour/${news?.tour?._id}/join`)} />
      }
    }else{
      return
    }
  }

  if (news?.action === "addedPhotosfromTour") {
    // если тур новости существует?...
    if(news?.tour?._id){
      action = <p>{t("addedPhotosfromTour", { ns: "tr" })}:</p>;
      content = <UserTourPhotoBlock tour={news?.tour} photoArr={news?.files}/>;
      msgBtn = <MainBtn title={t("seeAllPhotos", { ns: "tr" })} color="blueVK" onClick={()=>navigate(`/user/${news?.fromUser?.username}/mygallery`)}/>
    }else{
      return
    }
  }

  if (news?.action === "addedPhotos2Account") {
      action = <p>{t("addedPhotos2Account", { ns: "tr" })}:</p>;
      content = <UserTourPhotoBlock photoArr={news?.files}/>;
      msgBtn = <MainBtn title={t("seeAllPhotos", { ns: "tr" })} color="blueVK" onClick={()=>navigate(`/user/${news?.fromUser?.username}/mygallery`)}/>
  }

  if (news?.action === "orgAddedPhotos2Tour") {
      action = <p>{t("orgAddedPhotos2Tour", { ns: "tr" })}:</p>;
      content = <UserTourPhotoBlock tour={news?.tour} photoArr={news?.files}/>;
      msgBtn = <MainBtn title={t("seeAllPhotos", { ns: "tr" })} color="blueVK" onClick={()=>navigate(`/tour/${news?.tour?._id}/gallery`)}/>
      userTitle = t("tourOrg", { ns: "tr" });
  }

  return (
    <div className="News_Wrapper">

      <div className="N_TopLine">
        <UserXSml user={news?.fromUser} noName />

        <div className="N_infoBox">
          <div className="N_titleDateLine">
            <div className="N_namePart">
              <h3 onClick={() => navigateTo(username)}>{userTitle} {fName} {lName}</h3>
              {action}
            </div>
            <span>{newsDate}</span>
          </div>
        </div>

      </div>

      <div className="N_MidLine">
        {content}
      </div>

      {mobileBig && status}

      <div className="N_ActionLine">
        <div className="N_BtnsBox">
          <NewsFavBtn news={news} jwtUser={jwtUser}/>
          <NewsAnswerBtn msgAmnt={comments?.length} onClick={()=>setFocus(true)}/>
        </div>

        {!mobileBig && status}

        {msgBtn}
      </div>



      <div className="N_BtmLine">
        <NewsFooter
          jwtUser={jwtUser}
          newsId={news._id}
          focus={focus}
          setFocus={setFocus}
          comments={comments}
          setComments={setComments}
          creatorId={creatorId}
        />
      </div>
    </div>
  );
};

export default News;
