import { useTranslation } from "react-i18next";
import notFoundImg from '../../assets/images/notFound.png'

import "./NoMatchPage.scss";

const NoMatchPage = () => {
  const { t } = useTranslation(["tr"]);

  return (
    <div className="NoMatch_wrapper">
      <img src={notFoundImg} alt="" />
      <p>{t("PageNotFound", { ns: "tr" })}</p>
      <h1>404</h1>
    </div>
  );
};

export default NoMatchPage;
