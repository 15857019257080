import { useContext, useEffect, useState } from "react";
import * as api from '../../utils/api/api.utils'
import { UserContext } from '../../context/user.context';

import Spinner from "../../components/Spinner/Spinner";
import VUser from "../../components/User/VUser";

import './FeedBackPage.scss'
import { handleError } from "../../utils/handleError";
const VerifyUserPage = () => {
  const { jwtUser} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState();

  useEffect(() => {
    api.getVerifyingUsers(jwtUser).then(res=> {
      if(res?.status===200){
        setUsers(res.data)
      }else{
        handleError(res)
      }
    })
    setLoading(false)
  }, []);

  if(loading){
    return <Spinner/>
  }

    return (
      <div className="VerifyUserPage_wrapper">

      <div className="VUP_container">
      {users && users.map((user) => (
            <div key={user._id}>
              <VUser user={user} />
            </div>
          ))}

      </div>
    </div>
  )
}

export default VerifyUserPage