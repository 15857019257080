import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../context/user.context";
import * as api from '../../utils/api/api.utils';

import FeedBack from "../../components/FeedBack/FeedBack";

import "./Test.scss";
import Modal from "../../components/Modal/Modal";
const Test = () => {
  const {jwtUser} = useContext(UserContext);
  const [feedbackOpen, setFeedbackOpen] = useState(false);


  return (
    <div className="test_wrapper">

<div className="Center_Screen">

<button onClick={()=> setFeedbackOpen(true)}>Feedback</button>

</div>

<Modal 
open={feedbackOpen} 
close={() => setFeedbackOpen(false)} 
NoCloseBtn={true}
>
    <FeedBack 
      jwtUser={jwtUser}
      close={()=> {
        document.body.classList.remove("active-modal");
        setFeedbackOpen(false)}}
    />

</Modal>
    </div>
  );
};

export default Test;
