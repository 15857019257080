import { useNavigate } from "react-router-dom";
import { thumb } from "../../utils/thumb";
import { config } from "../../utils/config";

import "./UserSml.scss";
const UserSml = ({ user }) => {
  const navigate = useNavigate();
  const navigateTo = (user) => {
    navigate(`/user/${user.username}/`);
  };
  return (
    <div className="userSml_wrapper" onClick={() => navigateTo(user)}>


      <div className="userSml_imgBG">
        <img src={thumb(user?.photos[0], config.thumb_width)} alt="user" loading="lazy" />
      </div>

      <p>{user?.fName}</p>
    </div>
  );
};

export default UserSml;
