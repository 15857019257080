import { useNavigate } from "react-router-dom";
import * as api from "../../utils/api/api.utils";
import { useTranslation } from "react-i18next";

import { ReactComponent as ProfileIcon } from "../../assets/user_menu/account.svg";
import { ReactComponent as SettingsIcon } from "../../assets/user_menu/settings.svg";
import { ReactComponent as DollarIcon } from "../../assets/user_menu/dollar.svg";
import { ReactComponent as LogoutIcon } from "../../assets/user_menu/logout.svg";
import { ReactComponent as AdminIcon } from "../../assets/user_menu/admin.svg";
import { ReactComponent as ControlIcon } from "../../assets/user_menu/control_sml.svg";
import { ReactComponent as VerifyIcon } from "../../assets/user_menu/verification.svg";

import { FaRegHeart } from "react-icons/fa";

import Verified from "../../assets/verified.svg";
import { handleError } from "../../utils/handleError";

import "./AccountMenu.scss";

const AccountMenu = ({ loggedUser, setJwtUser, menuOpen, setMenuOpen }) => {
  const { t } = useTranslation(["tr"]);

  const navigate = useNavigate();

  //---- Закрытие на ESC ----
  const escClose = (event) => {
    if (event.key === "Escape") {
      document.removeEventListener("keydown", escClose, false);
      setMenuOpen(false);
    }
  };

  if (menuOpen) {
    document.addEventListener("keydown", escClose, false);
  }

  const logout = () => {
    setMenuOpen(false);

    api.logoutUser().then((res) => {
      if (res?.status === 200) {
        console.log("Deleted refresh token from DB", res);
        localStorage.removeItem("profile");
        setJwtUser(null);
        navigate("/");
      } else {
        handleError(res);
      }
    });
  };

  const toProfile = () => {
    setMenuOpen(false);
    navigate(`/user/${loggedUser.username}`);
  };

  const toBalance = () => {
    setMenuOpen(false);
    navigate(`/balance`);
  };

  const toVerification = () => {
    setMenuOpen(false);
    navigate(`/verification`);
  };

  const toFavorites = () => {
    setMenuOpen(false);
    navigate(`/favorites`);
  };

  const toModerate = () => {
    setMenuOpen(false);
    navigate(`/moderation/verify/`);
  };

  const toControl = () => {
    setMenuOpen(false);
    navigate(`/control/tours/`);
  };

  const toSettings = () => {
    setMenuOpen(false);
    navigate(`/settings`);
  };

  // console.log(menuOpen);

  return (
    <div className={`UserMenu_wrapper ${!menuOpen ? "UM_hide" : null}`}>
      <div className="UM_name_area">
        <div className="UM_name_line">
          {loggedUser?.verified && (
            <div className="Tooltip_wrapper">
              <img
                className="UM_verified_img"
                src={Verified}
                alt="verified user"
              />
              <div
                className="TT_tip"
                dangerouslySetInnerHTML={{
                  __html: t("verifiedUser", { ns: "tr" }),
                }}
              />
            </div>
          )}

          <h3>{loggedUser?.fName}</h3>
        </div>
        <h4>@{loggedUser?.username}</h4>
      </div>

      <div className="UM_menuItem" onClick={toProfile}>
        <ProfileIcon className="UM_menuIcon" />
        <span>{t("myAccount", { ns: "tr" })}</span>
      </div>

      {loggedUser?.isAdmin && (
        <div className="UM_menuItem" onClick={toBalance}>
          <DollarIcon className="UM_menuIcon" />
          <span>
            {t("myBallance", { ns: "tr" })}: $ <b>{loggedUser?.balance}</b>
          </span>
        </div>
      )}

      <div className="UM_menuItem" onClick={toControl}>
        <ControlIcon className="UM_menuIcon" />
        <span>{t("control", { ns: "tr" })}</span>
      </div>

      {!loggedUser?.verified && (
        <div className="UM_menuItem" onClick={toVerification}>
          <VerifyIcon className="UM_menuIcon" />
          <span>{t("verification", { ns: "tr" })}</span>
        </div>
      )}

      <div className="UM_menuItem" onClick={toSettings}>
        <SettingsIcon className="UM_menuIcon" />
        <span>{t("settings", { ns: "tr" })}</span>
      </div>

      <div className="UM_menuItem" onClick={toFavorites}>
        <FaRegHeart size={18} color="#87589C"/>
        <span>{t("menu_favorites", { ns: "translation" })}</span>
      </div>

      {loggedUser?.isAdmin && (
        <div className="UM_menuItem UM_admin" onClick={toModerate}>
          <AdminIcon className="UM_menuIcon" />
          <span>{t("moderation", { ns: "tr" })}</span>
        </div>
      )}

      <div className="UM_menuItem" onClick={logout}>
        <LogoutIcon className="UM_menuIcon" />
        <span>{t("logout", { ns: "tr" })}</span>
      </div>
    </div>
  );
};

export default AccountMenu;
