import help_purp from "../../assets/create_account/help_purp.svg";

import './Tooltip.scss'

const TooltipPurp = ({text}) => {
  return (
    <div className='Tooltip_wrapper'>

        <img className="TT_img" src={help_purp} alt="tooltip" /> 

        <div className="TT_tip" dangerouslySetInnerHTML={{ __html: text }} />
        {/* <div className="TT_tip"> {text} </div> */}

    </div>
  )
}

export default TooltipPurp