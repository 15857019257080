import React, {useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import Picker from "@emoji-mart/react";
import SendBtn from "../Buttons/SendBtn";
import * as api from "../../utils/api/api.utils";
import data from '@emoji-mart/data'

import { thumb } from "../../utils/thumb";
import { config } from "../../utils/config";
import { handleError } from "../../utils/handleError";

import "./news.scss";
import Comment from "./Comment";
const NewsFooter = ({comments, setComments, focus, setFocus, jwtUser, newsId, creatorId}) => {

  const [showEmoji, setShowEmoji] = useState(false);
  const [text, setText] = useState("");
  const [answeredToName, setAnsweredToName] = useState(null);
  const [answeredToId, setAnsweredToId] = useState(null);
  const [showAmnt, setShowAmnt] = useState(1);

  // const myId = jwtUser?.data?.user?._id

  const {t} = useTranslation(['tr']);

  const inputRef = useRef(null);

  const handleFocus = () => {
    inputRef.current.focus();
  };

  const setAnswer =(fName, fromUserId)=>{
    setAnsweredToName(fName);
    setAnsweredToId(fromUserId);
    setText(fName + ", ");
    handleFocus();
  }

  if(focus) handleFocus();

  const handleEmoji=(e)=>{
    let msg = text;
    msg += e.native;
    setText(msg)
  }

  const pressKey = (e) => {
    //metaKey = Command on Mac
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter") sendComment();
  };

const deleteComment=(com)=>{
  const newArr = comments.filter(item=> item!==com)
  setComments(newArr)
}

  const sendComment = ()=>{
    const sendObj = {
      newsId,
      text,
    }
    if(answeredToId){
      sendObj.answeredToId = answeredToId;
      sendObj.answeredToName = answeredToName;
    }
    if(text!==""){
      api.sendComment(sendObj).then(res=>{
        if(res.status===200){
          setComments(res.data);
          setShowAmnt(1000);
          setText("");
        }else{
          handleError(res)
        }
      })
  }
}

  return (
    <div className="News_Footer">

      <div className="NewsCommentsBox">
        {comments && comments.map((com, idx) =>
          idx < showAmnt &&
            <Comment key={com._id}
              idx={idx}
              newsId={newsId}
              setAnswer={setAnswer}
              deleteComment={deleteComment}
              com={com}
              jwtUser={jwtUser}
              creatorId={creatorId}

            />
          )}
      </div>
      {comments?.length>1 &&
        <div className="News_ShowComments">
            {showAmnt===1 &&
              <p onClick={()=>setShowAmnt(1000)}>{t("showAllComments", { ns: "tr" })}</p>
            }
        </div>
      }

      <div className="News_SendCommentLine">
        <div className="NewsUser_imgBG">
          <img src={thumb(jwtUser?.data?.user?.photos[0], config.thumb_width)} alt="user" loading="lazy" />
        </div>

        <textarea
          className="News_textInput"
          ref={inputRef}
          value={text}
          type="text"
          onBlur={()=>setFocus(false)}
          onChange={(e) => {
            setText(e.target.value);
          }}
          onKeyDown={(event) => pressKey(event)}
          placeholder={t("writeComment",{ ns: "tr" })}

        />

        <div className="News_SmileBtn" onClick={(e) => {
            e.stopPropagation();
            setShowEmoji(!showEmoji);
          }}
        >
          &#9786;
        </div>

        {showEmoji &&
          <div className="News_Emoji_box">
            <Picker
              data={data}
              onEmojiSelect={handleEmoji}
              theme="light"
              onClickOutside={() => setShowEmoji(false)}
            />
          </div>
        }

        <div className="MPF_SendBtnArea">
          <SendBtn disabled={!text} onClick={text ? () => sendComment() : () => {}} />
        </div>
      </div>
    </div>
  );
};

export default NewsFooter;
