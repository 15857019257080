import data from '@emoji-mart/data'
import { init } from 'emoji-mart'
import ReactTimeAgo from 'react-time-ago'



import './BigEmoji.scss'
import { thumb } from '../../utils/thumb'
import { config } from '../../utils/config'

const BigEmoji = ({msg, jwtUser, chatUsers}) => {

    const myId = jwtUser?.data?.user?._id;
    const msgUserId = msg?.userId;
    const myMsg = myId===msgUserId;
    const chatUser = chatUsers.find(user => user._id === msgUserId);


    init({ data })

  return (
    <div className={myMsg ? "BigEmoji_wrapper BE_Msg_own" : "BigEmoji_wrapper"}>

        <div className="BE_Msg_top">

          {!myMsg && chatUser?.photos &&
            <img className='BE_user_photo'
            src={thumb(chatUser?.photos[0], config.user_photo_width)}
            alt="" />
          }
          <div className="BE_Emoji_img">
            <em-emoji set="apple" native={msg?.text} size="6em"></em-emoji>
          </div>

        </div>

        {msg?.createdAt ?
          <h5><ReactTimeAgo date={Date.parse(msg?.createdAt)} locale="ru-RU"/></h5>
          :
          null
        }

    </div>
  )
}

export default BigEmoji