import { FaStar } from "react-icons/fa";

import "./Rating.scss";
const RatingSml = ({ rating, size=18, short=false }) => {

  return (
    <div className="ratingSML">
      <FaStar size={size} color="#ffc107" />
      {short?
        <p> {rating} </p>
        :
        <p> {rating}.0 </p>
      }
    </div>
  );
};

export default RatingSml;
