import FinishIcon from '../../assets/join/finish.svg'


import './JoinedChip.scss'

const FinishChip = ({tooltip, title, small, mobile}) => {
  return (

    <div className='Chip_Tooltip_wrapper'>
      
    {!mobile ? 
      <div className={small? "TP_Chip_wrapper Chip_small": "TP_Chip_wrapper"}>
        <img src={FinishIcon} alt="" />
        {!small &&
        <h4>{title}</h4>}
      </div>
      :
      <div className="TP_Chip_mob">
        <img src={FinishIcon} alt="" />
      </div>

    }
      
      <div className="Chip_tip Chiptip_down" dangerouslySetInnerHTML={{ __html: tooltip }} />
      </div>

    )
}

export default FinishChip