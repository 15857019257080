import JoinedIcon from '../../assets/join/confirm.svg'


import './JoinedChip.scss'

const JoinedChip = ({title, tooltip, small=false, mobile=false}) => {
  return (
    <div className='Chip_Tooltip_wrapper'>
    {!mobile ?
    <div className={small? "TP_Chip_wrapper Chip_small": "TP_Chip_wrapper"}>
    <img src={JoinedIcon} alt="" />
    {!small &&
    <p>{title}</p>}
    </div>
    :
    <div className="TP_Chip_mob">
        <img src={JoinedIcon} alt="" />
      </div>
    }

    <div className="Chip_tip" dangerouslySetInnerHTML={{ __html: tooltip }} />

    </div>
    )
}

export default JoinedChip