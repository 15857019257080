export const config = {

//--- Backend Server ---

// URL:'https://dev3.tripico.net',

URL:'https://tripico.net',
// URL:'http://192.168.1.131:4000',

//--- Tour Card ---
    titleCharAmount:50,
    charAmount: 275,
    moreChars: 400,

    thumb_width: 200,
    image_width: 600,
    tourImg_width: 600,

//--UserPage photo ---
    userImg_width: 400,

//--- Upload User Photos config ------
    uploadImage_maxWidth: 1024,
    uploadImage_maxHeight: 1024,
    uploadImage_format: "webp",
    uploadImage_quality: 90,
    uploadImage_maxAmount: 20,

//--- Upload Tour Photos config ------
    uploadTourImage_maxWidth: 1024,
    uploadTourImage_maxHeight: 1024,
    uploadTourImage_format: "webp",
    uploadTourImage_quality: 90,
    uploadTourImage_maxAmount: 10,

//--- Thumb width User photo

    user_photo_width: 200,

//--- Thumb width Logged User photo in header

    loggedUser_photo_width: 200,

}