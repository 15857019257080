
import './PageBtn.scss'

const PageBtn = ({number, title, checked, highlighted, onClick}) => {
  return (
    <div 
   
    className= {checked ? "PageBtn_wrapper PB_clicked" : "PageBtn_wrapper"}
    onClick={onClick}
    >
        <div 
         style={{outline: highlighted ? '3px solid red' : 'none' }}
         
        className="PB_number"> {number}</div>
        <p style={{ color: highlighted ? 'red' : null }} >{title}</p>

    </div>

    )
}

export default PageBtn