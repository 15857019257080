import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../../utils/api/api.utils'
import Spinner from '../../components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

import { interests } from "../../data/interests";

import PageHeader from "../../components/PageHeader/PageHeader";
import Post from "../../components/Post/Post";

import Pagination from '../../components/Pagination/Pagination';
import PostSearchBar from '../../components/SearchBar/PostSearchBar';
import MPost from '../../components/Post/MPost';
import { handleError } from '../../utils/handleError';

import "./PostsPage.scss";
const PostsPage = () => {
  const [posts, setPosts] = useState();
  const [allInterests, setAllInterests] = useState([...interests]);
  // const [allLangs, setAllLangs] = useState([...langs]); //-- All langs

  const [loading, setLoading] = useState(true);

  const { pageNumber } = useParams();
  const pageNum = pageNumber || 1;
  const startShowAmnt = 20

  const [page, setPage] = useState(pageNum);
  const [amount, setAmount] = useState(startShowAmnt);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();

  const [country, setCountry] = useState(null)
  const [gender, setGender] = useState('');
  const [startAge, setStartAge] = useState("");
  const [endAge, setEndAge] = useState("");

  const [startDate, setStartDate] = useState(null);

  const [updated, setUpdated] = useState(null); // Для обновления страницы после удаления поста

  const [search, setSearch] = useState({})
  const [sorting, setSorting] = useState("")

  const navigate = useNavigate();
  const {t} = useTranslation(['tr']);
  const searchDate = new Date(startDate).toISOString()

  useEffect(() => {
    api.getInterests().then((res) => {
      if(res.status===200){
        setAllInterests([...interests, ...res.data])
      }else{
        handleError(res)
      }
    });

    // api.getLangs().then((res) => {
    //   if(res.status===200){
    //     setAllLangs([...langs, ...res.data])
    //   }else{
    //     handleError(res)
    //   }
    // });

  }, []);

  const makeSearch=()=>{
    api.getPosts(page, amount,
      sorting ? sorting: null,
      search.country ? search.country: (country? country?.value: null),
      search.startDate ? search.startDate: (startDate? searchDate: null),
      search.gender ? search.gender: (gender? gender: null),
      search.startAge ? search.startAge: (startAge? startAge: null),
      search.endAge ? search.endAge: (endAge? endAge: null),
      search.depart ? search.depart: null,
      search.city ? search.city: null,
      search.anyAge ? search.anyAge: null,
      search.endDate ? search.endDate: null,
      search.noStartDate ? search.noStartDate: null,
      search.noEndDate ? search.noEndDate: null,
      search.daysAmount ? search.daysAmount: null,
      search.anyDates ? search.anyDates: null,
      search.interests ? search.interests: null,
      search.langs ? search.langs: null,
      search.verified ? search.verified: null,
      search.orientation ? search.orientation: null,
      search.character ? search.character: null,
      search.finances ? search.finances: null,
      ).then((res) => {
        if(res.status===200){
          setPosts(res.data.data)
          setPages(res.data.pages)
          setTotal(res.data.total)
          setLoading(false)
          navigate(`/posts/${page}`)
        }else if(res?.response?.status===403){
          setLoading(false)
          setPage(1)
        }else{
          handleError(res)
        }
  });
  }

  useEffect(() => {
    if(startAge.length===2 || endAge.length===2){
      makeSearch();
    }
  }, [startAge, endAge]);

  useEffect(() => {
    makeSearch();
  }, [page, amount, country, startDate,gender, sorting, search, updated]);

  const resetSearch=()=>{
    // console.log("reset search");
      setSearch({});
      setSorting("");
      setGender("")
      setStartAge("")
      setEndAge("")
      setStartDate(null)
      setCountry(null)
  }

  const sortSearch= (sorting)=>{
    setPage(1)
    setSorting(sorting);
  }

  const filterSearch= (query)=>{
    setPage(1)
    setSearch(query)
  }



  if(loading){
    return <Spinner/>
  }

  return (
    <div className="PostsPage_wrapper">
      <PageHeader
        icon="posts"
        title={t("adverts",{ns:"tr"})}
        // title="Объявления"
        text={t("adv_text",{ns:"tr"})}
        // text="Для быстрого поиска попутчика"
      />

    <PostSearchBar
        country={country}
        setCountry={setCountry}

        gender={gender}
        setGender={setGender}
        startAge={startAge}
        setStartAge={setStartAge}
        endAge={endAge}
        setEndAge={setEndAge}

        startDate={startDate}
        setStartDate={setStartDate}
        query={search}
        sorting={sorting}
        sortSearch={sortSearch}
        filterSearch={filterSearch}
        resetSearch={resetSearch}
      />

    <div className="PostP_PostsBox">
      {posts && posts?.map((post, index) => (

      !post?.creator?.deleted &&
          <div key={index} id={post._id}>

            <div className="PostP_MPost">
              <MPost post={post} />
             </div>

            <div className="PostP_Post">
              <Post
                post={post}
                setUpdated={setUpdated}
                allInterests={allInterests}
              />
             </div>

          </div>
        ))}
    </div>

        <div className="PP_pagination">
        <Pagination
          found={total}
          pages={pages}
          page={page}
          setPage={setPage}
          showAmnt={amount}
          setAmnt={setAmount}
          startShowAmount={startShowAmnt}
          amountStep={10}
        />
      </div>

    </div>
  );
};

export default PostsPage;
