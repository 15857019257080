import { useTranslation } from 'react-i18next';

import TooltipPurp from '../Tooltip/TooltipPurp'
import './AddTicket.scss'

const AddTicket = () => {
  const {t} = useTranslation(['tr']);

  return (
    <div className="AddTicket_wrapper">

      <div className="ADDT_title">
        <div className="ADDT_title_line">
            <p>{t("Tickets",{ns:"tr"})}</p> 
            <TooltipPurp text={t("Tickets_tt",{ns:"tr"})}/>
        </div>
        <span>{t("Tickets_text",{ns:"tr"})}</span>
        <p><b> {t("underDevSection",{ns:"tr"})}</b></p>

      </div>

        <div className="ADDT_btn"> + {t("addTickets",{ns:"tr"})}</div>

    </div>
  )
}

export default AddTicket