import { useNavigate} from "react-router-dom";

import BackBtnIcon from '../../assets/back.svg'

import './BackBtn.scss'

const BackBtn = () => {
  
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(-1)} className="BackBtn">
    <img src={BackBtnIcon} alt="share" />
</div>
  )
}
// onClick={() => navigate('/')}

export default BackBtn