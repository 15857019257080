import { useState } from 'react';
import { useMatchMedia } from "../../hooks/use-match-media";
import { useTranslation } from 'react-i18next';

import searchIcon from '../../assets/searchFilter.svg'
import { IoSearch } from "react-icons/io5";

import closeW from '../../assets/close_white.svg'


import SearchCountry from '../Input/SearchCountry';
import SearchGender from '../Input/SearchGender';
import SearchBarAges from '../Input/SearchBarAges';
import PostSort from '../Sort/PostSort';

import "./SearchBar.scss";
import UserSearchWin from '../Window/UserSearchWin';
import UserSort from '../Sort/UserSort';
import ModalWin from '../Modal/ModalWin';

const UserSearchBar = ({country, setCountry, gender, setGender,
  startDate, sortSearch, filterSearch, search, sorting, resetSearch,
  startAge, setStartAge, endAge, setEndAge}) => {

    const [modalOpen, setModalOpen] = useState(false);
    const {mobile} = useMatchMedia();

    const {t} = useTranslation(['translation']);

  return (
    <div className="search_bar_container Peoples_page">
      <div className="SB_top_line">

      {!mobile&&
        <div className="PSB_country">
            <SearchCountry
              country={country}
              setCountry={setCountry}
              isClearable={false}
              isMulti={false}
            />
        </div>
      }

          <div className="SB_genderAges_Block">
            <div className="SB_Gender">
              <SearchGender
                gender={gender}
                setGender={setGender}
              />
            </div>
            <div className="SB_ages">
              <SearchBarAges
                startAge={startAge}
                setStartAge={setStartAge}
                endAge={endAge}
                setEndAge={setEndAge}
              />
            </div>
          </div>


    <div className="SB_rightSide">
        <div className="PSB_resetBtn">
          {(Object.keys(search).length!==0||sorting||gender||startAge||endAge||startDate||country) &&
          <div className="search_filters SB_reset" onClick={resetSearch}>
            <img src={closeW} alt="" />
          </div>
          }
        </div>


        <div className="search_filters" onClick={()=>setModalOpen(true)}>
          {/* <img src={searchIcon} alt="" /> */}
          <IoSearch size={20} color='#87589C'/>
        </div>

        <UserSort
          search={sortSearch}
          sorting={sorting}
        />
    </div>



        <ModalWin noEsc={false} open={modalOpen} close={() => {
          document.body.classList.remove("active-modal");
          resetSearch();
          setModalOpen(false)
          }}>

        <UserSearchWin
          close={()=> {
              document.body.classList.remove("active-modal");
              setModalOpen(false);
              }}

          search={filterSearch}
        />

        </ModalWin>
        </div>

      {/* <div className="SB_bottom_line SBBL_pp">
        <div className="SB_Gender">
          <SearchGender
            gender={gender}
            setGender={setGender}
          />
        </div>

          <div className="SB_ages">
            <SearchBarAges
              startAge={startAge}
              setStartAge={setStartAge}
              endAge={endAge}
              setEndAge={setEndAge}
            />
          </div>
      </div> */}

        <div className="SB_stateLine">
          {sorting === "whenReady" &&
            <div className="SB_stateLine">
              <p className='SB_anyTime'>{t("4_anyMoment", { ns: "translation" })}</p>
              <p className='SB_thisMonth'>{t("3_thisMonth", { ns: "translation" })}</p>
              <p className='SB_thisYear'>{t("2_thisYear", { ns: "translation" })}</p>
            </div>
          }

          {(sorting === "orgRating" ||  sorting === "orgRating_up") &&
            <div className="SB_stateLine">
              <p>{t("Sorting", { ns: "translation" })}:</p>
              <p>{t("ByOrgRating", { ns: "translation" })}</p>
            </div>
          }

          {(sorting === "userAge" ||  sorting === "userAge_up") &&
            <div className="SB_stateLine">
              <p>{t("Sorting", { ns: "translation" })}:</p>
              <p>{t("ByUserAge", { ns: "translation" })}</p>
            </div>
          }

          {(sorting === "userHeight" ||  sorting === "userHeight_up") &&
            <div className="SB_stateLine">
              <p>{t("Sorting", { ns: "translation" })}:</p>
              <p>{t("ByHeight", { ns: "translation" })}</p>
            </div>
          }
        </div>

    </div>
  );
};

export default UserSearchBar;
