import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from "../../context/user.context";
import * as api from '../../utils/api/api.utils'
import { handleError } from '../../utils/handleError';

import './SendMsgWin.scss'
const SendMsgWin = ({userId, close}) => {
  const { jwtUser, socket } = useContext(UserContext);

  const [text, setText] = useState("");
  const [err, setErr] = useState("");

  const {t} = useTranslation(['tr']);
  const myId = jwtUser?.data?.user?._id

  useEffect(() => {
    if(text!=="") setErr("")
  }, [text]);

  const sendMsg = ()=>{
    if(text!==""){
      setErr("")
      close();
      try {
        socket&& socket?.emit("send_msg", {
          senderId: myId,
          receiverId: userId,
          text: text
        })

        api.sendMsg(userId, text).then(res=>{
          if(res.status===200){
            console.log("Message Sent")
            // jwtUser.data.user.userChats = res.data.user.userChats;
            // setJwtUser(jwtUser)
          }else{
            handleError(res)
          }
        })
      } catch (error) {
        console.log(error);
      }
    }else{
      setErr(t("enterMsgText",{ns:"tr"}))
    }
  }

  return (
    <div className="SendMsg_Window_wrapper">
      <p>{t("SendTextMsg",{ns:"tr"})}</p>

      <textarea
        value={text}
        autoFocus
        onChange={(e)=>setText(e.target.value)}
        className='MW_textInput'
        name="msgText"
      />

      <span>{err}</span>
      <button className="MW_btn" type="button" onClick={sendMsg}>{t("Send",{ns:"tr"})}</button>

</div>
  )
}

export default SendMsgWin