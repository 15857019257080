import { config } from "../../utils/config";
import { useTranslation } from 'react-i18next';


import lamp from '../../assets/images/lightbulb.png'
import problem from '../../assets/images/issue.png'
import cloud from '../../assets/images/cloud.png'

import './FeedBackLine.scss'
const FeedBackLine = ({fb}) => {
  const {t} = useTranslation(['translation', 'countries']);

  return (

    <div className="FBL_wrapper">

        <div className="FBL_img">
        {fb.type==="issue"&& <img src={problem} alt="issue" /> }
        {fb.type==="idea"&& <img src={lamp} alt="idea" /> }
        {fb.type==="other"&& <img src={cloud} alt="other" /> }
        </div>

        <div className="FBL_item">
            <p><b>{new Date(fb.createdAt).toLocaleString('en-us',{year:'numeric'})}</b></p>
            <p>{new Date(fb.createdAt).toLocaleString('en-us',{day:'numeric', month:'long'})}</p>
        </div>

        <div className="FBL_item">
            {/* <p>{fb.username}</p> */}
            <a href={`${config.URL}/user/${fb.username}`} target="_blank" rel="noopener noreferrer">{fb.username}</a>
            <p>{fb.email}</p>
        </div>



        <div className="FBL_item FBL_text">
            <p><b>{fb.text}</b></p>
        </div>

        <div className="FBL_item">
            <p>{t(fb.country, { ns: "countries" })}</p>
            <p>{fb.city}</p>
        </div>

        <div className="FBL_item">
        <b>
        {fb.gender==="male"&& <p>M</p>}
        {fb.gender==="female"&& <p>F</p>}
        </b>
        <p>{fb.lang.toUpperCase()}</p>
        <a href={fb.fromUrl} target="_blank" rel="noopener noreferrer">link</a>
        </div>

    </div>

    )
}

export default FeedBackLine