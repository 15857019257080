import { useContext, useEffect, useState } from 'react'
import { UserContext } from "../../context/user.context";
import { Navigate } from "react-router-dom";

import HeaderTour from '../../components/Chat/HeaderTour';
import HeaderUser from '../../components/Chat/HeaderUser';

import Inboxes from './Inboxes';
import MessageBox from './MessageBox';
import ChatFooter from './ChatFooter';

import './Messenger.scss'
const Messenger = ({inTour, tour}) => {
  const { jwtUser, socket } = useContext(UserContext);

  const [chats, setChats] = useState([]);
  const [headerUser, setHeaderUser] = useState();
  const [headerTour, setHeaderTour] = useState();
  const [messages, setMessages] = useState([]);
  const [chatUsers, setChatUsers] = useState([]);
  const [arrivalMsg, setArrivalMsg] = useState(null);
  const [arrivalRoomMsg, setArrivalRoomMsg] = useState(null);
  const [headerSelected, setHeaderSelected] = useState(false);

  useEffect(() => {
    socket && socket.on("get_msg", msg=>{
      setArrivalMsg({
        userId:msg.senderId,
        text: msg.text,
        files: msg.files,
        createdAt: new Date (Date.now()).toString()
      })
    })

    socket && socket.on("get_room_msg", msg=>{
      setArrivalRoomMsg({
        userId:msg.senderId,
        room:msg.room,
        text: msg.text,
        files: msg.files,
        createdAt: new Date (Date.now()).toString()
      })
    })
  }, [socket, jwtUser])

  useEffect(() => {
    if(chats.some(c=>c.tour)){
      const tours = chats.filter(c=>c?.tour===true)

      tours.forEach(t=>{
        const data = {
          room: t.chatID,
          name: jwtUser?.data?.user?.fName
          }
        socket?.emit("join_room", data)
      })
    }
  }, [chats])


  useEffect(() => {
    //открыто окно чата куда пришло сообщение
    if(arrivalRoomMsg?.room===headerTour?.chatID){
      //отобразить прибывшее сообщение
      setMessages(prev=>[...prev, arrivalRoomMsg])
    }else{
      //Добавляет новое сообщение в уголок юзера, если ты не смотришь в его чат.
      const index = chats.findIndex((c=>c.chatID === arrivalRoomMsg?.room));
      const newChats = [...chats];

      if( index >=0) newChats[index].unreadMsg ++
      setChats(newChats)
    }
  }, [arrivalRoomMsg])

  useEffect(() => {
    //если открыт чат с юзером то добавляется новое сообщение
    if(arrivalMsg?.userId === headerUser?._id){
      setMessages(prev=>[...prev, arrivalMsg])
    }
    else{
      //Добавляет новое сообщение в уголок юзера, если ты не смотришь в его чат.
      const index = chats.findIndex((c=>c?._id===arrivalMsg?.userId));
      const newChats = [...chats];

      if( index >=0) newChats[index].unreadMsg ++
      setChats(newChats)
    }
  }, [arrivalMsg])


  useEffect(() => {
    if(headerTour||headerUser){
      setHeaderSelected(true)
    }else{
      setHeaderSelected(false)
    }
  }, [headerUser, headerTour])

  if(!jwtUser){
    return <Navigate to={`/login`} />
  }

  return (
    <div className="Messenger_wrapper">
      <div className="MP_content_box">
        <div className={`MP_left ${headerSelected ? "MPL_show":null}`}>
          <Inboxes
            chats={chats}
            setChats={setChats}
            tour={tour}
            inTour={inTour}
            setHeaderUser={setHeaderUser}
            setHeaderTour={setHeaderTour}
            setMessages={setMessages}
            setChatUsers={setChatUsers}
          />
        </div>

        <div className={`MP_right ${headerSelected ? "MPR_show":null}`}>
          <div className="MP_Header">
            {headerUser&&
              <HeaderUser
                setHeaderSelected={setHeaderSelected}
                user={headerUser}
                setHeaderUser={setHeaderUser}
                msgLength={messages?.length}
              />
            }

            {headerTour&&
              <HeaderTour
                setHeaderSelected={setHeaderSelected}
                tour={headerTour}
                setHeaderTour={setHeaderTour}
                msgLength={messages?.length}
              />
            }
          </div>

          <MessageBox
            jwtUser={jwtUser}
            messages={messages}
            chatUsers={chatUsers}
            headerUser={headerUser}
            headerTour={headerTour}
          />

          <ChatFooter
            headerUser={headerUser}
            headerTour={headerTour}
            setMessages={setMessages}
            setChatUsers={setChatUsers}
          />

        </div>
      </div>
    </div>
  )
}

export default Messenger