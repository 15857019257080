import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import * as api from '../../utils/api/api.utils'
import { useMatchMedia } from "../../hooks/use-match-media";

import { FaHeart } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa6";
import { handleError } from "../../utils/handleError";

import OutsideClick from "../../utils/OutsideClick";
import LikesMenu from "../Menu/LikesMenu";

import "./FavButton.scss";
const NewsFavBtn = ({news, jwtUser}) => {

  const myId = jwtUser?.data?.user?._id
  const doILike = news?.user_liked_me?.includes(myId);

  const [pressed, setPressed] = useState(doILike);
  const [clickable, setClickable] = useState(true);

  const [likedUsers, setLikedUsers] = useState(news?.user_liked_me);
  const [like, setLike] = useState(news?.user_liked_me?.length);

  const [likesMenuOpen, setLikesMenuOpen] = useState(false);

  const navigate = useNavigate();

  const { isTablet } = useMatchMedia();

  const addUserLike =(arr, el)=>{
    const newArr=[el, ...arr]
    setLikedUsers(newArr);
  }

  const removeUserLike = (arr, el)=>{
    if(Array.isArray(arr)){
      const newArr = arr.filter(item=>item!==el)
      if(newArr?.length===0){
        setLikesMenuOpen(false)
      }
      setLikedUsers(newArr);
    }
  }

  const likeAction = async()=>{
    if(pressed){
    //-- Unlike
      try {
        setLike((like) => like - 1);
        setPressed(false)
        setClickable(false)
        removeUserLike(likedUsers, myId)
        const res = await api.unLikeNews(news?._id)

        if(res.status===200){
          setPressed(false)
          setClickable(true)
        }else{
          setLike((like) => like + 1);
          addUserLike(likedUsers, myId)
          setPressed(true)
          setClickable(true)
          throw res
        }

      } catch (error) {
        handleError(error)
      }
    }else{
    //-- Like
    if(!jwtUser){ navigate('/login') }

      try {
        setLike((like) => like + 1);
        addUserLike(likedUsers, myId)
        setPressed(true);
        setClickable(false)
        const res = await api.likeNews(news?._id)

        if(res.status===200){
          setPressed(true);
          setClickable(true)
        }else{
          setLike((like) => like - 1);
          removeUserLike(likedUsers, myId)
          setPressed(false);
          setClickable(true)
          throw res;
        }

      } catch (error) {
        handleError(error)
      }
    }
  }

  return (
    <OutsideClick doSmth={() => setLikesMenuOpen(false)}>
      <div className="FavBtnNews_wrapperBig">
        <div className="FavBtnNews_wrapper"
          onClick={clickable? likeAction : ()=>{}}
          onMouseEnter={isTablet? ()=>{} : () => {
            if(likedUsers?.length>0) {
                setLikesMenuOpen(true)
              }
            }}
          onMouseLeave={() => {setLikesMenuOpen(false)}}
        >
          {pressed ?
            <FaHeart size={20} color="#A681B6" />
          :
            <FaRegHeart size={20} color="#A681B6" />
          }

          {like > 0 && <span>{like}</span>}

        </div>
          <div className="FavBtnNews_LikesMenu"
            onMouseEnter={isTablet? ()=>{} : () => {
              if(likedUsers?.length>0) {
                setLikesMenuOpen(true)
              }
            }}
            onMouseLeave={() => {setLikesMenuOpen(false)}}
          >
            <LikesMenu
              leftSide
              users={likedUsers}
              menuOpen={likesMenuOpen}
              setMenuOpen={setLikesMenuOpen}
            />
          </div>
      </div>

    </OutsideClick>

  );
};

export default NewsFavBtn;
