import { useRef, useState } from 'react';
import * as api from '../../utils/api/api.utils'
import Resizer from "react-image-file-resizer";
import { config } from "../../utils/config";
import { useTranslation } from 'react-i18next';

import Modal from "../Modal/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Keyboard, Zoom } from "swiper";

import "swiper/scss";
import 'swiper/css/zoom';
import "swiper/scss/navigation";
import { MdFileUpload } from "react-icons/md";
import { FiEdit } from "react-icons/fi";

import saveIcon from '../../assets/save.svg'
import cancelIcon from '../../assets/cancel.svg'
import deleteIcon from '../../assets/close.svg'
import SpinnerSmall from '../Spinner/SpinnerSmall';
import { thumb } from '../../utils/thumb';
import { handleError } from '../../utils/handleError';

import './Gallery.scss'
const EditTourGallery = ({ tour, setTour }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [galleryPhotos, setGalleryPhotos] = useState(tour?.tourGallery);
  const [photos, setPhotos] = useState(tour?.tourGallery); // нужен чтобы можно было сделать Cancel
  // const [showInTour, setShowInTour] = useState(true);


  SwiperCore.use([Keyboard]);
  const {t} = useTranslation(['tr']);

  //--- Image File Resizer ----------
  const resizeFile = (file) =>
      new Promise((resolve) => {
      Resizer.imageFileResizer( file,
        config.uploadImage_maxWidth,
        config.uploadImage_maxHeight,
        config.uploadImage_format,
        config.uploadImage_quality,
         0, (uri) => {
          resolve(uri)}, "file" )
        })
  //----------------

  //---- Drag n Drop Sorting (очень круто) --------
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleDragSort = (e) => {
    if(editMode){
      let _selectedFiles = [...photos];
      //сюда сохраняем елемент который подняли
      const draggedImage = _selectedFiles.splice(dragItem.current, 1)[0];
      // тут вставляем поднятый элемент в место над которым висим
      _selectedFiles.splice(dragOverItem.current, 0, draggedImage);
      // сбрасываем значения чтобы каждый раз они были пустые
      dragItem.current = null;
      dragOverItem.current = null;
      setPhotos(_selectedFiles);
      setGalleryPhotos(_selectedFiles)
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.outline = "4px solid #A681B6";
  };

  const handleDragLeave = (e) => {
    e.target.style.outline = "none";
  };

  const handleDrop = (e) => {
    e.target.style.outline = "none";
  };

  //--------------------------------------

  const hiddenFileInput = useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleUpload = async (e) => {
    let resizedFiles=[]

    if(e.target.files.length>0){
      setLoading(true)

      for (let i=0; i<e.target.files.length; i++) {
        resizeFile(e.target.files[i]).then((resizedImage)=>{
          resizedFiles.push(resizedImage)

          if(resizedFiles.length===e.target.files.length){

            const formData = new FormData();
            formData.append("tourId", tour?._id);

            for (let i=0; i<resizedFiles.length; i++) {
              formData.append("photos", resizedFiles[i])
            }

            api.uploadTourGalleryPhotos(formData).then(res=> {
              if(res.status===200){
                setLoading(false)
                const tourPhotos = res?.data?.tourGallery

                setGalleryPhotos(tourPhotos)
                setPhotos(tourPhotos)
              }else{
                handleError(res)
              }
            })
          }
        })
      }
    }
  };

    const handleModal = (index) => {
        setModalOpen(true);
        setPhotoIndex(index);
      };

      const handleEdit=()=>{
        setEditMode(!editMode)
      }

      const handleDelete = (_, index) => {
        const afterDelete = photos.filter((_, i) => i !== index);
        setPhotos(afterDelete);
        setGalleryPhotos(afterDelete?.slice(0,1000))

      };

      const handleCancel=()=>{
        setPhotos(tour?.tourGallery);
        setGalleryPhotos(tour?.tourGallery)
        setEditMode(!editMode)
      }

      const handleSave=()=>{
        setLoading(true)

         const Data = {
          tourId: tour?._id,
          photos: photos
        }

        api.saveGalleryPhotos(Data).then(res=> {
          if(res.status===200){
            tour.tourGallery = photos;
            setTour(tour)
            setLoading(false)
            setEditMode(!editMode)
          }else{
            handleError(res)
          }
        })

      }

  return (

    <div className="ManagePhotos_wrapper">

      <div className="Gallery_Title_edit">

          <div className="Gallery_title">
            <p><b>{t("afterTripPhotos",{ns:"tr"})}:</b></p>
          </div>

        <div className="Gallery_Btn_Line">
          {!editMode && <>
              {loading ? <SpinnerSmall/> :
              <button className="MP_btn" onClick={handleClick} disabled={loading} >
                  <MdFileUpload size={20} color="#87589C" />
                  <h4>{t("Upload",{ns:"tr"})}</h4>
              </button>
              }
              {galleryPhotos?.length >0 &&
                <button className="MP_btn" onClick={handleEdit} disabled={loading} >
                    <FiEdit size={20} color="#87589C" />
                    <h4>{t("Edit",{ns:"tr"})}</h4>
                </button>
              }
              </> }

            {editMode &&
              <>
                {loading ?
                  <SpinnerSmall/>
                :
                <button className="MP_btn" onClick={handleSave} disabled={loading} >
                    <img src={saveIcon} alt="" />
                    <h4>{t("Save",{ns:"tr"})}</h4>
                </button>
                }

                <button className="MP_btn" onClick={handleCancel} disabled={loading} >
                    <img src={cancelIcon} alt="" />
                    <h4>{t("Cancel",{ns:"tr"})}</h4>
                </button>
              </>
            }
        </div>
      </div>


      {galleryPhotos?.length===0 ?
        <div className="Gallery_empty"> {t("emptyHere",{ns:"tr"})} </div>
      :
        <div className="Gallery_photo_container">

            {galleryPhotos && galleryPhotos?.map((photo, index) => (
              <div  className="G_box"
                key={index}
                draggable
                onDragStart={(e) => (dragItem.current = index)}
                onDragEnter={(e) => (dragOverItem.current = index)}
                onDragEnd={(e) => handleDragSort(e)}
                onDragOver={(e) => handleDragOver(e)}
                onDragLeave={(e) => handleDragLeave(e)}
                onDrop={(e) => handleDrop(e)}
              >
                <img className="Gallery_photo" onClick={() => handleModal(index)}
                src={thumb(photo, config.thumb_width)} alt="user" loading="lazy"/>
              {editMode&&
                <div className="GP_deleteBtn" onClick={(f)=>handleDelete(f, index)}>
                  <img className='GP_cross' src={deleteIcon} alt="" />
                </div>
              }
              </div>
            ))}

        </div>
      }

      <input
        type="file"
        ref={hiddenFileInput}
        multiple
        onChange={handleUpload}
        style={{display: 'none'}}
      />

      <Modal open={modalOpen} close={() => setModalOpen(false)}>
        <div className="ModalPhoto">

          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            modules={[Navigation, Zoom]}
            zoom={true}
            initialSlide={photoIndex}
            keyboard={{ enabled: true }}
            loop
            navigation
            style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
              }}
            >
              {photos && photos?.map((pic, index) => (
                <div key={index} className="slider_img_box">
                  <SwiperSlide>
                    <div style={{backgroundImage: `url("${thumb(pic, config.thumb_width)}")`}}
                        className="lowResImgView swiper-zoom-container" >
                      <img className="highResImgView" src={pic}  alt="" />
                      <div class="swiper-lazy-preloader"></div>
                    </div>
                  </SwiperSlide>
                </div>
              ))}

          </Swiper>
        </div>

      </Modal>




    </div>

  )
}

export default EditTourGallery