import { useTranslation } from 'react-i18next';

import infoPic from "../../assets/info_window/warning.jpg";
import "./ConfirmWin.scss";

const ConfirmWin = ({ close, doSmth, title, info }) => {
  const {t} = useTranslation(['tr']);

  return (
    <div className="Confirm_Window_wrapper">
      <div className="CW_contatiner">
        <div className="CW_pic_area">
          <img src={infoPic} alt="assistant" />
        </div>

        <div className="CW_info_area">
          <p>{title}</p>
          <div
            className="CW_info_text"
            dangerouslySetInnerHTML={{ __html: info }}
          />
        </div>
      </div>
      <div className="CW_Btn_line">
      <div className="CW_btn CW_NO_btn" onClick={close}> 
      {t("Cancel",{ns:"tr"})} </div>
      <div className="CW_btn" onClick={doSmth}>
      {t("Yes",{ns:"tr"})} </div>
      </div>
    </div>
  );
};

export default ConfirmWin;
