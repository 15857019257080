import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import date from '../../assets/sortMenu/date.svg'
import amount from '../../assets/sortMenu/amount.svg'
import age from '../../assets/sortMenu/age.svg'
import finish from '../../assets/sortMenu/finish.svg'
import started from '../../assets/sortMenu/started.svg'
import rating from '../../assets/sortMenu/rating.svg'
import { FaSort } from "react-icons/fa";

import './TourSort.scss'
import OutsideClick from '../../utils/OutsideClick';

const PostSort = ({sorting, search}) => {
  const [sortMenuOpen, setSortMenuOpen] = useState(false);

  const {t} = useTranslation(['translation']);

  //-- Закрытие на ESC клавишу --
  const onClose = () => {
    document.removeEventListener("keydown", escClose, false);
    setSortMenuOpen(!sortMenuOpen);
  };
  const escClose = (event) => {
    if (event.key === "Escape") onClose();
  };
  if (sortMenuOpen) {
    document.addEventListener("keydown", escClose, false);
  }
  //------------------------------

  return (
    <div className="TourSort_wrapper">
          <OutsideClick doSmth={() => setSortMenuOpen(false)}>
        <div className="TS_sortBtn" onClick={() => setSortMenuOpen(!sortMenuOpen)} >
            <FaSort size={20} color='#87589C'/>
      </div>

      {sortMenuOpen && (

            <div className="TS_menu">

        <div className="TS_item" >
            <div className={sorting==="orgRating" ? "TS_item_up TS_act":"TS_item_up"}
                    onClick={() => {
                    search("orgRating")
                    setSortMenuOpen(false)
                    }}>
                        <img src={rating} alt="" />
                        <p>{t("sortByOrgRating", { ns: "translation" })}</p>
                    </div>
            <div className={sorting==="orgRating_up" ? "TS_item_down TS_act":"TS_item_down"}
                    onClick={() => {
                    search("orgRating_up")
                    setSortMenuOpen(false)
                    }}>  &uarr; </div>
        </div>

        <div className="TS_item">
            <div className={sorting==="startDate" ? "TS_item_up TS_act":"TS_item_up"}
                    onClick={() => {
                    search("startDate")
                    setSortMenuOpen(false)
                    }}>
                        <img src={date} alt="" />
                        <p>{t("sortByStartDate_post", { ns: "translation" })}</p>
                    </div>
            <div className={sorting==="startDate_up" ? "TS_item_down TS_act":"TS_item_down"}
                    onClick={() => {
                    search("startDate_up")
                    setSortMenuOpen(false)
                    }}>  &uarr; </div>
        </div>

        <div className="TS_item">
            <div className={sorting==="daysAmount" ? "TS_item_up TS_act":"TS_item_up"}
                    onClick={() => {
                    search("daysAmount")
                    setSortMenuOpen(false)
                    }}>
                        <img src={amount} alt="" />
                        <p>{t("sortByDaysAmount", { ns: "translation" })}</p>
                    </div>
            <div className={sorting==="daysAmount_up" ? "TS_item_down TS_act":"TS_item_down"}
                    onClick={() => {
                    search("daysAmount_up")
                    setSortMenuOpen(false)
                    }}>  &uarr; </div>
        </div>

        <div className="TS_item">
            <div className={sorting==="orgAge" ? "TS_item_up TS_act":"TS_item_up"}
                    onClick={() => {
                    search("orgAge")
                    setSortMenuOpen(false)
                    }}>
                        <img src={age} alt="" />
                        <p>{t("sortByOrgAge", { ns: "translation" })}</p>
                    </div>
            <div className={sorting==="orgAge_up" ? "TS_item_down TS_act":"TS_item_down"}
                    onClick={() => {
                    search("orgAge_up")
                    setSortMenuOpen(false)
                    }}>  &uarr; </div>
        </div>

        <div className={sorting==="startedTours" ? "TS_item TS_act":"TS_item"}
                onClick={() => {
                    search("startedTours")
                    setSortMenuOpen(false)
                    }}>
                    <div className="TS_item_up">
                        <img src={started} alt="" />
                        <p>{t("sortByStarted_post", { ns: "translation" })}</p>
                    </div>
        </div>

        <div className={sorting==="finishedTours" ? "TS_item TS_act":"TS_item"}
                onClick={() => {
                    search("finishedTours")
                    setSortMenuOpen(false)
                    }}>
                    <div className="TS_item_up">
                    <img src={finish} alt="" />
                    <p>{t("sortByFinished_post", { ns: "translation" })}</p>
                    </div>
        </div>

            </div>

        )}
          </OutsideClick>

    </div>
  )
}

export default PostSort