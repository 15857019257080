import { useTranslation } from 'react-i18next';

import { age } from '../../utils/age'
import { config } from '../../utils/config'
import { thumb } from '../../utils/thumb'
import './MsgUser.scss'

const InboxUser = ({user, active, online, org}) => {

    const {t} = useTranslation(['translation', 'countries']);

  return (

    <div className={active? "MsgUser_Wrapper MsgUser_active": "MsgUser_Wrapper" }>

        <div className="MU_PicArea">
            {online&&<div className="MU_user_online"></div> }
            <img
            src={thumb(user?.photos?.[0], config.user_photo_width)}
            alt="" />
        </div>
        <div className="MU_InfoArea" >
            <p>{user?.fName}, {age(user?.birthDate)}</p>

            {org ? <h5>Организатор</h5>
            :
            <span>{t(user?.country, { ns: "countries" })}, {user?.city}</span>
            }

        </div>
        {user?.unreadMsg >0 &&
        <div className="MU_unreadMsg">{user?.unreadMsg}</div>
        }
    </div>

    )
}

export default InboxUser