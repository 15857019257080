import { useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import * as api from '../../utils/api/api.utils'

import InputField from "../../components/Input/InputField";
import PageHeader from "../../components/PageHeader/PageHeader";

import "./ForgotPassPage.scss";
import { handleError } from "../../utils/handleError";
import MainBtn from "../../components/Buttons/MainBtn";

const ForgotPassPage = () => {
   const [counter, setCounter] = useState(0);
   const [serverError, setServerError] = useState();
   const [info, setInfo] = useState("");
   const [email, setEmail] = useState("");
   const [loading, setLoading] = useState(false);

   useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if(counter===0) {
        setServerError("")
        setInfo("")
    }
  }, [counter]);

  useEffect(() => {
    if(email.length===0){
      setLoading(true)
    }else{
        setLoading(false)
      }
}, [email]);

   const {t} = useTranslation(['translation']);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setServerError(null)
    setLoading(true)
    try {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (email.match(validRegex)) {

        const res = await api.resetPassword({email})

        if(res?.status===200){
          setCounter(60)
          setInfo(res?.data?.message)
          setEmail("")
          setLoading(false)
        }else if(res?.response?.status===403){
          setCounter(60)
          setServerError(t("UserNotFound",{ns:"tr"}))
          // setServerError(data?.response?.data?.message)
          setLoading(false)
        }else{
          throw res
        }

      }else{
        setServerError(t("EnterValidEmail",{ns:"tr"}))
      }

    } catch (error) {
      handleError(error);
      setServerError(error.response.data.message)
    }

  }


  return (
    <div className="FP_wrapper">
       <PageHeader
        icon="info"
        title={t("resetPassword",{ns:"tr"})}
        text={t("resetPassword_text",{ns:"tr"})}
      />

    <div className="FP_content_box">
        <form  onSubmit={handleSubmit}>
        <div className="FP_mid_content_box">

        <p className="FP_title">{t("resetPassword",{ns:"tr"})}</p>
        <span>{t("resetPassword_text2",{ns:"tr"})}</span>
        <span>{t("resetPassword_text3",{ns:"tr"})}</span>

        <div className="FP_inputDiv">
          <InputField
            title={t("Email",{ns:"translation"})}
            icon="email"
            type="email"
            placeholder={t("yourEmail",{ns:"translation"})}
            name="email"
            value={email}
            disabled={counter!==0}
            onChange={(e)=>setEmail(e.target.value)}
          />
        </div>

<div className="FP_error_line">
  {info&& <h5><b>{info}</b> {t("nextTryAfter",{ns:"tr"})}{counter} {t("sec",{ns:"tr"})} </h5>}
  {serverError&& <h6><b>{serverError}</b> {t("nextTryAfter",{ns:"tr"})}{counter} {t("sec",{ns:"tr"})}</h6>}
</div>

    <MainBtn
      color='purp'
      type="submit"
      title={t("resetPassword",{ns:"tr"})}
      disabled={loading || counter!==0}
      onClick={()=>{}}
    />

      </div>
    </form>
      </div>
    </div>
  );
};

export default ForgotPassPage;
