import { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext} from "react-router-dom";
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';

import * as api from '../../utils/api/api.utils'

import Spinner from "../../components/Spinner/Spinner";

import TourReview from "../../components/Review/TourReview";
import OrgReview from "../../components/Review/OrgReview";
import UserReview from "../../components/Review/UserReview";

import './MakeReviews.scss'
import { handleError } from "../../utils/handleError";

const MakeReviews = () => {
  const { jwtUser} = useContext(UserContext);
  const {tour, setTour} = useOutletContext();

  const [loading, setLoading] = useState(true);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [org, setOrg] = useState();
  const [orgReview, setOrgReview] = useState();
  const [tourReview, setTourReview] = useState({tourID: tour?._id, rating: 5});
  const [joinedUsers, setJoinedUsers] = useState();
  const [usersReviews, setUsersReviews] = useState([]);

    useEffect(() => {
    api.getUserByUsername(tour?.creator?.username).then(res=> {
      if(res?.status===200){
        setOrg(res.data)
        setOrgReview({orgID: res?.data?._id, rating: 5})
        setLoading(false)
      }else{
        handleError(res)
      }
    })

    api.findUsersByIdArray(tour?.joined_users).then(res=> {
      if(res?.status===200){
        setJoinedUsers(res.data)
      }else{
        handleError(res)
      }
    })
      setLoading(false)
  }, []);

  useEffect(() => {
    const usersWithoutMe = joinedUsers?.filter(user => user?._id!==myId)
    const reviews = usersWithoutMe?.map((user) => {
      return {user: user, mark:"good", text:""}
      })
    setUsersReviews(reviews)
  }, [joinedUsers]);

  const myId = jwtUser?.data?.user?._id
  const navigate = useNavigate();
  const {t} = useTranslation(['tr']);

if(loading){
  return <Spinner/>
}

const handleSubmit = async(e)=>{
  e.preventDefault();

    const formData = new FormData();
    formData.append("tourReview", JSON.stringify(tourReview));
    formData.append("orgReview",  JSON.stringify(orgReview));
    formData.append("userID", myId);

    for (let i=0; i<usersReviews.length; i++) {
      const userReview = {toUserID:usersReviews[i].user._id, mark: usersReviews[i].mark, text: usersReviews[i].text }
      formData.append("userReview",  JSON.stringify(userReview));
    }

    try {
      const res = await api.createReviews(tour?._id, formData)

      if(res.status===200){
        setTour(res.data)
        navigate(`/tour/${tour?._id}/reviews`)
      }else{
        throw res
      }
  } catch (error) {
    handleError(error)
  }
}

  return (
    <div className="TourReviews_container">

      <div className="TRP_title_area">
        <p>{t("makeReview",{ns:"tr"})}</p>
        <span>{t("makeReview_descr",{ns:"tr"})}.</span>
        <h4>{t("makeReview_text",{ns:"tr"})}.</h4>
      </div>

      <form  onSubmit={handleSubmit}>


    <TourReview
      tourReview={tourReview}
      setTourReview={setTourReview}
    />

  {tour?.creator?._id!==myId &&
    <OrgReview
      org={org}
      orgReview={orgReview}
      setOrgReview={setOrgReview}
    />
  }

<div className="TRP_users_block">

<h3>{t("makePPLReview",{ns:"tr"})}</h3>

    {usersReviews && usersReviews.map((review, index)=>(
      <UserReview
        key={index}
        review={review}
        index={index}
        usersReviews={usersReviews}
        setUsersReviews={setUsersReviews}
      />
    ))
    }
    </div>

    <div className="TRP_Send_line">

    <h4>{t("thanxForReview",{ns:"tr"})}!</h4>
      <button
      type="submit"
      // disabled={isSubmitting}
      className="TRP_SendBTN"> {t("Send",{ns:"tr"})}
      </button>

    </div>

    </form>

    </div>
  )
}

export default MakeReviews