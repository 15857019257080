
import './Menu.scss'
const TabsMenu = ({ menuActive, setMenuActive, title1, title2}) => {

  return (
    <div className="TabsMenu_wrapper">
        <div className="TabsMenu_content">

            <div className={`TabMenu_el TabMenu_l ${menuActive===1 ? "TabMenu_active" : "TabMenu_notActive"}`}
                onClick={() => setMenuActive(1)}>
                <p>{title1}</p>
            </div>

            <div className={`TabMenu_el TabMenu_r ${menuActive===2 ? "TabMenu_active" : "TabMenu_notActive"}`}
                onClick={() => setMenuActive(2)}>
                <p>{title2}</p>
            </div>

        </div>
    </div>
  );
};

export default TabsMenu;
