import React from 'react'
import WelcomeNews from './Blocks/WelcomeNews';

const SystemNews = ({news, welcome}) => {

let content;

if(news?.action==="welcome" || welcome){
  content = <WelcomeNews/>
}

  return (
    <div className="News_Wrapper">
      {content}
    </div>
  )
}

export default SystemNews