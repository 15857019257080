import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { age } from '../../utils/age'
import { config } from '../../utils/config'
import { thumb } from '../../utils/thumb'

import './HeaderUser.scss'
import BackBtnEmpty from '../Buttons/BackBtnEmpty';

const HeaderUser = ({setHeaderSelected, setHeaderUser, user, msgLength}) => {
  const {t} = useTranslation(['translation', 'countries']);

  const navigate = useNavigate();

  const navigateToUser =()=>{
    navigate(`/user/${user.username}/`)
  }

  return (
    <div className="ChatHeaderUser_wrapper">

    <div className='CHU_Header_User'>

    <div className="CHT_Mob_BackBtn" onClick={()=>{
      setHeaderSelected(false)
      setHeaderUser(null)
      }}>
      <BackBtnEmpty/>
    </div>

      <div className="CHU_Header_PicArea" onClick={navigateToUser}>
        <img
        src={thumb(user?.photos[0], config.user_photo_width)}
        alt="" />
      </div>

      <div className="CHU_Header_infoArea">
        <p>{user?.fName}, {age(user?.birthDate)}</p>
        <span>{t(user?.country, { ns: "countries" })}, {user?.city}</span>
      </div>

    </div>

    <div className="CHU_infoRightSide">
      <h4><b>Сообщ:</b> {msgLength}</h4>


    </div>
    </div>
  )
}

export default HeaderUser