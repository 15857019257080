import { Link, useNavigate, } from "react-router-dom";
import { thumb } from "../../utils/thumb";
import { useTranslation } from 'react-i18next';
import { age } from "../../utils/age";


import { ReactComponent as Verified } from "../../assets/verified.svg";

import './NewUser.scss'
import { config } from "../../utils/config";

const NewUser = ({user}) => {

  const navigate = useNavigate();

  const navigateTo = (user)=>{
    navigate(`/user/${user.username}/`)
  };
  const {t} = useTranslation(['translation', 'countries']);

    const { fName, country, city} = user;

  return (
    // <Link to={`/user/${user?._id}/`}>

    <div className="new_user_wrapper" onClick={() => navigateTo(user)} >

      <div className="new_user_imgBG">
        <img src={thumb(user.photos[0], config.thumb_width)} alt="new" loading="lazy"/>
      </div>

      <div className="new_user_text_area">
      <div className="nu_text_up">
      {user.verified&&<Verified className='new_user_verified'/>}
        <p>{fName}, {age(user?.birthDate)}</p>
      </div>
        <span>{t(country, { ns: "countries" })}, {city}</span>
      </div>
    </div>

        // </Link>

  );
};

export default NewUser;
