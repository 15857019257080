import {createPortal} from 'react-dom';
import CloseBtn from '../Buttons/CloseBtn';

import './Modal.scss'
const Modal = ({open, close, noEsc=false, NoCloseBtn=false, children}) => {

  const escClose = (event) => {
    if (event.key === "Escape") onClose();
    };

  if (!open) return null
    //-- Отключение скролла  и закрытие на ESC при открытом модале ---

    if (open) {
    document.body.classList.add("active-modal");
  }

    if (open && !noEsc) {
        console.log("Add ESC");
        document.body.addEventListener("keydown", escClose, {once:true} );
  }



  const onClose =()=>{
    console.log("Remove ESC");
        document.body.classList.remove("active-modal");
        document.body.removeEventListener("keydown", escClose);
        close();
}
  //---------------------------------------------

    return createPortal(

    <div className="modal">
        <div onClick={!NoCloseBtn? onClose : null} className="modal_ovrlay"></div>
        <div className="modal_content">

           {children}

           {!NoCloseBtn &&
            <div className='ModalCloseBtn' onClick={onClose}>
              <CloseBtn/>
            </div>
           }

        </div>

    </div>
    ,document.getElementById("modal")
  )
}

export default Modal