import i18n from "./i18next";

export const showDate = (item) => {
    const {t} = i18n;
    const lang = i18n.language

    const daysBefore = Math.ceil(((new Date(item?.startDate).getTime()) - (new Date().getTime())) / (1000 * 3600 * 24))
    const isStarted = new Date(item?.startDate) < new Date();
    const isFinished = (!item?.noEndDate && (new Date() > new Date(item?.endDate)))

    const inProgress = daysBefore<0 && !isFinished && (item.anyDates===false || item.noStartDate===false || item.noEndDate===false)


    let year = "";
    if (item?.startDate) {
        if(new Date().getFullYear() !== new Date(item?.startDate).getFullYear()){
            year = new Date(item?.startDate).toLocaleString(lang, {year: "numeric"});
        }
    }

    let previewStartDate = t("startDate", { ns: "translation" });
    if(item?.noStartDate){
      previewStartDate = t("anyDate", { ns: "tr" });
    }else if(item?.startDate) {
      previewStartDate = new Date(item?.startDate).toLocaleString(lang,{day:"numeric",month:"short"});
    }
    let previewEndDate = t("endDate", { ns: "translation" });
    if (item?.noEndDate) {
      previewEndDate = t("anyDate", { ns: "tr" });
    }else if(item?.endDate){
      previewEndDate = new Date(item?.endDate).toLocaleString(lang,{day:"numeric",month:"short"});
    }
    let date;
    if(item?.noStartDate && item?.noEndDate ) {
      date = t("anyDates",{ns:"translation"})
    }else{
      date = previewStartDate + " - " + previewEndDate;
    }
    return {date, year, daysBefore, isStarted, isFinished, inProgress}
  }