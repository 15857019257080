import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { config } from '../../utils/config'
import { thumb } from '../../utils/thumb'
import { showDate } from '../../utils/showDate';
import OutsideClick from '../../utils/OutsideClick';
import BackBtnEmpty from '../Buttons/BackBtnEmpty';
import ShowUsersMenu from './ShowUsersMenu';

import './HeaderTour.scss'
const HeaderTour = ({setHeaderSelected, setHeaderTour, tour, msgLength}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const {t} = useTranslation(['translation', 'countries']);
  const navigate = useNavigate();

  const {date, year} = showDate(tour);

  const usersID = [tour?.creator,...tour?.joined_users, ...tour?.confirmed_users]

  const navigateToTour =()=>{
    navigate(`/tour/${tour?._id}/`)
  }

  return (
    <div className='ChatHeaderTour_Wrapper'>

    <div className="CHT_left">

    <div className="CHT_Mob_BackBtn" onClick={()=>{
      setHeaderSelected(false)
      setHeaderTour(null)
      }}>
      <BackBtnEmpty/>
    </div>

      <div className="CHT_PicArea" onClick={navigateToTour}>
        <img
         src={thumb(tour?.titleImages?.[0], config.user_photo_width)}
        alt="" />
      </div>
      <div className="CHT_infoArea">
        <div className="CHT_DateLine">
          <span>{date} {year}</span>
        </div>

          <p><b>{t(tour?.country, { ns: "countries" })}</b>   , {tour?.city}</p>
      </div>
    </div>

      <div className="CHT_MembersArea">

        <h4><b>Сообщ:</b> {msgLength}</h4>

        <h3 onClick={() => setMenuOpen(!menuOpen)}>
        <b>В чате:</b> {tour?.joined_users?.length+1}</h3>

      <OutsideClick doSmth={() => setMenuOpen(false)}>

        <ShowUsersMenu
        tour={tour}
        usersID={usersID}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        />

      </OutsideClick>

      </div>

    </div>
  )
}

export default HeaderTour