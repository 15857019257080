import i18n from "./i18next";

export const showAges = (item ) => {
  const {t} = i18n;

  let ages;
  if(item.anyAge){
    ages = t("anyAge",{ns:"translation"})
  }else{
    ages = `${item.startAge} - ${item.endAge} ${t("years",{ns:"translation"})}`
  }
    return ages
  }