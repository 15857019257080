export const interests = [
    {
        _id: '6',
        isPublic: true,
        code: "beach",
        eng_title: "Beach relax",
        rus_title: "Пляжный отдых",
        icon:'/assets/interests/beach.png',
    },
    {
        _id: '15',
        isPublic: true,
        code: "cruise",
        eng_title: "Cruise tour",
        rus_title: "Круизный тур",
        icon:'/assets/interests/cruise.png',
    },
    {
        _id: '60',
        isPublic: true,
        code: "lingua",
        eng_title: "Lingua trip",
        rus_title: "Языковой тур",
        icon: '/assets/interests/lingua.png',
    },
    {
        _id: '31',
        isPublic: true,
        code: "shopping",
        eng_title: "Shopping tour",
        rus_title: "Шопинг тур",
        icon:'/assets/interests/shopping.png',
    },
    {
        _id: '38',
        isPublic: true,
        code: "tents",
        eng_title: "Tour with tents",
        rus_title: "Тур с палатками",
        icon:'/assets/interests/tents.png',
    },
    {
        _id: '11',
        isPublic: true,
        code: "business",
        eng_title: "Business tour",
        rus_title: "Бизнес тур",
        icon:'/assets/interests/business.png',
    },
    {
        _id: '18',
        isPublic: true,
        code: "food",
        eng_title: "Food trip",
        rus_title: "Гастро тур",
        icon:'/assets/interests/food.png',
    },
    {
        _id: '1',
        isPublic: true,
        code: "adult",
        eng_title: "Tour for adults",
        rus_title: "Тур для взрослых",
        icon:'/assets/interests/adult.png',
    },

    {
        _id: '19',
        isPublic: true,
        code: "gay",
        eng_title: "Tour for LGBT",
        rus_title: "Тур для LGBT",
        icon:'/assets/interests/gay.png',
    },
    {
        _id: '43',
        isPublic: true,
        code: "swing",
        rus_title: "Тур для свингеров",
        icon:'/assets/interests/swing.png',
    },
    {
        _id: '42',
        isPublic: true,
        code: "pickup",
        rus_title: "Тур для пикаперов",
        icon:'/assets/interests/pickup.png',
    },
    {
        _id: '41',
        isPublic: true,
        code: "dance",
        rus_title: "Ночные клубы",
        icon:'/assets/interests/dance.png',
    },
    {
        _id: '46',
        isPublic: true,
        code: "pill",
        rus_title: "Оздоровительный тур",
        icon:'/assets/interests/pill.png',
    },
    {
        _id: '56',
        isPublic: true,
        code: "jeep",
        icon:'/assets/interests/jeep.png',
    },
    {
        _id: '57',
        isPublic: true,
        code: "diver",
        icon:'/assets/interests/diver.png',
    },
    {
        _id: '44',
        isPublic: true,
        code: "bars",
        rus_title: "Бары",
        icon:'/assets/interests/bars.png',
    },
    {
        _id: '16',
        isPublic: true,
        code: "diving",
        eng_title: "Diving & Snorkling",
        rus_title: "Дайвинг и Снорклинг",
        icon:'/assets/interests/diving.png',
    },

    {
        _id: '2',
        isPublic: true,
        code: "astronomy",
        eng_title: "Astronomy",
        rus_title: "Астрономия",
        icon:'/assets/interests/astronomy.png',
    },
    {
        _id: '3',
        isPublic: true,
        code: "attractions",
        eng_title: "Attractions",
        rus_title: "Аттракционы",
        icon:'/assets/interests/attractions.png',
    },

    {
        _id: '4',
        isPublic: true,
        code: "badminton",
        eng_title: "Badminton",
        rus_title: "Бадминтон",
        icon:'/assets/interests/badminton.png',
    },
    {
        _id: '5',
        isPublic: true,
        code: "basketball",
        eng_title: "Basketball",
        rus_title: "Баскетбол",
        icon:'/assets/interests/basketball.png',
    },

    {
        _id: '7',
        isPublic: true,
        code: "beer",
        eng_title: "Beer",
        rus_title: "Пиво",
        icon:'/assets/interests/beer.png',
    },
    {
        _id: '8',
        isPublic: true,
        code: "biking",
        eng_title: "Biking",
        rus_title: "Велосипеды",
        icon:'/assets/interests/biking.png',
    },
    {
        _id: '9',
        isPublic: true,
        code: "boat",
        eng_title: "Boat",
        rus_title: "Лодки",
        icon:'/assets/interests/boat.png',
    },
    {
        _id: '10',
        isPublic: true,
        code: "bowling",
        eng_title: "Bowling",
        rus_title: "Боулинг",
        icon:'/assets/interests/bowling.png',
    },

    {
        _id: '12',
        isPublic: true,
        code: "cards",
        eng_title: "Playing cards",
        rus_title: "Игры в карты",
        icon:'/assets/interests/cards.png',
    },
    {
        _id: '13',
        isPublic: true,
        code: "casino",
        eng_title: "Casino",
        rus_title: "Казино",
        icon:'/assets/interests/casino.png',
    },
    {
        _id: '14',
        isPublic: true,
        code: "city",
        eng_title: "Big cities",
        rus_title: "Большие города",
        icon:'/assets/interests/city.png',
    },


    {
        _id: '17',
        isPublic: true,
        code: "fishing",
        eng_title: "Fishing",
        rus_title: "Рыбалка",
        icon:'/assets/interests/fishing.png',
    },
    {
        _id: '20',
        isPublic: true,
        code: "golf",
        eng_title: "Golf",
        rus_title: "Гольф",
        icon:'/assets/interests/golf.png',
    },
    {
        _id: '21',
        isPublic: true,
        code: "hockey",
        eng_title: "Hockey",
        rus_title: "Хоккей",
        icon:'/assets/interests/hockey.png',
    },
    {
        _id: '22',
        isPublic: true,
        code: "horsing",
        eng_title: "Horse riding",
        rus_title: "Езда верхом",
        icon:'/assets/interests/horsing.png',
    },
    {
        _id: '23',
        isPublic: true,
        code: "martial",
        eng_title: "Martial arts",
        rus_title: "Восточные единоборства",
        icon:'/assets/interests/martial.png',
    },
    {
        _id: '24',
        isPublic: true,
        code: "moto",
        eng_title: "Motocycles",
        rus_title: "Мотоциклы",
        icon:'/assets/interests/moto.png',
    },
    {
        _id: '25',
        isPublic: true,
        code: "mountain_biking",
        eng_title: "Mountain biking",
        rus_title: "Горные велосипеды",
        icon:'/assets/interests/mountain_biking.png',
    },
    {
        _id: '26',
        isPublic: true,
        code: "mountains",
        eng_title: "Mountains",
        rus_title: "Горы",
        icon:'/assets/interests/mountains.png',
    },
    {
        _id: '27',
        isPublic: true,
        code: "music",
        eng_title: "Music",
        rus_title: "Музыка",
        icon:'/assets/interests/music.png',
    },
    {
        _id: '28',
        isPublic: true,
        code: "painting",
        eng_title: "Painting",
        rus_title: "Рисование",
        icon:'/assets/interests/painting.png',
    },

    {
        _id: '29',
        isPublic: true,
        code: "party",
        eng_title: "Parties",
        rus_title: "Вечеринки",
        icon:'/assets/interests/party.png',
    },
    {
        _id: '30',
        isPublic: true,
        code: "scooter",
        eng_title: "Scooter",
        rus_title: "Скутер",
        icon:'/assets/interests/scooter.png',
    },

    {
        _id: '32',
        isPublic: true,
        code: "sights",
        eng_title: "Sightseeing",
        rus_title: "Музеи",
        icon:'/assets/interests/sights.png',
    },
    {
        _id: '33',
        isPublic: true,
        code: "skating",
        eng_title: "Skating",
        rus_title: "Скейт",
        icon:'/assets/interests/skating.png',
    },
    {
        _id: '34',
        isPublic: true,
        code: "skiis",
        eng_title: "Skiing",
        rus_title: "Лыжи",
        icon:'/assets/interests/skiis.png',
    },
    {
        _id: '35',
        isPublic: true,
        code: "snowboard",
        eng_title: "Snowboarding",
        rus_title: "Сноуборд",
        icon:'/assets/interests/snowboard.png',
    },
    {
        _id: '36',
        isPublic: true,
        code: "sport_events",
        eng_title: "Sport events",
        rus_title: "Спортивные события",
        icon:'/assets/interests/sport_events.png',
    },
    {
        _id: '37',
        isPublic: true,
        code: "surfing",
        eng_title: "Surfing",
        rus_title: "Серфинг",
        icon:'/assets/interests/surfing.png',
    },

    {
        _id: '39',
        isPublic: true,
        code: "valleyball",
        eng_title: "Valleyball",
        rus_title: "Воллейбол",
        icon:'/assets/interests/valleyball.png',
    },
    {
        _id: '40',
        isPublic: true,
        code: "zoo",
        eng_title: "Zoo",
        rus_title: "Зоопарки",
        icon:'/assets/interests/zoo.png',
    },
    {
        _id: '45',
        isPublic: true,
        code: "no_alkohol",
        rus_title: "Без алкоголя(ЗОЖ)",
        icon:'/assets/interests/no_alkohol.png',
    },
    {
        _id: '47',
        isPublic: true,
        code: "with_children",
        rus_title: "Бары",
        icon:'/assets/interests/with_children.png',
    },
    {
        _id: '48',
        isPublic: true,
        code: "alpinist",
        icon:'/assets/interests/alpinist.png',
    },
    {
        _id: '49',
        isPublic: true,
        code: "baloon",
        icon:'/assets/interests/baloon.png',
    },
    {
        _id: '50',
        isPublic: true,
        code: "helicopter",
        icon:'/assets/interests/helicopter.png',
    },
    {
        _id: '51',
        isPublic: true,
        code: "fire",
        icon:'/assets/interests/fire.png',
    },
    {
        _id: '52',
        isPublic: true,
        code: "mini_ven",
        icon:'/assets/interests/mini_ven.png',
    },
    {
        _id: '53',
        isPublic: true,
        code: "yoga",
        icon:'/assets/interests/yoga.png',
    },
    {
        _id: '54',
        isPublic: true,
        code: "elephant",
        icon:'/assets/interests/elephant.png',
    },
    {
        _id: '55',
        isPublic: true,
        code: "architecture",
        icon:'/assets/interests/architecture.png',
    },
    {
        _id: '58',
        isPublic: true,
        code: "all_inclusive",
        icon:'/assets/interests/all_inclusive.png',
    },
    {
        _id: '59',
        isPublic: true,
        code: "motor_yacht",
        icon:'/assets/interests/motor_yacht.png',
    },
    {
        _id: '61',
        isPublic: true,
        code: "sail_yacht",
        icon:'/assets/interests/sail_yacht.png',
    },


]