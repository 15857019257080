import { useTranslation } from 'react-i18next';

import User from '../User/User'
import './OrgReview.scss'
import Rating from './Rating';

const OrgReview = ({org, orgReview, setOrgReview}) => {
  const {t} = useTranslation(['tr']);

  return (
   <div className="OrgReview_wrapper">
    <h2>{t("Rate_org",{ns:"tr"})}</h2>

    <div className="TR_marks">
      <Rating size={32}
      rating={orgReview?.rating}
      setRating={(rating)=>setOrgReview({...orgReview, rating: rating})}
      />
    </div>

    {/* <div className="TR_marks">
        <div onClick={()=>setOrgReview({...orgReview, rating: 1})} className={orgReview?.rating===1 ? "TR_mark TR_mark_active":"TR_mark"}> 1 </div>
        <div onClick={()=>setOrgReview({...orgReview, rating: 2})} className={orgReview?.rating===2 ? "TR_mark TR_mark_active":"TR_mark"}> 2 </div>
        <div onClick={()=>setOrgReview({...orgReview, rating: 3})} className={orgReview?.rating===3 ? "TR_mark TR_mark_active":"TR_mark"}> 3 </div>
        <div onClick={()=>setOrgReview({...orgReview, rating: 4})} className={orgReview?.rating===4 ? "TR_mark TR_mark_active":"TR_mark"}> 4 </div>
        <div onClick={()=>setOrgReview({...orgReview, rating: 5})} className={orgReview?.rating===5 ? "TR_mark TR_mark_active":"TR_mark"}> 5 </div>
        <div onClick={()=>setOrgReview({...orgReview, rating: 6})} className={orgReview?.rating===6 ? "TR_mark TR_mark_active":"TR_mark"}> 6 </div>
        <div onClick={()=>setOrgReview({...orgReview, rating: 7})} className={orgReview?.rating===7 ? "TR_mark TR_mark_active":"TR_mark"}> 7 </div>
        <div onClick={()=>setOrgReview({...orgReview, rating: 8})} className={orgReview?.rating===8 ? "TR_mark TR_mark_active":"TR_mark"}> 8 </div>
        <div onClick={()=>setOrgReview({...orgReview, rating: 9})} className={orgReview?.rating===9 ? "TR_mark TR_mark_active":"TR_mark"}> 9 </div>
        <div onClick={()=>setOrgReview({...orgReview, rating: 10})} className={orgReview?.rating===10 ? "TR_mark TR_mark_active":"TR_mark"}> 10 </div>
    </div> */}

    <div className="ORG_Review">

    <User user={org} />

    <textarea
    className='OR_input'
    name="text"
    value={orgReview?.text}
    onChange={e=>setOrgReview({...orgReview,  text: e.target.value})}
    placeholder={t("WriteReview_ph",{ns:"tr"})}/>

    </div>

   </div>
  )
}

export default OrgReview