import { useTranslation } from 'react-i18next';

import construction from '../../assets/images/construction.png'

import './UnderConstrPage.scss'
const UnderConstrPage = () => {
  const {t} = useTranslation(['tr']);

  return (

    <div className="UndConstrPage_wrapper">
        <div className="UndConP_contentBox">

            <h2>{t("underConstr",{ns:"tr"})}</h2>
            {/* <h3>{t("commingSoon",{ns:"tr"})}!</h3> */}

            <img src={construction} alt="" />
        </div>

    </div>

    )
}

export default UnderConstrPage