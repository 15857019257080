import {useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as api from '../../utils/api/api.utils'

import { thumb } from "../../utils/thumb";

import Verified from "../../assets/verified.svg";
import daysAmnt from "../../assets/sortMenu/amount.svg";

import EditBtn from "../Buttons/EditBtn";
import LiftUpBtn from "../Buttons/LiftUpBtn";
import EyeBtn from "../Buttons/EyeBtn";
import { config } from "../../utils/config";
import Modal from "../Modal/Modal";
import ConfirmWin from "../Window/ConfirmWin";
import DeleteBtn from "../Buttons/DeleteBtn";
import { handleError } from "../../utils/handleError";
import { showDate } from "../../utils/showDate";

import { FaSearch } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";
import { IoLanguageSharp } from "react-icons/io5";

import PublishedText from "../Chips/PublishedText";
import { showGender } from "../../utils/showGender";
import { showAges } from "../../utils/showAges";
import { showLangs } from "../../utils/showLangs";
import LikesChip from "../Chips/LikesChip";

import "./MPost.scss";
const ConPost = ({ post, jwtUser, setJwtUser, setUpdated }) => {
  const [isPublic, setIsPublic] = useState(post?.isPublic);
  const [modalOpen, setModalOpen] = useState(false);

  const {t} = useTranslation(['translation', 'countries', 'int', 'langs']);
  const navigate = useNavigate();

  const {date, year, isFinished} = showDate(post);
  const gender = showGender(post, "post")
  const langs = showLangs(post)
  const ages = showAges(post)

  const deletePost=()=>{
    api.deletePost(post._id).then(res=> {
      if(res.status===200){
        jwtUser.data.user.created_posts = res.data.created_posts;
        setJwtUser(jwtUser)
        setUpdated(res.data.created_posts)
        document.body.classList.remove("active-modal");
        setModalOpen(false)
      }else{
        handleError(res)
      }
    })
}

  return (
    <div className="Mpost_Big_wrapper">

      <div className="Mpost_countryLine">
        <p><b>{t(post.country,{ns:"countries"})},</b> {post?.city}</p>
        <span><b>{date}</b> {year}</span>
      </div>

      <div className="Mpost_wrapper">

        <div className="Mpost_top">
        <div className="Mpost_left_side">

          <div className="Mpost_pic_area" onClick={() => navigate(`/user/${post?.creator?.username}`)} >
            <img className="Mpost_ibg"
              src={thumb(post?.creator?.photos[0], config.thumb_width)} alt="" loading="lazy"/>
          </div>

        </div>

        <div className="Mpost_right_side ConPost_RS">

            <div className="MPost_btns">
              <div className="ConPost_control_Btns_line">

                <EditBtn small
                  tooltip={t("Edit",{ns:"tr"})}
                  onClick={()=>navigate(`/editpost/${post._id}`)}
                />

                <DeleteBtn small
                  tooltip={t("Delete",{ns:"tr"})}
                  onClick={()=> setModalOpen(true)}
                />

                <div className="dummy_div"></div>
                {/* <LiftUpBtn small tooltip={t("raiseViews",{ns:"tr"})} /> */}

                <EyeBtn
                  small
                  tooltip={t("Publish_tt",{ns:"tr"})}
                  post={post}
                  isPublic={isPublic}
                  setIsPublic={setIsPublic}
                  />

                </div>
            </div>

              <div className="Mpost_line">
                {post?.creator?.verified ?
                  <img src={Verified} alt="" />
                  :
                  <FaRegUserCircle size={16} color="#87589C" />
                }
                <p><b>{post?.creator?.fName}</b> </p>
                <p> {post?.org_age} </p>
                <img className="Mpost_flag" src={`/assets/flags/small/${post?.depart?.toLowerCase()}.jpg`} alt="" />
              </div>

              <div className="Mpost_line">
                {post?.daysAmount > 0 && <>
                  <img src={daysAmnt} alt="travel dates" />
                    <p> <b>{t("inAll", { ns: "tr" })}:</b> </p>
                    <p>{post?.daysAmount}</p>
                    <p>{t("days", { ns: "tr" })}</p>
                </>}
              </div>

              <div className="Mpost_line">
                <FaSearch size={14} color="#87589C" />
                <p><b>{gender}</b></p>
                <p>{ages}</p>
              </div>

              <div className="Mpost_line">
                <IoLanguageSharp size={18} color="#87589C" />
                <p>{langs}</p>
              </div>

        </div>
        </div>


          <div className={`Mpost_right_btm ${post?.org_gender==='male'? 'Mpost_M': 'Mpost_F'}`}>
            <div dangerouslySetInnerHTML={{ __html: post.text }} />
          </div>

          <div className='Mpost_finished_line'>

            <PublishedText isPublic={isPublic} />

            {isFinished && <div className='finishChipText'>{t("tourFinished",{ns:"tr"})}</div>}

            <LikesChip item={post} size={15}/>

          </div>



          <Modal open={modalOpen} close={() => setModalOpen(false)} NoCloseBtn={true} >
            <ConfirmWin close={()=> {
              document.body.classList.remove("active-modal");
              setModalOpen(false)}}

            doSmth={deletePost}
            title={t("AreYouSureToDeletePost",{ns:"tr"})}
            info={`<b>${t("AllBeDeleted",{ns:"tr"})}</b>
            <br/>  ${t("NoWayBack",{ns:"tr"})}`}
            />
        </Modal>


      </div>
    </div>
  );
};

export default ConPost;
