import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import verify from '../../assets/events/verify.svg'
import verify_tour from '../../assets/events/verify_tour2.svg'

import './Event.scss'
import { thumb } from '../../utils/thumb'
import { config } from '../../utils/config'
import i18next from 'i18next';

const AdminEvent = ({e, menuOpen, setMenuOpen}) => {
    const navigate = useNavigate();
    const {t} = useTranslation(['tr']);
    const lang = i18next.language

    // console.log(e);

    const {fName, lName, photos, username} = e?.fromUserId
    const x = e?.action

    const date =  new Date(e?.createdAt).toLocaleString(lang,{day:'numeric', month:'short'});

    const navigateTo =()=>{
        if(x==="verify") navigate(`/moderation/verify/`)
        if(x==="verify_tour" || x==="verify_edited_tour") navigate(`/moderation/moderate/`)
        setMenuOpen(!menuOpen)
    }

  return (
    <div className="Event_Wrapper" onClick={navigateTo}>
        <div className="E_imgbox">
            <img className='E_userPhoto' src={thumb(photos[0], config.user_photo_width)} alt=""/>

            {x==="verify"&& <img className='E_icon' src={verify} alt="" />}
            {(x==="verify_tour"||x==="verify_edited_tour")&& <img className='E_icon' src={verify_tour} alt="" />}
        </div>

        <div className="Event_infoArea">

            {/*//-- User хочет пройти верификацию */}
            {(x==="verify")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <h6><b>{t("wantsToVerify",{ns:"tr"})}</b></h6>
            </>}

            {/*//-- Tour хочет пройти модерацию */}
            {(x==="verify_tour")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <h6><b>{t("createdTrip",{ns:"tr"})}</b></h6>
            </>}

            {/*//-- Tour хочет пройти верификацию после редактирования */}
            {(x==="verify_edited_tour")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <h6><b>{t("modifiedTrip",{ns:"tr"})}</b></h6>
            </>}

        </div>
    </div>
  )
}

export default AdminEvent