import { useEffect, useState } from 'react';
import * as api from '../../utils/api/api.utils'

import { useTranslation } from 'react-i18next';

import './SettingsPage.scss'
import InputPassword from '../../components/Input/InputPassword';
import { handleError } from '../../utils/handleError';
import MainBtn from '../../components/Buttons/MainBtn';
const ChangePass = ({jwtUser, setJwtUser}) => {
    const [counter, setCounter] = useState(0);
    const [oldPass, setOldPass] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [isPassOK, setIsPassOK] = useState(false);
    const [info, setInfo] = useState("");
    const [infoError, setInfoError] = useState("");
    const [serverError, setServerError] = useState("");
    const {t} = useTranslation(['translation']);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

        if(counter===0) {
            setServerError("")
            setInfo("")
        }
      }, [counter]);

    useEffect(() => {
        if(pass.length===0||oldPass.length===0){
          setIsPassOK(false)
        }else if(pass.length < 6){
            setServerError(t("err_pass6",{ns:"tr"}));
          setIsPassOK(false)
          }else if(pass.length > 20) {
            setServerError(t("err_pass20",{ns:"tr"}));
          setIsPassOK(false)
          } else if(pass!==confirmPass) {
            setServerError(t("err_passNotMatch",{ns:"tr"}));
            setIsPassOK(false)
          }else{
            setInfoError("")
            setIsPassOK(true)
          }
    }, [pass, confirmPass, oldPass]);


    var passValidation = (/[a-zA-Z0-9 >>!#$%&?<< ]/ig, '')

    const setNewPass= async()=>{
      try {
        const res = await api.changePass(jwtUser?.data?.user?._id, oldPass, pass)

        if(res?.status===200){
          setCounter(60)
          setInfo(t("newPassSaved",{ns:"tr"}))
          setOldPass("")
          setPass("")
          setConfirmPass("")
        }else if(res?.response?.status===403){
          setCounter(60)
          setOldPass("")
          setPass("")
          setConfirmPass("")
          setServerError(res?.response?.data?.message)
        }else{
          throw res
        }
      } catch (error) {
        handleError(error)
      }
    }

  return (
    <div className="SP_content_wrapper">
        <div className="SP_Header">
            <p>{t("ChangingPass",{ns:"tr"})}</p>
            <span>{t("toChangePass",{ns:"tr"})}</span>
        </div>

        <div className="SP_contentBox">

    <div className="SP_inputDiv">
        <InputPassword
            title={t("currentPassword",{ns:"translation"})}
            icon="pass"
            placeholder={t("enterCurrentPassword",{ns:"translation"})}
            name="password"
            value={oldPass}
            disabled={counter!==0}
            onChange={(e)=>setOldPass(e.target.value)}
        />
    </div>


<div className="SP_NewPassLine">
    <div className="SP_inputDiv">
    <InputPassword
            title={t("newPassword",{ns:"translation"})}
            icon="pass"
            placeholder={t("enterNewPassword",{ns:"translation"})}
            name="password"
            value={pass}
            disabled={counter!==0}
            onChange={(e)=>setPass(e.target.value.replace(/[^A-Za-z0-9-]/ig, ''))}
        />
    </div>

    <div className="SP_inputDiv">
        <InputPassword
            title={t("confirmPassword",{ns:"translation"})}
            icon="conf"
            placeholder={t("confirmPassword",{ns:"translation"})}
            name="confirmPassword"
            value={confirmPass}
            disabled={counter!==0}
            onChange={(e)=>setConfirmPass(e.target.value.replace(/[^A-Za-z0-9-]/ig, ''))}
        />
    </div>
</div>

<div className="SP_error_line">
    {info&& <h5><b>{info}</b> {t("nextTryAfter",{ns:"tr"})} {counter} {t("sec",{ns:"tr"})}. </h5>}

    <h6>{infoError}</h6>

    {(!infoError&&serverError) && <h6><b>{serverError}</b>
    {t("nextTryAfter",{ns:"tr"})} {counter} {t("sec",{ns:"tr"})}.</h6>}
</div>

</div>

  <MainBtn
    color='purp'
    title={t("Send",{ns:"tr"})}
    disabled={!isPassOK}
    onClick={setNewPass}
  />

    </div>

    )
}

export default ChangePass