import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import { useFormik } from "formik";
import { validationSchema } from "../../utils/validations/createPost_Yup";
import { UserContext } from "../../context/user.context";
import * as api from '../../utils/api/api.utils'

import { age } from "../../utils/age";

import AddLang from '../../components/AddLang/AddLang'
import AddInterest from "../../components/AddInterest/AddInterest";

import AgesField from '../../components/Input/AgesField'
import DatesField from '../../components/Input/DatesField'
import InputField from '../../components/Input/InputField'
import SelectField from '../../components/Input/SelectField'
import TextField from '../../components/Input/TextField'
import PageHeader from '../../components/PageHeader/PageHeader'

import Spinner from "../../components/Spinner/Spinner";
import InputCountry from "../../components/Input/InputCountry";

import './EditPostPage.scss'
import { handleError } from "../../utils/handleError";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import MainBtn from "../../components/Buttons/MainBtn";

const EditPostPage = () => {
  const { jwtUser, setJwtUser } = useContext(UserContext);
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);

  const [serverError, setServerError] = useState("");

  const [usersLangs, setUsersLangs] = useState([]); //-- Users Langs
  const [usersList, setUsersList] = useState([]); //-- Users Interests

  useEffect(() => {
    formik.setFieldValue("langs", usersLangs);
    if(usersLangs.length===0) {
      formik.setFieldValue("langs", "");
    }
  }, [usersLangs]);

  useEffect(() => {
    formik.setFieldValue("interests", usersList);
    if(usersList.length===0) {
      formik.setFieldValue("interests", "");
    }
    }, [usersList]);

  const {t} = useTranslation(['translation']);
  const { id } = useParams();
  const { ipadSml } = useMatchMedia();

  useEffect(() => {
    api.getPostById(id).then(res=> {
      if (res?.status === 200) {
        setPost(res.data)
        setUsersLangs(res?.data?.langs)
        setUsersList(res?.data?.interests)
        setLoading(false)
      }else{
        handleError(res)
      }
    })
  }, []);

  const navigate = useNavigate();

  const formik = useFormik({initialValues: {
        depart: post?.depart,
        creator: jwtUser?.data?.user?._id,
        startAge: post?.startAge,
        endAge: post?.endAge||90,
        anyAge: post?.anyAge,
        text: post?.text,
        country: post?.country,
        city: post?.city,

        langs: post?.langs,
        interests: post?.interests,
        gender: post?.gender,

        startDate: post?.startDate ? new Date(post?.startDate): "",
        endDate:post?.endDate ? new Date(post?.endDate): "",
        noStartDate:post?.noStartDate,
        noEndDate:post?.noEndDate,
        anyDates:post?.anyDates,
        daysAmount:post?.daysAmount,

      },
      validationSchema: validationSchema ,
      enableReinitialize: true,
      onSubmit: (values) => {
          // console.log('Formik values', values);
      },
    });

    useEffect(() => {
        if(!formik.values.endDate ||
            !formik.values.startDate ||
            formik.values.noStartDate ||
            formik.values.noEndDate ||
            (formik.values.endDate - formik.values.startDate)<0){
            formik.setFieldValue("daysAmount", 0);
          }else{
            const daysAmount = Math.ceil((formik.values.endDate - formik.values.startDate) / (1000 * 3600 * 24))
            formik.setFieldValue("daysAmount", daysAmount);
            }
        }, [formik.values.endDate,
            formik.values.startDate,
            formik.values.noStartDate,
            formik.values.noEndDate,
            ]);

            if(!jwtUser){
                return <Navigate to={`/login`} />
            }

            const handleSubmit = async(e)=>{
                e.preventDefault();
                formik.handleSubmit();

                console.log("post formik", formik);

                try {
                  const formData = new FormData();
                  formData.append("text", formik.values.text);

                  formData.append("creator", jwtUser.data.user._id);
                  formData.append("gender", formik.values.gender);

                  formData.append("org_gender", jwtUser?.data?.user?.gender);
                  formData.append("org_age", age(jwtUser?.data?.user?.birthDate));
                  formData.append("org_verified", jwtUser?.data?.user?.verified);
                  formData.append("org_orientation", jwtUser?.data?.user?.orientation);
                  formData.append("org_finances", jwtUser?.data?.user?.finances);
                  formData.append("org_character", jwtUser?.data?.user?.character);
                  formData.append("org_rating", jwtUser?.data?.user?.rating||0);

                  formData.append("startAge", formik.values.startAge);
                  formData.append("endAge", formik.values.endAge);
                  formData.append("anyAge", formik.values.anyAge);

                  formData.append("depart", formik.values.depart);
                  formData.append("country", formik.values.country);
                  formData.append("city", formik.values.city);

                  for (let i=0; i<usersLangs.length; i++) {
                    formData.append("langs", usersLangs[i]);
                  }

                  for (let i=0; i<usersList.length; i++) {
                    formData.append("interests", usersList[i]);
                  }

                  formData.append("startDate", formik.values.startDate);
                  formData.append("endDate", formik.values.endDate);
                  formData.append("noStartDate", formik.values.noStartDate);
                  formData.append("noEndDate", formik.values.noEndDate);

                  if(formik.values.noStartDate && formik.values.noEndDate){
                    formData.append("anyDates", true);
                  }else{
                    formData.append("anyDates", false);
                  }
                  formData.append("daysAmount", formik.values.daysAmount);
                  formData.append("isPublic", true);

                  if(formik.isValid){
                    setSending(true)

                    const res = await api.editPost(id, formData)

                    if (res?.status === 200) {
                      setSending(false)
                      navigate('/posts')
                    }else{
                      throw res
                    }

                  }else{
                    console.log("Forms are not correct")
                    setServerError(t("fillAllFields",{ns:"tr"}))
                  }
              } catch (error) {
                handleError(error);
                setSending(false)
                setServerError(error.response.message)
              }
              }


              if(loading){
                return <Spinner/>
              }


  return (
    <div className="EditPostPage_wrapper">

{sending && <LoadingScreen/>}


<PageHeader
      icon="edittour"
      title={t("editPost",{ns:"tr"})}
      text={t("creatingPost_text",{ns:"tr"})}
      tooltip={t("creatingPost_tt",{ns:"tr"})}

      />
 <form  onSubmit={handleSubmit}>

<div className="EPP_content_box">

    <div className="EPP_title_area">
        <p>{t("editPost",{ns:"tr"})}</p>
        <span>{t("someFieldsRequired",{ns:"tr"})}</span>
    </div>

    <div className="EPP_info_body">


        <div className="EPP_inputDiv">
        <InputCountry
            icon="depart"
            name="depart"
            title={t("fromWhatCountry", { ns: "translation" })}
            placeholder={t("SelectCountry", { ns: "translation" })}
            tooltip={t("fromWhatCountry_tt", { ns: "translation" })}
            isSearchable={!ipadSml}
            onChange={(val)=> val?
                formik.setFieldValue("depart", val.value):
                formik.setFieldValue("depart", null)
              }
            onBlur={()=>formik.setFieldTouched("depart")}
            value={formik.values.depart}
          />

          <div className="invalid-feedback">
            {formik.errors.depart && formik.touched.depart
              ? formik.errors.depart
              : null}
          </div>
        </div>


        <div className="EPP_inputDiv">
        <InputCountry
            icon="country"
            name="country"
            title={t("toWhatCountry", { ns: "translation" })}
            placeholder={t("SelectCountry", { ns: "translation" })}
            tooltip={t("toWhatCountry_tt", { ns: "translation" })}
            isSearchable={!ipadSml}
            onChange={(val)=> val?
                formik.setFieldValue("country", val.value):
                formik.setFieldValue("country", null)
              }
            onBlur={()=>formik.setFieldTouched("country")}
            value={formik.values.country}
          />

          <div className="invalid-feedback">
            {formik.errors.country && formik.touched.country
              ? formik.errors.country
              : null}
          </div>
        </div>


        <div className="EPP_inputDiv">
          <InputField
            icon="country"
            type="text"
            title={t("toWhatCity", { ns: "translation" })}
            placeholder={t("writeCity", { ns: "translation" })}
            tooltip={t("toWhatCity_tt", { ns: "translation" })}
            name="city"
            {...formik.getFieldProps("city")}
          />
          <div className="invalid-feedback">
            {formik.errors.city && formik.touched.city
              ? formik.errors.city
              : null}
          </div>
        </div>


        <div className="EPP_inputDiv">
          <SelectField
            title={t("post_gender", { ns: "translation" })}
            icon="sex"
            name="gender"
            {...formik.getFieldProps("gender")}
            tooltip={t("pplGender_tt",{ns:"tr"})}
            options={[
              { label: t("any",{ns:"translation"}), value: "any" },
              { label: t("men",{ns:"translation"}), value: "men" },
              { label: t("women",{ns:"translation"}), value: "women" },
            ]}
          />
        </div>

        <div className="EPP_Empty_Div"></div>

        <div className="EPP_inputDiv">
          <AgesField
            title={t("companionAge",{ns:"tr"})}
            icon="ages"
            tooltip={t("companionAge_tt",{ns:"tr"})}
            formik={formik}
          />
          <div className="invalid-feedback">
            {formik.errors.startAge && formik.touched.startAge
              ? formik.errors.startAge
              : null}
            <br />
            {formik.errors.endAge && formik.touched.endAge
              ? formik.errors.endAge
              : null}
          </div>
        </div>



        <div className="EPP_textarea">
          <TextField
            title={t("postText",{ns:"tr"})}
            icon="doc"
            tooltip={t("postText_tt",{ns:"tr"})}
            placeholder={t("postText_ph",{ns:"tr"})}
            maxLength="350"
            name="text"
            {...formik.getFieldProps("text")}
          />
          <div className="invalid-feedback">
            {formik.errors.text && formik.touched.text
              ? formik.errors.text
              : null}
          </div>
        </div>

        <div className="EPP_Dates_inputDiv">
          <DatesField
            title={t("postDates",{ns:"tr"})}
            icon="dates"
            tooltip={t("postDates_tt",{ns:"tr"})}
            formik={formik}
          />
        </div>


        <div className="EPP_lang">
          <AddLang
            usersLangs={usersLangs}
            setUsersLangs={setUsersLangs}
            title={t("postLangs",{ns:"tr"})}
            max={2}
            tooltip={t("postLangs_tt",{ns:"tr"})}
            {...formik.getFieldProps("langs")}
          />
        </div>
        <div className="invalid-feedback">
            {formik.errors.langs}
        </div>

        <div className="CPP_interest">
          <AddInterest
            usersList={usersList}
            setUsersList={setUsersList}
            max={6}
            title={t("postInts",{ns:"tr"})}
            tooltip={t("postInts_tt",{ns:"tr"})}
          />
        </div>

    </div>

</div>

    <div className="EPP_CreateBtn_line">

        <div className="EPP_notValid_error">
           <p>{serverError}</p>
        </div>

        <MainBtn
          color='purp'
          type="submit"
          title={t("Save",{ns:"tr"})}
          onClick={()=>{}}
        />

    </div>

</form>

    </div>
  )
}

export default EditPostPage