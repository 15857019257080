import { useNavigate } from "react-router-dom";
import { thumb } from "../../utils/thumb";
import { config } from "../../utils/config";
import RatingSml from '../Review/RatingSml';

import './PageItems.scss'
import MsgBtn from '../Buttons/MsgBtn';
const OrgBlock = ({ tour, isMyTour }) => {

  const navigate = useNavigate();

  const navigateToUser = (user)=>{
    navigate(`/user/${user.username}`)
  };

  return (
    <div className="PageItem_org_box">
        <img onClick={() => navigateToUser(tour?.creator)} alt="org"
          src={thumb(tour?.creator?.photos[0], config.user_photo_width)}/>

      <div className="PageItem_org_box_right">
        <p>{tour?.creator?.fName} {tour?.creator?.lName}</p>

        <div className="PageItem_btn_line">
            <RatingSml rating={tour?.creator?.rating > 0 ? tour?.creator?.rating : "new"} short />

            {!isMyTour && <MsgBtn userId={tour?.creator._id}/> }
        </div>
      </div>

    </div>
  );
};

export default OrgBlock;
