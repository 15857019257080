import { useContext } from "react";
import { UserContext } from "../../context/user.context";
import { Navigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { useMatchMedia } from "../../hooks/use-match-media";

import Messenger from "../../components/Chat/Messenger";

import "./MessengerPage.scss";
const MessengerPage = () => {
  const { jwtUser } = useContext(UserContext);
  const { t } = useTranslation(["tr"]);
  const { mobile } = useMatchMedia();

  if (!jwtUser) {
    return <Navigate to={`/login`} />;
  }
  return (
    <div className="MessengerPage_wrapper">
      {!mobile && (
        <PageHeader
          icon="msg"
          title={t("messenger", { ns: "tr" })}
          text={t("messenger_text", { ns: "tr" })}
          tooltip={t("messenger_tooltip", { ns: "tr" })}
          btn="none"
        />
      )}

      <Messenger />
    </div>
  );
};

export default MessengerPage;
