import { useTranslation } from 'react-i18next';
import { showDate } from '../../utils/showDate';

import "./JoinedChip.scss";
const TimeStatusText = ({item}) => {

  const {t} = useTranslation(['tr']);
  const {isFinished, inProgress, daysBefore} = showDate(item);

  let status;
  let clName;
  if(isFinished){
    status = t("tourFinished",{ns:"tr"})
    clName = "TimeStatusText_Finished"
  }else if(inProgress){
    status = t("inProgress",{ns:"tr"})
    clName = "TimeStatusText_InProgress"
  }else if(daysBefore >= 0 ){
    status = <p>{t("BeforTrip",{ns:"tr"})}:  <b>{daysBefore}</b>  {t("days",{ns:"tr"})} </p>
    clName = "TimeStatusText_DaysBefore"
  }

  return (
    <div className={clName}>{status}</div>
  );
};

export default TimeStatusText;
