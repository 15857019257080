import { useTranslation } from 'react-i18next';

import AddHotel from "../../components/AddHotel/AddHotel";
import AddTicket from "../../components/AddTicket/AddTicket";
import PriceInput from "../../components/Input/PriceInput";
import AddImage from "../../components/AddImage/AddImage";

import "./EditTourPages.scss";

const EditTourPage2 = ({formik, tour}) => {

  const {t} = useTranslation(['tr']);
  // console.log(formik);

  return (
    <div className="EditTour_wrapper">

      <div className="ET_content_box">

        <div className="ET_title_area">
        <p>{t("TicketsAndCosts",{ns:"tr"})}</p>
          <h3>{t("TicketsAndCosts_text",{ns:"tr"})}</h3>
        </div>

      <div className="ET_price_info">
        <div className="ET_tourPrice">
          <PriceInput
            title={t("TourCost",{ns:"tr"})}
            subtitle={t("TourCost_st",{ns:"tr"})}
            description={t("TourCost_descr",{ns:"tr"})}
            tooltip={t("TourCost_tt",{ns:"tr"})}
            name="tourPrice"
            type="number"
            inputmode="numeric"
            {...formik.getFieldProps("tourPrice")}
          />
          <div className="invalid-feedback">
              {formik.errors.tourPrice && formik.touched.tourPrice
              ? formik.errors.tourPrice : null}
          </div>
        </div>



        <div className="ET_tourPrice">
          <PriceInput
            title={t("TourFee",{ns:"tr"})}
            subtitle={t("TourFee_st",{ns:"tr"})}
            description={t("TourFee_descr",{ns:"tr"})}
            tooltip={t("TourFee_tt",{ns:"tr"})}
            name="tourFee"
            type="number"
            inputmode="numeric"
            {...formik.getFieldProps("tourFee")}
          />
          <div className="invalid-feedback">
              {formik.errors.tourFee && formik.touched.tourFee
              ? formik.errors.tourFee
              : null}
          </div>
        </div>

        <div className="ET_tourPrice">
          <PriceInput
            title={t("PocketMoney",{ns:"tr"})}
            subtitle={t("PocketMoney_st",{ns:"tr"})}
            description={t("PocketMoney_descr",{ns:"tr"})}
            tooltip={t("PocketMoney_tt",{ns:"tr"})}
            name="pocketMoney"
            type="number"
            inputmode="numeric"
            {...formik.getFieldProps("pocketMoney")}
          />
          <div className="invalid-feedback">
              {formik.errors.pocketMoney && formik.touched.pocketMoney
              ? formik.errors.pocketMoney
              : null}
          </div>
        </div>

        <div className="ET_tourMap_area">
        <AddImage
          title={t("UploadTravelMap",{ns:"tr"})}
          subtitle={t("UploadTravelMap_st",{ns:"tr"})}
          tooltip={t("UploadTravelMap_tt",{ns:"tr"})}
          formik={formik}
          name="tourMap"
        />
        </div>


        <div className="ET_AddHotel_area">
        <AddHotel/>
        </div>

        <div className="ET_AddTicket_area">
        <AddTicket/>
        </div>

      </div>
      </div>
    </div>
  );
};

export default EditTourPage2;
