import { useTranslation } from 'react-i18next';
import { FaStar } from "react-icons/fa";


import Org from "../../assets/star.svg";
import Good from "../../assets/good.svg";
import Bad from "../../assets/bad.svg";
import Neutral from "../../assets/neutral.svg";



import './AllReviews.scss'

const AllReviews = ({good_reviews, bad_reviews, neutral_reviews, org_reviews}) => {
  const {t} = useTranslation(['tr']);

  return (
    <div className="AllReviews_container">

    <div className="AllR_box">
        {bad_reviews !== 0 &&
          <div className="AllR_review_amount">
            <div className="AllR_smile_line">
              <p>{bad_reviews}</p>
              <img src={Bad} alt="" />
            </div>
            <p>{t("bad",{ns:"tr"})}</p>
          </div>
        }

        {neutral_reviews !== 0 &&
          <div className="AllR_review_amount">
            <div className="AllR_smile_line">
              <p>{neutral_reviews}</p>
              <img src={Neutral} alt="" />
            </div>
            <p>{t("neutral",{ns:"tr"})}</p>
          </div>
        }

          {good_reviews !== 0 &&
          <div className="AllR_review_amount">
            <div className="AllR_smile_line">
              <p>{good_reviews}</p>
              <img src={Good} alt="" />
            </div>
            <p>{t("good",{ns:"tr"})}</p>
          </div>
          }

          {org_reviews !== 0 &&
          <div className="AllR_review_amount">
            <div className="AllR_smile_line">
              <p>{org_reviews}</p>
            <FaStar size={22} color= "#ffc107" />

              {/* <img src={Org} alt="" /> */}
            </div>
            <p>{t("Organizator",{ns:"tr"})}</p>
          </div>
          }

        {(good_reviews === 0 &&
          neutral_reviews === 0 &&
          bad_reviews === 0 &&
          org_reviews===0) && (
            <div className="UP_No_Info">
            {t("noReviews",{ns:"tr"})}
            </div>
          )}

    </div>
  </div>
  )
}

export default AllReviews