import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Messenger from '../../components/Chat/Messenger'

import './TourChat.scss'
const TourChat = () => {
    const {tour, inJoined, inConfirmed, isCreator } = useOutletContext();
    const {t} = useTranslation(['tr']);

  return (
    <div className="TourChat_wrapper">
        <div className="TCH_box">
            {(inJoined ||inConfirmed || isCreator) ?
                <Messenger tour={tour} inTour={true}/>
                :
                <div className="TCH_noChat">
                    <h4>{t("TourChat_text",{ns:"tr"})}!</h4>
                    <h3>{t("TourChat_descr",{ns:"tr"})}</h3>
                </div>
            }
        </div>
    </div>

    )
}

export default TourChat