import { useOutletContext } from "react-router-dom";

import GalleryNoEdit from "../../components/Gallery/GalleryNoEdit";

import './MyGallery.scss'
import EditUserTourPhotos from "../../components/Gallery/EditUserTourPhotos";

const MyGallery = () => {
    const { user, setUser, isMyPage } = useOutletContext();

  return (
    <div className="MyGallery_wrapper">

    <GalleryNoEdit
      tour={null}
      user={user}
      type="user" //tour, user, userTour
      isMy={isMyPage}
    />

    {user?.tour_photos && user?.tour_photos?.map((tourPhotoObj, index) => {
      const notEmpty = tourPhotoObj?.photos?.length > 0
      return (
        <div className="MG_key" key={index}>
          {isMyPage?
            <div className="MyGallery_Block">
              <EditUserTourPhotos
                tour={tourPhotoObj}
                user={user}
                setUser={setUser}
              />
            </div>
          :
          notEmpty &&
            <div className="MyGallery_Block">
              <GalleryNoEdit
                tour={tourPhotoObj}
                user={user}
                type="userTour" //tour, user, userTour
              />
            </div>
          }
      </div>
      )
    }
  )}


    </div>
    )
}

export default MyGallery