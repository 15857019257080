import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import height from '../../assets/sortMenu/height.svg'
import rating from '../../assets/sortMenu/rating.svg'
import age from '../../assets/sortMenu/age.svg'
import OutsideClick from '../../utils/OutsideClick';
import { FaSort } from "react-icons/fa";
import { FaPlaneDeparture } from "react-icons/fa";

import './TourSort.scss'
const UserSort = ({sorting, search}) => {
  const [sortMenuOpen, setSortMenuOpen] = useState(false);

  const {t} = useTranslation(['translation']);

  //-- Закрытие на ESC клавишу --
  const onClose = () => {
    document.removeEventListener("keydown", escClose, false);
    setSortMenuOpen(!sortMenuOpen);
  };
  const escClose = (event) => {
    if (event.key === "Escape") onClose();
  };
  if (sortMenuOpen) {
    document.addEventListener("keydown", escClose, false);
  }
  //------------------------------

  return (
    <div className="TourSort_wrapper">
          <OutsideClick doSmth={() => setSortMenuOpen(false)}>
        <div className="TS_sortBtn" onClick={() => setSortMenuOpen(!sortMenuOpen)} >
          <FaSort size={20} color='#87589C'/>
        </div>

      {sortMenuOpen && (
        <div className="TS_menu">

          <div className="TS_item" >

            <div className={sorting==="whenReady" ? "TS_item_up TS_act":"TS_item_up"}
              onClick={() => {
              search("whenReady")
              setSortMenuOpen(false)
              }}>
                <FaPlaneDeparture size={20} color='#87589C'/>
                <p>{t("sortByReadyToGo", { ns: "translation" })}</p>
            </div>

          </div>

          <div className="TS_item" >
            <div className={sorting==="orgRating" ? "TS_item_up TS_act":"TS_item_up"}
              onClick={() => {
              search("orgRating")
              setSortMenuOpen(false)
              }}>
                <img src={rating} alt="" />
                <p>{t("sortByOrgRating", { ns: "translation" })}</p>
            </div>

              <div className={sorting==="orgRating_up" ? "TS_item_down TS_act":"TS_item_down"}
                onClick={() => {
                search("orgRating_up")
                setSortMenuOpen(false)
                }}>
                  &uarr;
              </div>

          </div>

        <div className="TS_item">
          <div className={sorting==="userAge" ? "TS_item_up TS_act":"TS_item_up"}
            onClick={() => {
            search("userAge")
            setSortMenuOpen(false)
            }}>
            <img src={age} alt="" />
            <p>{t("sortByUserAge", { ns: "translation" })}</p>
          </div>

          <div className={sorting==="userAge_up" ? "TS_item_down TS_act":"TS_item_down"}
            onClick={() => {
            search("userAge_up")
            setSortMenuOpen(false)
            }}>
              &uarr;
          </div>

        </div>

      <div className="TS_item">
        <div className={sorting==="userHeight" ? "TS_item_up TS_act":"TS_item_up"}
          onClick={() => {
          search("userHeight")
          setSortMenuOpen(false)
          }}>
            <img src={height} alt="" />
            <p>{t("sortByHeight", { ns: "translation" })}</p>
        </div>
        <div className={sorting==="userHeight_up" ? "TS_item_down TS_act":"TS_item_down"}
          onClick={() => {
          search("userHeight_up")
          setSortMenuOpen(false)
          }}>
            &uarr;
        </div>
      </div>
        </div>
        )}
          </OutsideClick>

    </div>
  )
}

export default UserSort