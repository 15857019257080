import {ReactComponent as MaleIcon} from '../../assets/create_account/male.svg'
import {ReactComponent as FemaleIcon} from '../../assets/create_account/female.svg'

import "./SearchGender.scss";

const SearchGender = ({ gender, setGender}) => {

  return (
    <div className="SG_container">
      
    <div className="SG_btns_box">
      <input 
      id="male" 
      type="radio"
      checked={(gender==='male')? true: null}
      value='male'
      onChange={()=>setGender('male')}
      />
    <label htmlFor="male">
      <div className="SG_genderBtn"> <MaleIcon className='SG_GenderSVG'/></div>
    </label>
      
      <input 
      id="female" 
      type="radio"
      checked={(gender==='female')? true: null}
      value='female'
      onChange={()=>setGender('female')}


      />
    <label htmlFor="female">
      <div className="SG_genderBtn"><FemaleIcon className='SG_GenderSVG'/></div>
    </label>

      </div>

    </div>
  );
};

export default SearchGender;
