import { useEffect, useState } from 'react';
import * as api from "../../utils/api/api.utils";
import { useTranslation } from 'react-i18next';

import PageHeader from '../../components/PageHeader/PageHeader'

import './NotificationsPage.scss'
import UserEvent from '../../components/Event/UserEvent';
import SysEvent from '../../components/Event/SysEvent';
import AdminEvent from '../../components/Event/AdminEvent';
import TourEvent from '../../components/Event/TourEvent';
import { useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import { handleError } from '../../utils/handleError';

const NotificationsPage = () => {
  const [events, setEvents] = useState();

  const { pageNumber } = useParams();
  const pageNum = pageNumber || 1;
  const startShowAmnt = 10

  const [page, setPage] = useState(pageNum);
  const [amount, setAmount] = useState(startShowAmnt);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();
  const navigate = useNavigate();
  const {t} = useTranslation(['tr']);

  useEffect(() => {
    api.getEvents(page, amount).then((res) => {

      if(res.status===200){
        setEvents(res.data.data.events);
        setPages(res.data.pages)
        setTotal(res.data.total)
        navigate(`/notifications/${page}`)
      }else if(res?.response?.status===403){
        setPage(1)
      }else{
        handleError(res)
      }
    });
  }, [page, amount]);

  const show_amount = 30;

  // const reversedEvents =  events&& [...events].reverse()
  return (

    <div className="NotificationsPage_wrapper">

    <PageHeader
      icon="notifications"
      title={t("Notifications",{ns:"tr"})}
      text={t("Notifications_text",{ns:"tr"})}
      tooltip={t("Notifications_tt",{ns:"tr"})}

      />

      <div className="NP_content_box">

      {events && events.map((e, index) =>
        index < show_amount &&
          <div key={index}>

            {e.eventType==="user"&& <UserEvent key={index} e={e} /> }
            {e.eventType==="tour"&& <TourEvent  key={index} e={e} /> }
            {e.eventType==="system"&& <SysEvent key={index} e={e} />}
            {e.eventType==="admin"&& <AdminEvent key={index} e={e} />}

          </div>
        )}
      </div>


      <div className="PP_pagination">
        <Pagination
          found={total}
          pages={pages}
          page={page}
          setPage={setPage}
          showAmnt={amount}
          setAmnt={setAmount}
          startShowAmount={startShowAmnt}
          amountStep={10}
        />
      </div>

    </div>
  )
}

export default NotificationsPage