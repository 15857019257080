import { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import * as api from '../utils/api/api.utils'

import { config } from "../utils/config";
import { handleError } from "../utils/handleError";

const URL=config.URL;

export const UserContext = createContext({

  unreadMsg: null,
  setUnreadMsg: () => null,
  onlineUsers: null,
  setOnlineUsers: () => null,
  socket: null,
  setSocket: () => null,
  location: null,
  setLocation: () => null,
  search: null,
  setSearch: () => null,
  found: null,
  setFound: () => null,
  jwtUser: null,
  setJwtUser: () => null,
  navBurg: null,
  setNavBurg: () => null,
});

export const UserProvider = ({ children }) => {
  const [jwtUser, setJwtUser] = useState();
  //JSON.parse(localStorage.getItem('profile'))
  const [found, setFound] = useState('');
  const [search, setSearch] = useState('');
  const [location, setLocation] = useState(null);
  const [navBurg, setNavBurg] = useState(false);
  const [socket, setSocket] = useState();
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [unreadMsg, setUnreadMsg] = useState([]);

  useEffect(() => {
    api.refreshUser().then(res=> {
      if(res?.status===200){
          console.log('%c User refreshed with Refresh token', 'color: orange');
          setJwtUser({data: res.data});
          localStorage.setItem('profile', JSON.stringify({data:res.data}));
        }
      })

    }, []);

    useEffect(() => {
    if(!location){
      api.getLocation().then((res) => {
        if (res?.status === 200) {
          setLocation(res?.data)
        }else{
          console.log("user.context getLocation:", res)
        }
      });
    }
  }, [location]);

  useEffect(() => {
  const myId = jwtUser?.data?.user?._id
  const newSocket = io(URL,{query:{"userId":myId}})

  setSocket(newSocket)

  newSocket.on("onlineUsers", data=>{
    console.log(`%c onlineUsers: ${data?.length}`, 'color: yellow', data);
    setOnlineUsers(data);

  })

  if(jwtUser){
    api.getUnreadMsg().then(res=> {
      if (res?.status === 200) {
        setUnreadMsg(res.data)
      }else{
        handleError(res)
      }
    })
  }

  return () => newSocket.close()
  }, [jwtUser])

  useEffect(() => {
  const myId = jwtUser?.data?.user?._id

    socket && socket.on("get_msg", msg=>{
    if((msg?.toUserID === myId) && (window.location.pathname!=='/messenger')){
        setUnreadMsg((prev)=>prev+1)
      }
      })

    socket && socket.on("get_room_msg", msg=>{
    if(window.location.pathname!=='/messenger'){
      setUnreadMsg((prev)=>prev+1)
    }
      })
  // return () => socket.off("get_msg")
  }, [socket])


  const value = {
    jwtUser, setJwtUser,
    found, setFound,
    search, setSearch,
    location, setLocation,
    navBurg, setNavBurg,
    socket, setSocket,
    onlineUsers, setOnlineUsers,
    unreadMsg, setUnreadMsg
  };

// console.log("CONTEXT Token", jwtUser.data.token);

  return (
    <UserContext.Provider value={value}> {children} </UserContext.Provider>
  );
};
