import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as api from '../../utils/api/api.utils'

import ppl from '../../assets/ConTour/confirmed.svg'
import confirmed from '../../assets/ConTour/thumb.svg'
import requests from '../../assets/ConTour/requests.svg'
import followers from '../../assets/ConTour/binocular.svg'
import eye from '../../assets/ConTour/eye.svg'
import heart from '../../assets/ConTour/heart_icon.svg'
import rejects from '../../assets/ConTour/reject.svg'
import blocked from '../../assets/ConTour/blocked.svg'

import TestChip from '../Chips/TestChip';
import { config } from '../../utils/config';
import { thumb } from '../../utils/thumb';
import FinishChip from '../Chips/FinishChip';
import EyeBtn from '../Buttons/EyeBtn';
import EditBtn from '../Buttons/EditBtn';
import Modal from '../Modal/Modal';
import ConfirmWin from '../Window/ConfirmWin';

import { showDate } from '../../utils/showDate';
import DeleteBtn from '../Buttons/DeleteBtn';
import MainBtn from '../Buttons/MainBtn';

import './ConTour.scss'
import { handleError } from '../../utils/handleError';
import LikesChip from '../Chips/LikesChip';
const ConTour = ({tour, jwtUser, setUpdated}) => {
    const [isPublic, setIsPublic] = useState(tour?.isPublic);
    const [modalOpen, setModalOpen] = useState(false);

    const navigate = useNavigate();
    const {t} = useTranslation(['translation', 'countries']);
    const {date, year, daysBefore, isFinished} = showDate(tour);

    const deleteTour =()=>{
      api.deleteTour(tour?._id).then(res=> {
        if(res.status===200){
          setUpdated(res.data)
          document.body.classList.remove("active-modal");
          setModalOpen(false)
        }else {
          handleError(res)
        }
      })
    }

  return (

      <div className="ConTour_wrapper">
    <div className="ConTour_content_box">
      <div className="ConTour__left_side">
        <div className="ConTour_country_part">
          <p className="ConTour_country">{t(tour?.country, { ns: "countries" })},</p>
          <p className="ConTour_city">{tour?.city}</p>
        </div>
        <div onClick={() => navigate(`/tour/${tour?._id}/`)} className="ConTour_pic_place">
          <img className="ibg" loading="lazy" alt="country"
            src={thumb(tour?.titleImages[0], config.thumb_width)} />

          <div className="new_dark_line">
            <p>{date}</p>
            <span>{year}</span>
          </div>

        {tour?.isTest &&
        <div className="СTP_Test_Chip">
            <TestChip
            title={t("testTrip",{ns:"tr"})}
            small={false}
            tooltip={t("testTrip_tt2",{ns:"tr"})}
            />
        </div>}

          {isPublic ?
          <div className="ConTour_isPublic"> {t("Published",{ns:"tr"})}  </div>:
          (tour?.isVerifying ?
          <div className="ConTour_onCheck"> {t("OnCheck",{ns:"tr"})}</div>
          :
          <div className="ConTour_isPublic ConTour_isNotPublic"> {t("notPublished",{ns:"tr"})} </div>
           )
          }

          {isFinished &&
            <div className="CTP_Finish_Chip">
              <FinishChip
              title={t("tourFinished",{ns:"tr"})}
              small={true}
              tooltip={t("tourFinished",{ns:"tr"})}
              />
            </div>}

          <div className="ConTour_price_days">
            <p>$ {tour?.tourPrice}</p>
            <span>{tour?.daysAmount} &nbsp; {t("days",{ ns:"tr"})}</span>
          </div>
        </div>
      </div>
      <div className="ConTour_rightside">
            <p className="ConTour_title">{tour?.title}</p>

          <div className="ConTour_rs_container">


          <div className="ConTour_rs_left">

          {tour?.joined_users?.length>0 &&
            <div className="CounT_item_line">
                <img src={ppl} alt="" />
                <p><b>{t("AlreadyJoined",{ns:"tr"})}:</b> {tour?.joined_users?.length}</p>
            </div>
          }
          {tour?.confirmed_users?.length>0 &&
            <div className="CounT_item_line">
                <img src={confirmed} alt="" />
                <p><b>{t("ConfirmedToJoin",{ns:"tr"})}:</b> {tour?.confirmed_users?.length}</p>
            </div>
          }

          {tour?.join_requests?.length>0 &&
            <div className="CounT_item_line">
                <img src={requests} alt="" />
                <p><b>{t("RequestToJoin",{ns:"tr"})}: </b> </p>
                {tour?.join_requests?.length===0 ?
                <p>&nbsp;  0</p>
                :
                <h2 onClick={()=>navigate(`/tour/${tour?._id}/people`)}>
                +{tour?.join_requests?.length}</h2>
                }
            </div>
          }

          {tour?.rejected_users?.length>0 &&
            <div className="CounT_item_line">
                <img src={rejects} alt="" />
                <p><b>{t("RejectedToJoin",{ns:"tr"})}:</b> {tour?.rejected_users?.length}</p>
            </div>
          }

          {tour?.following_users?.length >0 &&
            <div className="CounT_item_line">
                <img src={followers} alt="" />
                <p><b>{t("tripFollowers",{ns:"tr"})}:</b> {tour?.following_users?.length}</p>
            </div>
          }
          {tour?.blocked_users?.length>0 &&
            <div className="CounT_item_line">
                <img src={blocked} alt="" />
                <p><b>{t("BlockedToTrip",{ns:"tr"})}:</b> {tour?.blocked_users?.length}</p>
            </div>
          }

          </div>


          <div className="ConTour_rs_right">

            <div className="ConT_control_Btns_line">

              <EyeBtn
                tooltip={t("Publish_tt",{ns:"tr"})}
                // post={post}
                tour={tour}
                isPublic={isPublic}
                setIsPublic={setIsPublic}
              />

              <EditBtn
                onClick={()=>navigate(`/edittour/${tour?._id}`)}
                tooltip={t("Edit",{ns:"tr"})}
              />


            <div onClick={()=> {
              if(tour?.joined_users?.length>0){
                alert(t("cantDelete",{ns:"tr"}))
              }else{
                setModalOpen(true)
              }
            }}>
                <DeleteBtn tooltip={t("Delete",{ns:"tr"})} />
            </div>

        </div>

            <div className="ConT_statistics">

                <div className="ConT_stat">
                    <img src={eye} alt="" />
                    <p>{tour?.visitorsUUID?.length}</p>
                </div>

                {/* <div className="ConT_stat">
                    <img src={msg} alt="" />
                    <p>5</p>
                </div> */}

                <LikesChip item={tour} size={24}/>


                {/* <div className="ConT_stat">
                    <img src={heart} alt="" />
                    <p>{tour?.user_liked_me?.length}</p>
                </div> */}

            </div>
            {daysBefore >=0 ?
            <h4>{t("BeforTrip",{ns:"tr"})}:
            <br/><b>{daysBefore}&nbsp;{t("days",{ns:"tr"})}</b></h4>
            :
            <div className="ConT_emptyDIV"></div>
            }

            <MainBtn
                color='yellow'
                title={t("raiseViews",{ns:"tr"})}
                onClick={()=>{}}
            />

          </div>

          </div>
      </div>

    </div>
{tour?.v_deny_reason&& <>
<h6>{t("TourIsNotPublished",{ns:"tr"})}"{tour?.v_deny_reason}"</h6>
<h5>{t("makeChangesAndTryAgain",{ns:"tr"})}</h5>
</>
}

<Modal open={modalOpen} close={() => setModalOpen(false)} NoCloseBtn={true} >
      <ConfirmWin
      close={()=> {
        document.body.classList.remove("active-modal");
        setModalOpen(false)}}

      doSmth={deleteTour}
      title={t("AreYouSureToDeleteTour",{ns:"tr"})}
      info={`<b>${t("AllBeDeleted",{ns:"tr"})}</b>
      <br/>  ${t("NoWayBack",{ns:"tr"})}`}
      />
</Modal>


    </div>

    )
}

export default ConTour