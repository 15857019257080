import i18n from "./i18next";
import { langs as allLangs } from "../data/languages.js";

export const showLangs = (item) => {
    const {t} = i18n;

    const item_langs = allLangs?.filter(({_id}) => item.langs?.includes(_id))
    let langs;
    if(item.langs?.length===0){
      langs="";
    }else if(item.langs?.length===1){
      langs = t(item_langs[0]?._id, {ns:"langs"})
    }else{
      langs = t(item_langs[0]?._id, {ns:"langs"}) +", "+ t(item_langs[1]?._id, {ns:"langs"})
    }

    return langs
  }