import { useTranslation } from "react-i18next";
import { useMatchMedia } from "../../../hooks/use-match-media";

import Logo from '../../../assets/images/logo.png'
import Pic_2 from '../../../assets/onboarding/ob_2.png'
import Pic_3 from '../../../assets/onboarding/ob_3.png'


import "./newsBlocks.scss";
const WelcomeNews = () => {

  const { t, i18n } = useTranslation(["tr"]);

  const { mobileBig } = useMatchMedia();

  let link;
  if (i18n.language === "ru") {
    link = "https://youtu.be/g2yhOPsfIlI";
  }else{
    link = "https://youtu.be/5W7hNkhHf4I";
  }


  return (
    <div className="WelcomeNews_wrapper">
      <div className="WN_TopLine">
        <img src={Logo} alt="logo" />
        <p>{t("welcomeTo",{ns:"tr"})} <span>{t("Tripico",{ns:"tr"})}</span></p>
      </div>

      {mobileBig && <p className="WNML_title">{t("TripicoIs",{ns:"tr"})}.</p> }

      <div className="WN_MidLine">
        <div className="WNML_TextBlock">
          {!mobileBig && <p className="WNML_title">{t("TripicoIs",{ns:"tr"})}.</p> }
          <span>{t("onTripicoYouCan",{ns:"tr"})}.</span>
          <h3>{t("moreDetailed",{ns:"tr"})} <b>{t("Tripico",{ns:"tr"})}</b> {t("youCanFind",{ns:"tr"})} <a href={link} >{t("fromThisVideo",{ns:"tr"})}</a>.</h3>
        </div>

        <img src={Pic_2} alt="" />
      </div>

      <div className="WN_BtmLine">

        <img src={Pic_3} alt="" />

        <div className="WNBL_TextBlock">
          <p>{t("makeAnAd",{ns:"tr"})} </p>
          <p>{t("findTour",{ns:"tr"})} </p>

          {!mobileBig &&  <>
              <h3>{t("findNewFriends",{ns:"tr"})}</h3>
              <h3>{t("andTravelWith",{ns:"tr"})}<span> {t("TripicoNet",{ns:"tr"})}!</span></h3>
          </> }
        </div>
      </div>
      {mobileBig && <h3 className="WNBL_FooterText">{t("findNewFriends",{ns:"tr"})} {t("andTravelWith",{ns:"tr"})} <span> {t("TripicoNet",{ns:"tr"})}!</span></h3> }
    </div>
  )
}

export default WelcomeNews