import { useContext, useEffect, useState} from "react";
import { UserContext } from '../../context/user.context';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { useFormik } from "formik";
import { validationSchema } from "../../utils/validations/Login_Yup";
import * as api from '../../utils/api/api.utils'

import InputField from "../../components/Input/InputField";
import PageHeader from "../../components/PageHeader/PageHeader";

import InputPassword from "../../components/Input/InputPassword";
import { handleError } from "../../utils/handleError";
import MainBtn from "../../components/Buttons/MainBtn";

import "./LoginPage.scss";
const LoginPage = () => {
    const { jwtUser, setJwtUser } = useContext(UserContext);
    const [serverError, setServerError] = useState();

    useEffect(() => {
    formik.setFieldError("email", "enter email")
    // сделал только для того, чтобы formik стал невалидным на старте
    }, []);


   const navigate = useNavigate();
   const {t} = useTranslation(['tr']);


    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // console.log('Formik values', values);
        },
    });

    // console.log(formik);

  const handleSubmit = async (event) => {
    event.preventDefault();
    formik.handleSubmit();

    setServerError(null)


    try {

        if(formik.isValid){

            const res = await api.loginUser({
                email: formik.values.email,
                password: formik.values.password,
            })

            if(res?.status===200){
              setServerError(null)
              setJwtUser({data:res.data});
              console.log("LOGIN_JWT_USER:", res)
              localStorage.setItem('profile', JSON.stringify({data:res.data}));
              navigate('/');
            }else if(res?.response?.status===403){
              setServerError(t("LoginOrPassIncorrect",{ns:"tr"}))
            }else{
              throw res
            }

        }else{
          console.log("Login Forms are not correct")

        }
    } catch (error) {
      handleError(error)
      setServerError(error.response.data.message)
    }

  }


  return (
    <div className="LoginPage_wrapper">
    {!jwtUser&&
      <PageHeader
        icon="login"
        title={t("login_title",{ns:"tr"})}
        text={t("login_title_text",{ns:"tr"})}
        btn="none"
      />
    }

      <div className="LP_content_box">

        {!jwtUser ?

        <form  onSubmit={handleSubmit}>
        <div className="LP_mid_content_box">

        <p className="LP_title">{t("Login",{ns:"tr"})}</p>

        <div className="LP_inputDiv">
          <InputField
            title="Email"
            icon="email"
            type="email"
            name="email"
            placeholder={t("yourEmail",{ns:"translation"})}
            {...formik.getFieldProps("email")}
          />
          <div className="errorBox">
            {formik.errors.email && formik.touched.email
              ? formik.errors.email : null}
          </div>
        </div>


        <div className="LP_inputDiv">
          <InputPassword
            icon="pass"
            name="password"
            title={t("Password",{ns:"translation"})}
            placeholder={t("enterPassword",{ns:"translation"})}
            {...formik.getFieldProps("password")}
          />
          <div className="errorBox">
            {formik.errors.password && formik.touched.password
              ? formik.errors.password : null}
          </div>
        </div>

        <MainBtn
          color='purp'
          type="submit"
          title={t("Login",{ns:"tr"})}
          onClick={()=>{}}
        />

            {serverError &&
              <div className="errorBox">
                <p>{serverError}</p>
              </div>
            }

            <div className="LP_register_line">
                <h5>{t("noAccount",{ns:"tr"})}  </h5>
                <h6 onClick={()=>navigate('/register')}>
                {t("createAccount",{ns:"tr"})} </h6>
            </div>

            <div className="LP_register_line">
                <h5>{t("ifForgotPassword",{ns:"tr"})}  </h5>
                <h6 onClick={()=>navigate('/forgot-password')}>
                {t("resetPassword",{ns:"tr"})}</h6>
            </div>

      </div>
    </form>
    :
    <p className="LP_title">{t("youAreLoggedIn",{ns:"tr"})}</p>
        }



      </div>
    </div>
  );
};

export default LoginPage;
