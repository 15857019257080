import * as Yup from 'yup';
import { config } from '../config';
import i18n from '../i18next';
const {t} = i18n

export const validationSchema = Yup.object().shape({

    fName: Yup.string()
      .required("First name is required")
      .matches(/^[^!@#$%^&*+=<>:;|~]*$/, { message: 'No symbols' })
      .matches(/^[A-Za-z\s+]+$/, t("onlyEnglish", {ns:"err"}))
      .min(1, "Must be at least 1 character")
      .max(30, "Must not exceed 30 characters"),

    lName: Yup.string()
      .required("Last name is required")
      .matches(/^[^!@#$%^&*+=<>:;|~]*$/, { message: 'No symbols' })
      .matches(/^[A-Za-z\s+]+$/, t("onlyEnglish", {ns:"err"}))
      .min(1, "Must be at least 1 character")
      .max(30, "Must not exceed 30 characters"),

    birthDate: Yup.date()
    .required("Birth date is required"),

    gender: Yup.string()
    .required("Gender is required"),

    country: Yup.string()
      .required("Country is required"),

    city: Yup.string().required("City is required"),

    address: Yup.string()
    .required("Address is required"),

    passport: Yup.string().required("Passport number is required"),

    phone: Yup.string().required("Phone number is required"),

    doc_photo_1: Yup.array().nullable()
    .required("Document photo is required")
    .min(1, "Document photo is required")
    ,
    doc_photo_2: Yup.array().nullable()
    .required("Document photo is required")
    .min(1, "Document photo is required")
    ,
    doc_photo_3: Yup.array().nullable()
    .required("Document photo is required")
    .min(1, "Document photo is required")
    ,

    });



