import * as Yup from 'yup';
import i18n from '../i18next';
const {t} = i18n

export const validationSchema = Yup.object().shape({

    email: Yup.string()
      .required(t("EmailRequared", {ns:"err"}))
      .email(t("EmailIsInvalid", {ns:"err"})),

    password: Yup.string()
      .required(t("PasswordRequared", {ns:"err"}))

    });



