import name_icon from '../../assets/create_account/man.svg'
import date_icon from '../../assets/create_account/calendar.svg'
import planet_icon from '../../assets/create_account/planet.svg'
import depart_icon from '../../assets/create_tour/depart.svg'
import email_icon from '../../assets/create_account/email.svg'
import pass_icon from '../../assets/create_account/password.svg'
import conf_icon from '../../assets/create_account/confirm.svg'
import title_icon from '../../assets/create_tour/title.svg'
import ppl_icon from '../../assets/ppl.svg'
import money_icon from '../../assets/user_page/square_dollar.svg'

import alkohol_icon from '../../assets/create_account/alkohol.svg'
import character_icon from '../../assets/create_account/character.svg'
import dollar_icon from '../../assets/create_account/dollar.svg'
import heart_icon from '../../assets/create_account/heart.svg'
import height_icon from '../../assets/create_account/height.svg'
import lang_icon from '../../assets/create_account/lang.svg'
import interest_icon from '../../assets/create_account/interest.svg'
import religion_icon from '../../assets/create_account/religion.svg'
import sex_icon from '../../assets/create_account/sex.svg'
import smoke_icon from '../../assets/create_account/smoke.svg'
import zodiak_icon from '../../assets/create_account/zodiak.svg'

import "./InputField.scss";
import TooltipPurp from "../Tooltip/TooltipPurp";

const InputField = ({ title, upTo, icon, value, maxLength, tooltip,...otherProps }) => {

    let img;
    if(icon==="date") {img = date_icon};
    if(icon==="daysAmount") {img = date_icon};
    if(icon==="name") {img = name_icon};
    if(icon==="email") {img = email_icon};
    if(icon==="pass") {img = pass_icon};
    if(icon==="conf") {img = conf_icon};
    if(icon==="country") {img = planet_icon};
    if(icon==="depart") {img = depart_icon};
    if(icon==="amount") {img = ppl_icon};
    if(icon==="amountTo") {img = ppl_icon};
    if(icon==="money") {img = money_icon};

    if(icon==="alkohol") {img = alkohol_icon};
    if(icon==="character") {img = character_icon};
    if(icon==="dollar") {img = dollar_icon};
    if(icon==="heart") {img = heart_icon};
    if(icon==="height") {img = height_icon};
    if(icon==="lang") {img = lang_icon};
    if(icon==="interest") {img = interest_icon};
    if(icon==="religion") {img = religion_icon};
    if(icon==="sex") {img = sex_icon};
    if(icon==="smoke") {img = smoke_icon};
    if(icon==="zodiak") {img = zodiak_icon};
    if(icon==="title") {img = title_icon};

  return (
    <div className="InputField_container">
      <div className="IF_title_line">
        <div className="IF_title_sign">
          <img src={img} alt="" />
          <p>{title}</p>
        </div>

        {maxLength&& <h4>{value?.length} / {maxLength} </h4>}
        {tooltip && <TooltipPurp text={tooltip}/> }

      </div>
      {(icon==="amount" || icon==="amountTo"|| icon==="daysAmount"||icon==="money")? 
      <div className="IF_amountLine">

        {upTo&&<p>{upTo} {icon==="money"&& "$"}</p>}

        <input className='IF_amount'
        value={value}
        {...otherProps} />
      </div>
    :      
    <input 
      maxLength={maxLength}
      value={value}
     {...otherProps} />}
    </div>
  );
};

export default InputField;
