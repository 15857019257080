// import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import './Interest.scss'

const Interest = ({icon, code, title, onClick, pointer}) => {

  const {t} = useTranslation(['int']);

  return (
    <div className="Interest" style={{ cursor: pointer ? 'pointer' : ''}} onClick={onClick}>

      {icon&& <img src={icon} alt="flag" loading="lazy"/>}

      {title?
      <p>{title}</p>
      :
      <p>{t(code,{ns:"int"})}</p>
      }

  </div>
  )
}

export default Interest