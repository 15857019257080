import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { config } from "../../utils/config";
import { useTranslation } from 'react-i18next';

import Upload_img from "../../assets/upload_img.svg";

import TooltipPurp from "../Tooltip/TooltipPurp";
import "./AddImage.scss";
import { thumb } from "../../utils/thumb";

const AddImage = ({ tooltip, formik, title, title2, subtitle, name }) => {
  const [image, setImage] = useState(formik.values[name]);

  useEffect(() => {
    formik.setFieldValue(name, image);

    if (image?.length === 0) {
      formik.setFieldValue(name, null);
    }
  }, [image]);

  const {t} = useTranslation(['tr']);

  // console.log(formik.values[name]);

  const onDrop = useCallback((acceptedFiles) => {
    Resizer.imageFileResizer(
        acceptedFiles[0],
        config.uploadImage_maxWidth,
        config.uploadImage_maxHeight,
        config.uploadImage_format,
        config.uploadImage_quality,
        0,
        (uri) => {
          const resizedImgObj = { source: uri, preview: URL.createObjectURL(uri) };
          setImage([resizedImgObj]);
          },
        "file"
      );

  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    uploadMultiple: false,
    maxFiles:1,
    accept: {
      "image/*": [],
    },
  });

//   console.log(formik.values);

  return (
    <div className="AddTourMap_wrapper">
      <div className="ATM_title">
        <p><b>{title}</b> {title2}</p>
        <TooltipPurp text={tooltip} />
      </div>
      <span>{subtitle}</span>

        {formik.values[name] === null &&
      <div className="ATM_dragndrop_box" {...getRootProps()}>
        <img src={Upload_img} alt="" />
        <p>{t("dragNdropPhotos",{ns:"tr"})} </p>
        <input {...getInputProps()} type="file" name="image" />
        <span>{t("upload",{ns:"tr"})} 1 {t("image",{ns:"tr"})}</span>
      </div>
      }

      {formik.values[name] !== null && (
        <div className="ATM_photo_box">
        <img className="ATM_photo"
            src={formik.values[name]?.[0]?.link ?
            thumb(formik.values[name]?.[0]?.link , config.userImg_width)
            : formik.values[name]?.[0]?.preview} alt="" />
        <span onClick={()=>setImage([])}>{t("Delete",{ns:"tr"})}</span>
        </div>
      )}
    </div>
  );
};

export default AddImage;
