import { useState } from 'react';
import { config } from "../../utils/config";
import { useTranslation } from 'react-i18next';

import Modal from "../Modal/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Keyboard, Zoom } from "swiper";
import { thumb } from '../../utils/thumb';

import "swiper/scss";
import 'swiper/css/zoom';
import "swiper/scss/navigation";

import './Gallery.scss'
import TourForReview from '../Tour/TourForReview';
import UserSml from '../User/UserSml';
const GalleryNoEdit = ({tour, user, album, type}) => {
  // tour: tour?.tour_photos(OBJ) объект с фотками с тура
  // user: просто юзер, в случае Фото аккаунта - берем фотки из аккаунта.
  //type: tour, user, userTour
  SwiperCore.use([Keyboard]);

  const [modalOpen, setModalOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  let photos;
  if(type==="userTour") photos = tour?.photos;
  if(type==="user") photos = user?.photos;
  if(type==="tour") photos = tour?.titleImages;
  if(type==="tourGallery") photos = tour?.tourGallery;
  if(type==="userAlbum") photos = album?.tourAlbum?.photos;


  const {t} = useTranslation(['tr']);

  const handleModal = (index) => {
      setModalOpen(true);
      setPhotoIndex(index);
    };

  return (

    <div className="ManagePhotos_wrapper">

      <div className="Gallery_Title_noEdit">
        {type==="userTour" &&
          <div className="GT_titleTour">
            <TourForReview tour={tour?.tourId}/>
          </div>
          }
        {type==="user" &&
          <div className="MP_GT_R_top">
            <p><b>{t("accountPhotos",{ns:"tr"})}:</b></p>
          </div>
          }
        {type==="tour" &&
          <div className="MP_GT_R_top">
            <p><b>{t("tripPhotos",{ns:"tr"})}:</b></p>
          </div>
          }
        {type==="tourGallery" &&
          <div className="MP_GT_R_top">
            <p><b>{t("afterTripPhotos",{ns:"tr"})}:</b></p>
          </div>
          }
        {type==="userAlbum" &&
          <div className="UserGalleryTitle">
            <UserSml user={album}/>
            <p><b>{t("userAlbumPhotos",{ns:"tr"})}</b></p>
          </div>
          }
      </div>


      {photos.length===0 ?
        <div className="Gallery_empty"> {t("emptyHere",{ns:"tr"})} </div>
      :
        <div className="Gallery_photo_container">

            {photos && photos?.map((img, index) => (
                <div  className="G_box" key={index} >
                  <img className="Gallery_photo" onClick={() => handleModal(index)}
                  src={thumb(img, config.thumb_width)} alt="user" loading="lazy"/>
                </div>
              ))
            }
        </div>
      }


      <Modal open={modalOpen} close={() => setModalOpen(false)}>
        <div className="ModalPhoto">

          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            modules={[Navigation, Zoom]}
            zoom={true}
            initialSlide={photoIndex}
            keyboard={{ enabled: true }}
            // loop
            navigation
            style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
              }}
              >

            {photos && photos?.map((pic, index) => (
                <div key={index} className="slider_img_box">
                  <SwiperSlide>
                  <div style={{backgroundImage: `url("${thumb(pic, config.thumb_width)}")`}}
                      className="lowResImgView swiper-zoom-container" >
                    <img className="highResImgView" src={pic}  alt="" />
                    <div class="swiper-lazy-preloader"></div>
                  </div>
                  </SwiperSlide>
                </div>
            ))}

          </Swiper>
        </div>

      </Modal>




    </div>

  )
}

export default GalleryNoEdit