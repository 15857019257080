import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FavHeart} from '../../assets/heart_2.svg'
import * as api from '../../utils/api/api.utils'



import './FavButton.scss'
import { handleError } from '../../utils/handleError';

const FavBtnWht = ({jwtUser, setJwtUser, user }) => {
    const doILike = jwtUser?.data?.user?.i_liked_user?.includes(user?._id)
    const [pressed, setPressed] = useState(doILike);
    const [clickable, setClickable] = useState(true);

    const navigate = useNavigate();
    const myId = jwtUser?.data?.user?._id

    const likeAction = async()=>{

      if(pressed){
      //-- Unlike
      try {
        setPressed(false)
        setClickable(false)
        const res = await api.unLikeUserById(myId, {unLikeID: user?._id})

        if(res.status===200){
        jwtUser.data.user.i_liked_user = res.data.user.i_liked_user;
        setJwtUser(jwtUser)
        setPressed(false)
        setClickable(true)
        }else{
          setPressed(true)
          setClickable(true)
          throw res
        }

      } catch (error) {
        handleError(error)
      }

      }else{
      //-- Like
      if(!jwtUser){ navigate('/login') }

      try {
        setPressed(true);
        setClickable(false)
        const res = await api.likeUserById(myId, {likeID: user?._id})

        if(res.status===200){
        jwtUser.data.user.i_liked_user = res.data.user.i_liked_user;
        setJwtUser(jwtUser)
        setPressed(true);
        setClickable(true)
        }else{
          setPressed(false);
          setClickable(true)
          throw res;
        }

      } catch (error) {
        handleError(error)
      }

      }

    }


  return (
    <div onClick={clickable? likeAction : ()=>{}} className={pressed ? "FavButton FavButton_clicked" : "FavButton"}>
        <FavHeart className="FavHeart" />
    </div>
  )
}

export default FavBtnWht