import i18n from "./i18next";

export const handleError =(err)=>{
    const {t} = i18n

    if(err.message==="Network Error"){
        console.log("network error:", err.message);
        alert(t("NetworkError",{ns:"tr"}));

    }else if(err?.response?.status === 403) {
        console.log("Error 403:", err.response.data.message);
        alert("Error 403: \n" +  err.response.data.message);

    }else if(err?.response?.status === 500){
        console.log("Error 500:", err.response.data.message);
        alert("Error 500: \n" +  err.response.data.message);

    }else{
        console.log("handleError:", err);
        alert(`${t("Error",{ns:"tr"})} ${err.response.status}`, err.response.data.message);
    }
}