import verified_img from '../../assets/verified.svg'

import TooltipPurp from "../Tooltip/TooltipPurp";
import "./InputField.scss";

const SearchVerified = ({ title, cb_title, verified, setVerified, tooltip,...otherProps }) => {

    return (
    <div className="InputField_container">
      <div className="IF_title_line">
        <div className="Verified_sign">
          <img src={verified_img} alt="" />
          <p>{title}</p>
        </div>
        {tooltip && <TooltipPurp text={tooltip}/> }
        </div>

      <div className="SVP_Dates_checkbox">
          <label className="SDA_checkbox_label">  {cb_title}
          <input
            type="checkbox"
            name="noStartDate"
            checked={verified}
            value={verified}
            onChange={()=>setVerified(!verified)}
          />
          <span className="SDA_checkmark SVP_checkmark"></span>
        </label>
        </div>
  
    </div>
  );
};

export default SearchVerified;
