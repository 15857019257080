import { useState } from 'react'
import { config } from '../../utils/config'
import { thumb } from '../../utils/thumb'
import { saveAs } from 'file-saver'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Keyboard, Zoom} from "swiper";
import { useTranslation } from 'react-i18next';


import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'

import Modal from '../Modal/Modal'
import 'swiper/scss';
import 'swiper/css/zoom';
import 'swiper/scss/navigation';
import "swiper/scss/effect-fade";
import './Msg.scss'
import i18n from '../../utils/i18next';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

const Msg = ({msg, jwtUser, chatUsers }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  SwiperCore.use([Keyboard]);

  const myId = jwtUser?.data?.user?._id
  const msgUserId = msg?.userId
  const myMsg = myId===msgUserId
  const chatUser = chatUsers.find(user => user._id === msgUserId)

  const {t} = useTranslation(['tr']);
  const lang = i18n.language

  // console.log(msg)

  const handleModal = (index) => {
    setModalOpen(true);
    setPhotoIndex(index);
  };

  const onDownload = (files) => {

    for (let i=0; i<files?.length; i++) {
      var filename = files[i].substring(files[i].lastIndexOf('/')+1);
      saveAs(files[i], `${filename}.jpg`)

    }
}

  return (
    <div className={myMsg ? "Msg_wrapper Msg_own" : "Msg_wrapper"}>
        <div className="Msg_top">
        {!myMsg && chatUser?.photos &&
           <img className='Msg_User_photo'
            src={thumb(chatUser?.photos[0], config.thumb_width)}
            alt="" />
        }
        {msg?.text &&
          <div className="Msg_textBox">
            {!myMsg && <h4>{chatUser?.fName}</h4> }
            <p className='Msg_text'>{msg?.text}</p>
          </div>
        }

        {msg?.files?.length >0 &&
        <div className="Msg_img_box">
        {msg?.files && msg?.files.map((image, index) =>(
            <div key={image} onClick={() => handleModal(index)}>
                <img className='MSG_sentImages'
                src={thumb(image, config.user_photo_width)}
                alt=""
                />
            </div>
          ))}
        </div>
        }

        </div>

        <div className="Msg_bottom">

          <div className="Msg_btm_info">

            {msg?.files?.length >0 &&
              <h6 onClick={()=>onDownload(msg?.files)}>{t("download",{ns:"tr"})}</h6>
            }

            {msg?.createdAt ?
              <span><ReactTimeAgo date={Date.parse(msg?.createdAt)} locale={lang}/></span>
              :
              null
            }
          </div>

        </div>

<Modal open={modalOpen} close={() => setModalOpen(false)}>

  <div className="ModalPhoto">
    <Swiper
      slidesPerView={1}
      spaceBetween={50}
      modules={[Navigation, Zoom]}
      zoom={true}
      initialSlide={photoIndex}
      keyboard={{ enabled: true }}
      loop
      navigation
      style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
      >

      {msg?.files && msg?.files.map((pic) => (

          <div key={pic} className="slider_img_box">

          <SwiperSlide>
            <div className="swiper-zoom-container">
              <img className="slider_img" src={pic} alt="" />
            </div>
          </SwiperSlide>

          </div>
        ))}

    </Swiper>
  </div>

</Modal>

    </div>
    )
}

export default Msg