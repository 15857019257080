import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import * as api from '../../utils/api/api.utils'

import { age } from "../../utils/age";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Keyboard, Zoom } from "swiper";

import BackBtn from "../../components/Buttons/BackBtn";
import FavBtnWht from "../../components/Buttons/UserPageFavBtn";
import LeftBtn from "../../components/Buttons/LeftBtn";
import RightBtn from "../../components/Buttons/RightBtn";
import Verified from "../../assets/verified.svg";

import Modal from "../../components/Modal/Modal";
import Spinner from "../../components/Spinner/Spinner";
import FeedBack from "../../components/FeedBack/FeedBack";
import OutsideClick from "../../utils/OutsideClick";
import UserMenu from "../../components/Menu/UserMenu";

import MsgBtn from "../../components/Buttons/MsgBtn";

import { thumb } from "../../utils/thumb";
import { config } from "../../utils/config";
import { handleError } from "../../utils/handleError";
import LoadingScreen from "../../components/Spinner/LoadingScreen";

import 'swiper/scss';
import 'swiper/css/zoom';
import 'swiper/scss/navigation';
import "./UserPage.scss";
import FollowUserBtn from "../../components/Buttons/FollowUserBtn";
import EditBtn from "../../components/Buttons/EditBtn";
import LikesChip from "../../components/Chips/LikesChip";
const UserPage = () => {
  const { jwtUser, setJwtUser } = useContext(UserContext);
  const [user, setUser] = useState({});
  const [deleted, setDeleted] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const myId = jwtUser?.data?.user?._id
  const username = useParams().id;
  const isMyPage = jwtUser?.data?.user?.username===username
  const navigate = useNavigate();
  const {t} = useTranslation(['translation','countries','tr']);
  const { mobile, mobileBig  } = useMatchMedia();

  const slides_num = mobile? 1 : 3;

  useEffect(() => {
    api.getUserByUsername(username).then(res=> {
      if(res?.status===200){
        if(res.data.deleted) setDeleted(true)
        setUser(res.data)
        setLoading(false)
      }else{
        handleError(res)
      }
    })
  }, [username]);

  SwiperCore.use([Keyboard]);

  const handleModal = (index) => {
    setModalOpen(true);
    setPhotoIndex(index);
  };

  if(loading){
    return <Spinner/>
  }

  if(deleted){
    return <h1 className="UP_deletedUser">{t("up_userDeleted",{ns:"tr"})}</h1>
  }

  return (

<div className="UserPage_wrapper">
      <div className="UserPage_pic_area">

    <Swiper
      slidesPerView={slides_num}
      initialSlide={0}
      modules={[Navigation]}
      navigation={{
        nextEl: '.UP_RightBtn',
        prevEl: '.UP_LeftBtn',
    }}
    >
        {user?.photos && user?.photos.map((photo, index) =>
        <SwiperSlide key={photo} onClick={() => handleModal(index)}>

            {/* <img className="highResImg"
              src={thumb(photo, config.thumb_width)}
              srcset={`${photo} 2x`}
              loading="lazy" alt="" />
            <div class="swiper-lazy-preloader"></div> */}

          <div className="lowResImg" style={{ backgroundImage: `url("${thumb(photo, config.thumb_width)}")` }} >
            <img className="highResImg" src={photo} loading="lazy" alt="" />
            <div class="swiper-lazy-preloader"></div>
          </div>

        </SwiperSlide>
        )}

    </Swiper>

      </div>

        <div className="UP_BackBtn" > <BackBtn/> </div>
        <div className="UP_LeftBtn" > <LeftBtn /></div>
        <div className="UP_RightBtn"> <RightBtn /></div>

        {!isMyPage&&
          <div className="UP_FavBtnWht">
            <FavBtnWht jwtUser={jwtUser} setJwtUser={setJwtUser} user={user} />
          </div>
        }

      <div className="UserPage_title_block">

        <div className="UP_title_block_left">
          <div className="UP_CountryLine">
            <img className="UP_flag" src={`/assets/flags/small/${user?.country?.toLowerCase()}.jpg`} alt="" />
            <span><b> {t(user.country, { ns: "countries" })}</b>, {user?.city}</span>
          </div>

          <div className="UP_NameLine">
              {user?.verified && <img className="UP_verified" src={Verified} alt="" /> }
              <p><b>{user?.fName}</b>, {age(user?.birthDate)}</p>
          </div>

        </div>


        <div className="UP_title_block_mid">
          <LikesChip item={user} size={24}/>

          <div className="UP_follow_area">
            <h4>@{user?.username}</h4>
            {!isMyPage &&
              <FollowUserBtn jwtUser={jwtUser} setJwtUser={setJwtUser} user={user}/>
            }
          </div>

        </div>

        <div className="UP_title_block_right">

          {isMyPage?
            <EditBtn
              tooltip={t("Edit",{ns:"tr"})}
              onClick={()=>navigate(`/edituser/${user.username}`)}
            />
            :
              <MsgBtn userId={user._id} small={mobileBig}/>
            }
        </div>

      </div>

      <div className="UserPage_NavBar">

      <NavLink to={`/user/${user?.username}/`}
      className={({isActive}) => isActive ? 'UP_Navlink_active' : ''}>
        <div className="UP_NavBar_Item">{t("up_AboutMe",{ns:"tr"})}</div></NavLink>

      <div className="UPNBI_followers">
        <NavLink to={`/user/${user?.username}/followers`}
        className={({isActive}) => isActive ? 'UP_Navlink_active' : ''}>
        <div className="UP_NavBar_Item">{t("up_Followers",{ns:"tr"})}</div></NavLink>
      </div>

      <div className="UPNBI_trips">
        <NavLink to={`/user/${user?.username}/mytours`}
        className={({isActive}) => isActive ? 'UP_Navlink_active' : ''}>
        <div className="UP_NavBar_Item">{t("up_MyTrips",{ns:"tr"})}</div></NavLink>
      </div>

      <div className="UPNBI_reviews">
        <NavLink to={`/user/${user?.username}/reviews`}
        className={({isActive}) => isActive ? 'UP_Navlink_active' : ''}>
        <div className="UP_NavBar_Item">{t("up_Reviews",{ns:"tr"})}</div></NavLink>
      </div>

      <div className="UPNBI_gallery">
        <NavLink to={`/user/${user?.username}/mygallery`}
        className={({isActive}) => isActive ? 'UP_Navlink_active' : ''}>
        <div className="UP_NavBar_Item">{t("up_Gallery",{ns:"tr"})}</div></NavLink>
      </div>

      <div className="UPNBI_other">
        <OutsideClick doSmth={() => setMenuOpen(false)}>
          <div className="UP_NavBar_Item"
          onClick={() => setMenuOpen(!menuOpen)} >
          <p>{t("More_info",{ns:"tr"})}</p>
            <UserMenu
            user={user}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            />
          </div>
        </OutsideClick>
      </div>


        {/* <div className="UP_NavBar_Item">More Info</div> */}
      </div>

      <div className="UP_Outlet_container">
        <div className="UP_Outlet">
          <Outlet context={{user, setUser, isMyPage}} />
        </div>

          <div className="UP_footer">
            {!isMyPage &&
              <p onClick={()=> setFeedbackOpen(true)}>{t("sendFeedback", { ns: "translation" })}</p>
            }
          </div>
    </div>


    <Modal open={modalOpen} close={() => setModalOpen(false)}>

      <div className="ModalPhoto">
        <Swiper
          slidesPerView={1}
          spaceBetween={50}
          modules={[Navigation, Zoom]}
          zoom={true}
          initialSlide={photoIndex}
          keyboard={{ enabled: true }}
          loop
          navigation
          style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }}
          >

          {user?.photos && user?.photos.map((pic) => (

            <div key={pic}>

              <SwiperSlide>
                <div style={{backgroundImage: `url("${thumb(pic, config.thumb_width)}")`}}
                  className="lowResImgView swiper-zoom-container" >
                      <img className="highResImgView" src={pic}  alt="" />
                      <div class="swiper-lazy-preloader"></div>
                    </div>
              </SwiperSlide>

            </div>
          ))}

        </Swiper>
      </div>

    </Modal>

<Modal
  open={feedbackOpen}
  close={() => setFeedbackOpen(false)}
  NoCloseBtn={true}
  >
    <FeedBack
      close={()=> {
        document.body.classList.remove("active-modal");
        setFeedbackOpen(false)}}
    />

</Modal>

    </div>
  );
};

export default UserPage;
