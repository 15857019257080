import { Suspense } from "react";
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import Post from "../../components/Post/Post";
import "./FavoritesPages.scss";
import MPost from "../../components/Post/MPost";

const FavPostsPage = ({jwtUser, setJwtUser, allLangs, allInterests, posts}) => {
  const {t} = useTranslation(['tr']);
  const { mobile, mobileBig } = useMatchMedia();

  return (
   
    <div className="FavPage_wrapper">

      <h1>{t("fav_posts",{ns:"tr"})}</h1>
<Suspense fallback="Loading...">

      {posts && posts.map((post, idx) => (
          <div key={idx}>

        {mobile? <MPost
                  post={post}
                  allLangs={allLangs}
                  allInterests={allInterests}
                  />
                  :
                  <Post  
                  post={post} 
                  allLangs={allLangs}
                  allInterests={allInterests}
                  jwtUser={jwtUser}
                  setJwtUser={setJwtUser}
                  />
      }

          </div>
        ))}
</Suspense>

    </div>

  );
};

export default FavPostsPage;
