import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMatchMedia } from "../../../hooks/use-match-media";

import { showDate } from '../../../utils/showDate';
import { thumb } from "../../../utils/thumb";
import { config } from "../../../utils/config";
import { showAges } from "../../../utils/showAges";

import "./../news.scss";

const TourBlock = ({noText, tour}) => {
  const { t } = useTranslation(["tr", "countries"]);
  const navigate = useNavigate();

  const { date, year } = showDate(tour);
  const ages = showAges(tour)
  const { mobile } = useMatchMedia();

  const navigateTo = (tour)=>{
    navigate(`/tour/${tour?._id}/`)
  };

  return (
    <div className="N_Tour_ContentBoxBig">
      {mobile &&<h2 onClick={() => navigateTo(tour)}>{tour?.title}</h2>}
      <div className="N_Tour_ContentBox">

        <div className="N_Tour_Top">
          <div className="N_Tour_ibg" style={{ backgroundImage: `url("${thumb(tour?.titleImages[0], config.thumb_width)}")`}} onClick={() => navigateTo(tour)}>
            <img className="N_Tour_ibg" src={thumb(tour?.titleImages[0], config.image_width)} loading="lazy" alt="country"/>
          </div>

          <div className="N_Tour_rightSide">
          {!mobile &&<h2 onClick={() => navigateTo(tour)}>{tour?.title}</h2>}
          {mobile ?
            <>
              <span> <b> {t(tour.country, { ns: "countries" })}, </b> {tour?.city}</span>
              <h3><b>{date}</b> {year}</h3>
            </>
              :
            <>
              <p><b>{t("Where", { ns: "tr" })}:</b> <span> <b> {t(tour.country, { ns: "countries" })}, </b> {tour?.city}</span></p>
              <h3><b>{t("Dates", { ns: "tr" })}: {date}</b> {year}</h3>
            </>
          }
            <p><b>{tour?.amount} {t("ppl",{ns:"tr"})}</b> ( {ages} )</p>
          </div>
        </div>


        {!noText && <div className="N_TextBox">{tour?.text}</div> }

      </div>
    </div>
  )
}

export default TourBlock