import { FiMessageSquare } from "react-icons/fi";

import './FavButton.scss'
const NewsAnswerBtn = ({msgAmnt, onClick}) => {

  return (
    <div className='FavBtnNews_wrapper' onClick={onClick}>
      <FiMessageSquare size={20} color='#A681B6'/>
      {msgAmnt>0 && <span>{msgAmnt}</span>}
    </div>
  )
}

export default NewsAnswerBtn