import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import './TravelPlan.scss'
import EmptyHere from "../../components/PageItems/EmptyHere";

const TravelPlan = () => {

  const {tour} = useOutletContext();
  const {t} = useTranslation(['tr']);


  return (
    <div className="TP_Travelplan_container">

      {tour?.tourPlan?
      <>
        <h4><b>{t("TripTravelPlan",{ns:"tr"})}:</b></h4>
        <div className="Travelplan_box">
          <div dangerouslySetInnerHTML={{ __html: tour?.tourPlan }} />
        </div>
      </>
      :
      <EmptyHere
        text={t("emptyHere",{ns:"tr"})}
        subText={t("TravelPlan_text",{ns:"tr"})}
      />

      }
    </div>
  )
}

export default TravelPlan