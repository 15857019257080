import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/user.context";
import { useNavigate, useParams } from "react-router-dom";
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';

import PageHeader from "../../components/PageHeader/PageHeader";
import User from "../../components/User/User";
import Spinner from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination/Pagination";

import "./PeoplePage.scss";
import UserSearchBar from "../../components/SearchBar/UserSearchBar";
import { birthDate } from "../../utils/birthDate";
import { handleError } from "../../utils/handleError";

const PeoplePage = () => {
  const [users, setUsers] = useState();
  const { onlineUsers} = useContext(UserContext);

  const [loading, setLoading] = useState(true);

  const { pageNumber } = useParams();
  const pageNum = pageNumber || 1;
  const startShowAmnt = 100;

  const [page, setPage] = useState(pageNum);
  const [amount, setAmount] = useState(startShowAmnt);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();

  const [country, setCountry] = useState(null)
  const [gender, setGender] = useState('');
  const [startAge, setStartAge] = useState("");
  const [endAge, setEndAge] = useState("");

  const [search, setSearch] = useState({})
  const [sorting, setSorting] = useState("")

  const navigate = useNavigate();
  const {t} = useTranslation(['tr']);

 // делаю массив из Id из массива объектов
  const onlineUsersArray = onlineUsers.map(el=> el.userId)

  const makeSearch=()=>{

    api.getUsers(page, amount,
      sorting ? sorting: null,
      search.country ? search.country: (country? country?.value: null),
      search.gender ? search.gender: (gender? gender: null),
      // search.startAge ? birthDate(search.startAge): (startAge? birthDate(startAge): null),
      // search.endAge ? birthDate(search.endAge): (endAge? birthDate(endAge): null),

      search.startAge ? search.startAge: (startAge? startAge: null),
      search.endAge ? search.endAge: (endAge? endAge: null),

      search.city ? search.city: null,
      search.interests ? search.interests: null,
      search.langs ? search.langs: null,
      search.verified ? search.verified: null,
      search.orientation ? search.orientation: null,
      search.character ? search.character: null,
      search.finances ? search.finances: null,
      search.height ? search.height: null,
      search.alkohol ? search.alkohol: null,
      search.relations ? search.relations: null,
      search.religion ? search.religion: null,
      ).then((res) => {

        if(res.status===200){
          setUsers(res.data.data)
          setPages(res.data.pages)
          setTotal(res.data.total)
          setLoading(false)
          navigate(`/people/${page}`)
        }else if(res?.response?.status===403){
          setLoading(false)
          setPage(1)
        }else{
          handleError(res)
        }
  });
  }

  useEffect(() => {
    if(startAge.length===2 || endAge.length===2){
      makeSearch();
    }
}, [startAge, endAge]);

  useEffect(() => {
    makeSearch();
}, [page, amount, country, gender, sorting, search]);


  const show_amount = 155;

  const resetSearch=()=>{
      setSearch({})
      setSorting("")
      setGender("")
      setStartAge("")
      setEndAge("")
      setCountry(null)
  }

  const sortSearch= (sorting)=>{
    setPage(1)
    setSorting(sorting);
  }

  const filterSearch= (query)=>{
    setPage(1)
    setSearch(query)
  }

  if(loading){
    return <Spinner/>
  }

  return (

    <div className="PeoplePage_wrapper">
      <PageHeader
        icon="people"
        title={t("people",{ns:"tr"})}
        text={t("people_text",{ns:"tr"})}
        btn={t("people_invite",{ns:"tr"})}
      />

      <UserSearchBar
        country={country}
        setCountry={setCountry}

        gender={gender}
        setGender={setGender}

        startAge={startAge}
        setStartAge={setStartAge}
        endAge={endAge}
        setEndAge={setEndAge}

        search={search}
        sorting={sorting}
        sortSearch={sortSearch}
        filterSearch={filterSearch}
        resetSearch={resetSearch}
      />

      <div className="UP_content_box">

      <div className="users_container">
      {users && users.map((user, index) =>
            index < show_amount &&  (
            <div key={user._id}>
              <User
                user={user}
                online={onlineUsersArray.includes(user._id)}
              />
            </div>
          ))}

      </div>

      <div className="UP_pagination">
        <Pagination
          found={total}
          pages={pages}
          page={page}
          setPage={setPage}
          showAmnt={amount}
          setAmnt={setAmount}
          startShowAmount={startShowAmnt}
          amountStep={10}
        />
      </div>

    </div>

    </div>

  );
};

export default PeoplePage;
