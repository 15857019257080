import { useState } from "react";
import { AiTwotoneHeart } from "react-icons/ai";
import OutsideClick from "../../utils/OutsideClick";
import LikesMenu from "../Menu/LikesMenu";

const LikesChip = ({item, like=0, size=15 }) => {
  const [likesMenuOpen, setLikesMenuOpen] = useState(false);


  return (

    <OutsideClick doSmth={() => setLikesMenuOpen(false)}>

        <div className="LikesChip"
        onClick={() => {setLikesMenuOpen(!likesMenuOpen)}}
        onMouseEnter={() => {setLikesMenuOpen(true)}}
        onMouseLeave={() => {setLikesMenuOpen(false)}}
         >
            {item?.user_liked_me?.length+like !== 0 && <>
                <AiTwotoneHeart size={size} color="#A681B6" />
                <p style={{fontSize: size > 20? "16px": "14px"}}>{item?.user_liked_me?.length+like}</p>
            </>}

        <LikesMenu
            users={item?.user_liked_me}
            menuOpen={likesMenuOpen}
            setMenuOpen={setLikesMenuOpen}
        />

        </div>
    </OutsideClick>



  )
}

export default LikesChip