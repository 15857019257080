import { useContext, useEffect, useState } from "react";
import * as api from '../../utils/api/api.utils'
import { UserContext } from '../../context/user.context';
import Spinner from "../../components/Spinner/Spinner";
import VerifyTour from "../../components/Tour/VerifyTour";
import { handleError } from "../../utils/handleError";

import './FeedBackPage.scss'
const VerifyTourPage = () => {
  const { jwtUser} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState();
  const [tours, setTours] = useState();

  useEffect(() => {
    api.getVerifyingTours(jwtUser).then(res=> {
      if(res?.status===200){
        setTours(res.data)
      }else{
        handleError(res)
      }
    })
    setLoading(false)
  }, [updated]);

  if(loading){
    return <Spinner/>
  }

    return (
      <div className="VerifyTourPage_wrapper">

      <div className="VTP_container">
      {tours && tours.map((tour) => (
          <div key={tour?._id}>
            <VerifyTour jwtUser={jwtUser} tour={tour} setUpdated={setUpdated} />
          </div>
        ))
      }

      </div>
    </div>
  )
}

export default VerifyTourPage