import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputCountry from "../Input/InputCountry";

import "./TourSearchWin.scss";
import InputField from '../Input/InputField';
import RSelectField from '../Input/RSelectField';
import SearchAges from '../Input/SearchAges';
import SearchStartDate from '../Input/SearchStartDate';
import SearchEndDate from '../Input/SearchEndDate';
import SearchDaysAmnt from '../Input/SearchDaysAmnt';
import SearchInt from '../AddInterest/SearchInt';
import SearchLang from '../AddLang/SearchLang';

const TourSearchWin = ({ search, close }) => {

  const [depart, setDepart] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState("");
  const [ppl, setPpl] = useState("");
  const [gender, setGender] = useState(null);
  const [startAge, setStartAge] = useState("");
  const [endAge, setEndAge] = useState("");
  const [anyAge, setAnyAge] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [noStartDate, setNoStartDate] = useState();
  const [noEndDate, setNoEndDate] = useState();
  const [daysAmount, setDaysAmount] = useState();
  const [anyDates, setAnyDates] = useState();

  const [tourPrice, setTourPrice] = useState("");
  const [tourFee, setTourFee] = useState("");
  const [pocketMoney, setPocketMoney] = useState("");

  const [intValue, setIntValue] = useState(null)
  const [lang, setLang] = useState(null)

  const [filter, setFilter] = useState({})



  useEffect(() => {
    const mapDepart = depart && depart.map(v=>v.value)
    const mapCountry = country && country.map(v=>v.value)
    const mapInterests = intValue && intValue.map(v=>v._id)
    const mapLangs = lang && lang.map(v=>v._id)

    setFilter({
        depart: mapDepart,
        country: mapCountry,
        gender:gender?.value,
        interests:mapInterests,
        langs:mapLangs,
        city,
        ppl,
        startAge,
        endAge,
        anyAge,
        startDate,
        endDate,
        noStartDate,
        noEndDate,
        daysAmount,
        anyDates,
        tourPrice,
        tourFee,
        pocketMoney,
      })

    }, [depart, country, city, ppl,gender, startAge, endAge, anyAge,
      startDate, endDate, noStartDate, noEndDate, daysAmount,anyDates, tourPrice, tourFee, pocketMoney,intValue, lang]);

  const {t} = useTranslation(['translation']);

const handleSearch=()=>{
  if(depart || country|| city||ppl||gender||startAge||endAge||(anyAge===true)||
  startDate || endDate|| noStartDate || noEndDate || daysAmount||anyDates||tourPrice|| tourFee|| pocketMoney||intValue||lang
  ){
    // console.log(query);
  search(filter)
  close()

  }else{
    close()
  }
}

  return (
    <div className="TourSearchWin_wrapper">
      <div className="TSW_contatiner">
        <div className="TSW_header">
          <h2>{t("TourSearch",{ns:"tr"})}</h2>
          <p>{t("TourSearch_t",{ns:"tr"})}</p>
        </div>

        <div className="TSW_contentBox">

<div className="TSW_line TSW_line1">

        <div className="TSW_SearchBlock">
          <InputCountry
              title={t("fromCountry", { ns: "translation" })}
              isMulti
              icon="depart"
              placeholder={t("fromWhatCountry", { ns: "translation" })}
              tooltip={t("TS_fromCountry_tt",{ns:"tr"})}
              name="depart"
              onChange={(val)=>setDepart(val)}
              value={depart}
            />
          </div>
        <div className="TSW_SearchBlock">
          <InputCountry
              title={t("toCountry", { ns: "translation" })}
              isMulti
              icon="country"
              placeholder={t("toWhatCountry", { ns: "translation" })}
              tooltip={t("TS_toCountry_tt",{ns:"tr"})}
              name="country"
              onChange={(val)=>setCountry(val)}
              value={country}
            />
          </div>

          <div className="TSW_SearchBlock">
          <InputField
            title={t("toCity", { ns: "translation" })}
            icon="country"
            type="text"
            placeholder={t("toWhatCity", { ns: "translation" })}
            tooltip={t("TS_toCity_tt",{ns:"tr"})}
            name="city"
            value={city}
            onChange={(e)=>setCity(e.target.value)}
          />
        </div>
</div>

<div className="TSW_line TSW_line2">
    <div className="TSW_searchInt">
      <SearchInt
        placeholder={t("chooseInt",{ns:"int"})}
        intValue={intValue}
        setIntValue={setIntValue}
        isMulti
      />
  </div>
    <div className="TSW_searchLang">
    <Suspense fallback="Loading...">

      <SearchLang
        placeholder={t("chooseLang",{ns:"langs"})}
        lang={lang}
        setLang={setLang}
        isMulti
      />
      </Suspense>
  </div>

</div>

<div className="TSW_line TSW_line3">

        <div className="TSW_SearchBlock">
          <InputField
            title={t("pplInGroup", { ns: "translation" })}
            icon="amountTo"
            type="number"
            inputmode="numeric"
            upTo={t("upTo", { ns: "translation" })}
            tooltip={t("TS_pplInGroup_tt",{ns:"tr"})}
            name="amount"
            value={ppl}
            onChange={(e)=>setPpl(e.target.value)}
          />
        </div>

        <div className="TSW_SearchBlock">
          <RSelectField
            title={t("pplGender", { ns: "translation" })}
            icon="sex"
            name="gender"
            tooltip={t("TS_pplGender_tt",{ns:"tr"})}

            defaultValue={{label:t("any",{ns:"translation"}),value:"any"}}
            value={gender}
            onChange={(value)=>setGender(value)}

            options={[
              { label: t("any",{ns:"translation"}), value: "any" },
              { label: t("men",{ns:"translation"}), value: "men" },
              { label: t("women",{ns:"translation"}), value: "women" },
            ]}
          />
        </div>

        <div className="TSW_SearchBlock">
          <SearchAges
            icon="ages"
            title={t("pplAges", { ns: "translation" })}
            startAge={startAge}
            setStartAge={setStartAge}
            endAge={endAge}
            setEndAge={setEndAge}
            anyAge={anyAge}
            setAnyAge={setAnyAge}

            tooltip={t("TS_pplAges_tt",{ns:"tr"})}
          />
        </div>
</div>


<div className="TSW_line TSW_line4">

        <div className="TSW_SearchDates">
          <SearchStartDate
            title={t("startDate", { ns: "translation" })}
            cb_title={t("noStartDate", { ns: "translation" })}
            cb_tooltip={t("TS_startDate_cb",{ns:"tr"})}
            icon="dates"
            tooltip={t("TS_startDate_tt",{ns:"tr"})}
            startDate={startDate}
            setStartDate={setStartDate}
            noStartDate={noStartDate}
            setNoStartDate={setNoStartDate}
            anyDates={anyDates}

          />
        </div>

        <div className="TSW_SearchDates">
          <SearchEndDate
            title={t("endDate", { ns: "translation" })}
            cb_title={t("noEndDate", { ns: "translation" })}
            cb_tooltip={t("TS_startDate_cb",{ns:"tr"})}
            icon="dates"
            tooltip={t("TS_endDate_tt",{ns:"tr"})}
            endDate={endDate}
            setEndDate={setEndDate}
            noEndDate={noEndDate}
            setNoEndDate={setNoEndDate}
            anyDates={anyDates}
          />
        </div>

        <div className="TSW_SearchDatesAmount">
          <SearchDaysAmnt
            title={t("daysAmount", { ns: "translation" })}
            icon="daysAmount"
            type="number"
            inputmode="numeric"
            upTo={t("upTo", { ns: "translation" })}
            tooltip={t("TS_daysAmount_tt",{ns:"tr"})}
            name="daysAmount"
            value={daysAmount}
            onChange={(e)=>setDaysAmount(e.target.value)}

            cb_title={t("anyDates", { ns: "translation" })}
            cb_tooltip={t("TS_startDate_cb",{ns:"tr"})}
            anyDates={anyDates}
            setAnyDates={()=>setAnyDates(!anyDates)}
          />
        </div>
</div>
<div className="TSW_line TSW_line5">

        <div className="TSW_SearchBlock">
          <InputField
            title={t("tourPrice", { ns: "translation" })}
            icon="money"
            type="number"
            inputmode="numeric"
            upTo={t("upTo", { ns: "translation" })}
            tooltip={t("TS_tourPrice_tt",{ns:"tr"})}
            name="tourPrice"
            value={tourPrice}
            onChange={(e)=>setTourPrice(e.target.value)}
          />
        </div>

      <div className="TSW_SearchBlock">
          <InputField
            title={t("tourFee", { ns: "translation" })}
            icon="money"
            type="number"
            inputmode="numeric"
            upTo={t("upTo", { ns: "translation" })}
            tooltip={t("TS_tourFee_tt",{ns:"tr"})}
            name="tourFee"
            value={tourFee}
            onChange={(e)=>setTourFee(e.target.value)}
          />
        </div>
      <div className="TSW_SearchBlock">
          <InputField
            title={t("pocketMoney", { ns: "translation" })}
            icon="money"
            type="number"
            inputmode="numeric"
            upTo={t("upTo", { ns: "translation" })}
            tooltip={t("TS_pocketM_tt",{ns:"tr"})}
            name="pocketMoney"
            value={pocketMoney}
            onChange={(e)=>setPocketMoney(e.target.value)}
          />
        </div>
</div>

        </div>

      </div>
      <div className="TSW_Btn_line">
      <div className="TSW_btn" onClick={handleSearch}>
      {t("SearchBtn",{ns:"tr"})}</div>
      </div>
    </div>
  );
};

export default TourSearchWin;
