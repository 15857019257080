// import { Suspense } from 'react';
import Select from 'react-select'
import {langs as _langs} from '../../data/languages'
import { useTranslation } from 'react-i18next';

import './SearchLang.scss'

const SearchLang = ({lang, placeholder, setLang, isMulti=false}) => {
    const {t} = useTranslation(['translation', 'countries', 'langs']);

    const langsArr = _langs.map((i) =>{
        return {
          _id: i._id,
          value: i._id,
          label:t(i._id, { ns: "langs" }),
          icon: <img style={{width:'30px',height:'20px' }}  
          src={`/assets/flags/${i._id}.jpg`} alt="" />
        }
      })

  return (
    <div className="SearchLang_wrapper">
        {/* <Suspense fallback="Loading..."> */}

      <Select 
      options={langsArr} 
      value={lang} 
      onChange={(v)=>setLang(v)}

      isMulti={isMulti}
      placeholder={placeholder}
      isClearable={true}
      getOptionValue={o => o.label}
      getOptionLabel={e=>(
      <div className='SL_MenuItem'> {e.icon} 
      <span className='SL_MenuText'>{e.label}</span> </div>)}

      theme={(theme) => ({...theme, borderRadius: 8,
      colors: {
        ...theme.colors,
        primary25: '#eee3f4',
        primary: '#87589C',
      },})} />
        {/* </Suspense> */}

    </div>
  )
}

export default SearchLang