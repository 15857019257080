import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from '../../utils/api/api.utils'

import NewUser from "./NewUser";

import "./NewUsers.scss";

const NewUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    api.getUsers().then((res) => setUsers(res.data.data));
  }, []);

  const show_amount = 6;
  const {t} = useTranslation(['tr']);

  //-- уменьшает массив до 6 чтобы не загружать много юзеров
  const new_users = [...users].slice(0, show_amount);

    return (
    <div className="NewPeople_container">
      <div className="NewPeople_title">
        <p>{t("newPeople",{ns:"tr"})}:</p>
      </div>

      {new_users && new_users.map((user) => (
            <div key={user._id}>
              <NewUser user={user} />
            </div>
          ))}

    </div>
  );
};

export default NewUsers;
