import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'

import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';

import Tours from '../../assets/Menu/tours_purpl.svg'
import Posts from '../../assets/Menu/posts_purpl.svg'
import People from '../../assets/Menu/people_purpl.svg'
import Help from '../../assets/Menu/info_purpl.svg'
// import {ReactComponent as Favorites} from '../../assets/Menu/heart.svg'
// import {ReactComponent as News} from '../../assets/Menu/news.svg'
// import {ReactComponent as Messenger} from '../../assets/Menu/msg.svg'
// import {ReactComponent as Groups} from '../../assets/Menu/community.svg'

import './Menu.scss'

const MobMenu = ({menuOpen, setMenuOpen}) => {

    const { unreadMsg, setUnreadMsg} = useContext(UserContext);
    const {t} = useTranslation(['translation']);
    const navigate = useNavigate();

    const toTours=()=>{
        setMenuOpen(false)
        navigate(`/`)
    }

    const posts=()=>{
        setMenuOpen(false)
        navigate(`/posts`)
    }

    const people=()=>{
        setMenuOpen(false)
        navigate(`/people`)
    }

    // const favorites=()=>{
    //     setMenuOpen(false)
    //     navigate(`/favorites`)
    // }

    // const messenger=()=>{
    //     setMenuOpen(false)
    //     navigate(`/messenger`)
    // }

    const societies=()=>{
        setMenuOpen(false)
        navigate(`/societies`)
    }

    const news=()=>{
        setMenuOpen(false)
        navigate(`/news`)
    }

    const help=()=>{
        setMenuOpen(false)
        navigate(`/help`)
    }

  return (
        <div className={`mob_menubox ${!menuOpen?'MobM_hide':null}`}>

            <div className="mob_menu_item" onClick={toTours}>
            <img src={Tours} alt="" />
                {/* <Tours className='menu_icon' />  */}
                <p>{t("menu_tours", { ns: "translation" })}</p>
            </div>

            <div className="mob_menu_item" onClick={posts}>
                <img src={Posts} alt="" />
                <p>{t("menu_posts", { ns: "translation" })}</p>

            </div>

            <div className="mob_menu_item" onClick={people}>
                <img src={People} alt="" />
                <p>{t("menu_people", { ns: "translation" })}</p>

            </div>

            {/* <div className="mob_menu_item" onClick={favorites}>
                <Favorites className='menu_icon' />
                <p>{t("menu_favorites", { ns: "translation" })}</p>

            </div>

            <div className="mob_menu_item"  onClick={messenger}>
                <Messenger className='menu_icon'/>
                <p>{t("menu_messanger", { ns: "translation" })}</p>
                {unreadMsg>0 && <div className='NavUnreadMsg'>{unreadMsg}</div> }
            </div> */}

            {/* <div className="mob_menu_item" onClick={societies}>
                <Groups className='menu_icon' />
                <p>{t("menu_societies", { ns: "translation" })}</p>

            </div>

            <div className="mob_menu_item" onClick={news}>
                <News className='menu_icon' />
                <p>{t("menu_news", { ns: "translation" })}</p>
            </div> */}

            <div className="mob_menu_item" onClick={help}>
                <img className='helpIcon' src={Help} alt="" />
                {/* <Help className='menu_icon' />  */}
                <p>{t("menu_help", { ns: "translation" })}</p>

            </div>

        </div>
  )
}

export default MobMenu