import React, { useContext, useEffect, useState } from 'react'
import InboxTour from './InboxTour';
import InboxUser from './InboxUser';
import { UserContext } from '../../context/user.context';
import { useTranslation } from 'react-i18next';
import * as api from '../../utils/api/api.utils'
import { handleError } from '../../utils/handleError';
import Spinner from '../Spinner/Spinner';

const Inboxes = ({chats, setChats, inTour, tour, setHeaderUser,
    setHeaderTour, setMessages, setChatUsers }) => {

    const { jwtUser, onlineUsers, setUnreadMsg} = useContext(UserContext);
    const [chatSelected, setChatSelected] = useState();
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    const {t} = useTranslation(['tr']);
    const myId = jwtUser?.data?.user?._id

    const remapChats =(data)=>{
        const TourChats = data.filter(chat=>chat.tourID)
        const UserChats = data.filter(chat=>!chat.tourID && chat.usersID.length===2)

        // console.log(TourChats.length)

        const chatTours = TourChats?.map(tour=>{
            const chatTour = tour?.tourID
            const readMsg = tour?.readByUser.find(u=>u?.userId===myId)?.msgAmnt

            let unreadMsg;
            if(!readMsg){
                unreadMsg = tour?.msgSize
            }else{
                unreadMsg = tour?.msgSize-readMsg
            }

            chatTour.updatedAt = tour?.updatedAt
            chatTour.unreadMsg = unreadMsg;
            chatTour.tour = true;
            return chatTour
        })

    const chatUsers = UserChats?.map(chat=>{
        //Находим второго участника чата кроме меня
        const chatUser = chat?.usersID.find(u=>u?._id!==myId)
        //Находим мои прочитанные сообщения
        const readMsg = chat?.readByUser.find(u=>u?.userId===myId)?.msgAmnt
        let unreadMsg;

        // console.log(chatUser)

        //Вычисляем сколько непрочитанных сообщений у меня
        if(chatUser){
            if(!readMsg){
                unreadMsg = chat?.msgSize
            }else{
                unreadMsg = chat?.msgSize-readMsg
            }

            chatUser.updatedAt = chat?.updatedAt
            chatUser.unreadMsg = unreadMsg;
            chatUser.chatID = chat?.chatID;
        }
        return chatUser
    })
    return [...chatTours, ...chatUsers]
    }

    useEffect(() => {
        if(inTour){
          api.findTourChats(tour?._id).then(res=> {
              if(res.status===200){
              const remappedChats = remapChats(res.data)
              setChats(remappedChats)
              setLoading(false);
              }else{
              handleError(res)
              }
          })
        }else{
          api.findMyChats().then(res=> {
              if(res.status===200){
              const remappedChats = remapChats(res.data)
              setChats(remappedChats)
              setLoading(false);
              }else{
              handleError(res)
              }
          })
        }
    }, [jwtUser, onlineUsers, tour]);


    let filteredChats = chats;
    if(search) {
        filteredChats = filteredChats.filter(c =>
        (c?.fName?.toLowerCase().includes(search.toLowerCase())) ||
        (c?.country?.toLowerCase().includes(search.toLowerCase())) ||
        (c?.city?.toLowerCase().includes(search.toLowerCase())))
    }

    const getMessages= async (index, chat)=>{
        setHeaderTour(null)
        setHeaderUser(chat)
        setChatSelected(index)
        chats[index].unreadMsg = 0
        setChats(chats)

        await api.getMessages(chat.chatID).then(res=> {
            if(res.status===200){
            setMessages(res.data?.messages)
            setChatUsers(res.data?.usersID)
            }else{
            handleError(res)
            }
        })

        await api.getUnreadMsg().then(res=> {
            if(res.status===200){
            setUnreadMsg(res.data)
            }else{
            handleError(res)
            }
        })
    }

    const getTourMessages= async (index, chat)=>{
        setHeaderUser(null)
        setHeaderTour(chat)
        setChatSelected(index)
        chats[index].unreadMsg = 0
        setChats(chats)

        await api.getMessages(chat.chatID).then(res=> {
            if(res.status===200){
            setMessages(res.data?.messages)
            setChatUsers(res.data?.usersID)
            }else{
            handleError(res)
            }
        })

        await api.getUnreadMsg().then(res=> {
            if(res.status===200){
            setUnreadMsg(res.data)
            }else{
            handleError(res)
            }
        })
    }

    if (loading) {
      return <Spinner />;
    }

  return (
    <>
        <div className="MP_Search">
          <input
            value={search}
            onChange={(e)=>setSearch(e.target.value)}
            className='MP_chat_search'
            type="search"
            placeholder={t("msngr_search",{ns:"tr"})}
          />
        </div>

        <h3><b>{t("msngr_chats",{ns:"tr"})}:</b> {chats?.length}</h3>
        <div className="MP_Users">

          {filteredChats?.map((chat, index) =>(
            <div key={index}>
              {chat?.tour ?
                <div className='MP_MsgTour' onClick={()=>getTourMessages(index, chat)}>
                  <InboxTour
                    active={index===chatSelected}
                    tour={chat}
                  />
                </div>
              :
                <div className='MP_MsgUser' onClick={()=>getMessages(index, chat)} >
                  <InboxUser
                    org={tour?.creator._id===chat?._id}
                    active={index===chatSelected}
                    online={onlineUsers.some((u) => u.userId === chat?._id)}
                    user={chat}
                  />
                </div>
              }
            </div>
            ))}

        </div>
    </>
  )
}

export default Inboxes