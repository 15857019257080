import gender_icon from '../../assets/create_account/sex.svg'
import {ReactComponent as MaleIcon} from '../../assets/create_account/male.svg'
import {ReactComponent as FemaleIcon} from '../../assets/create_account/female.svg'

import "./RadioBtn.scss";

const RadioBtn = ({ icon, title,male, female, value, ...otherProps }) => {

  let img;
  if(icon==="gender") {img = gender_icon};

  return (
    <div className="RB_container">
      <div className="RB_title_line">
      <div className="RB_title_sign">
        <img src={img} alt="" />
        <p>{title}</p>
      </div>

      </div>
      
      <div className="RB_btns_box">

      <input 
      id="male" 
      type="radio"
      checked={(value==='male')? true: null}
      {...otherProps}
      value='male'
      />
      
      <label htmlFor="male">

      <div className="RB_genderBtn">
        <MaleIcon className='RB_GenderSVG'/>
      <p>{male}</p>
      </div>

      </label>
      
      <input 
      id="female" 
      type="radio"
      checked={(value==='female')? true: null}
      value='female'
      {...otherProps} 
      />

      <label htmlFor="female">
      <div className="RB_genderBtn">
      <FemaleIcon className='RB_GenderSVG'/>
      <p>{female}</p>
      </div>
      </label>

      </div>

    </div>
  );
};

export default RadioBtn;
