export const thumb = (url, width=300) => {

  if (url?.includes("filestack")) {
    const path = new URL(url);
    return (
      path.protocol + "//" + path.hostname + `/resize=width:${width}` + path.pathname
    );

  } else if (url?.includes("digitaloceanspaces")) {

    // 800 на самом деле с разрешением 400 пикселей, просто оставил так как ссылки ломаются

    const sizes = [200, 800].filter((size)=>size>=width);
    if(sizes.length===0){
      return url;
    }

    const path = new URL(url);
    const pathNameArr = path.pathname.split("/");
    const filename = `thumb_${sizes[0]}_` + pathNameArr.pop();
    const pathName = pathNameArr.join("/") + "/" + filename;
    const thumbURL = path.protocol + "//" + path.hostname + pathName;

    return thumbURL;
  }
  return url;
};
