import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../utils/config";
import { useTranslation } from 'react-i18next';

import ppl from "../../assets/ppl.svg";
import Lang from "../../assets/lang.svg";
import TourFavBtn from "../Buttons/TourFavBtn";
import MsgBtnSml from "../Buttons/MsgBtnSml";

import Modal from "../Modal/Modal";
import SendMsgWin from "../Window/SendMsgWin";
import { thumb } from "../../utils/thumb";
import { showDate } from "../../utils/showDate";
import { showLangs } from "../../utils/showLangs";

import StatusText from "../Chips/StatusText";
import LikesChip from "../Chips/LikesChip";
import TimeStatusText from "../Chips/TimeStatusText";

import "./Tour.scss";
import EditBtn from "../Buttons/EditBtn";
import { showAges } from "../../utils/showAges";
const Tour = ({tour, jwtUser, setJwtUser}) => {
  const [msgModalOpen, setMsgModalOpen] = useState(false);
  const [like, setLike] = useState(0);

  const myId = jwtUser?.data?.user?._id
  const isMy = tour?.creator?._id === myId;

  const navigate = useNavigate();
  const {t} = useTranslation(['translation', 'countries', 'langs']);

  const {date, year } = showDate(tour);
  const langs = showLangs(tour)
  const ages = showAges(tour)

  const sendMessage =()=>{
    if(!jwtUser) navigate('/login')
    setMsgModalOpen(true)
  }

  const navigateTo = (tour)=>{
      navigate(`/tour/${tour?._id}/`)
    };

  const navigateToUser = (user)=>{
    navigate(`/user/${user.username}/`)
  };

  const haveUsers = tour?.joined_users?.length > 0;

  return (
    <div className="Tour_WrapperBig">

      <div className="Tour_contryLine">
        <div className="Tour_countrySide">
          <img className="Tour_cp_flag" src={`/assets/flags/small/${tour?.country.toLowerCase()}.jpg`} alt="" />
          <p><b>{t(tour?.country, { ns: "countries" })}</b>, {tour?.city}</p>
        </div>
        <p><b>{date}</b> {year}</p>
      </div>

      <div className="Tour_wrapper">

        <div className="Tour_topLine">
          <p className="Tour_title" onClick={() => navigateTo(tour)}>{tour?.title}</p>

            {isMy ?
              <EditBtn noShadow small
                tooltip={t("Edit",{ns:"tr"})}
                onClick={()=>navigate(`/edittour/${tour?._id}`)}
              />
              :
              <div className="Tour_Btns_Block">
                <MsgBtnSml onClick={sendMessage} />

                <TourFavBtn
                  jwtUser={jwtUser}
                  setJwtUser={setJwtUser}
                  setLike={setLike}
                  tour={tour}
                />
              </div>
            }

        </div>

        <dir className="Tour_content">

          <div className="Tour__left_side">
            <div onClick={() => navigateTo(tour)} className="Tour_pic_place">
              <div className="Tour_ibg" style={{ backgroundImage: `url("${thumb(tour?.titleImages[0], config.thumb_width)}")`}}>
                <img className="Tour_ibg" src={thumb(tour?.titleImages[0], config.image_width)} loading="lazy" alt="country"/>
              </div>

              <div className="new_dark_line">
                <span>{tour?.daysAmount} {t("days",{ ns:"tr"})}</span>
                <p>$ {tour?.tourPrice}</p>
              </div>

              <div className="Tour_Org_Block">
                <img src={thumb(tour?.creator?.photos[0], config.thumb_width)}
                  alt="org" loading="lazy" />

                <div className="Tour_Org_Rating_Box">
                  <p>{tour?.creator?.rating ? tour?.creator?.rating.toFixed(1) : "new"}</p>
                </div>
              </div>

            </div>
          </div>
          <div className="Tour_rightside">
            <div className="Tour_rs_top">
              <div className="Tour_tourInfo">
                <div className="Tour_infoLine">
                  <img src={ppl} alt="" />
                  <p><b>{tour?.amount} {t("ppl",{ns:"tr"})}</b></p>
                  <span>{ages}</span>
                </div>
                <div className="Tour_infoLine">
                  <img src={Lang} alt="" />
                  <span>{langs}</span>
                </div>
              </div>

            </div>
            <div className="Tour_rs_mid" onClick={() => navigateTo(tour)}>

            {tour?.text?.length > config.charAmount ?
              <div dangerouslySetInnerHTML={{ __html: [`${tour?.text.substring(0, haveUsers? config.charAmount: config.moreChars )}...`] }} />
            :
              <div dangerouslySetInnerHTML={{ __html: tour?.text }} />
            }

            </div>

            {haveUsers &&
              <div className="Tour_rs_btm">
                <div className="rs_btm_users">

                  {tour?.joined_users && tour?.joined_users.map((user, index) =>
                  index < 5 && (
                    <div onClick={() => navigateToUser(user)} key={user._id}>
                        <img className="Tour_userImg" src={thumb(user?.photos[0], config.thumb_width)} alt="joined user" loading="lazy"/>
                    </div>
                  ))}

                  {((tour?.joined_users)?.length>=8)&&
                  <div onClick={()=>navigate(`/tour/${tour?._id}/people`)} className="rs_btm_more_users">+{tour?.joined_users?.length - 8}</div>
                  }

                </div>
              </div>
            }

          </div>



        </dir>

        <div className="Tour_bottomLine">

        {!tour?.isTest &&
          <TimeStatusText item={tour}/>
        }
          <StatusText tour={tour} jwtUser={jwtUser} />

          {tour?.isTest ?
            <div className="Tour_TestText">{t("testTrip2",{ns:"tr"})}</div>
            :
            <LikesChip item={tour} like={like} size={15}/>
          }
        </div>

        <Modal open={msgModalOpen} close={()=>setMsgModalOpen(false)}>

        <SendMsgWin
          jwtUser={jwtUser}
          setJwtUser={setJwtUser}
          user={tour?.creator}
          close={()=>{
            document.body.classList.remove("active-modal");
            setMsgModalOpen(false)
            }}
        />

        </Modal>

      </div>
    </div>
  );
};

export default Tour;
