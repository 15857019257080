import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';

import { config } from "../../utils/config";
import { thumb } from "../../utils/thumb";
import TourFavBtn from "../Buttons/TourFavBtn";

import RequestChip from "../Chips/RequestChip";
import JoinedChip from "../Chips/JoinedChip";
import ConfirmedChip from "../Chips/ConfirmedChip";
import RejectChip from "../Chips/RejectChip";
import { showDate } from "../../utils/showDate";
import StatusText from "../Chips/StatusText";

import './MTour.scss'
const MTour = ({tour}) => {
  const { jwtUser, setJwtUser } = useContext(UserContext);

  const navigate = useNavigate();
  const {t} = useTranslation(['translation', 'countries']);
  const {date, year, isFinished} = showDate(tour);
  const myId = jwtUser?.data?.user?._id

  const inJoined = tour?.joined_users?.some(user => user._id === myId)
  const inConfirmed = tour?.confirmed_users?.includes(myId)
  const inRequests = tour?.join_requests?.includes(myId)
  const inRejected = tour?.rejected_users?.includes(myId)

  const navigateTo = (tour)=>{
      navigate(`/tour/${tour?._id}/`)
    };

  const navigateToUser = (user)=>{
      navigate(`/user/${user.username}/`)
    };

  return (
    <div className="MTour_wrapper">
      <div className="MTour_dateLine">
        <p><b>{date}</b> {year}</p>
        {tour?.daysAmount>0 &&
          <p>{tour?.daysAmount} {t("days",{ ns:"tr"})}</p>
        }
      </div>

    <div className="MTour_container">
      <div className="MTour_pic_area" onClick={() => navigateTo(tour)} >
        <img className='ibg' alt="background" loading="lazy" src={thumb(tour?.titleImages[0], config.image_width)} />
        <div className="MTour_darkline">

          <div className="MT_pa_top_city">
            <img className="MT_pa_flag" src={`/assets/flags/small/${tour?.country.toLowerCase()}.jpg`} loading="lazy" alt="" />
            <p><b>{t(tour?.country, { ns: "countries" })}</b>, {tour?.city}</p>
          </div>

          <span className='MT_price'>$ {tour?.tourPrice}</span>
        </div>

          {tour?.isTest &&
            <div className="MT_testChip"> {t("testTrip2",{ ns:"tr"})} </div>
          }

        <div className="MTour_pa_bottom">

          <div className="MT_pa_b_org">
            <img src={thumb(tour?.creator?.photos[0], config.thumb_width)}
            alt="organizator" loading="lazy" />
            <p>{tour?.creator?.rating ? tour?.creator?.rating.toFixed(1) : "new"}</p>
          </div>

          <div className="MT_icons_side">

              {inRequests && <div className="MT_Chip">
                <RequestChip tooltip={t("youRequested",{ns:"tr"})} small mobile />
              </div>}

              {inConfirmed&& <div className="MT_Chip">
                <ConfirmedChip tooltip={t("youConfirmed",{ns:"tr"})} small mobile />
              </div>}

              {inRejected && <div className="MT_Chip">
                <RejectChip tooltip={t("youRejected",{ns:"tr"})} small mobile />
              </div>}

              {inJoined && <div className="MT_Chip">
                <JoinedChip tooltip={t("youJoined",{ns:"tr"})} small mobile />
              </div>}

              <div onClick={(e) => e.stopPropagation()}  className="MT_pa_b_like">
                <TourFavBtn
                  jwtUser={jwtUser}
                  setJwtUser={setJwtUser}
                  tour={tour}
                  mobile={true}
                />
              </div>
          </div>
        </div>
    </div>

    <div className="MTour_text_area">
      <p>{t("joined_sml",{ns:"tr"})}: <b>{tour?.joined_users?.length+1} / </b>{tour?.amount}</p>

      {isFinished ?
        <div className="MTour_FinishedText">{t("tourFinished",{ns:"tr"})}</div>
      :
        <StatusText tour={tour} jwtUser={jwtUser} mob/>
      }
    </div>

      <div className="MTour_users_area">
        {tour?.joined_users && tour?.joined_users.map((user, index) =>
            index < 7 && (
              <div onClick={() => navigateToUser(user)} key={user._id}>
                  <img src={thumb(user?.photos[0], config.thumb_width)}
                  alt="joined user" loading="lazy"/>
              </div>
        ))}

        {(tour?.joined_users?.length>7)&&
        <div onClick={()=>navigate(`/tour/${tour?._id}/people`)} className="MT_ua_more_users">
          +{tour?.joined_users?.length - 7}
        </div>
        }
      </div>


    </div>
    </div>
  )
}

export default MTour
