import React, { useContext, useRef, useState } from "react";
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';
import SpinnerSmall from "../Spinner/SpinnerSmall";
import Picker from "@emoji-mart/react";
import SendBtn from "../Buttons/SendBtn";
import * as api from "../../utils/api/api.utils";
import data from '@emoji-mart/data'
import { useMatchMedia } from "../../hooks/use-match-media";

import clip from "../../assets/clip.svg";

import { config } from "../../utils/config";
import { resize } from "../../utils/resize";
import { handleError } from "../../utils/handleError";

import "./Messenger.scss";
const ChatFooter = ({ headerUser, headerTour, setMessages, setChatUsers }) => {
  const { jwtUser, socket } = useContext(UserContext);

  const [uploading, setUploading] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);

  const [prevText, setPrevText] = useState("");
  const [text, setText] = useState("");

  const userId = jwtUser?.data?.user?._id;
  const inputRef = useRef(null);
  const {t} = useTranslation(['tr']);

  const { ipadSml } = useMatchMedia();

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleEmoji=(e)=>{
    let msg = text;
    msg += e.native;
    setText(msg)
  }

  const pressKey = (e) => {
    //metaKey = Command on Mac
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter") sendMsg();
    if (e.key === "ArrowUp") setText(prevText);
  };

// console.log(headerUser)

  const sendMsg = ()=>{
  if(text!==""){

    if(headerTour){
      socket?.emit("send_room_msg", {
        senderId: userId,
        room: headerTour?.chatID,
        text: text
      })

      api.sendTourMsg(headerTour?.chatID, text).then(res=>{
        if(res.status===200){
          setPrevText(text)
          setText("")
          api.getMessages(headerTour?.chatID).then(res=> {
            if(res.status===200){
              setMessages(res.data?.messages)
              setChatUsers(res.data?.usersID)
            }else{
              handleError(res)
            }
          })
        }else{
          handleError(res)
        }
      })
    }

    if(headerUser){

      socket?.emit("send_msg", {
        senderId: userId,
        receiverId: headerUser?._id,
        text: text
      })

      api.sendMsgToUserFromChat(headerUser?.chatID, text).then(res=>{
        if(res.status===200){
          setPrevText(text)
          setText("")
          api.getMessages(headerUser?.chatID).then(res=> {
            if(res.status===200){
              setMessages(res.data?.messages)
              setChatUsers(res.data?.usersID)
            }else{
              handleError(res)
            }
          })
        }else{
          handleError(res)
        }
      })
    }
}
}

  const handleFile = async (event) => {
    try {
      if (event.target.files?.length > 0) setUploading(true);

      new Promise((resolve) => {
        let resizedImages = [];
        for (let i = 0; i < event.target.files?.length; i++) {
          resize(
            event.target.files[i],
            config.uploadImage_maxWidth,
            config.uploadImage_maxHeight,
            config.uploadImage_format,
            config.uploadImage_quality
          ).then((res) => {
            resizedImages.push(res);
            if (resizedImages.length === event.target.files?.length) {
              resolve(resizedImages);
            }
          });
        }
      }).then(async (resizedImages) => {
        const formData = new FormData();
        formData.append("text", text ? text : "");

        for (let i = 0; i < resizedImages?.length; i++) {
          formData.append("sendImages", resizedImages[i]);
          // console.log(i);
        }

        let chatID;
        if (headerTour) {
          chatID = headerTour?.chatID;
        }
        if (headerUser) {
          chatID = headerUser?.chatID;
        }

        await api.sendFiles(chatID, formData).then((res) => {
          setUploading(false);
          if (headerTour) {
            socket?.emit("send_room_msg", {
              senderId: userId,
              room: headerTour?.chatID,
              text: "",
              files: res?.data?.msg?.files,
            });
          }
          if (headerUser) {
            socket?.emit("send_msg", {
              senderId: userId,
              receiverId: headerUser?._id,
              text: "",
              files: res?.data?.msg?.files,
            });
          }

          api.getMessages(chatID).then((res) => {
            if (res.status === 200) {
              setMessages(res.data?.messages);
              setChatUsers(res.data?.usersID);
            } else {
              handleError(res);
            }
          });
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="MP_Footer">
      {uploading ?
        <SpinnerSmall />
       :
        <div className="MP_sendFile" onClick={handleClick}>
          <img src={clip} alt="" />
        </div>
      }

      <input
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        multiple
        disabled={!headerUser && !headerTour}
        onChange={handleFile}
      />

      <textarea
        className="MP_chat_input"
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        onKeyDown={(event) => pressKey(event)}
        autoFocus
        type="text"
        disabled={!headerUser && !headerTour}
        placeholder={t(headerUser || headerTour ? (ipadSml? "mob_writeMsg" : "msngr_writeMsg") : "msngr_noChatSelected",{ ns: "tr" })}
      />

      <div className="MP_SmileBtn" onClick={(e) => {
          e.stopPropagation();
          if (headerUser || headerTour) setShowEmoji(!showEmoji);
        }}
      >
        &#9786;
      </div>

      {showEmoji &&
        <div className="MP_Emoji_box">
          <Picker
            data={data}
            onEmojiSelect={handleEmoji}
            theme="light"
            onClickOutside={() => setShowEmoji(false)}
          />
        </div>
      }

      <div className="MPF_SendBtnArea">
        <SendBtn disabled={!text} onClick={text ? () => sendMsg() : () => {}} />
      </div>
    </div>
  );
};

export default ChatFooter;
