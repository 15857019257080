import axios from "axios";
import { config } from "../config";

const URL=config.URL;

const allowedURLS = ['/login','/tours/','/tour/', '/posts/', '/people/', '/reset-password', '/user/', '/societies', '/news', '/help', '/register']

axios.interceptors.request.use((req) => {

  const fromStorage= JSON.parse(localStorage.getItem('profile'))

    if (localStorage.getItem("profile")) {

    req.headers.Authorization = `Bearer ${fromStorage?.data?.token}`;
    }
    return req;
});

axios.interceptors.response.use(res => {
  return res;
}, async (error) => {
console.error("Axios response:",error);

const originalRequest = error.config;
// console.log(originalRequest);

if (error?.response?.status === 401 && !originalRequest._retry) {
  originalRequest._retry = true;
  const data = await refreshUser();
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.token;
  console.log('%c API Utils Refresh token done', 'color: lightBlue');
  localStorage.setItem('profile', JSON.stringify({data: data.data}));
  return axios(originalRequest);
}

 if (error.response?.status === 402) {
   console.log('%c API error 402 - Refresh Token not found', 'color: red');
   localStorage.removeItem('profile');

  if(allowedURLS.filter(url=>window.location.pathname.includes(url)).length===0 && window.location.pathname!=="/" ){
    window.location.href = '/login'
  }
 }

 return error;
});

//-- Сохранение нового посетителя на Tripico
export const setNewVisitorID = (newID, fromCountry, fromCity) =>{
  const res = axios.post(`${URL}/api/addnewvisitor`, {
    visitorID: newID,
    fromCountry,
    fromCity
  });
  return res;
}

//-- Получить статистку сайта для Админа
export const getStats = () =>{
  const res = axios.get(`${URL}/api/stats`,);
  return res;
}

//-- Получение Геолокации и города
export const getLocation = async () =>{
  try {
    const location = await axios.get(`https://ipapi.co/json`);
    return location;
  } catch (error) {
    return error
  }
}


//-- Получение всех Интересов -----------------
export const getInterests = () =>{
  const interests = axios.get(`${URL}/api/interests`);
  return interests;
}

//-- Создание Интереса -----------------
export const createInterest = (formFields) =>{
  const interest = axios.post(`${URL}/api/interests`, formFields);
  return interest;
}
//-- Получение всех Языков -----------------
export const getLangs = () =>{
  const langs = axios.get(`${URL}/api/langs`);
  return langs;
}

//-- Создание Языка -----------------
export const createLang = (formFields) =>{
  const lang = axios.post(`${URL}/api/langs`, formFields);
  return lang;
}


//----------- USER VERIFICATION -------------

//-- Get all verifying users------- ---------
export const getVerifyingUsers = (user) =>{
  const users = axios.post(`${URL}/api/vusers`, user);
  return users;
}

//-- Admin Verify User  -----------------
export const adminVerifyUser = (id, formFields) =>{
  const data = axios.patch(`${URL}/api/vuser/${id}`, formFields);
  return data;
}

//-- Admin Deny User  -----------------
export const adminDenyUser = (id, formFields) =>{
  const data = axios.patch(`${URL}/api/duser/${id}`, formFields);
  return data;
}

//----------- TOUR VERIFICATION -------------

//-- Get all verifying users------- ---------
export const getVerifyingTours = (user) =>{
  const users = axios.post(`${URL}/api/vtours`, user);
  return users;
}

//-- Admin Verify Tour  -----------------
export const adminVerifyTour = (id, formFields) =>{
  const data = axios.patch(`${URL}/api/vtour/${id}`, formFields);
  return data;
}

//-- Admin Deny Tour  -----------------
export const adminDenyTour = (id, formFields) =>{
  const data = axios.patch(`${URL}/api/dtour/${id}`, formFields);
  return data;
}

//-- Admin move Tour 2 Trash  -----------------
export const adminMoveTour2Trash = (id, formFields) =>{
  const data = axios.patch(`${URL}/api/move2trash/${id}`, formFields);
  return data;
}

//-------------- NEWS -----------------------

//-- Получение всех новостей -----------------
export const getAllNews = () =>{
  const res = axios.get(`${URL}/api/news/getallnews`);
  return res;
}

//-- Отправить комментарий к новости -----------------
export const sendComment = (sendObj) =>{
  const res = axios.post(`${URL}/api/news/sendcomment`, sendObj);
  return res;
}

//-- UnLike News By News Id ---------------------------
export const unLikeNews = async (newsId) =>{
  const res = await axios.patch(`${URL}/api/news/unlikenews/${newsId}`);
  return res;
  }

//-- Like News By News Id ---------------------------
export const likeNews = async (newsId) =>{
  const res = await axios.patch(`${URL}/api/news/likenews/${newsId}`);
  return res;
  }

//-- Like Comment By News Id & Idx ---------------------------
export const likeComment = async (newsId, idx) =>{
  const res = await axios.patch(`${URL}/api/news/likecomment`, {newsId, idx});
  return res;
  }

//-- UNLike Comment By News Id & Idx ---------------------------
export const unLikeComment = async (newsId, idx) =>{
  const res = await axios.patch(`${URL}/api/news/unlikecomment`, {newsId, idx});
  return res;
  }

  //-- Delete your Comment -----------
export const deleteComment = (newsId, idx) =>{
  const res = axios.patch(`${URL}/api/news/deletecomment`, {newsId, idx});
  return res;
}

  //-- Edit your Comment -----------
export const saveComment = (newsId, idx, text) =>{
  const res = axios.patch(`${URL}/api/news/savecomment`, {newsId, idx, text});
  return res;
}

//-------------- USER -----------------------
//-------------- USER -----------------------


//-- Получение всех пользователей -----------------
export const getUsers = (page, amount, sorting, country, gender, startAge, endAge, city, int, langs, verified, orientation, character, finances, height, alkohol, relations, religion) =>{
  const users = axios.get(`${URL}/api/users?page=${page}&limit=${amount}&sorting=${sorting}&country=${country}&gender=${gender}&startAge=${startAge}&endAge=${endAge}&city=${city}&int=${int}&langs=${langs}&verified=${verified}&orientation=${orientation}&character=${character}&finances=${finances}&height=${height}&alkohol=${alkohol}&relations=${relations}&religion=${religion}`);
  return users;
}

//-- Регистрация Пользователя -----------------
export const registerUser = (formFields) =>{
  const data = axios.post(`${URL}/api/register`, formFields, {withCredentials:true});
  return data;
}

//-- Edit User Profile -----------------
export const editUser = async(id, formFields) =>{
  const data = await axios.patch(`${URL}/api/edituser/${id}`, formFields);
  return data;
}

//-- Verify User  -----------------
export const verifyUser = (id, formFields) =>{
  const data = axios.patch(`${URL}/api/verify/${id}`, formFields);
  return data;
}

//-- Логин Пользователя  ----------------------
export const loginUser = (formFields) =>{
const data = axios.post(`${URL}/api/login`, formFields, {withCredentials:true});
return data;
}

//-- Логаут Пользователя  ----------------------
export const logoutUser = () =>{
const data = axios.get(`${URL}/api/logout`, { withCredentials: true });
return data;
}

//-- Refresh User  ----------------------
export const refreshUser = () =>{
const data = axios.get(`${URL}/api/refresh`, { withCredentials: true });

// localStorage.setItem('profile', JSON.stringify({data:data.data}));
return data;
}

//-- Find Users By ID Array ---------------------------
export const findUsersByIdArray = async (IdArray) =>{
  const res = await axios.post(`${URL}/api/findusers/`, {IdArray});
  return res;
  }

//-- Поиск бейджиков пользователей (фото + имя + возраст, страна)
export const findUsersBadges = async (IdArray) =>{
  const res = await axios.post(`${URL}/api/findusersbadges/`, {IdArray});
  return res;
  }

//-- Get User By ID ---------------------------
export const getUserById = async (id) =>{
  const res = await axios.get(`${URL}/api/users/${id}`);
  return res;
  }

//-- Get User By username ---------------------------
export const getUserByUsername = async (id) =>{
  const res = await axios.get(`${URL}/api/users/${id}`);
  // console.log(id);

  return res;
  }

//-- Follow User By ID ---------------------------
export const followUserById = async (id, followID) =>{
  const res = await axios.patch(`${URL}/api/follow/${id}`, followID);
  return res;
  }

//-- UnFollow User By ID ---------------------------
export const unFollowUserById = async (id, unFollowID) =>{
  const res = await axios.patch(`${URL}/api/unfollow/${id}`, unFollowID);
  return res;
  }

//-- Like User By ID ---------------------------
export const likeUserById = async (id, likeID) =>{
  const res = await axios.patch(`${URL}/api/like/${id}`, likeID);
  return res;
  }

//-- UnLike User By ID ---------------------------
export const unLikeUserById = async (id, unLikeID) =>{
  const res = await axios.patch(`${URL}/api/unlike/${id}`, unLikeID);
  return res;
  }

//---================== TOURS ========================

//--- Get all tours -----------------
export const getTours = (page, limit, country, int, date, depart,city,ppl,gender, startAge, endAge, anyAge, startDate, endDate, noStartDate, noEndDate, daysAmount,anyDates, tourPrice, tourFee, pocketMoney, langs, sorting) =>{

  const tours = axios.get(`${URL}/api/tours?page=${page}&limit=${limit}&country=${country}&int=${int}&langs=${langs}&date=${date}&depart=${depart}&city=${city}&ppl=${ppl}&gender=${gender}&startAge=${startAge}&endAge=${endAge}&anyAge=${anyAge}&startDate=${startDate}&endDate=${endDate}&noStartDate=${noStartDate}&noEndDate=${noEndDate}&daysAmount=${daysAmount}&anyDates=${anyDates}&tourPrice=${tourPrice}&tourFee=${tourFee}&pocketMoney=${pocketMoney}&sorting=${sorting}`);
  return tours;
}

//-- Find Tours By ID Array ---------------------------
export const findToursByIdArray = async (idArray) =>{
  const res = await axios.post(`${URL}/api/findtours/`, {IdArray: idArray});
  return res;
  }

//-- Get Tour By ID ---------------------------
export const getTourById = async (id, visitorID) =>{
  const tour = await axios.post(`${URL}/api/tours/${id}`, {visitorID:visitorID});
  return tour;
  }

//--- Create Tour -----------------
export const createTour = (formFields) =>{
  const tour = axios.post(`${URL}/api/createtour`, formFields);
  return tour;
}

//-- Edit Tour  -----------------
export const editTour = (id, formFields) =>{
  const data = axios.patch(`${URL}/api/edittour/${id}`, formFields);
  return data;
}

//-- Like Tour By ID ---------------------------
export const likeTourById = async (id, likeID) =>{
  const res = await axios.patch(`${URL}/api/liketour/${id}`, likeID);
  return res;
  }

//-- UnLike Tour By ID ---------------------------
export const unLikeTourById = async (id, unLikeID) =>{
  const res = await axios.patch(`${URL}/api/unliketour/${id}`, unLikeID);
  return res;
  }

  //-- Follow Tour By ID ---------------------------
export const followTourById = async (id, followID) =>{
  const res = await axios.patch(`${URL}/api/followtour/${id}`, followID);
  return res;
  }

//-- UnFollow Tour By ID ---------------------------
export const unFollowTourById = async (id, unFollowID) =>{
  const res = await axios.patch(`${URL}/api/unfollowtour/${id}`, unFollowID);
  return res;
  }


  //-- Send Request to Join Tour ---------------------------
export const sendRequest = async (tourID) =>{
  const res = await axios.patch(`${URL}/api/sendrequest/`, {tourID});
  return res;
  }

  //-- Move to Tour JOIN in Tour and User  ---------------------------
export const moveToJoin = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/movetojoin/${userID}`, tourID);
  return res;
  }

  //-- Move to Tour Confirm in Tour and User  ---------------------------
export const moveToConfirm = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/movetoconfirm/${userID}`, tourID);
  return res;
  }

  //-- Move to Tour Rejected in Tour and User  ---------------------------
export const moveToRejected = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/movetorejected/${userID}`, tourID);
  return res;
  }

  //-- Move to Tour Followers in Tour and User  ---------------------------
export const moveToFollowers = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/movetofollowers/${userID}`, tourID);
  return res;
  }

  //-- Move USER to Block in Tour and User  ---------------------------
export const moveToBlock = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/movetoblock/${userID}`, tourID);
  return res;
  }

  //-- Remove from Joined List in Tour and User ---------------------------
export const removeFromJoin = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/removefromjoin/${userID}`, tourID);
  return res;
  }

  //-- Remove from Confirmed List in Tour and User ---------------------------
export const removeFromConf = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/removefromconf/${userID}`, tourID);
  return res;
  }

  //-- Remove from Requests List in Tour and User ---------------------------
export const removeFromRequests = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/removefromrequests/${userID}`, tourID);
  return res;
  }

  //-- Remove from Reject List in Tour ---------------------------
export const removeFromFollowers = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/removefromfollowers/${userID}`, tourID);
  return res;
  }

  //-- Remove from Reject List in Tour ---------------------------
export const removeFromReject = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/removefromreject/${userID}`, tourID);
  return res;
  }

  //-- Remove from Blocked List in Tour and User ---------------------------
export const removeFromBlock = async (userID, tourID) =>{
  const res = await axios.patch(`${URL}/api/removefromblock/${userID}`, tourID);
  return res;
  }


  //----================= Tour Control ==================


  //--------- UnPublic Tour ---------------------------
export const unPublicTour = async (tourID) =>{
  const res = await axios.patch(`${URL}/api/unpublictour/${tourID}`);
  return res;
  }

  //--------- Public Tour ---------------------------
export const publicTour = async (tourID) =>{
  const res = await axios.patch(`${URL}/api/publictour/${tourID}`);
  return res;
  }

//-- Delete your Tour  (он берет UserID из токена)-----------
export const deleteTour = (tourID) =>{
  const data = axios.patch(`${URL}/api/deletetour/${tourID}`);
  return data;
}


  //--================ Reviews ========================


//--------- Creating Reviews after Tour----------------
export const createReviews = async (tourID, formData) =>{
const res = await axios.post(`${URL}/api/createreviews/${tourID}`, formData);
return res;
}

//-- Get All Tour Reviews  -----------------
export const getAllTourReviews = (id) =>{
  const data = axios.get(`${URL}/api/getalltourreviews/${id}`);
  return data;
}

//-- Get User Reviews From Tour -----------------
export const getUserReviewsFromTour = (tourID, userID) =>{
  const data = axios.post(`${URL}/api/getuserreviewsfromtour/${tourID}`, {userID:userID});
  return data;
}

//-- Get All User Reviews -----------------
export const getAllUserReviews = (userID) =>{
  const data = axios.get(`${URL}/api/getalluserreviews/${userID}`);
  return data;
}

//-- =========== POSTS =====================


//--- Create Post -----------------
export const createPost = (formFields) =>{
  const tour = axios.post(`${URL}/api/createpost`, formFields);
  return tour;
}

//-- Edit Tour  -----------------
export const editPost = (id, formFields) =>{
  const data = axios.patch(`${URL}/api/editpost/${id}`, formFields);
  return data;
}

//--- Get all POSTS -----------------
export const getPosts = (page, amount, sorting, country, startDate, gender, startAge, endAge, depart, city, anyAge, endDate, noStartDate, noEndDate, daysAmount, anyDates, int, langs, verified, orientation, character, finances ) =>{
  const posts = axios.get(`${URL}/api/getposts?page=${page}&limit=${amount}&sorting=${sorting}&country=${country}&startDate=${startDate}&gender=${gender}&startAge=${startAge}&endAge=${endAge}&depart=${depart}&city=${city}&anyAge=${anyAge}&endDate=${endDate}&noStartDate=${noStartDate}&noEndDate=${noEndDate}&daysAmount=${daysAmount}&anyDates=${anyDates}&int=${int}&langs=${langs}&verified=${verified}&orientation=${orientation}&character=${character}&finances=${finances}`);
  return posts;
}

//-- Find One Post By ID ---------------------------
export const getPostById = async (id) =>{
  const post = await axios.get(`${URL}/api/post/${id}`);
  return post;
  }

//-- Find Posts By ID Array ---------------------------
export const findPostsByIdArray = async (idArray) =>{
  const res = await axios.post(`${URL}/api/findposts/`, {IdArray: idArray});
  return res;
  }

//-- Delete Post  -----------------
export const deletePost = (postId) =>{
  const data = axios.patch(`${URL}/api/deletepost/${postId}`);
  return data;
}

  //--------- UnPublic Post ---------------------------
  export const unPublicPost = async (postID) =>{
    const res = await axios.patch(`${URL}/api/unpublicpost/${postID}`);
    return res;
    }

    //--------- Public Post ---------------------------
  export const publicPost = async (postID) =>{
    const res = await axios.patch(`${URL}/api/publicpost/${postID}`);
    return res;
    }

//-- Like POST ---------------------------
export const likePost = async (id, likeID) =>{
  const res = await axios.patch(`${URL}/api/likepost/${id}`, likeID);
  return res;
  }

//-- UnLike Post---------------------------
export const unLikePost = async (id, unLikeID) =>{
  const res = await axios.patch(`${URL}/api/unlikepost/${id}`, unLikeID);
  return res;
  }


  //--=========== MESSAGES =================

//--- SEND MESSAGE TO USER from Messenger to ChatID
export const sendMsgToUserFromChat = (chatID, text) =>{
  const res = axios.post(`${URL}/api/sendmsgtouserfromchat/${chatID}`, {text:text});
  return res;
}

//--- Send Message -----fromUserID - берется из токена
export const sendMsg = (toUserId, text) =>{
  const res = axios.post(`${URL}/api/sendmsg/${toUserId}`, {text:text});
  return res;
}

//--- Send Tour Message -----
export const sendTourMsg = (chatID, text) =>{
  const res = axios.post(`${URL}/api/sendtourmsg/${chatID}`, {text:text});
  return res;
}

//--- Get messages - fromUserID - берется из токена
export const getMessages = (chatID) =>{
  const res = axios.get(`${URL}/api/getmsgs/${chatID}`);
  return res;
}

//-- Get All Unread Msg number ---------------------------
export const getUnreadMsg = async () =>{
  const res = await axios.get(`${URL}/api/getunreadmsg/`);
  return res;
  }

//-- Find Chat Users By ID Array ---------------------------
export const findMyChats = async () =>{
  const res = await axios.get(`${URL}/api/findmychats/`);
  return res;
  }

//-- Find Tour Chats By tourID---------------------------
export const findTourChats = async (tourID) =>{
  const res = await axios.get(`${URL}/api/findtourchats/${tourID}`);
  return res;
  }

  //--- Send Files -----------------
  export const sendFiles = async (chatID, formFields) =>{
    const res = await axios.post(`${URL}/api/sendfiles/${chatID}`, formFields);
    return res;
  }

  //--================ NOTIFICATIONS - EVENTS =========

  //--- Get Events -----------------
  export const getEvents = async (page, amount) =>{
  const res = await axios.get(`${URL}/api/events/getevents?page=${page}&limit=${amount}`);
  return res;
  }

  //--- Get Unread Events Amount -----------------
  export const getUnreadEventsAnmt = async () =>{
  const res = await axios.get(`${URL}/api/events/unreadeventsnum/`);
  return res;
  }

  //--=============== GALLERY ====================


//-- Upload Tour gallery after trip by organizer-----------------
export const uploadTourGalleryPhotos = (formFields) =>{
  const res = axios.post(`${URL}/api/uploadtourgallery`, formFields);
  return res;
}

//-- Save Tour gallery after edit -----------------
export const saveGalleryPhotos = (Data) =>{
  const res = axios.post(`${URL}/api/savetourgallery`, Data);
  return res;
}

//-- Upload users photos to Tour gallery -----------------
export const uploadUserTourPhotos = (formFields) =>{
  const res = axios.post(`${URL}/api/uploadusertourphotos`, formFields);
  return res;
}

//-- Save users photos to Tour gallery -----------------
export const saveUserTourPhotos = (Data) =>{
  const res = axios.post(`${URL}/api/saveusertourphotos`, Data);
  return res;
}

//-- Save users photos to Tour gallery -----------------
export const switchShowInTour = (Data) =>{
  const res = axios.post(`${URL}/api/switchshowintour`, Data);
  return res;
}

//-- Get User Albums for Tour gallery -----------------
export const getUserAlbums = (tourId) =>{
  const res = axios.get(`${URL}/api/getuseralbums/${tourId}`);
  return res;
}

//--============== SETTINGS ========================

//-- Reset Password -----------------
export const resetPassword = (Data) =>{
  const res = axios.post(`${URL}/api/resetpassword`, Data);
  return res;
}

//-- Set New Password -----------------
export const setNewPassword = (id, token, pass) =>{
  const res = axios.post(`${URL}/api/setnewpass`, {id, token, pass});
  return res;
}

//-- Change Password -----------------
export const changePass = (id, oldPass, newPass) =>{
  const res = axios.post(`${URL}/api/changepass`, {id, oldPass, newPass});
  return res;
}

//-- Change Username -----------------
export const changeUsername = (id, username) =>{
  const res = axios.post(`${URL}/api/changeusername`, {id, username});
  return res;
}

//-- Verify Reset Token -----------------
export const verifyResetToken = (id, token) =>{
  const res = axios.get(`${URL}/api/verifyreset?token=${token}&id=${id}`);
  return res;
}

//-- Send Email Activation Link -----------------
export const sendActivationLink = (email) =>{
  const res = axios.post(`${URL}/api/sendactivationlink`, {email});
  return res;
}

//-- Delete Account -----------------
export const deleteAccount = (id, pass) =>{
  const res = axios.post(`${URL}/api/deleteaccount`, {id, pass});
  return res;
}

//-- Send Feedback -----------------
export const sendFeedback = (data) =>{
  const res = axios.post(`${URL}/api/sendfeedback`, data);
  return res;
}

//-- Get Feedbacks -----------------
export const getFeedbacks = () =>{
  const res = axios.get(`${URL}/api/getfeedbacks`);
  return res;
}
