export const countryArr = [
    {value: "AD", icon: "/assets/flags/small/ad.jpg"},
    {value: "AE", icon: "/assets/flags/small/ae.jpg"},
    {value: "AF", icon: "/assets/flags/small/af.jpg"},
    {value: "AG", icon: "/assets/flags/small/ag.jpg"},
    {value: "AI", icon: "/assets/flags/small/ai.jpg"},
    {value: "AL", icon: "/assets/flags/small/al.jpg"},
    {value: "AM", icon: "/assets/flags/small/am.jpg"},
    {value: "AO", icon: "/assets/flags/small/ao.jpg"},
    {value: "AQ", icon: "/assets/flags/small/aq.jpg"},
    {value: "AR", icon: "/assets/flags/small/ar.jpg"},
    {value: "AS", icon: "/assets/flags/small/as.jpg"},
    {value: "AT", icon: "/assets/flags/small/at.jpg"},
    {value: "AU", icon: "/assets/flags/small/au.jpg"},
    {value: "AW", icon: "/assets/flags/small/aw.jpg"},
    {value: "AX", icon: "/assets/flags/small/ax.jpg"},
    {value: "AZ", icon: "/assets/flags/small/az.jpg"},
    {value: "BA", icon: "/assets/flags/small/ba.jpg"},
    {value: "BB", icon: "/assets/flags/small/bb.jpg"},
    {value: "BD", icon: "/assets/flags/small/bd.jpg"},
    {value: "BE", icon: "/assets/flags/small/be.jpg"},
    {value: "BF", icon: "/assets/flags/small/bf.jpg"},
    {value: "BG", icon: "/assets/flags/small/bg.jpg"},
    {value: "BH", icon: "/assets/flags/small/bh.jpg"},
    {value: "BI", icon: "/assets/flags/small/bi.jpg"},
    {value: "BJ", icon: "/assets/flags/small/bj.jpg"},
    {value: "BL", icon: "/assets/flags/small/bl.jpg"},
    {value: "BM", icon: "/assets/flags/small/bm.jpg"},
    {value: "BN", icon: "/assets/flags/small/bn.jpg"},
    {value: "BO", icon: "/assets/flags/small/bo.jpg"},
    {value: "BQ", icon: "/assets/flags/small/bq.jpg"},
    {value: "BR", icon: "/assets/flags/small/br.jpg"},
    {value: "BS", icon: "/assets/flags/small/bs.jpg"},
    {value: "BT", icon: "/assets/flags/small/bt.jpg"},
    {value: "BV", icon: "/assets/flags/small/bv.jpg"},
    {value: "BW", icon: "/assets/flags/small/bw.jpg"},
    {value: "BY", icon: "/assets/flags/small/by.jpg"},
    {value: "BZ", icon: "/assets/flags/small/bz.jpg"},
    {value: "CA", icon: "/assets/flags/small/ca.jpg"},
    {value: "CC", icon: "/assets/flags/small/cc.jpg"},
    {value: "CD", icon: "/assets/flags/small/cd.jpg"},
    {value: "CF", icon: "/assets/flags/small/cf.jpg"},
    {value: "CG", icon: "/assets/flags/small/cg.jpg"},
    {value: "CH", icon: "/assets/flags/small/ch.jpg"},
    {value: "CI", icon: "/assets/flags/small/ci.jpg"},
    {value: "CK", icon: "/assets/flags/small/ck.jpg"},
    {value: "CL", icon: "/assets/flags/small/cl.jpg"},
    {value: "CM", icon: "/assets/flags/small/cm.jpg"},
    {value: "CN", icon: "/assets/flags/small/cn.jpg"},
    {value: "CO", icon: "/assets/flags/small/co.jpg"},
    {value: "CR", icon: "/assets/flags/small/cr.jpg"},
    {value: "CU", icon: "/assets/flags/small/cu.jpg"},
    {value: "CV", icon: "/assets/flags/small/cv.jpg"},
    {value: "CW", icon: "/assets/flags/small/cw.jpg"},
    {value: "CX", icon: "/assets/flags/small/cx.jpg"},
    {value: "CY", icon: "/assets/flags/small/cy.jpg"},
    {value: "CZ", icon: "/assets/flags/small/cz.jpg"},
    {value: "DE", icon: "/assets/flags/small/de.jpg"},
    {value: "DJ", icon: "/assets/flags/small/dj.jpg"},
    {value: "DK", icon: "/assets/flags/small/dk.jpg"},
    {value: "DM", icon: "/assets/flags/small/dm.jpg"},
    {value: "DO", icon: "/assets/flags/small/do.jpg"},
    {value: "DZ", icon: "/assets/flags/small/dz.jpg"},
    {value: "EC", icon: "/assets/flags/small/ec.jpg"},
    {value: "EE", icon: "/assets/flags/small/ee.jpg"},
    {value: "EG", icon: "/assets/flags/small/eg.jpg"},
    {value: "EH", icon: "/assets/flags/small/eh.jpg"},
    {value: "ER", icon: "/assets/flags/small/er.jpg"},
    {value: "ES", icon: "/assets/flags/small/es.jpg"},
    {value: "ET", icon: "/assets/flags/small/et.jpg"},
    {value: "FI", icon: "/assets/flags/small/fi.jpg"},
    {value: "FJ", icon: "/assets/flags/small/fj.jpg"},
    {value: "FK", icon: "/assets/flags/small/fk.jpg"},
    {value: "FM", icon: "/assets/flags/small/fm.jpg"},
    {value: "FO", icon: "/assets/flags/small/fo.jpg"},
    {value: "FR", icon: "/assets/flags/small/fr.jpg"},
    {value: "GA", icon: "/assets/flags/small/ga.jpg"},
    {value: "GB", icon: "/assets/flags/small/gb.jpg"},
    {value: "GD", icon: "/assets/flags/small/gd.jpg"},
    {value: "GE", icon: "/assets/flags/small/ge.jpg"},
    {value: "GF", icon: "/assets/flags/small/gf.jpg"},
    {value: "GG", icon: "/assets/flags/small/gg.jpg"},
    {value: "GH", icon: "/assets/flags/small/gh.jpg"},
    {value: "GI", icon: "/assets/flags/small/gi.jpg"},
    {value: "GL", icon: "/assets/flags/small/gl.jpg"},
    {value: "GM", icon: "/assets/flags/small/gm.jpg"},
    {value: "GN", icon: "/assets/flags/small/gn.jpg"},
    {value: "GP", icon: "/assets/flags/small/gp.jpg"},
    {value: "GQ", icon: "/assets/flags/small/gq.jpg"},
    {value: "GR", icon: "/assets/flags/small/gr.jpg"},
    {value: "GS", icon: "/assets/flags/small/gs.jpg"},
    {value: "GT", icon: "/assets/flags/small/gt.jpg"},
    {value: "GU", icon: "/assets/flags/small/gu.jpg"},
    {value: "GW", icon: "/assets/flags/small/gw.jpg"},
    {value: "GY", icon: "/assets/flags/small/gy.jpg"},
    {value: "HK", icon: "/assets/flags/small/hk.jpg"},
    {value: "HM", icon: "/assets/flags/small/hm.jpg"},
    {value: "HN", icon: "/assets/flags/small/hn.jpg"},
    {value: "HR", icon: "/assets/flags/small/hr.jpg"},
    {value: "HT", icon: "/assets/flags/small/ht.jpg"},
    {value: "HU", icon: "/assets/flags/small/hu.jpg"},
    {value: "ID", icon: "/assets/flags/small/id.jpg"},
    {value: "IE", icon: "/assets/flags/small/ie.jpg"},
    {value: "IL", icon: "/assets/flags/small/il.jpg"},
    {value: "IM", icon: "/assets/flags/small/im.jpg"},
    {value: "IN", icon: "/assets/flags/small/in.jpg"},
    {value: "IO", icon: "/assets/flags/small/io.jpg"},
    {value: "IQ", icon: "/assets/flags/small/iq.jpg"},
    {value: "IR", icon: "/assets/flags/small/ir.jpg"},
    {value: "IS", icon: "/assets/flags/small/is.jpg"},
    {value: "IT", icon: "/assets/flags/small/it.jpg"},
    {value: "JE", icon: "/assets/flags/small/je.jpg"},
    {value: "JM", icon: "/assets/flags/small/jm.jpg"},
    {value: "JO", icon: "/assets/flags/small/jo.jpg"},
    {value: "JP", icon: "/assets/flags/small/jp.jpg"},
    {value: "KE", icon: "/assets/flags/small/ke.jpg"},
    {value: "KG", icon: "/assets/flags/small/kg.jpg"},
    {value: "KH", icon: "/assets/flags/small/kh.jpg"},
    {value: "KI", icon: "/assets/flags/small/ki.jpg"},
    {value: "KM", icon: "/assets/flags/small/km.jpg"},
    {value: "KN", icon: "/assets/flags/small/kn.jpg"},
    {value: "KP", icon: "/assets/flags/small/kp.jpg"},
    {value: "KR", icon: "/assets/flags/small/kr.jpg"},
    {value: "KW", icon: "/assets/flags/small/kw.jpg"},
    {value: "KY", icon: "/assets/flags/small/ky.jpg"},
    {value: "KZ", icon: "/assets/flags/small/kz.jpg"},
    {value: "LA", icon: "/assets/flags/small/la.jpg"},
    {value: "LB", icon: "/assets/flags/small/lb.jpg"},
    {value: "LC", icon: "/assets/flags/small/lc.jpg"},
    {value: "LI", icon: "/assets/flags/small/li.jpg"},
    {value: "LK", icon: "/assets/flags/small/lk.jpg"},
    {value: "LR", icon: "/assets/flags/small/lr.jpg"},
    {value: "LS", icon: "/assets/flags/small/ls.jpg"},
    {value: "LT", icon: "/assets/flags/small/lt.jpg"},
    {value: "LU", icon: "/assets/flags/small/lu.jpg"},
    {value: "LV", icon: "/assets/flags/small/lv.jpg"},
    {value: "LY", icon: "/assets/flags/small/ly.jpg"},
    {value: "MA", icon: "/assets/flags/small/ma.jpg"},
    {value: "MC", icon: "/assets/flags/small/mc.jpg"},
    {value: "MD", icon: "/assets/flags/small/md.jpg"},
    {value: "ME", icon: "/assets/flags/small/me.jpg"},
    {value: "MF", icon: "/assets/flags/small/mf.jpg"},
    {value: "MG", icon: "/assets/flags/small/mg.jpg"},
    {value: "MH", icon: "/assets/flags/small/mh.jpg"},
    {value: "MK", icon: "/assets/flags/small/mk.jpg"},
    {value: "ML", icon: "/assets/flags/small/ml.jpg"},
    {value: "MM", icon: "/assets/flags/small/mm.jpg"},
    {value: "MN", icon: "/assets/flags/small/mn.jpg"},
    {value: "MO", icon: "/assets/flags/small/mo.jpg"},
    {value: "MP", icon: "/assets/flags/small/mp.jpg"},
    {value: "MQ", icon: "/assets/flags/small/mq.jpg"},
    {value: "MR", icon: "/assets/flags/small/mr.jpg"},
    {value: "MS", icon: "/assets/flags/small/ms.jpg"},
    {value: "MT", icon: "/assets/flags/small/mt.jpg"},
    {value: "MU", icon: "/assets/flags/small/mu.jpg"},
    {value: "MV", icon: "/assets/flags/small/mv.jpg"},
    {value: "MW", icon: "/assets/flags/small/mw.jpg"},
    {value: "MX", icon: "/assets/flags/small/mx.jpg"},
    {value: "MY", icon: "/assets/flags/small/my.jpg"},
    {value: "MZ", icon: "/assets/flags/small/mz.jpg"},
    {value: "NA", icon: "/assets/flags/small/na.jpg"},
    {value: "NC", icon: "/assets/flags/small/nc.jpg"},
    {value: "NE", icon: "/assets/flags/small/ne.jpg"},
    {value: "NF", icon: "/assets/flags/small/nf.jpg"},
    {value: "NG", icon: "/assets/flags/small/ng.jpg"},
    {value: "NI", icon: "/assets/flags/small/ni.jpg"},
    {value: "NL", icon: "/assets/flags/small/nl.jpg"},
    {value: "NO", icon: "/assets/flags/small/no.jpg"},
    {value: "NP", icon: "/assets/flags/small/np.jpg"},
    {value: "NR", icon: "/assets/flags/small/nr.jpg"},
    {value: "NU", icon: "/assets/flags/small/nu.jpg"},
    {value: "NZ", icon: "/assets/flags/small/nz.jpg"},
    {value: "OM", icon: "/assets/flags/small/om.jpg"},
    {value: "PA", icon: "/assets/flags/small/pa.jpg"},
    {value: "PE", icon: "/assets/flags/small/pe.jpg"},
    {value: "PF", icon: "/assets/flags/small/pf.jpg"},
    {value: "PG", icon: "/assets/flags/small/pg.jpg"},
    {value: "PH", icon: "/assets/flags/small/ph.jpg"},
    {value: "PK", icon: "/assets/flags/small/pk.jpg"},
    {value: "PL", icon: "/assets/flags/small/pl.jpg"},
    {value: "PM", icon: "/assets/flags/small/pm.jpg"},
    {value: "PN", icon: "/assets/flags/small/pn.jpg"},
    {value: "PR", icon: "/assets/flags/small/pr.jpg"},
    {value: "PS", icon: "/assets/flags/small/ps.jpg"},
    {value: "PT", icon: "/assets/flags/small/pt.jpg"},
    {value: "PW", icon: "/assets/flags/small/pw.jpg"},
    {value: "PY", icon: "/assets/flags/small/py.jpg"},
    {value: "QA", icon: "/assets/flags/small/qa.jpg"},
    {value: "RE", icon: "/assets/flags/small/re.jpg"},
    {value: "RO", icon: "/assets/flags/small/ro.jpg"},
    {value: "RS", icon: "/assets/flags/small/rs.jpg"},
    {value: "RU", icon: "/assets/flags/small/ru.jpg"},
    {value: "RW", icon: "/assets/flags/small/rw.jpg"},
    {value: "SA", icon: "/assets/flags/small/sa.jpg"},
    {value: "SB", icon: "/assets/flags/small/sb.jpg"},
    {value: "SC", icon: "/assets/flags/small/sc.jpg"},
    {value: "SD", icon: "/assets/flags/small/sd.jpg"},
    {value: "SE", icon: "/assets/flags/small/se.jpg"},
    {value: "SG", icon: "/assets/flags/small/sg.jpg"},
    {value: "SH", icon: "/assets/flags/small/sh.jpg"},
    {value: "SI", icon: "/assets/flags/small/si.jpg"},
    {value: "SJ", icon: "/assets/flags/small/sj.jpg"},
    {value: "SK", icon: "/assets/flags/small/sk.jpg"},
    {value: "SL", icon: "/assets/flags/small/sl.jpg"},
    {value: "SM", icon: "/assets/flags/small/sm.jpg"},
    {value: "SN", icon: "/assets/flags/small/sn.jpg"},
    {value: "SO", icon: "/assets/flags/small/so.jpg"},
    {value: "SR", icon: "/assets/flags/small/sr.jpg"},
    {value: "SS", icon: "/assets/flags/small/ss.jpg"},
    {value: "ST", icon: "/assets/flags/small/st.jpg"},
    {value: "SV", icon: "/assets/flags/small/sv.jpg"},
    {value: "SX", icon: "/assets/flags/small/sx.jpg"},
    {value: "SY", icon: "/assets/flags/small/sy.jpg"},
    {value: "SZ", icon: "/assets/flags/small/sz.jpg"},
    {value: "TC", icon: "/assets/flags/small/tc.jpg"},
    {value: "TD", icon: "/assets/flags/small/td.jpg"},
    {value: "TF", icon: "/assets/flags/small/tf.jpg"},
    {value: "TG", icon: "/assets/flags/small/tg.jpg"},
    {value: "TH", icon: "/assets/flags/small/th.jpg"},
    {value: "TJ", icon: "/assets/flags/small/tj.jpg"},
    {value: "TK", icon: "/assets/flags/small/tk.jpg"},
    {value: "TL", icon: "/assets/flags/small/tl.jpg"},
    {value: "TM", icon: "/assets/flags/small/tm.jpg"},
    {value: "TN", icon: "/assets/flags/small/tn.jpg"},
    {value: "TO", icon: "/assets/flags/small/to.jpg"},
    {value: "TR", icon: "/assets/flags/small/tr.jpg"},
    {value: "TT", icon: "/assets/flags/small/tt.jpg"},
    {value: "TV", icon: "/assets/flags/small/tv.jpg"},
    {value: "TW", icon: "/assets/flags/small/tw.jpg"},
    {value: "TZ", icon: "/assets/flags/small/tz.jpg"},
    {value: "UA", icon: "/assets/flags/small/ua.jpg"},
    {value: "UG", icon: "/assets/flags/small/ug.jpg"},
    {value: "UM", icon: "/assets/flags/small/um.jpg"},
    {value: "US", icon: "/assets/flags/small/us.jpg"},
    {value: "UY", icon: "/assets/flags/small/uy.jpg"},
    {value: "UZ", icon: "/assets/flags/small/uz.jpg"},
    {value: "VA", icon: "/assets/flags/small/va.jpg"},
    {value: "VC", icon: "/assets/flags/small/vc.jpg"},
    {value: "VE", icon: "/assets/flags/small/ve.jpg"},
    {value: "VG", icon: "/assets/flags/small/vg.jpg"},
    {value: "VI", icon: "/assets/flags/small/vi.jpg"},
    {value: "VN", icon: "/assets/flags/small/vn.jpg"},
    {value: "VU", icon: "/assets/flags/small/vu.jpg"},
    {value: "WF", icon: "/assets/flags/small/wf.jpg"},
    {value: "WS", icon: "/assets/flags/small/ws.jpg"},
    {value: "XK", icon: "/assets/flags/small/xk.jpg"},
    {value: "YE", icon: "/assets/flags/small/ye.jpg"},
    {value: "YT", icon: "/assets/flags/small/yt.jpg"},
    {value: "ZA", icon: "/assets/flags/small/za.jpg"},
    {value: "ZM", icon: "/assets/flags/small/zm.jpg"},
    {value: "ZW", icon: "/assets/flags/small/zw.jpg"},
]