import { useState } from "react";
import { useTranslation } from "react-i18next";
import MainBtn from "../Buttons/MainBtn";
import * as api from '../../utils/api/api.utils'
import { handleError } from "../../utils/handleError";
import Picker from "@emoji-mart/react";
import data from '@emoji-mart/data'


import "./news.scss";
const EditComment = ({newsId, idx, commentText, setEditMode, setComText}) => {
  const [showEmoji, setShowEmoji] = useState(false);
  const [text, setText] = useState(commentText);

  const { t } = useTranslation(["tr"]);

  const handleEmoji=(e)=>{
    let msg = text;
    msg += e.native;
    setText(msg)
  }

  const saveComment = async()=>{
    try {
      const res = await api.saveComment(newsId, idx, text)
      if(res.status===200){
        setComText(text)
        setEditMode(false)
      }else{
        throw res
      }
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <div className="EditComment_wrapper">
      <textarea
        className="EditComment_textarea"
        value={text}
        autoFocus
        rows="4"
        type="text"
        onChange={(e) => {
          setText(e.target.value);
        }}
      />

      <div className="EditComment_SmileBtn" onClick={(e) => {
          e.stopPropagation();
          setShowEmoji(!showEmoji);
        }}>
        &#9786;
      </div>

      {showEmoji &&
        <div className="EditComment_Emoji_box">
          <Picker
            data={data}
            onEmojiSelect={handleEmoji}
            theme="light"
            onClickOutside={() => setShowEmoji(false)}
          />
        </div>
      }

      <div className="EditComment_BtnLine">
        <MainBtn title="Отмена" color="blueVK_light" onClick={()=>setEditMode(false)}/>
        <MainBtn title="Сохранить" color="blueVK" onClick={()=>saveComment()}/>
      </div>
    </div>
  );
};

export default EditComment;
