import * as api from '../../utils/api/api.utils'
import { useTranslation } from "react-i18next";

import { IoClose } from "react-icons/io5";
import { handleError } from '../../utils/handleError';

const CommentDelBtn = ({newsId, idx, com, delComment}) => {

  const { t } = useTranslation(["tr"]);

const confirmDel =()=>{
    if (window.confirm(t("deleteComment", { ns: "tr" }))) deleteComment()
}

const deleteComment = async()=>{

  try {
    const res = await api.deleteComment(newsId, idx)

    if(res.status===200){
      delComment(com)
    }else{
      throw res
    }
  } catch (error) {
    handleError(error)
  }
}

  return (
    <div onClick={()=>confirmDel()}>
      <IoClose size={20} color='#aaa' />
    </div>
  )
}

export default CommentDelBtn