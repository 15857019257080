import TooltipPurp from "../Tooltip/TooltipPurp";

import "./CheckBox.scss";

const CheckBox = ({ title, subtitle, disabled, checked, tooltip, name, color, formik }) => {

  return (
    
    <div className="Checkbox_wrapper">
    
      <label className={`CB_checkbox_label 
      ${(color==="purp" ?  "CB_label_purp": "")}
      ${(disabled ?  "CB_disabled": "")} `}
      > {title}

        {disabled ? null :
        <input
          type="checkbox"
          checked={checked}
          name={name}
          {...formik.getFieldProps({name})}
        />
        }

        <div className="CB_right_side">
          <span className="CB_checkmark"></span>

          <div className="CB_Tooltip">
          {tooltip&& <TooltipPurp text={tooltip}/>}
          </div>
        </div>
      </label>
      <span>{subtitle}</span>
    </div>
  );
};

export default CheckBox;
