import { useTranslation } from "react-i18next";

import { config } from "../../utils/config";
import { thumb } from "../../utils/thumb";
import "./MsgTour.scss";
import { showDate } from "../../utils/showDate";

const InboxTour = ({ tour, active }) => {
  const { t } = useTranslation(["translation", "countries"]);
  const { date, year } = showDate(tour);

  return (
    <div className={active ? "MsgTour_Wrapper MsgTour_active" : "MsgTour_Wrapper"} >
      <div className="MsgT_PicArea">
        <img src={thumb(tour?.titleImages?.[0], config.thumb_width)} alt="" />
      </div>

      <div className="MsgT_InfoArea">
        <span>{date} {year}</span>
        <p><b>{t(tour?.country, { ns: "countries" })}</b> , {tour?.city}</p>
      </div>

      {tour?.unreadMsg > 0 && (
        <div className="MsgT_unreadMsg">{tour?.unreadMsg}</div>
      )}
    </div>
  );
};

export default InboxTour;
