import planet_icon from '../../assets/create_account/planet.svg'
import depart_icon from '../../assets/create_tour/depart.svg'
import Select from 'react-select'
// import countryObj from '../../utils/countries.json'
import { useTranslation } from 'react-i18next';
import { countryArr } from '../../data/countryArr';

import TooltipPurp from "../Tooltip/TooltipPurp";
import "./InputCountry.scss";

const InputCountry = ({ title, icon, onChange, value, onBlur, tooltip,...otherProps }) => {

  const {t} = useTranslation(['translation', 'countries']);

  const countryList = countryArr.map((item)=>{
      return {...item, label: t(item.value, {ns:"countries"})}})
        .sort((a, b) => a.label.localeCompare(b.label))


  const selectValue = (options, value)=>{
    return options? options.find(o=>o.value===value):null
  }

    let img;
    if(icon==="country") {img = planet_icon};
    if(icon==="depart") {img = depart_icon};

  return (
    <div className="InputCountry_container">
      <div className="IC_title_line">
        <div className="IC_title_sign">
          <img src={img} alt="" />
          <p>{title}</p>
        </div>

        {tooltip && <TooltipPurp text={tooltip}/> }
      </div>

      <div className="IC_country">
        <Select
          options={countryList}
          value={selectValue(countryList, value)}
          onChange={onChange}
          onBlur={onBlur}
          isClearable
          getOptionValue={o => o.label}
          getOptionLabel={e=>(
            <div className="IC_icon">
              <img src={e.icon} alt="" />
              <p>{e.label}</p>
            </div>
            )}

          theme={(theme) => ({...theme, borderRadius: 8,
          colors: {
            ...theme.colors,
            primary25: '#eee3f4',
            primary: '#87589C',
          },})}
          {...otherProps}
        />

      </div>



    </div>
  );
};

export default InputCountry;
