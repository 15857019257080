import ArrowIcon from '../../assets/right_arrow.svg'

import './LeftRightBtn.scss'

const RightBtn = () => {
  return (
    <div className="CommonBtn">
    <img src={ArrowIcon} alt="share" />
</div>
  )
}

export default RightBtn