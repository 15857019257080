import DatePicker from "react-datepicker";
import dates_icon from "../../assets/create_account/calendar.svg";

import TooltipPurp from "../Tooltip/TooltipPurp";

import "react-datepicker/dist/react-datepicker.css";
import "./DatesField.scss";

const SearchStartDate = ({ title,cb_title, cb_tooltip, icon, tooltip, startDate, setStartDate,  noStartDate, setNoStartDate, anyDates}) => {

  let img;
  if (icon === "dates") {
    img = dates_icon;
  }

  return (
    <div className="DatesField_container">
      <div className="SSD_title_line">
        <div className="SSD_title_sign">
          <img src={img} alt="" />
          <p>{title}</p>
        </div>
        {tooltip && <TooltipPurp text={tooltip} />}
      </div>

      <div className="DatesField_box">

        <DatePicker
          className="DatesField_input SSD_input"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          minDate={new Date()}
          disabled={noStartDate===true||anyDates}
          isClearable={startDate}
          placeholderText={title}
          dateFormat="MMMM d, yyyy"
        />

        <div className="SSD_Dates_checkbox">
          <label className="DF_checkbox_label"> {cb_title}
          <input
            type="checkbox"
            name="noStartDate"
            checked={noStartDate}
            value={noStartDate}
            onChange={()=>setNoStartDate(!noStartDate)}
          />
          <span className="DF_checkmark"></span>
        </label>
        <TooltipPurp 
        text={cb_tooltip}
        />
        </div>

      </div>
    </div>
  );
};

export default SearchStartDate;
