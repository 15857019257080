import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputCountry from "../Input/InputCountry";

import "./TourSearchWin.scss";
import InputField from '../Input/InputField';
import RSelectField from '../Input/RSelectField';
import SearchAges from '../Input/SearchAges';
import SearchInt from '../AddInterest/SearchInt';
import SearchLang from '../AddLang/SearchLang';
import SelectField from '../Input/SelectField';
import SearchVerified from '../Input/SearchVerified';

const UserSearchWin = ({ search, close }) => {

  const [country, setCountry] = useState(null);
  const [city, setCity] = useState("");
  const [gender, setGender] = useState(null);
  const [startAge, setStartAge] = useState("");
  const [endAge, setEndAge] = useState("");

  const [height, setHeight] = useState();
  const [orientation, setOrientation] = useState();
  const [character, setCharacter] = useState();
  const [finances, setfinances] = useState();
  const [alkohol, setAlkohol] = useState();
  const [relations, setRelations] = useState();
  const [religion, setReligion] = useState();

  const [verified, setVerified] = useState(false);

  const [intValue, setIntValue] = useState(null)
  const [lang, setLang] = useState(null)

  const [filter, setFilter] = useState({})

// console.log(filter);

  useEffect(() => {
    const mapCountry = country && country.map(v=>v.value)
    const mapInterests = intValue && intValue.map(v=>v._id)
    const mapLangs = lang && lang.map(v=>v._id)

    setFilter({
        country: mapCountry,
        gender:gender?.value,
        interests:mapInterests,
        langs:mapLangs,
        city,
        startAge,
        endAge,
        verified,
        orientation,
        character,
        finances,
        height,
        alkohol,
        relations,
        religion
      })

    }, [country, city, gender, startAge, endAge, intValue,verified, lang, orientation, character, finances, height, alkohol, relations, religion]);

  const {t} = useTranslation(['tr']);

const handleSearch=()=>{
  if(country|| city||gender||startAge||endAge||intValue||verified||lang||orientation||finances||character||height||alkohol||relations||religion
  ){
  search(filter)
  close()

  }else{
    close()
  }
}

  return (
    <div className="TourSearchWin_wrapper">
      <div className="TSW_contatiner">
        <div className="TSW_header">
        <h2>{t("UserSearch",{ns:"tr"})}</h2>
          <p>{t("PostSearch_t",{ns:"tr"})}</p>
        </div>

        <div className="TSW_contentBox">

<div className="TSW_line TSW_line1">

        <div className="TSW_SearchBlock">
          <InputCountry
              title={t("userCountry", { ns: "translation" })}
              isMulti
              icon="country"
              placeholder={t("userCountry", { ns: "translation" })}
              tooltip={t("US_Country_tt",{ns:"tr"})}
              name="country"
              onChange={(val)=>setCountry(val)}
              value={country}
            />
          </div>

          <div className="TSW_SearchBlock">
          <InputField
            title={t("userCity", { ns: "translation" })}
            icon="country"
            type="text"
            placeholder={t("userCity", { ns: "translation" })}
            tooltip={t("TS_toCity_tt",{ns:"tr"})}
            name="city"
            value={city}
            onChange={(e)=>setCity(e.target.value)}
          />
        </div>
      <div className="TSW_SearchDatesAmount">
        <SearchVerified
          title={t("verification", { ns: "translation" })}
          cb_title={t("cb_verification_user", { ns: "translation" })}
          tooltip={t("US_verified_tt",{ns:"tr"})}
          name="verified"
          verified={verified}
          setVerified={setVerified}
        />
      </div>

</div>

<div className="TSW_line TSW_line2">
    <div className="TSW_searchInt">
      <SearchInt
        placeholder={t("userInt",{ns:"int"})}
        intValue={intValue}
        setIntValue={setIntValue}
        isMulti
      />
  </div>
    <div className="TSW_searchLang">
    <Suspense fallback="Loading...">

      <SearchLang
        placeholder={t("userLangs",{ns:"langs"})}
        lang={lang}
        setLang={setLang}
        isMulti
      />
      </Suspense>
  </div>

</div>

<div className="TSW_line TSW_line3">

        <div className="TSW_SearchBlock">
          <RSelectField
            title={t("userGender", { ns: "translation" })}
            icon="sex"
            name="gender"
            tooltip={t("US_Gender_tt",{ns:"tr"})}

            defaultValue={{label:t("any",{ns:"translation"}),value:"any"}}
            value={gender}
            onChange={(value)=>setGender(value)}

            options={[
              { label: t("any",{ns:"translation"}), value: "any" },
              { label: t("men",{ns:"translation"}), value: "male" },
              { label: t("women",{ns:"translation"}), value: "female" },
            ]}
          />
        </div>

        <div className="TSW_SearchBlock">

          <SearchAges
            icon="ages"
            title={t("age", { ns: "translation" })}
            startAge={startAge}
            setStartAge={setStartAge}
            endAge={endAge}
            setEndAge={setEndAge}
            noAnyAge={true}

          />
        </div>

        <div className="TSW_SearchBlock">
          <InputField
                  title={t("height", { ns: "translation" })}
                  icon="height"
                  type="number"
                  inputmode="numeric"
                  placeholder={t("heightUnits", { ns: "translation" })}
                  name="height"
                  value={height}
                  onChange={(e)=>setHeight(e.target.value)}
              />
        </div>

</div>
<div className="TSW_line TSW_line4">

<div className="TSW_SearchBlock">
  <SelectField
          title={t("relations", { ns: "translation" })}
          icon="heart"
          name="relationship"
          value={relations}
          onChange={(e)=>setRelations(e.target.value)}

          options={[
            { label: t("noAnswer", { ns: "translation" }), value: '' },
            { label: t("dating", { ns: "translation" }), value: 'dating' },
            { label: t("single", { ns: "translation" }), value: 'single' },
            { label: t("married", { ns: "translation" }), value: 'married' },
            { label: t("devorsed", { ns: "translation" }), value: 'devorsed' },
            { label: t("other", { ns: "translation" }), value: 'other' },
          ]}/>
  </div>

  <div className="TSW_SearchBlock">
  <SelectField
        title={t("alkohol", { ns: "translation" })}
        icon="alkohol"
        name="alkohol"
        value={alkohol}
        onChange={(e)=>setAlkohol(e.target.value)}

        options={[
          { label: t("noAnswer", { ns: "translation" }), value: '' },
          { label: t("dontDrink", { ns: "translation" }), value: 'dontDrink' },
          { label: t("Drink", { ns: "translation" }), value: 'Drink' },
          { label: t("inCompany", { ns: "translation" }), value: 'inCompany' },
          { label: t("other", { ns: "translation" }), value: 'other' },
        ]}/>
  </div>

  <div className="TSW_SearchBlock">
  <SelectField
        title={t("religion", { ns: "translation" })}
        icon="religion"
        name="religion"
        value={religion}
        onChange={(e)=>setReligion(e.target.value)}

        options={[
          { label: t("noAnswer", { ns: "translation" }), value: '' },
          { label: t("notReligious", { ns: "translation" }), value: 'notReligious' },
          { label: t("christianity", { ns: "translation" }), value: 'christianity' },
          { label: t("islam", { ns: "translation" }), value: 'islam' },
          { label: t("buddhism", { ns: "translation" }), value: 'buddhism' },
          { label: t("judaism", { ns: "translation" }), value: 'judaism' },
          { label: t("other", { ns: "translation" }), value: 'other' },
        ]}/>
  </div>

</div>

<div className="TSW_line TSW_line5">

<SelectField
        title={t("orientation", { ns: "translation" })}
        icon="sex"
        name="orientation"
        value={orientation}
        onChange={(e)=>setOrientation(e.target.value)}
        // {...formik.getFieldProps('orientation')}

        options={[
          { label: t("noAnswer", { ns: "translation" }), value: '' },
          { label: t("hetero", { ns: "translation" }), value: 'hetero' },
          { label: t("bisexual", { ns: "translation" }), value: 'bisexual' },
          { label: t("gay", { ns: "translation" }), value: 'gay' },
          { label: t("lesbi", { ns: "translation" }), value: 'lesbi' },
          { label: t("other", { ns: "translation" }), value: 'other' },
        ]}/>

<SelectField
        title={t("character", { ns: "translation" })}
        icon="character"

        name="character"
        value={character}
        onChange={(e)=>setCharacter(e.target.value)}
        // {...formik.getFieldProps('character')}

        options={[
          { label: t("noAnswer", { ns: "translation" }), value: '' },
          { label: t("extravert", { ns: "translation" }), value: 'extravert' },
          { label: t("intravert", { ns: "translation" }), value: 'intravert' },
          { label: t("other", { ns: "translation" }), value: 'other' },
        ]}/>

<SelectField
        title={t("finances", { ns: "translation" })}
        icon="dollar"

        name="finances"
        value={finances}
        onChange={(e)=>setfinances(e.target.value)}
        // {...formik.getFieldProps('finances')}

        options={[
  { label: t("noAnswer", { ns: "translation" }), value: '' },
  { label: t("payForMyself", { ns: "translation" }), value: 'payForMyself' },
  { label: t("canBeSponsor", { ns: "translation" }), value: 'canBeSponsor' },
  { label: t("needSponsor", { ns: "translation" }), value: 'needSponsor' },
  { label: t("other", { ns: "translation" }), value: 'other' },
        ]}/>

</div>
        </div>

      </div>
      <div className="TSW_Btn_line">
      <div className="TSW_btn" onClick={handleSearch}>Поиск</div>
      </div>
    </div>
  );
};

export default UserSearchWin;
