import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';

import {ReactComponent as Tours} from '../../assets/Menu/tours.svg'
import {ReactComponent as Posts} from '../../assets/Menu/posts.svg'
import {ReactComponent as People} from '../../assets/Menu/people.svg'
import {ReactComponent as Favorites} from '../../assets/Menu/heart.svg'
import {ReactComponent as News} from '../../assets/Menu/news.svg'
import {ReactComponent as Messenger} from '../../assets/Menu/msg.svg'
import {ReactComponent as Groups} from '../../assets/Menu/community.svg'
import {ReactComponent as Help} from '../../assets/Menu/info.svg'

import './Menu.scss'

const Menu = () => {
    const { unreadMsg, setUnreadMsg} = useContext(UserContext);
    const {t} = useTranslation(['translation', 'tr']);

  return (
        <div className="menubox">

        <NavLink to="/tours/1" >
            <div className="menu_item" >
                <Tours className='menu_icon' />
                <p>{t("menu_tours", { ns: "translation" })}</p>
                <div className="menu_line"></div>
            </div>
        </NavLink>

        <NavLink to="/posts" className='NavLink_style'>
            <div className="menu_item">
                <Posts className='menu_icon' />
                <p>{t("menu_posts", { ns: "translation" })}</p>
                <div className="menu_line"></div>
            </div>
        </NavLink>

        <NavLink to="/people" className='NavLink_style'>
            <div className="menu_item">
                <People className='menu_icon' />
                <p>{t("menu_people", { ns: "translation" })}</p>
                <div className="menu_line"></div>
            </div>
        </NavLink>

        <NavLink to="/news" className='NavLink_style'>
            <div className="menu_item">
                <News className='menu_icon' />
                <p>{t("menu_news", { ns: "translation" })}</p>
                <div className="menu_line"></div>
            </div>
        </NavLink>

        {/* <NavLink to="/favorites" className='NavLink_style'>
            <div className="menu_item">
                <Favorites className='menu_icon' />
                <p>{t("menu_favorites", { ns: "translation" })}</p>
            </div>
        </NavLink> */}

        <NavLink to="/messenger" className='NavLink_style'>
            <div className="menu_item Nav_Messanger">
                <Messenger className='menu_icon'/>
                <p>{t("menu_messanger", { ns: "translation" })}</p>
                {unreadMsg>0 && <div className='NavUnreadMsg'>{unreadMsg}</div> }
                <div className="menu_line"></div>
            </div>
        </NavLink>

        {/* <NavLink to="/societies" className='NavLink_style'>
            <div className="menu_item">
                <Groups className='menu_icon' />
                <p>{t("menu_societies", { ns: "translation" })}</p>
                <div className="menu_line"></div>
            </div>
        </NavLink> */}

        <NavLink to="/help" className='NavLink_style'>
            <div className="menu_item">
                <Help className='menu_icon' />
                <p>{t("menu_help", { ns: "translation" })}</p>
                <div className="menu_line"></div>
            </div>
        </NavLink>

        </div>
  )
}

export default Menu