import { useTranslation } from 'react-i18next';

import { FaStar } from "react-icons/fa";
import "./Rating.scss";

const RatingBig = ({ rating, reviews }) => {
  const {t} = useTranslation(['tr']);

  return (
    <div className="ratingBigBox">

      <h1> {rating} </h1>

    <div className="starsBoxRight">

    <div className="starsBox">
      {[...Array(5)].map((_, idx) => {
        const ratingValue = idx + 1;
        return (
          <FaStar key={idx} size={20} color={ratingValue <= rating ? "#ffc107" : "#e4e5e9"} />
        );
      })}
    </div>

      <h3>{t("BasedOn",{ns:"tr"})} {reviews} {t("reviews",{ns:"tr"})}</h3>
    </div>


    </div>
  );
};

export default RatingBig;
