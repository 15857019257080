import AttentionIcon from '../../assets/join/attention.svg'


import './JoinedChip.scss'

const TestChip = ({tooltip, title, small, mobile}) => {
  return (

    <div className='Chip_Tooltip_wrapper'>
      
      {!mobile ? 
      <div className={small? "TP_Chip_wrapper Chip_small": "TP_Chip_wrapper"}>
        <img src={AttentionIcon} alt="" />
        {!small &&
        <h2>{title}</h2>}
      </div>
      :
      <div className="TP_Chip_mob">
        <img src={AttentionIcon} alt="" />
      </div>
      }


      <div className="Chip_tip Chiptip_down" dangerouslySetInnerHTML={{ __html: tooltip }} />
      </div>

    )
}

export default TestChip