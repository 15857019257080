import { useTranslation } from 'react-i18next';

import {ReactComponent as RightArrow} from '../../assets/right_arrow.svg'
import Ppl_group from '../../assets/Advices/ppl_group.jpg'

import './Advice.scss'
const Advice = () => {
  const {t} = useTranslation(['tr']);

  return (
    <div className="advice_container">
    <div className="advice_title">
    <p>{t("advices",{ns:"tr"})}:</p>
    </div>

    <div className="advice">
        <img src={Ppl_group} alt="" />
        <div className="advice_title_sml">
            <p>{t("advice_HowToFind",{ns:"tr"})}?</p>
            <RightArrow className='sml_arrow'/>
        </div>
    </div>

    </div>
  )
}

export default Advice