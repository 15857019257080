import infoPic from '../../assets/info_window/assistant.jpg'


import './InfoWindow.scss'

const InfoWindow = ({close, title, info}) => {
    
  return (
    <div className="Info_Window_wrapper"> 

  <div className="IW_pic_area">
    <img src={infoPic} alt="assistant" />
  </div>

  <div className="IW_info_area">
    <p>{title}</p>
    <div className="IW_info_text" dangerouslySetInnerHTML={{ __html: info }} />

    <div className="IW_btn" onClick={close}> OK</div>
  </div>

</div>
  )
}

export default InfoWindow