import { useContext, useEffect, useState } from 'react';
import Spinner from '../../components/Spinner/Spinner';
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import * as api from '../../utils/api/api.utils'

import { handleError } from '../../utils/handleError';
import ConTour from '../../components/Tour/ConTour';
import ConTourMob from '../../components/Tour/ConTourMob';

import './ConTours.scss'
const ConTours = () => {
  const { jwtUser} = useContext(UserContext);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState();
  const [actualTours, setActualTours] = useState();
  const {t} = useTranslation(['tr']);

  const { mobileBig } = useMatchMedia();


  useEffect(() => {
    api.findToursByIdArray(jwtUser?.data?.user?.created_tours).then(res=> {
      if (res?.status === 200) {
        setActualTours(res.data)
        setLoading(false)
      }else{
        handleError(res)
      }
    })
  }, [updated]);

  const finishedTours = actualTours?.filter(tour=>{
    if(!tour?.noEndDate && (new Date() > new Date(tour?.endDate))){
      return true
    }else{
      return false
    }
  })

  const notFinishedTours = actualTours?.filter(tour=>{
    if(!tour?.noEndDate && (new Date() > new Date(tour?.endDate))){
      return false
    }else{
      return true
    }
  })


  if(loading){
    return <Spinner/>
  }

  return (
    <div className="ConTours_wrapper">

    <div className="ConTours_title_area">
        <p>{t("CP_myTours",{ns:"tr"})}</p>
        <h4>{t("CP_myTours_text",{ns:"tr"})}</h4>
    </div>


    <div className="ConTPage_NavBar">
        <div
        onClick={()=>setPage(0)}
        className={page===0 ? "ConT_Item active_l":"ConT_Item"}>
        {t("Actual",{ns:"tr"})}: {notFinishedTours?.length}</div>

        <div
        onClick={()=>setPage(1)}
        className={page===1 ? "ConT_Item active_l":"ConT_Item"}>
        {t("Finished",{ns:"tr"})}: {finishedTours?.length}</div>

        {/* <div
        onClick={()=>setPage(2)}
        className={page===2 ? "ConT_Item active_l":"ConT_Item"}>
        Черновики</div> */}
    </div>


    {page===0 &&

      <div className="ConToursActual_wrapper">
      {notFinishedTours && notFinishedTours.map((tour, idx) => (

        <div key={idx}>
          {!mobileBig?
              <ConTour
              jwtUser={jwtUser}
              setUpdated={setUpdated}
              tour={tour}
                />
              :
              <ConTourMob
              jwtUser={jwtUser}
              setUpdated={setUpdated}
              tour={tour}
              />
          }
        </div>
      ))}

    </div>

    }

    {page===1 &&

      <div className="ConToursActual_wrapper">
        {finishedTours && finishedTours.map((tour, idx) => (

          <div key={idx}>
            {!mobileBig?
                <ConTour
                jwtUser={jwtUser}
                setUpdated={setUpdated}
                tour={tour}
                  />
                :
                <ConTourMob
                jwtUser={jwtUser}
                setUpdated={setUpdated}
                tour={tour}
                />
            }
          </div>
      ))}

    </div>

    }

    {/* {page===2 && <ConToursTemplate/>} */}

    </div>
  )
}

export default ConTours