import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as api from '../../utils/api/api.utils'
import Spinner from '../../components/Spinner/Spinner';

import ShowReview from '../../components/Review/ShowReview';
import RatingBig from '../../components/Review/RatingBig';
import { handleError } from '../../utils/handleError';

import './TourReviews.scss'
import { showDate } from '../../utils/showDate';
import EmptyHere from '../../components/PageItems/EmptyHere';
const TourReviews = () => {
  const {tour} = useOutletContext();

  const {isFinished} = showDate(tour);
  const {t} = useTranslation(['tr']);

  const [reviews, setReviews] = useState();
  const [loading, setLoading] = useState(isFinished);


  useEffect(() => {
    if(isFinished){
      api.getAllTourReviews(tour?._id).then(res=> {
        if(res.status===200){
          setReviews(res.data)
          setLoading(false)
        }else{
          handleError(res)
          setLoading(false)
        }
      })
    }
  }, [tour]);


    //-- Calculating average
  const newArray = reviews?.map(item => item.rating)

  const avg = (array)=> {
    var total = 0;
    var count = 0;
    array?.forEach(function(item) {
        total += item;
        count++;
    });
    return total / count;
  }

  const avgRating = avg(newArray).toFixed(1);

  if(loading){
    return <Spinner/>
  }


  if(!isFinished){
    return (
      <EmptyHere
          text={t("emptyHere",{ns:"tr"})}
          subText={t("NoTourReviews",{ns:"tr"})}
        />
    )
  }

  return (

    <div className="TourReviews_wrapper">
      {!isNaN(avgRating)&&
        <div className="TourReviews_Title">
          <RatingBig rating={avgRating} reviews={reviews?.length}/>
        </div>
      }

      <div className='TR_bottom'>
        {reviews && reviews?.map((review, index)=>(
          <ShowReview key={index} review={review} />
        ))
        }
      </div>
    </div>

    )
}

export default TourReviews