import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import "./JoinedChip.scss";
import { showDate } from '../../utils/showDate';
const StatusText = ({ jwtUser, tour, mob=false }) => {
  const {t} = useTranslation(['tr']);
  const navigate = useNavigate();

  const {isFinished, isStarted} = showDate(tour);

  const myId = jwtUser?.data?.user?._id

  const amICreator = tour?.creator?._id=== myId;
  const amIinJoinedList = tour?.joined_users?.some(user => user._id === myId);
  const amIinConfirmedList = tour?.confirmed_users?.includes(myId);
  const amIinRequestsList = tour?.join_requests?.includes(myId);
  const amIinRejectedList = tour?.rejected_users?.includes(myId);

  let status;
  let clName;
  let onClick;
  if(amIinJoinedList){
    status = t("youJoined",{ns:"tr"})
    clName = "StatusText_Joined"
  }else if(amIinConfirmedList){
    status = t("youConfirmed",{ns:"tr"})
    clName = "StatusText_Confirmed"
  }else if(amIinRequestsList){
    status = t("youRequested",{ns:"tr"})
    clName = "StatusText_Requested"
  }else if(amIinRejectedList){
    status = t("youRejected",{ns:"tr"})
    clName = "StatusText_Rejected"
  }else if(amICreator){
    status = ""
  }else if(!isStarted){
    status = t("YouJoin",{ns:"tr"})
    clName = mob? "StatusText_Join_mob": "StatusText_Join"
    onClick = ()=> navigate(`/tour/${tour?._id}/join`)
  }

  return (
    <div className={mob? "StatusText_wrapper_mob" :"StatusText_wrapper"}>
      <div className={clName} onClick={onClick}><b>{status}</b></div>
    </div>
  );
};

export default StatusText;
