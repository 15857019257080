import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import Tours from '../../assets/Menu/tours_purpl.svg'
import Posts from '../../assets/Menu/posts_purpl.svg'

import './Menu.scss'
const MobAddMenu = ({addMenuOpen, setAddMenuOpen}) => {

    const {t} = useTranslation(['translation']);
    const navigate = useNavigate();

    const createTour=()=>{
        setAddMenuOpen(false)
        navigate(`/createtour`)
    }

    const createPost=()=>{
        setAddMenuOpen(false)
        navigate(`/createpost`)
    }


  return (
        <div className={`mob_menubox addMenu ${!addMenuOpen?'MobM_hide':null}`}>

            <div className="mob_menu_item" onClick={createTour}>
                <img src={Tours} alt="" />
                <p>{t("ph_createTour", { ns: "tr" })}</p>
            </div>

            <div className="mob_menu_item" onClick={createPost}>
                <img src={Posts} alt="" />
                <p>{t("ph_createPost", { ns: "tr" })}</p>
            </div> 

        </div>
  )
}

export default MobAddMenu