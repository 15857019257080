import { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from "../../context/user.context";
import { useFormik } from "formik";
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import * as api from '../../utils/api/api.utils'
import { validationSchema } from "../../utils/validations/createTour_Yup";

import PageBtn from '../../components/PageBtn/PageBtn';
import PageHeader from '../../components/PageHeader/PageHeader'
import PreviewTour from '../../components/Tour/PreviewTour';
import Spinner from '../../components/Spinner/Spinner';

import EditTourPage1 from './EditTourPage1';
import EditTourPage2 from './EditTourPage2';
import EditTourPage3 from './EditTourPage3';
import EditTourPage4 from './EditTourPage4';
import Modal from '../../components/Modal/Modal';
import InfoWindow from '../../components/Window/InfoWindow';

import './EditTourPage.scss'
import LoadingScreen from '../../components/Spinner/LoadingScreen';
import { handleError } from '../../utils/handleError';
import MainBtn from '../../components/Buttons/MainBtn';

const EditTourPage = () => {
  const { jwtUser } = useContext(UserContext);
  const [tour, setTour] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);


  const [serverError, setServerError] = useState();
  const [errorsPage1, setErrorsPage1] = useState();
  const [errorsPage2, setErrorsPage2] = useState();
  const [page, setPage] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([])
  const [usersList, setUsersList] = useState([])
  const [usersLangs, setUsersLangs] = useState([])
  const { ipadSml } = useMatchMedia();

  useEffect(() => {
    formik.setFieldValue("image", selectedFiles);
    if(selectedFiles.length===0) {
    formik.setFieldValue("image", null);
    }
    }, [selectedFiles]);

  useEffect(() => {
    formik.setFieldValue("interests", usersList);
    }, [usersList]);

  useEffect(() => {
    formik.setFieldValue("langs", usersLangs);
    if(usersLangs.length===0) {
      formik.setFieldValue("langs", "");
      }
    }, [usersLangs]);

    const { id } = useParams();

    useEffect(() => {
      api.getTourById(id).then(res=> {
        if (res?.status === 200) {
          setTour(res.data)
          setUsersLangs(res?.data?.langs)
          setUsersList(res?.data?.interests)
          setLoading(false)
        }else{
          handleError(res)
        }
    })

  }, []);


  const {t} = useTranslation(['tr']);


  const navigate = useNavigate();

  const formik = useFormik({initialValues: {
        depart: tour?.depart,
        title: tour?.title,
        creator: jwtUser?.data?.user?._id,
        amount: tour?.amount,
        startAge: tour?.startAge,
        endAge: tour?.endAge || 90,
        anyAge: tour?.anyAge,
        text: tour?.text,
        country: tour?.country,
        city: tour?.city,
        interests: tour?.interests,
        langs: tour?.langs,
        gender: tour?.gender,

        startDate: tour?.startDate ? new Date(tour?.startDate): "",
        endDate:tour?.endDate ? new Date(tour?.endDate): "",
        noStartDate:tour?.noStartDate,
        noEndDate:tour?.noEndDate,
        anyDates:tour?.anyDates,
        daysAmount:tour?.daysAmount,

        tourPrice:tour?.tourPrice,
        tourFee:tour?.tourFee,
        pocketMoney:tour?.pocketMoney,

        image:null,
        tourMap:[],

        tourPlan: tour?.tourPlan,
        tourRules: tour?.tourRules,

        isModerating:true,
        isPublic:false,

      },
      validationSchema: validationSchema ,
      enableReinitialize: true,
      onSubmit: (values) => {
          // console.log('Formik values', values);
      },
    });

  //--- Add Tour initial titleImages and tourMap only for Edit Tour page ----
  useEffect(() => {

    if(tour?.titleImages){
      tour?.titleImages.forEach((file) => {
        const linkImgObj={ link: file }
        setSelectedFiles((curr) => [...curr, linkImgObj]);
      })
    }

    if(tour?.tourMap){
      tour?.tourMap?.forEach((file) => {
        const linkMapObj=[{ link: file }]
        formik.setFieldValue("tourMap", linkMapObj);
      });
    }

  }, [tour]);

    useEffect(() => {

        if(!formik.values.endDate ||
            !formik.values.startDate ||
            formik.values.noStartDate ||
            formik.values.noEndDate ||
            (formik.values.endDate - formik.values.startDate)<0){
            formik.setFieldValue("daysAmount", 0);
          }else{
            const daysAmount = (Math.ceil((formik.values.endDate - formik.values.startDate) / (1000 * 3600 * 24)))+1;
            formik.setFieldValue("daysAmount", daysAmount);
        }
        }, [formik.values.endDate,
            formik.values.startDate,
            formik.values.noStartDate,
            formik.values.noEndDate,
            ]);

            useEffect(() => {
              if(!formik.errors.pocketMoney && ! formik.errors.tourPrice){
                setErrorsPage2(false)
              }

              if(!formik.errors?.image &&
                !formik.errors?.title &&
                !formik.errors?.depart &&
                !formik.errors?.country &&
                !formik.errors?.amount &&
                !formik.errors?.endAge &&
                !formik.errors?.startDate &&
                !formik.errors?.endDate &&
                !formik.errors?.text &&
                !formik.errors?.langs &&
                !formik.errors?.title
                ){
                  setErrorsPage1(false)
                }

          }, [formik.errors]);

          useEffect(() => {
            if(!errorsPage1 && !errorsPage2){
              setServerError("")
            }

        }, [errorsPage1, errorsPage2]);


    if(!jwtUser){
        return <Navigate to={`/login`} />
    }



    const nextPage =()=>{
        if(page<3) setPage(page=>page+1)
    }
    const prevPage =()=>{
        if(page>0) setPage(page=>page-1)
    }

    const handleSubmit = async(e)=>{
      e.preventDefault();
      formik.handleSubmit();

      setServerError(null)
      console.log("tour edit formik", formik);

      try {
        const formData = new FormData();

        formData.append("title", formik.values.title);
        formData.append("creator", jwtUser.data.user._id);

        formData.append("amount", formik.values.amount);
        formData.append("gender", formik.values.gender);

        formData.append("startAge", formik.values.startAge);
        formData.append("endAge", formik.values.endAge);
        formData.append("anyAge", formik.values.anyAge);
        formData.append("text", formik.values.text);

        formData.append("depart", formik.values.depart);
        formData.append("country", formik.values.country);
        formData.append("city", formik.values.city);

        for (let i=0; i<usersLangs.length; i++) {
          formData.append("langs", usersLangs[i]);
        }

        for (let i=0; i<usersList.length; i++) {
          formData.append("interests", usersList[i]);
        }

        for (let i=0; i<selectedFiles.length; i++) {
          if(selectedFiles[i].link){
               formData.append("image_links",
          new Array([JSON.stringify({id:i, path:selectedFiles[i].link})]));
          }else{
              formData.append(i, selectedFiles[i].source);
          }
         }

        if(formik.values?.tourMap?.length>0){
          if(formik.values?.tourMap[0].source){
            formData.append("tourMap", formik.values?.tourMap[0].source);
          }
        }else{
        formData.append("tourMap", null);
         }

        formData.append("startDate", formik.values.startDate);
        formData.append("endDate", formik.values.endDate);
        formData.append("noStartDate", formik.values.noStartDate);
        formData.append("noEndDate", formik.values.noEndDate);

        if(formik.values.noStartDate && formik.values.noEndDate){
          formData.append("anyDates", true);
        }else{
          formData.append("anyDates", false);
        }

        formData.append("daysAmount", formik.values.daysAmount);

        formData.append("tourPrice", formik.values.tourPrice);
        formData.append("tourFee", formik.values.tourFee);
        formData.append("pocketMoney", formik.values.pocketMoney);

        formData.append("tourPlan", formik.values.tourPlan);
        formData.append("tourRules", formik.values.tourRules);

        formData.append("isVerifying", true);
        formData.append("isPublic", false);



        if(formik.isValid){
          setSending(true)
          const res = await api.editTour(tour?._id, formData)

          if (res?.status === 200) {
            setSending(false)
            setModalOpen(true)
          }else{
            throw res
          }

        }else{

          if(formik.errors?.image ||
            formik.errors?.title ||
            formik.errors?.depart ||
            formik.errors?.country ||
            formik.errors?.amount ||
            formik.errors?.endAge ||
            formik.errors?.startDate ||
            formik.errors?.endDate ||
            formik.errors?.text ||
            formik.errors?.langs ||
            formik.errors?.title
            ){setErrorsPage1(true)}
            else{setErrorsPage1(false)}

            if(formik.errors.tourPrice || formik.errors.pocketMoney)
            {setErrorsPage2(true)}
            else{setErrorsPage2(false)}

          console.log("Forms are not correct")

          setServerError(t("fillAllFields",{ns:"tr"}))
        }
    } catch (error) {
        setSending(false)
        handleError(error);
        setServerError(error.response.message)
    }
    }


    if(loading){
      return <Spinner/>
    }


    return (
        <div className="EditTourPage_wrapper">

        {sending && <LoadingScreen/>}

    <PageHeader
      icon="edittour"
      title={t("ph_editTour",{ns:"tr"})}
      text={t("ph_editTour_text",{ns:"tr"})}
      tooltip={t("ph_editTour_tt",{ns:"tr"})}

      />

      <div className="CTP_PageBtns">
        <PageBtn
            number="1"
            title={t("mainInfo",{ns:"tr"})}
            checked={page===0}
            highlighted={errorsPage1}
            onClick={()=>setPage(0)}
        />
        <PageBtn
            number="2"
            title={t("TicketsAndCosts",{ns:"tr"})}
            checked={page===1}
            highlighted={errorsPage2}
            onClick={()=>setPage(1)}
        />
        <PageBtn
            number="3"
            title={t("ControlAndProgramm",{ns:"tr"})}
            checked={page===2}
            onClick={()=>setPage(2)}
        />
        <PageBtn
            number="4"
            title={t("RulesAndPublishing",{ns:"tr"})}
            checked={page===3}
            onClick={()=>setPage(3)}
        />

      </div>

  <form  onSubmit={handleSubmit}>

    {page===0 &&
    <EditTourPage1
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        usersLangs={usersLangs}
        setUsersLangs={setUsersLangs}
        usersList={usersList}
        setUsersList={setUsersList}
        formik={formik}
        jwtUser={jwtUser}
    /> }

    {page===1 &&
    <EditTourPage2
        tour={tour}
        formik={formik}
    /> }

    {page===2 &&
    <EditTourPage3
        formik={formik}
    /> }

    {page===3 &&
    <EditTourPage4
        formik={formik}
        jwtUser={jwtUser}

    /> }

    <div className="ET_nextBtn_line">

    <div className="ET_navBtn_container">
      {page!==0&&
        <MainBtn
          color='white'
          type="submit"
          title={t("Back",{ns:"tr"})}
          onClick={prevPage}
        />
      }
    </div>

    {page!==3&&
      <div className="ET_navBtn_container">
        <MainBtn
          color='purp'
          type="submit"
          title={t("Next",{ns:"tr"})}
          onClick={nextPage}
        />
      </div>
    }

    {page===3&&
    <div className="ET_navBtn_container">

      <MainBtn
        color='purp'
        type="submit"
        title={t("Save",{ns:"tr"})}
        onClick={()=>{}}
      />

    </div>

    }

    </div>

    </form>


    {serverError &&
        <div className="ET_notValid_error">
           <p>{serverError}</p>
        </div>
    }

    {!ipadSml&&
    <div className="CT_Preview_Area">

    <div className="ET_dotted_line"></div>

    <div className="ET_preview_title">
    <p>{t("TourCardPreview",{ns:"tr"})}</p>
    <span>{t("TourCardPreview_text",{ns:"tr"})}</span>
    </div>

    <PreviewTour
        selectedFiles={selectedFiles}
        formik={formik}
        jwtUser={jwtUser}
    />
</div>
}


<Modal open={modalOpen} close={() => setModalOpen(false)} NoCloseBtn={true} >

        <InfoWindow
          close={()=> {
            document.body.classList.remove("active-modal");
            setModalOpen(false)
            navigate('/')
            }}
          title={t("TourIsOnCheck",{ns:"tr"})}
          info={t("TourIsOnCheck_info",{ns:"tr"})}
        />

</Modal>

    </div>
  )
}

export default EditTourPage