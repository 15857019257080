import { useContext, useState } from 'react';
import { UserContext } from '../../context/user.context';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import MsgIcon from '../../assets/msg_white.svg'
import Modal from '../Modal/Modal';
import SendMsgWin from '../Window/SendMsgWin';
import MainBtn from './MainBtn';

import './MsgBtnSml.scss'
const MsgBtn = ({ userId, small}) => {
  const { jwtUser } = useContext(UserContext);

  const [msgModalOpen, setMsgModalOpen] = useState(false);

  const navigate = useNavigate();
  const {t} = useTranslation(['tr']);

  const sendMessage =()=>{
    if(!jwtUser) navigate('/login')

    setMsgModalOpen(true)
  }

  return (
    <div className="MsgBtn_wrapper">

      {small?
        <div className="MsgBtnUP" onClick={sendMessage}>
          <img src={MsgIcon} alt="" />
        </div>
        :
        <MainBtn title={t("up_text", { ns: "tr" })} color='tortSmall' onClick={sendMessage}/>
      }


      <Modal open={msgModalOpen} close={()=>setMsgModalOpen(false)}>
        <SendMsgWin userId={userId}
          close={()=>{
            document.body.classList.remove("active-modal");
            setMsgModalOpen(false)}}
        />
      </Modal>

    </div>

  )
}

export default MsgBtn