import name_icon from '../../assets/create_account/man.svg'
import date_icon from '../../assets/create_account/calendar.svg'
import planet_icon from '../../assets/create_account/planet.svg'
import email_icon from '../../assets/create_account/email.svg'
import pass_icon from '../../assets/create_account/password.svg'
import conf_icon from '../../assets/create_account/confirm.svg'

import alkohol_icon from '../../assets/create_account/alkohol.svg'
import character_icon from '../../assets/create_account/character.svg'
import dollar_icon from '../../assets/create_account/dollar.svg'
import heart_icon from '../../assets/create_account/heart.svg'
import height_icon from '../../assets/create_account/height.svg'
import lang_icon from '../../assets/create_account/lang.svg'
import interest_icon from '../../assets/create_account/interest.svg'
import religion_icon from '../../assets/create_account/religion.svg'
import sex_icon from '../../assets/create_account/sex.svg'
import smoke_icon from '../../assets/create_account/smoke.svg'
import zodiak_icon from '../../assets/create_account/zodiak.svg'

import { FaPlaneDeparture } from "react-icons/fa";

import TooltipPurp from "../Tooltip/TooltipPurp";
import "./SelectField.scss";

const SelectField = ({ title, icon, tooltip, options, ...otherProps }) => {

    let img;
    if(icon==="date") img = <img src={date_icon} alt="" />
    if(icon==="name") img = <img src={name_icon} alt="" />
    if(icon==="email") img = <img src={email_icon} alt="" />
    if(icon==="pass") img = <img src={pass_icon} alt="" />
    if(icon==="conf") img = <img src={conf_icon} alt="" />
    if(icon==="country") img = <img src={planet_icon} alt="" />

    if(icon==="alkohol") img = <img src={alkohol_icon} alt="" />
    if(icon==="character") img = <img src={character_icon} alt="" />
    if(icon==="dollar") img = <img src={dollar_icon} alt="" />
    if(icon==="heart") img = <img src={heart_icon} alt="" />
    if(icon==="height") img = <img src={height_icon} alt="" />
    if(icon==="lang") img = <img src={lang_icon} alt="" />
    if(icon==="interest") img = <img src={interest_icon} alt="" />
    if(icon==="religion") img = <img src={religion_icon} alt="" />
    if(icon==="sex") img = <img src={sex_icon} alt="" />
    if(icon==="smoke") img = <img src={smoke_icon} alt="" />
    if(icon==="zodiak") img = <img src={zodiak_icon} alt="" />
    if(icon==="whenReady") img = <FaPlaneDeparture size={20} color='#87589C'/>

  return (
    <div className="SelectField_container">
      <div className="SF_title_line">
        <div className="SF_title_sign">
          {img}
          <p>{title}</p>
        </div>

        {tooltip && <TooltipPurp text={tooltip}/> }

      </div>

      <select {...otherProps }>

        {options.map((option) => (
          <option
          key={option.label}
          value={option.value}
          disabled={option.disabled}
          hidden={option.hidden}

          >{option.label}</option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
