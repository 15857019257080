import { useEffect, useState } from 'react';
import * as api from '../../utils/api/api.utils'
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import InputPassword from '../../components/Input/InputPassword';
import Modal from '../../components/Modal/Modal';
import ConfirmWin from '../../components/Window/ConfirmWin';
import './SettingsPage.scss'
import { handleError } from '../../utils/handleError';
import MainBtn from '../../components/Buttons/MainBtn';
const DeleteAccount = ({jwtUser, setJwtUser}) => {
    const [counter, setCounter] = useState(0);
    const [pass, setPass] = useState("");
    const [info, setInfo] = useState("");
    const [serverError, setServerError] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

        if(counter===0) {
            setServerError("")
            setInfo("")
            setDisabled(false)
        }
            if(pass.length===0) setDisabled(true)
      }, [counter, pass]);

    const {t} = useTranslation(['tr']);
    const navigate = useNavigate();

    const deleteAccount= async()=>{

      try {
        setDisabled(true)

        const res = await api.deleteAccount(jwtUser?.data?.user?._id, pass)

        if(res?.status===200){
          setPass("")
          setJwtUser(null)
          setInfo(t("accountDeleted",{ns:"tr"}))
          navigate('/')
        }else if(res?.response?.status===403){
          setCounter(60)
          setPass("")
          setServerError(res?.response?.data?.message)
        }else{
          throw res
        }
      } catch (error) {
          handleError(error);
      }
    }

  return (
    <div className="SP_content_wrapper">
        <div className="SP_Header">
        <p>{t("deleteAccount",{ns:"tr"})}</p>
        <span>{t("toDeleteAccount",{ns:"tr"})}</span>
        </div>

        <div className="SP_contentBox">

    <div className="SP_inputDiv">
        <InputPassword
            title={t("currentPassword",{ns:"translation"})}
            icon="pass"
            placeholder={t("enterCurrentPassword",{ns:"translation"})}
            name="password"
            value={pass}
            disabled={counter!==0}
            onChange={(e)=>setPass(e.target.value)}
        />
    </div>

<div className="SP_error_line">
    {info&& <h5><b>{info}</b> {t("nextTryAfter",{ns:"tr"})} {counter} {t("sec",{ns:"tr"})}. </h5>}

    {serverError && <h6><b>{serverError}</b> {t("nextTryAfter",{ns:"tr"})} {counter} {t("sec",{ns:"tr"})}.</h6>}
</div>
        </div>

        <MainBtn
            color='purp'
            title={t("deleteAccount",{ns:"tr"})}
            disabled={disabled}
            onClick={() => setModalOpen(true)}
        />

<Modal open={modalOpen} close={() => setModalOpen(false)} NoCloseBtn={true} >
      <ConfirmWin
      close={()=> {
        document.body.classList.remove("active-modal");
        setPass("")
        setModalOpen(false)}}

        doSmth={()=>{
        document.body.classList.remove("active-modal");
        setModalOpen(false)
        deleteAccount()}}

      title={t("AreYouSuretoDeleteAccount",{ns:"tr"})}
      info={`<b>${t("AllBeDeleted",{ns:"tr"})}</b>
      <br/>  ${t("NoWayBack",{ns:"tr"})}`}
      />
</Modal>


    </div>

    )
}

export default DeleteAccount