import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'


import good from '../../assets/check.svg'
import bad from '../../assets/bad.svg'

import './Event.scss'
import i18next from 'i18next';

const SysEvent = ({e, menuOpen, setMenuOpen}) => {

    const {t} = useTranslation(['tr']);
    const lang = i18next.language;
    const navigate = useNavigate();

    const x = e?.action;
    const sysGood = (x==="verified" || x==="publicated" || x==="you_joined"|| x==="you_confirmed" )
    const sysBad = (x==="not_verified" || x==="not_publicated" || x==="you_rejected" || x==="you_blocked")
    const date =  new Date(e?.createdAt).toLocaleString(lang,{day:'numeric', month:'short'});

    const navigateTo =()=>{
      if(x==="you_joined"|| x==="you_confirmed"){
        navigate(`/tour/${e?.tour?._id}/`)
        setMenuOpen(!menuOpen)
      }
    }

  return (
    <div className="Event_Wrapper" onClick={navigateTo}>

        <div className="E_imgbox">
            {sysGood && <img className='E_sysIcon' src={good} alt=""/>}
            {sysBad && <img className='E_sysIcon' src={bad} alt=""/>}
        </div>

        <div className="Event_infoArea">

            {(x==="verified")&&  <>
              <div className="E_sysLine">
                <h3>{t("congrats",{ns:"tr"})}</h3>  <h5>{date}</h5>
              </div>
              <span><b>{t("youPassedVerification",{ns:"tr"})}!</b></span>
            </>}

            {(x==="not_verified")&& <>
              <div className="E_sysLine">
                <h3>{t("unfortunately",{ns:"tr"})},</h3>  <h5>{date}</h5>
              </div>
              <span><b>{t("youdidntPassVerification",{ns:"tr"})}!</b></span>
            </>}

            {(x==="publicated")&&  <>
              <div className="E_sysLine">
                <h3>{t("congrats",{ns:"tr"})}</h3>  <h5>{date}</h5>
              </div>
              <span className='E_tort'><b>{t("yourTourIsPublicated",{ns:"tr"})}!</b></span>
              <h4>"{e.tour?.title}"</h4>
            </>}

            {(x==="not_publicated")&& <>
              <div className="E_sysLine">
                <h3>{t("unfortunately",{ns:"tr"})},</h3>  <h5>{date}</h5>
              </div>
              <span className='E_red'><b>{t("yourTourIsNotPublicated",{ns:"tr"})}!</b></span>
              <h4>"{e.tour?.title}"</h4>
            </>}

            {/* Вы добавлены в участники тура */}
            {(x==="you_joined")&& <>
              <div className="E_sysLine">
                <h3>{t("congrats",{ns:"tr"})},</h3>  <h5>{date}</h5>
              </div>
              <span className='E_tort'><b>{t("youJoinedToTour",{ns:"tr"})}:</b></span>
              <h4>"{e.tour?.title}"</h4>
            </>}

            {/* Вас одобрили в тур */}
            {(x==="you_confirmed")&& <>
              <div className="E_sysLine">
                <h3>{t("congrats",{ns:"tr"})},</h3>  <h5>{date}</h5>
              </div>
              <span className='E_green'><b>{t("youConfirmedToTour",{ns:"tr"})}:</b></span>
              <h4>"{e.tour?.title}"</h4>
            </>}

            {/* Вас не одобрили в тур */}
            {(x==="you_rejected")&& <>
              <div className="E_sysLine">
                <h3>{t("unfortunately",{ns:"tr"})},</h3>  <h5>{date}</h5>
              </div>
              <span className='E_orange'><b>{t("youRejectedToTour",{ns:"tr"})}:</b></span>
              <h4>"{e.tour?.title}"</h4>
            </>}

            {/* Вас заблокировали в туре */}
            {(x==="you_blocked")&& <>
              <div className="E_sysLine">
                <h3>{t("unfortunately",{ns:"tr"})},</h3>  <h5>{date}</h5>
              </div>
              <span className='E_red'><b>{t("youBlockedFromTour",{ns:"tr"})}:</b></span>
              <h4>"{e.tour?.title}"</h4>
            </>}

        </div>

    </div>
  )
}

export default SysEvent