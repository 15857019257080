import React from "react";
import ReactDOM from "react-dom/client";
import { UserProvider } from "./context/user.context";
import {
  Route,
  createRoutesFromElements,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import App from "./App";
import ToursPage from "./pages/ToursPage/ToursPage";
import PostsPage from "./pages/PostsPage/PostsPage";
import PeoplePage from "./pages/PeoplePage/PeoplePage";
import SocietiesPage from "./pages/SocietiesPage/SocietiesPage";
import MessengerPage from "./pages/MessengerPage/MessengerPage";
import FavoritesPage from "./pages/FavoritesPage/FavoritesPage";
import NewsPage from "./pages/NewsPage/NewsPage";
import HelpPage from "./pages/HelpPage/HelpPage";

import TourPage from "./pages/TourPage/TourPage";
import Overview from "./pages/TourPages/Overview";
import People from "./pages/TourPages/People";
import TravelPlan from "./pages/TourPages/TravelPlan";
import Join from "./pages/TourPages/Join";
import UserPage from "./pages/UserPage/UserPage";
import AboutMe from "./pages/UserPages/AboutMe";
import MyTours from "./pages/UserPages/MyTours";
import MyReviews from "./pages/UserPages/MyReviews";
import Followers from "./pages/UserPages/Followers";
import MyGallery from "./pages/UserPages/MyGallery";
import TourGallery from "./pages/TourPages/TourGallery";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import Test from "./pages/Test/Test";
import LoginPage from "./pages/LoginPage/LoginPage";
import NoMatchPage from "./pages/NoMatchPage/NoMatchPage";
import BalancePage from "./pages/BalancePage/BalancePage";
import UserPageEdit from "./pages/UserPageEdit/UserPageEdit";
import CreateTourPage from "./pages/CreateTourPage/CreateTourPage";
import VerificationPage from "./pages/VerificationPage/VerificationPage";
import ModerationPage from "./pages/ModerationPage/ModerationPage";
import VerifyUserPage from "./pages/ModPages/VerifyUserPage";
import FeedbackPage from "./pages/ModPages/FeedbackPage";
import ControlPage from "./pages/ControlPage/ControlPage";
import ConTours from "./pages/ControlPages/ConTours";
import ConAdverts from "./pages/ControlPages/ConAdverts";
import ConNews from "./pages/ControlPages/ConNews";
import VerifyTourPage from "./pages/ModPages/VerifyTourPage";
import EditTourPage from "./pages/EditTourPage/EditTourPage";
import MakeReviews from "./pages/TourPages/MakeReviews";

import TourReviews from "./pages/TourPages/TourReviews";
import CreatePostPage from "./pages/CreatePostPage/CreatePostPage";
import EditPostPage from "./pages/EditPostPage/EditPostPage";
import TourChat from "./pages/TourPages/TourChat";
import NotificationsPage from "./pages/NotificationsPage/NotificationsPage";
import ForgotPassPage from "./pages/ForgotPassPage/ForgotPassPage";
import ResetPassPage from "./pages/ResetPassPage/ResetPassPage";
import SettingsPage from "./pages/SettingsPages/SettingsPage";


import "./index.css";
import "./null.css";
import TripRules from "./pages/TourPages/TripRules";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<ToursPage />} />
      <Route exact path="/tours/:pageNumber" element={<ToursPage />} />
      
      <Route path="people" element={<PeoplePage />} />
      <Route exact path="people/:pageNumber" element={<PeoplePage />} />

      <Route path="posts" element={<PostsPage />} />
      <Route exact path="posts/:pageNumber" element={<PostsPage />} />

      <Route path="notifications" element={<NotificationsPage />} />
      <Route exact path="notifications/:pageNumber" element={<NotificationsPage />} />



      <Route exact path="/user/:id" element={<UserPage />} >
          <Route index element={<AboutMe />} />
          <Route path="mytours" element={<MyTours />} />
          <Route path="followers" element={<Followers />} />
          <Route path="reviews" element={<MyReviews />} />
          <Route path="mygallery" element={<MyGallery />} />
      </Route>

      <Route path="/tour/:id" element={<TourPage />} >
          <Route index element={<Overview />} />
          <Route path="rules" element={<TripRules />} />
          <Route path="travelplan" element={<TravelPlan />} />
          <Route path="people" element={<People />} />
          <Route path="chat" element={<TourChat />} />
          <Route path="gallery" element={<TourGallery />} />
          <Route path="join" element={<Join />} />
          <Route path="makereviews" element={<MakeReviews />} />
          <Route path="reviews" element={<TourReviews />} />
      </Route>

      <Route path="favorites" element={<FavoritesPage />} />

      <Route path="/editpost/:id" element={<EditPostPage />} />
      <Route path="/edittour/:id" element={<EditTourPage />} />
      <Route path="/edituser/:id" element={<UserPageEdit />} />

      <Route path="moderation" element={<ModerationPage />} >
          <Route index element={<VerifyUserPage />} />  
          <Route path="verify" element={<VerifyUserPage />} />  
          <Route path="moderate" element={<VerifyTourPage />} />  
          <Route path="feedback" element={<FeedbackPage />} />  
      </Route>

      <Route path="control" element={<ControlPage />} >
          <Route index element={<ConTours />} />  
          <Route path="tours" element={<ConTours />} />  
          <Route path="adverts" element={<ConAdverts />} />  
          <Route path="news" element={<ConNews />} />  
      </Route>

      <Route path="createpost" element={<CreatePostPage />} />
      <Route path="verification" element={<VerificationPage />} />
      <Route path="createtour" element={<CreateTourPage />} />
      <Route path="societies" element={<SocietiesPage />} />
      <Route path="messenger" element={<MessengerPage />} />
      <Route path="news" element={<NewsPage />} />
      <Route path="help" element={<HelpPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="forgot-password" element={<ForgotPassPage />} />
      <Route path="reset-password" element={<ResetPassPage />} />
      <Route path="balance" element={<BalancePage />} />
      <Route path="settings" element={<SettingsPage />} />
      <Route path="test" element={<Test />} />
      <Route path="*" element={<NoMatchPage />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
<RouterProvider router={router} />
</UserProvider>
);
