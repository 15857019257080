import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./TourMenu.scss"
 
const UserMenu = ({user, menuOpen, setMenuOpen}) => {

    const {t} = useTranslation(['tr']);
    const navigate = useNavigate();
    const { id } = useParams();
    
    //---- Закрытие на ESC ---- 
    const escClose = (event) => {
        if (event.key === "Escape") {
        document.removeEventListener("keydown", escClose, false)
        setMenuOpen(false)
        }};

    if(menuOpen){
        document.addEventListener("keydown", escClose, false);
    }

  return (

    <div className={`TourMenu_wrapper ${!menuOpen?'TMM_hide':null}`}  >


    <div className="TMM_menuItem UPM_followers" onClick={()=>{
     setMenuOpen(false)
     navigate(`/user/${user?.username}/followers`)
    }}>
    <span> {t("up_Followers",{ns:"tr"})}</span>
    </div>

    <div className="TMM_menuItem UPM_mytours" onClick={()=>{
     setMenuOpen(false)
     navigate(`/user/${user?.username}/mytours`)
    }}>
    <span>{t("up_MyTrips",{ns:"tr"})}</span>
    </div>

    <div className="TMM_menuItem UPM_mygallery" onClick={()=>{
     setMenuOpen(false)
     navigate(`/user/${user?.username}/mygallery`)
    }}>
    <span>{t("up_Gallery",{ns:"tr"})}</span>
    </div>

    </div>
  )
}

export default UserMenu