import { useTranslation } from 'react-i18next';

import "./JoinedChip.scss";
const PublishedText = ({ isPublic, isVerifying, }) => {

  const {t} = useTranslation(['tr']);

  let sign;
  let clName;
  if(isPublic){
    if(isVerifying){
      sign = t("OnCheck",{ns:"tr"})
      clName = "PublishedText_verifying"
    }else{
      sign = t("Published",{ns:"tr"})
      clName = "PublishedText_published"
    }
  }else{
    sign = t("notPublished",{ns:"tr"})
    clName = "PublishedText_notPublished"
  }

  return (
    <div className={clName}><b>{sign}</b></div>
  );
};

export default PublishedText;
