import ppl from "../../assets/ppl.svg";
import Lang from "../../assets/lang.svg";
import MsgIcon from '../../assets/msg_purp.svg'
import { config } from "../../utils/config";
import { langs } from "../../data/languages.js";
import { useTranslation } from 'react-i18next';
import * as api from '../../utils/api/api.utils'

import "./PreviewTour.scss";
import { useEffect, useState } from "react";
import TourFavBtnEmpty from "../Buttons/TourFavBtnEmpty";
import { showDate } from "../../utils/showDate";
import { handleError } from "../../utils/handleError";

const PreviewTour = ({jwtUser, formik, selectedFiles}) => {
  const [allLangs, setAllLangs] = useState([...langs]); //-- All langs
  const [previewLangs, setPreviewLangs] = useState([]);

  const {t} = useTranslation(['translation', 'countries']);

  useEffect(() => {
    api.getLangs().then((res) => {
      if(res.status===200){
        setAllLangs([...langs, ...res.data])
      }else{
        handleError(res)
      }
    });
    }, []);

  useEffect(() => {
    const preview_langs =
    allLangs.filter(({_id}) => formik?.values?.langs?.includes(_id))
    setPreviewLangs(preview_langs)
    }, [formik.values.langs]);

  const {date, year} = showDate(formik.values);

  return (
    <div className="PreviewTour_wrapper">
      <div className="PreviewTour__left_side">
        <div className="PreviewTour_country_part">
          <p className="PreviewTour_country">
          {formik.values.country ? t(formik.values.country, { ns: "countries" }) : t("country", { ns: "translation" }) },
          </p>
          <p className="PreviewTour_city">
          {formik.values.city ? formik.values.city : t("city", { ns: "translation" })  }
          </p>
        </div>
        <div className="PreviewTour_pic_place">
        {formik.values.image?
          <img className="ibg" src={selectedFiles?.[0]?.link ? selectedFiles?.[0]?.link :  selectedFiles?.[0]?.preview}
          // src={formik.values.image[0].preview}
          alt="country" />
          : <h2> {t("choosePhoto",{ns:"tr"})}</h2>}

          <div className="new_dark_line">
            <p>{date}</p>
            <h5>{year}</h5>
          </div>

          <div className="PT_price_days">
            <p>$ {formik.values.tourPrice} </p>
            {formik.values.daysAmount!==0 &&
            <span> {formik.values.daysAmount} days</span>}
          </div>
        </div>
      </div>
      <div className="PreviewTour_rightside">
        <div className="PreviewTour_rs_top">
          <div className="PT_rs_top_left_part">

            <p className="PreviewTour_title">
            {formik.values.title ? formik.values.title :
              t("tourTitle",{ns:"tr"}) } </p>


            <div className="PT_rs_top_leftside">
              <div className="PT_rs_tour_info">
                <div className="top_mid_amount">
                  <img src={ppl} alt="" />
                  <p>{formik.values.amount} {t("ppl",{ns:"tr"})}</p> <span>({formik.values.startAge}-{formik.values.endAge})
                  {t("years",{ns:"translation"})}</span>
                </div>
                <div className="top_mid_lang">
                  <img src={Lang} alt="" />
                  <span>
                  {(formik.values.langs?.length===0)?
                    t("chooseLang",{ns:"tr"}) :
                    t(previewLangs[0]?._id, { ns: "langs" }) },
                  {(formik.values.langs?.length>1) ?
                    t(previewLangs[1]?._id, { ns: "langs" }): null }
                   </span>
                </div>
              </div>

              <div className="PT_rs_org_area">
                <img className="PT_rs_org_img" src={jwtUser?.data?.user?.photos[0]} alt="organizator" />

                <div className="left_org_rating">
                  <p>{jwtUser?.data?.user?.fName}</p>
                  <p>{jwtUser?.data?.user?.lName}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="PT_rs_top_right_part">

          <div className="FavButton PTFavButton">
          <TourFavBtnEmpty/>
          </div>

          <div className="MsgBtnSml">
            <img src={MsgIcon} alt="" />
          </div>


          </div>
        </div>
        <div className="PreviewTour_rs_mid">

          {formik.values.text?.length > config.charAmount ?
        (
          <div dangerouslySetInnerHTML={{ __html: [`${formik.values.text?.substring(0, config.charAmount)}...`, '<a href="#">Read more</a>'] }} />
        ) :
        <div dangerouslySetInnerHTML={{ __html: formik.values.text }} />
      }

        </div>
        <div className="PreviewTour_rs_btm">
          <div className="PT_rs_btm_text">
            <p>{t("AlreadyJoined",{ns:"tr"})}: 0/{formik.values.amount}</p>
            <span >{t("seeAllPpl",{ns:"tr"})}</span>
          </div>

          <div className="PT_rs_btm_no_users">
          {t("pplPlace",{ns:"tr"})}
          </div>


        </div>
      </div>
    </div>
  );
};

export default PreviewTour;
