export const age = (b) => {
    

    const today = new Date();
    const birthdate =  new Date(b);
    

    const age = today?.getFullYear() - birthdate?.getFullYear() - 
               (today?.getMonth() < birthdate?.getMonth() || 
               (today?.getMonth() === birthdate?.getMonth() && today?.getDate() < birthdate?.getDate()));
    
    return age;
  }