import { useNavigate } from 'react-router-dom';
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';

import { ReactComponent as JoinIcon } from '../../assets/user_con_menu/confirm.svg'
import { ReactComponent as ProfileIcon } from '../../assets/user_con_menu/profile.svg'
import { ReactComponent as MsgIcon } from '../../assets/user_con_menu/msg.svg'
import { ReactComponent as ConfirmIcon } from '../../assets/user_con_menu/thumb.svg'
import { ReactComponent as ToFollowersIcon } from '../../assets/user_con_menu/binocular.svg'
import { ReactComponent as BlockIcon } from '../../assets/user_con_menu/block.svg'
import { ReactComponent as RemoveIcon } from '../../assets/user_con_menu/reject.svg'
import { ReactComponent as UnBlockIcon } from '../../assets/user_con_menu/unblock.svg'

import './UserConMenu.scss'
import { handleError } from '../../utils/handleError';

const UserConMenu = ({user, tour, menu, setTour, setSending, menuOpen, setMenuOpen}) => {

    const navigate = useNavigate();
    const {t} = useTranslation(['tr']);

    //---- Закрытие на ESC ----
    const escClose = (event) => {
        if (event.key === "Escape") {
        document.removeEventListener("keydown", escClose, false)
        setMenuOpen(false)
        }};

    if(menuOpen){
        document.addEventListener("keydown", escClose, false);
    }

    //-------- Simple API Functions ----------

    const toProfile=()=>{
        setMenuOpen(false)
        navigate(`/user/${user.username}`)
    }

    const writeMsg=()=>{
    }

    const refreshTour = async ()=>{
       await api.getTourById(tour?._id).then(res=> {
            if(res.status===200){
                setTour(res.data)
            }else{
                handleError(res)
            }
        })
    }

    const removeFromJoin = async()=>{
       await api.removeFromJoin(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
        });
    }

    const removeFromConf = async()=>{
        await api.removeFromConf(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
          });
    }

    const removeFromRequest = async()=>{
        await api.removeFromRequests(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
          });
    }

    const moveToJoin = async()=>{
        await api.moveToJoin(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
          });
    }

    const moveToConfirm = async()=>{
        await api.moveToConfirm(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
          });
    }

    const moveToRejected = async()=>{
        await api.moveToRejected(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
          });
    }

    const moveToFollowers = async()=>{
        await api.moveToFollowers(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
          });
    }

    const moveToBlock = async()=>{
        await api.moveToBlock(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
          });
    }

    const removeFromFollowers = async()=>{
        await api.removeFromFollowers(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
          });
    }

    const removeFromReject = async()=>{
        await api.removeFromReject(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
          });
    }

    const removeFromBlock = async()=>{
        await api.removeFromBlock(user._id, {tourID: tour?._id}).then(res => {
            if(res.status!==200) handleError(res)
          });
    }


//--========= Compound API Functions ============

//--Joined block
const joinToConfirm=async()=>{
    setMenuOpen(false)
    setSending(true)
    try {
        await moveToConfirm()
        await removeFromJoin()
        await refreshTour()
    } catch (error) {
        handleError(error)
      }
}

const joinToFollowers=async()=>{
    setMenuOpen(false)
    setSending(true)
    try {
        await moveToFollowers()
        await removeFromJoin()
        await refreshTour()
    } catch (error) {
        handleError(error)
      }
}

const joinToBlock=async()=>{
    setMenuOpen(false)
    setSending(true)
    try {
        await moveToBlock()
        await removeFromJoin()
        await removeFromFollowers()
        await refreshTour()
    } catch (error) {
        handleError(error)
      }
}


//--Confirmed block
const confToJoin=async()=>{
    setMenuOpen(false)
    setSending(true)
    try {
        await moveToJoin()
        await removeFromConf()
        await refreshTour()
    } catch (error) {
        handleError(error)
      }
}


const confToFollowers=async()=>{
    setMenuOpen(false)
    setSending(true)
    try {
        await moveToFollowers()
        await removeFromConf()
        await refreshTour()
    } catch (error) {
        handleError(error)
      }
}

const confToBlock=async()=>{
    setMenuOpen(false)
    setSending(true)
    try {
        await moveToBlock()
        await removeFromConf()
        await removeFromFollowers()
        await refreshTour()
    } catch (error) {
        handleError(error)
      }
}

//--Requests block
    const reqToJoin=async()=>{
        setMenuOpen(false)
        setSending(true)
        try {
            await moveToJoin()
            await removeFromRequest()
            await refreshTour()
        } catch (error) {
            handleError(error)
          }
    }

    const reqToConfirm=async()=>{
        setMenuOpen(false)
        setSending(true)
        try {
            await moveToConfirm()
            await removeFromRequest()
            await refreshTour()
        } catch (error) {
            handleError(error)
          }
    }

    const reqToFollowers=async()=>{
        setMenuOpen(false)
        setSending(true)
        try {
            await moveToRejected()
            await moveToFollowers()
            await removeFromRequest()
            await refreshTour()
        } catch (error) {
            handleError(error)
          }
    }

    const reqToBlock=async()=>{
        setMenuOpen(false)
        setSending(true)
        try {
            await moveToBlock()
            await removeFromRequest()
            await removeFromFollowers()
            await refreshTour()
        } catch (error) {
            handleError(error)
          }
    }

    //--Followers block


    const removeFollower=async()=>{
        setMenuOpen(false)
        setSending(true)
        try {
            await removeFromFollowers()
            await refreshTour()
        } catch (error) {
            handleError(error)
          }
    }

    const follToBlock=async()=>{
        setMenuOpen(false)
        setSending(true)
        try {
            await moveToBlock()
            await removeFromFollowers()
            await refreshTour()
        } catch (error) {
            handleError(error)
          }
    }

    //--Rejected block

    const rejToConfirm=async()=>{
        setMenuOpen(false)
        setSending(true)
        try {
            await moveToConfirm()
            await removeFromReject()
            await refreshTour()
        } catch (error) {
            handleError(error)
          }
    }

    const rejToBlock=async()=>{
        setMenuOpen(false)
        setSending(true)
        try {
            await moveToBlock()
            await removeFromReject()
            await removeFromFollowers()
            await refreshTour()
        } catch (error) {
            handleError(error)
          }
    }


    //--Block block
    const blockToUnBlock=async()=>{
        setMenuOpen(false)
        setSending(true)
        try {
            await removeFromBlock()
            await refreshTour()
    } catch (error) {
        handleError(error)
      }
    }


  return (
    <div className={!menuOpen ? 'UserConMenu_wrapper UCM_hide' : 'UserConMenu_wrapper' }>

    <div className="UCM_menuItem" onClick={toProfile}>
         <ProfileIcon className="UCM_menuIcon"/>
         <span>{t("profile",{ns:"tr"})}</span>
    </div>

    <div className="UCM_menuItem" onClick={writeMsg}>
         <MsgIcon className="UCM_menuIcon"/>
         <span>{t("SendTextMsg",{ns:"tr"})}</span>
    </div>

 {/* //--- JOINED */}

    {menu==="joined" &&
    <>
    <div className="UCM_menuItem" onClick={joinToConfirm}>
         <ConfirmIcon className="UCM_menuIcon"/>
         <span>{t("moveToConfirmed",{ns:"tr"})}</span>
    </div>

    <div className="UCM_menuItem" onClick={joinToFollowers}>
         <ToFollowersIcon className="UCM_menuIcon"/>
         <span>{t("moveToFollowers",{ns:"tr"})}</span>
    </div>

    <div className="UCM_menuItem" onClick={joinToBlock}>
         <BlockIcon className="UCM_menuIcon"/>
         <span>{t("moveToBlocked",{ns:"tr"})}</span>
    </div>
    </>
    }

 {/* //--- CONFIRMED */}

    {menu==="confirm" &&
    <>
    <div className="UCM_menuItem" onClick={confToJoin}>
         <JoinIcon className="UCM_menuIcon"/>
         <span>{t("moveToJoined",{ns:"tr"})}</span>
    </div>

    <div className="UCM_menuItem" onClick={confToFollowers}>
         <ToFollowersIcon className="UCM_menuIcon"/>
         <span>{t("moveToFollowers",{ns:"tr"})}</span>
    </div>

    <div className="UCM_menuItem" onClick={confToBlock}>
         <BlockIcon className="UCM_menuIcon"/>
         <span>{t("moveToBlocked",{ns:"tr"})}</span>
    </div>
    </>
    }


 {/* //--- REQUESTS */}

    {menu==="requests" &&
    <>
    <div className="UCM_menuItem" onClick={reqToJoin}>
         <JoinIcon className="UCM_menuIcon"/>
         <span>{t("moveToJoined",{ns:"tr"})}</span>
    </div>

    <div className="UCM_menuItem" onClick={reqToConfirm}>
         <ConfirmIcon className="UCM_menuIcon"/>
         <span>{t("moveToConfirmed",{ns:"tr"})}</span>
    </div>

    <div className="UCM_menuItem" onClick={reqToFollowers}>
         <RemoveIcon className="UCM_menuIcon"/>
         <span>{t("moveToRejectAndFollowers",{ns:"tr"})}</span>
    </div>

    <div className="UCM_menuItem" onClick={reqToBlock}>
         <BlockIcon className="UCM_menuIcon"/>
         <span>{t("moveToBlocked",{ns:"tr"})}</span>
    </div>
    </>
    }

    {/* //--- REJECTED */}

    {(menu==="rejected") &&
    <>
    <div className="UCM_menuItem" onClick={rejToConfirm}>
         <ConfirmIcon className="UCM_menuIcon"/>
         <span>{t("moveToConfirmed",{ns:"tr"})}</span>
    </div>

    <div className="UCM_menuItem" onClick={rejToBlock}>
         <BlockIcon className="UCM_menuIcon"/>
         <span>{t("moveToBlocked",{ns:"tr"})}</span>
    </div>
    </>
    }

    {/* //--- FOLLWERS */}

    {(menu==="follow") &&
    <>
    <div className="UCM_menuItem" onClick={removeFollower}>
         <RemoveIcon className="UCM_menuIcon"/>
         <span>{t("removeFromFollowers",{ns:"tr"})}</span>
    </div>

    <div className="UCM_menuItem" onClick={follToBlock}>
         <BlockIcon className="UCM_menuIcon"/>
         <span>{t("moveToBlocked",{ns:"tr"})}</span>
    </div>
    </>
    }

    {/* //--- BLOCKED */}

    {(menu==="blocked") &&
    <div className="UCM_menuItem" onClick={blockToUnBlock}>
         <UnBlockIcon className="UCM_menuIcon"/>
         <span>{t("moveToUnBlocked",{ns:"tr"})}</span>
    </div>}




    </div>
  )
}

export default UserConMenu