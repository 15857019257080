import { useNavigate } from "react-router-dom";
import { config } from "../../utils/config";
import { thumb } from "../../utils/thumb";
import { useTranslation } from 'react-i18next';

import "./MyTourSml.scss";
import { showDate } from "../../utils/showDate";

const MyTourSml = ({ tour }) => {

  const navigate = useNavigate();
  const {t} = useTranslation(['translation', 'countries']);

  const {date, year} = showDate(tour);

  return (
    <div
      onClick={() => navigate(`/tour/${tour?._id}`)}
      className="MyTourSml_wrapper"
    >
      <img src={thumb(tour?.titleImages[0], config.thumb_width)} alt="my tour" loading="lazy"/>

      <div className="MyTourSml_text_area">
        <h6><b>{year}</b></h6>
        <span>{date}</span>

        <h5 className="MyTourSml_p_country">
          <b>{t(tour?.country, { ns: "countries" })},</b> {tour?.city}
        </h5>

      </div>
    </div>
  );
};

export default MyTourSml;
