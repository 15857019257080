import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import * as api from '../../utils/api/api.utils'
import { zodiak } from "../../utils/zodiak";

import { langs } from "../../data/languages";
import { interests} from "../../data/interests";

import { FaStar } from "react-icons/fa";
import { FaPlaneDeparture } from "react-icons/fa";

import Star from "../../assets/user_page/star2.svg";
import Heart from "../../assets/user_page/heart.svg";
import Sex from "../../assets/user_page/sex.svg";
import Height from "../../assets/user_page/height.svg";
import Zodiak from "../../assets/user_page/stellar.svg";
import Smoking from "../../assets/user_page/smoking.svg";
import Alkohol from "../../assets/user_page/drinking.svg";
import Religion from "../../assets/user_page/religion.svg";
import Character from "../../assets/user_page/character.svg";
import Finances from "../../assets/user_page/square_dollar.svg";
import Verified from "../../assets/verified.svg";

import AllReviews from "../../components/Review/AllReviews";
import MyTourSml from "../../components/Tour/MyTourSml";
import Lang from "../../components/Lang/Lang";
import Interest from "../../components/Interest/Interest";
import { handleError } from "../../utils/handleError";

import "./AboutMe.scss";
const AboutMe = () => {
  const [allMyReviews, setAllMyReviews] = useState();
  const [tours, setTours] = useState();

  const { user, isMyPage } = useOutletContext();

  useEffect(() => {
    const allMyTours = [...user?.created_tours, ...user?.joined_to_tour]

    api.findToursByIdArray(allMyTours).then(res=> {
      if (res?.status === 200) {
        const realTrips = res.data.filter(trip => trip.isTest!==true)
        setTours(realTrips)
      }else{
        handleError(res)
      }
    })

    api.getAllUserReviews(user._id).then(res=> {
      if (res?.status === 200) {

        setAllMyReviews(res.data)
      }else{
        handleError(res)
      }
    })
  }, [user]);

  const {t} = useTranslation(['translation', 'int', 'tr']);

  const goodRevAmount = allMyReviews?.filter(rev=>rev.mark==="good")?.length
  const midRevAmount = allMyReviews?.filter(rev=>rev.mark==="neutral")?.length
  const badRevAmount = allMyReviews?.filter(rev=>rev.mark==="bad")?.length

  const MyRevAsUser = allMyReviews?.filter(rev=>rev.mark)

  //-- Calculating average
  // const MyRevAsOrg = allMyReviews?.filter(rev=>rev.rating)
  // const MapMyRevAsOrg = MyRevAsOrg?.map(rev=>rev.rating)

  // const avg = (array)=> {
  //   var total = 0;
  //   var count = 0;
  //   array?.forEach(function(item, index) {
  //       total += item;
  //       count++;
  //   });
  //   return total / count;
  //   }

// const avgRating = avg(MapMyRevAsOrg).toFixed(1);
//-----------------------------

// const allMyToursAmount = [...user?.created_tours, ...user?.joined_to_tour]

const { id } = useParams();
const navigate = useNavigate();

const zodiac = zodiak(user?.birthDate)

const my_langs = langs.filter(({_id})=> user?.speak_lang?.includes(_id));
const my_interests = interests.filter(({_id}) => user?.interests?.includes(_id));

let whenReady;
  if(user.whenReady==="4_anyMoment") whenReady = "AMReady_anyTime"
  if(user.whenReady==="3_thisMonth") whenReady = "AMReady_thisMonth"
  if(user.whenReady==="2_thisYear") whenReady = "AMReady_thisYear"


  return (
    <div className="AboutMePage_Wrapper">
      <div className="AboutMePage">
        <div className="AM_text">
          <div className="AM_block_header">
            <p><b>{t("upAM_AboutMe",{ns:"tr"})}:</b></p>
            {/* <span>Rus / Eng</span> */}
          </div>

          <div className="AM_text_block">
            <div dangerouslySetInnerHTML={{ __html: user?.about }} />
          </div>
        </div>

        <div className="AM_Personal_info">
          <div className="AM_block_header">
            <p>
              <b>{t("upAM_PersonalInfo",{ns:"tr"})}:</b>
            </p>
          </div>
          <div className="AM_info_box">

            {user?.rating > 0 &&
              <div className="AM_Info_line">
                <img src={Star} alt="GroupIcon" />
                <p>{t("upAM_orgRating",{ns:"tr"})}:</p>

                <div className="AM_ratingLine">
                  <FaStar size={22} color= "#ffc107" />
                  <div className="AM_ratingTag">{user?.rating}</div>
                </div>
              </div>
            }

            {user?.relationship&&
              <div className="AM_Info_line">
                <img src={Heart} alt="GroupIcon" />
                <p>{t("upAM_Relations",{ns:"tr"})}:</p>
                <span>{t(user?.relationship,{ns:"translation"})}</span>
              </div>
            }

            {user?.orientation&&
              <div className="AM_Info_line">
                <img src={Sex} alt="AgesIcon" />
                <p>{t("upAM_Orientation",{ns:"tr"})}:</p>
                <span>{t(user?.orientation,{ns:"translation"})} </span>
              </div>
            }

            {user?.height&&
              <div className="AM_Info_line">
                <img src={Height} alt="HotelsIcon" />
                <p>{t("upAM_Height",{ns:"tr"})}:</p>
                <span>{user?.height} cm</span>
              </div>
            }


            <div className="AM_Info_line">
              <img src={Zodiak} alt="TourFeeIcon" />
              <p>{t("upAM_Zodiac",{ns:"tr"})}:</p>
              <span>{t(zodiac,{ns:"tr"})}</span>
            </div>

            {user?.smoking&&
              <div className="AM_Info_line">
                <img src={Smoking} alt="PocketMoneyIcon" />
                <p>{t("upAM_Smoking",{ns:"tr"})}:</p>
                <span>{t(user?.smoking,{ns:"translation"})}</span>
              </div>
            }

            {user?.alkohol&&
              <div className="AM_Info_line">
                <img src={Alkohol} alt="LangIcon" />
                <p>{t("upAM_Alkohol",{ns:"tr"})}:</p>
                <span>{t(user?.alkohol,{ns:"translation"})}</span>
              </div>
            }

            {user?.religion&&
              <div className="AM_Info_line">
                <img src={Religion} alt="LangIcon" />
                <p>{t("upAM_Religion",{ns:"tr"})}:</p>
                <span>{t(user?.religion,{ns:"translation"})}</span>
              </div>
            }

            {user?.character&&
              <div className="AM_Info_line">
                <img src={Character} alt="LangIcon" />
                <p>{t("upAM_Character",{ns:"tr"})}:</p>
                <span>{t(user?.character,{ns:"translation"})}</span>
              </div>
            }

            {user?.finances&&
              <div className="AM_Info_line">
                <img src={Finances} alt="LangIcon" />
                <p>{t("upAM_Finances",{ns:"tr"})}:</p>
                <span>{t(user?.finances,{ns:"translation"})}</span>
              </div>
            }

            {user?.whenReady&&
              <div className="AM_Info_line">
                <FaPlaneDeparture size={20} color='#87589C'/>
                <p>{t("whenReady",{ns:"translation"})}</p>
                <span className={whenReady}>{t(user?.whenReady,{ns:"translation"})}</span>
              </div>
            }

          </div>


          {allMyReviews&&
          <div className="AM_AllReviews">
            <div className="AM_block_header">
              <p>
                <b>{t("myReviews",{ns:"tr"})}:</b> {MyRevAsUser?.length + user?.rating_reviews }
              </p>
              {(MyRevAsUser?.length + user?.rating_reviews)!==0 &&
              <span onClick={() => navigate(`/user/${id}/reviews`)}>
              {t("readAll",{ns:"tr"})}
              </span>
              }
            </div>

            <AllReviews
              org_reviews={user?.rating_reviews}
              good_reviews={goodRevAmount}
              neutral_reviews={midRevAmount}
              bad_reviews={badRevAmount}
            />
          </div>
          }

        </div>


        {tours?.length>0 &&
        <div className="AM_My_Tours">

          <div className="AM_block_header">
            <p> <b>{t("allMyTrips",{ns:"tr"})}:</b> {tours?.length} </p>
            <span onClick={() => navigate(`/user/${user?.username}/mytours`)}>
            {t("seeAll",{ns:"tr"})}
            </span>
          </div>

        <div className="AM_TripsBox">
          {tours &&  tours.map((tour, index) =>
            index < 3 && <MyTourSml key={index} tour={tour} />
          )}
        </div>
          </div>
        }


        <div className="AM_My_Lang">

        {my_langs&&
        <>
          <div className="AM_block_header">
            <p>
              <b>{t("langsISpeak",{ns:"tr"})}:</b>{" "}
            </p>
          </div>

          <div className="AM_lang_box">
            {my_langs && my_langs.map((lang) => (

              <Lang
              key={lang._id}
              title={lang.rus_title}
              code={lang.code}
              icon={lang.flag}
              pointer={false}
            />

              ))}
          </div>
          </> }

          <div className="AM_Verification">
            <div className="AM_verification_header">
              <p>
                <b>{t("verification",{ns:"tr"})}:</b>
              </p>
              {user?.verified && <img src={Verified} alt="verified" />}
              {isMyPage && !user?.verified &&
              <p>{t("notVerified",{ns:"tr"})}</p> }
            </div>

            <div className="AM_verification_box">

                <div className="AM_verified">
                {user?.isEmailActivated &&
                  <div className="AM_verified_line">
                    <img src={Verified} alt="verified" />
                    <p>{t("emailIsVerified",{ns:"tr"})}</p>
                  </div>
                }
                  {user?.verified ?
                    <>
                      <div className="AM_verified_line">
                        <img src={Verified} alt="verified" />
                        <p>{t("photosAreVerified",{ns:"tr"})}</p>
                      </div>
                      <div className="AM_verified_line">
                        <img src={Verified} alt="verified" />
                        <p>{t("idIsVerified",{ns:"tr"})}</p>
                      </div>
                    </>
                    :
                    <div className="AM_verified_line">
                      <p className="AM_notVerified_line">- {t("userIsNotVerified",{ns:"tr"})}</p>
                    </div>
                  }
                </div>

              {/* {!isMyPage&& !user?.verified &&
                <div className="AM_verified_line">
                  <p>{t("userIsNotVerified",{ns:"tr"})}</p>
                </div>
              } */}
              {isMyPage && !user?.verified &&
                <div className="UP_No_Info">
                <div className="UP_verifyBtn" onClick={()=>navigate(`/verification`)}>{t("verification",{ns:"tr"})}</div>
                </div>
              }

            </div>
          </div>
          </div>

          {my_interests?.length!==0 &&
        <div className="AM_Interests">
          <div className="AM_block_header">
            <p>
              <b>{t("MyInterests",{ns:"tr"})}:</b>{" "}
            </p>
          </div>
          <div className="AM_Interests_box">

            {my_interests && my_interests.map((int) => (

              <Interest
              key={int._id}
              title={t(int.code, { ns: "int" })}
              icon={int.icon}
              pointer={false}
            />
              ))}
          </div>
        </div>
        }

      </div>
    </div>
  );
};

export default AboutMe;
