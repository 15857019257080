import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./TourMenu.scss"

const TourMenu = ({isFinished, menuOpen, setMenuOpen}) => {

    const {t} = useTranslation(['tr']);
    const navigate = useNavigate();
    const { id } = useParams();

    //---- Закрытие на ESC ----
    const escClose = (event) => {
        if (event.key === "Escape") {
        document.removeEventListener("keydown", escClose, false)
        setMenuOpen(false)
        }};

    if(menuOpen){
        document.addEventListener("keydown", escClose, false);
    }

  return (

    <div className={`TourMenu_wrapper ${!menuOpen?'TMM_hide':null}`}  >

  {isFinished &&
    <div className="TMM_menuItem" onClick={()=>{
     setMenuOpen(false)
     navigate(`/tour/${id}/travelplan`)
    }}>
      <span> {t("TravelPlan",{ns:"tr"})}</span>
    </div>
  }
    <div className="TMM_menuItem TMM_gallery" onClick={()=>{
     setMenuOpen(false)
     navigate(`/tour/${id}/gallery`)
    }}>
      <span>{t("TourGallery",{ns:"tr"})}</span>
    </div>

    <div className="TMM_menuItem TMM_chat" onClick={()=>{
     setMenuOpen(false)
     navigate(`/tour/${id}/chat`)
    }}>
      <span>{t("TourChatRoom",{ns:"tr"})}</span>
    </div>



{isFinished?
    <div className="TMM_menuItem" onClick={()=>{
     setMenuOpen(false)
     navigate(`/tour/${id}/people`)
    }}>
      <span>{t("TourPeople",{ns:"tr"})}</span>
    </div>
:
    <div className="TMM_menuItem" onClick={()=>{
     setMenuOpen(false)
     navigate(`/tour/${id}/reviews`)
    }}>
      <span>{t("TourReviews",{ns:"tr"})}</span>
    </div>
}


    <div className="TMM_menuItem" onClick={()=>{
     setMenuOpen(false)
     navigate(`/tour/${id}/rules`)
    }}>
      <span>{t("TourRules",{ns:"tr"})}</span>
    </div>

    </div>
  )
}

export default TourMenu