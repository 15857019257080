import * as Yup from 'yup';
import { config } from '../config';
import i18n from '../i18next';
const {t} = i18n

export const validationSchema = Yup.object().shape({

    image: Yup.array().nullable()
    .required(t("photoRequared", {ns:"err"}))
    .max(config.uploadImage_maxAmount,
      `${t("noMoreThan", {ns:"err"})} ${config.uploadImage_maxAmount} ${t("photos", {ns:"err"})}`),

  fName: Yup.string()
    .required(t("fNameRequared", {ns:"err"}))
    .matches(/^[^!@#$%^&*+=<>:;|~]*$/, t("noSymbols", {ns:"err"}))
    .matches(/^[A-Za-z\s+]+$/, t("onlyEnglish", {ns:"err"}))
    .min(1, t("Min1", {ns:"err"}))
    .max(20, t("Max20", {ns:"err"})),

  lName: Yup.string()
    .required(t("lNameRequared", {ns:"err"}))
    .matches(/^[^!@#$%^&*+=<>:;|~]*$/, t("noSymbols", {ns:"err"}))
    .matches(/^[A-Za-z\s+]+$/, t("onlyEnglish", {ns:"err"}))
    .min(1, t("Min1", {ns:"err"}))
    .max(20, t("Max20", {ns:"err"})),
  country: Yup.string()
    .matches(/^[^!@#$%^&*+=<>:;|~]*$/, t("noSymbols", {ns:"err"}))
    .required(t("countryRequared", {ns:"err"})),
  about: Yup.string()
    .required(t("aboutRequared", {ns:"err"})),
  gender: Yup.string()
    .required(t("genderRequared", {ns:"err"})),
  city: Yup.string()
    .matches(/^[^!@#$%^&*+=<>:;|~]*$/, t("noSymbols", {ns:"err"}))
    .matches(/^[A-Za-z\s+]+$/, t("onlyEnglish", {ns:"err"}))
    .required(t("cityRequared", {ns:"err"})),
  birthDate: Yup.date()
  .required(t("birthDateRequared", {ns:"err"})),
  email: Yup.string()
    .required(t("EmailRequared", {ns:"err"}))
    .email(t("EmailIsInvalid", {ns:"err"})),
  password: Yup.string()
    .required(t("PasswordRequared", {ns:"err"}))
    .min(6, t("PasswordMin", {ns:"err"}))
    .max(20, t("PasswordMax", {ns:"err"})),
  confirmPassword: Yup.string()
    .required(t("ConfirmRequared", {ns:"err"}))
    .oneOf([Yup.ref('password'), null], t("PassDontMatch", {ns:"err"}))

    });



