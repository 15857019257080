import { useContext, useState, useEffect, Suspense,} from "react";
import { UserContext } from '../../context/user.context';
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";


import * as api from '../../utils/api/api.utils'
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { validationSchema } from "../../utils/validations/register_Yup";
import { langs } from "../../data/languages";

import InputField from '../../components/Input/InputField'
import PageHeader from '../../components/PageHeader/PageHeader'
import SelectField from "../../components/Input/SelectField";
import TextField from "../../components/Input/TextField";
import AddInterest from "../../components/AddInterest/AddInterest";
import AddLang from "../../components/AddLang/AddLang";

import AddPhoto from "../../components/AddPhoto/AddPhoto";
import RadioBtn from "../../components/Input/RadioBtn";
import InputCountry from "../../components/Input/InputCountry";
import InputPassword from "../../components/Input/InputPassword";
import { handleError } from "../../utils/handleError";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import MainBtn from "../../components/Buttons/MainBtn";

import './RegisterPage.scss'
const RegisterPage = () => {
    const { jwtUser, setJwtUser, location } = useContext(UserContext);
    const [selectedFiles, setSelectedFiles] = useState([]);//-- Files to upload
    const [serverError, setServerError] = useState();
    const [sending, setSending] = useState(false);
    const [usersList, setUsersList] = useState([]); //-- Users Interests

    const {t, i18n} = useTranslation(['translation']);

    const deviceLang = i18n.language;
    const foundLang = langs.find(item=>item.code2===deviceLang).code
    const [usersLangs, setUsersLangs] = useState([foundLang]); //-- Users Langs

    const { ipadSml } = useMatchMedia();

    useEffect(() => {
        formik.setFieldValue("image", selectedFiles);

    if(selectedFiles.length===0) {
        formik.setFieldValue("image", null);
    }
    }, [selectedFiles]);

    useEffect(() => {
        if(location) {
            formik.setFieldValue("country", location.country);
            formik.setFieldValue("city", location.city);
         }
    }, [location]);

    // console.log(location);

    const navigate = useNavigate();

    const formik = useFormik({initialValues: {
      fName: "",
      lName: "",
      email: "",
      gender: "",
      password: "",
      about: "",
      confirmPassword: "",
      country: '',
      city: '',
      birthDate: "",
      relationship: "",
      orientation: "",
      height: "",
      smoking: "",
      alkohol: "",
      religion: "",
      character: "",
      finances: "",
      whenReady: "4_anyMoment",
      image:null,

    },
    validationSchema: validationSchema ,
    onSubmit: (values) => {
        // console.log('Formik values', values);
    },
  });

  //--- перенаправляет на страницу пользователя если он в системе
  if(jwtUser){
    return <Navigate to={`/user/${jwtUser?.data?.user.username}`} />
}

    const handleSubmit = async (event) => {
      event.preventDefault();
      formik.handleSubmit();

      setServerError(null)

        try {
          const formData = new FormData();
          formData.append("fName", formik.values.fName);
          formData.append("lName", formik.values.lName);
          formData.append("email", formik.values.email);
          formData.append("password", formik.values.password);
          formData.append("confirmPassword", formik.values.confirmPassword);
          formData.append("birthDate", formik.values.birthDate);
          formData.append("country", formik.values.country);
          formData.append("city", formik.values.city);
          formData.append("gender", formik.values.gender);
          formData.append("about", formik.values.about);

          for (let i=0; i<selectedFiles.length; i++) {
            formData.append("image", selectedFiles[i].source);
       }

          formData.append("relationship", formik.values.relationship);
          formData.append("orientation", formik.values.orientation);
          formData.append("height", formik.values.height);
          formData.append("smoking", formik.values.smoking);
          formData.append("alkohol", formik.values.alkohol);
          formData.append("religion", formik.values.religion);
          formData.append("character", formik.values.character);
          formData.append("finances", formik.values.finances);
          formData.append("whenReady", formik.values.whenReady);
          formData.append("balance", 0);
          formData.append("earned", 0);
          formData.append("rating", 0);

          formData.append("verified", false);
          formData.append("isVerifying", false);

          for (let i=0; i<usersLangs.length; i++) {
            formData.append("speak_lang", usersLangs[i]);
       }

          for (let i=0; i<usersList.length; i++) {
            formData.append("interests", usersList[i]);
       }


          if(formik.isValid){
            setSending(true)
            const res = await api.registerUser(formData)

            if(res?.status===200){
                alert(t("emailVerification_text",{ns:"tr"}))
                setSending(false)
                localStorage.setItem('profile', JSON.stringify(res));
                setJwtUser(res);
                navigate('/');
            }else{
                throw res
            }
        }else{
            console.log("Forms are not correct")
        }
    } catch (error) {
        handleError(error)
        setSending(false)
        setServerError(error.response.data.message)
    }
};


  return (

    <div className="RegisterPage_wrapper">

    {sending && <LoadingScreen/>}


    <PageHeader
        icon="create"
        title={t("Create account",{ns:"translation"})}
        tooltip={t("CreateAccount_tooltip",{ns:"translation"})}
        text={t("yourIDCard",{ns:"translation"})}
        btn="none"
    />

    <form  onSubmit={handleSubmit}>

    <div className="RegP_content_box">

    <AddPhoto
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        formik_errors={formik.errors}
        formik_touched={formik.touched}
    />

    <div className="RegP_info_block">

    <div className="RegP_title_area">
            <p>{t("mainInfo",{ns:"tr"})}</p>
            <span>{t("mainInfo_text",{ns:"tr"})}.</span>
    </div>

    <div className="RegP_info_body">

        <div className="RegP_inputDiv">
            <InputField
                title={t("firstName",{ns:"translation"})}
                icon="name"
                type="text"
                placeholder={t("yourName",{ns:"translation"})}
                name="fName"
                {...formik.getFieldProps('fName')}
                //onBlur={formik.handleBlur}
                //onChange={formik.handleChange}
                //value={formik.values.email}
            />
        <div className="invalid-feedback">
          {formik.errors.fName && formik.touched.fName
           ? formik.errors.fName : null}
        </div>
        </div>

        <div className="RegP_inputDiv">
            <InputField
                title={t("lastName",{ns:"translation"})}
                icon="name"
                type="text"
                placeholder={t("yourLastName",{ns:"translation"})}
                name="lName"
                {...formik.getFieldProps('lName')}
            />
            <div className="invalid-feedback">
                {formik.errors.lName && formik.touched.lName
                ? formik.errors.lName : null}
            </div>
        </div>

        <div className="RegP_inputDiv">
            <Suspense fallback="Loading...">
                <InputCountry
                    title={t("country", { ns: "translation" })}
                    icon="country"
                    placeholder={t("yourCountry", { ns: "translation" })}
                    tooltip={t("country_tooltip", { ns: "translation" })}
                    name="country"
                    isSearchable={!ipadSml}
                    onChange={(value)=>formik.setFieldValue("country", value.value)}
                    onBlur={()=>formik.setFieldTouched("country")}
                    value={formik.values.country}
                />
            </Suspense>

            <div className="invalid-feedback">
                {formik.errors.country && formik.touched.country
                ? formik.errors.country : null}
            </div>
        </div>


            <div className="RegP_inputDiv">
            <InputField
                title={t("birthDate", { ns: "translation" })}
                icon="date"
                type="date"
                placeholder={t("birthDate", { ns: "translation" })}
                name="birthDate"
                {...formik.getFieldProps('birthDate')}
            />
        <div className="invalid-feedback">
            {formik.errors.birthDate && formik.touched.birthDate
            ? formik.errors.birthDate : null}
        </div>
        </div>


        <div className="RegP_gender">

            <RadioBtn
            title={t("yourGender", { ns: "translation" })}
            icon="gender"
            name="gender"
            male={t("Male", { ns: "translation" })}
            female={t("Female", { ns: "translation" })}
            onChange={formik.handleChange}
            />
            <div className="invalid-feedback">
                {formik.errors.gender && formik.touched.gender
                ? formik.errors.gender : null}
            </div>
        </div>


        <div className="RegP_textarea">

         <TextField
            title={t("aboutYou", { ns: "translation" })}
            icon="doc"
            tooltip={t("aboutYou_tooltip", { ns: "translation" })}
            placeholder={t("aboutYou_tooltip", { ns: "translation" })}
            maxLength="1000"
            name="about"
            {...formik.getFieldProps('about')}
         />
         <div className="invalid-feedback">
            {formik.errors.about && formik.touched.about
            ? formik.errors.about : null}
        </div>

         </div>


    <div className="RegP_inputDiv">
        <InputField
            title={t("city",{ns:"translation"})}
            icon="country"
            type="text"
            tooltip={t("city_tooltip",{ns:"translation"})}
            placeholder={t("yourCity", { ns: "translation" })}
            name="city"
            {...formik.getFieldProps('city')}
        />
        <div className="invalid-feedback">
            {formik.errors.city && formik.touched.city
            ? formik.errors.city : null}
        </div>
    </div>

    <div className="RegP_inputDiv">
    <InputField
                title={t("Email",{ns:"translation"})}
                icon="email"
                type="email"
                placeholder={t("yourEmail",{ns:"translation"})}
                name="email"
                {...formik.getFieldProps('email')}
            />
        <div className="invalid-feedback">
            {formik.errors.email && formik.touched.email
            ? formik.errors.email : null}
        </div>
    </div>

    <div className="RegP_inputDiv">
        <InputPassword
            title={t("Password",{ns:"translation"})}
            icon="pass"
            placeholder={t("enterPassword",{ns:"translation"})}
            name="password"
            {...formik.getFieldProps('password')}
        />
        <div className="invalid-feedback">
            {formik.errors.password && formik.touched.password
            ? formik.errors.password : null}
        </div>
    </div>

    <div className="RegP_inputDiv">
        <InputPassword
            title={t("confirmPassword",{ns:"translation"})}
            icon="conf"
            placeholder={t("confirmPassword",{ns:"translation"})}
            name="confirmPassword"
            {...formik.getFieldProps('confirmPassword')}
        />
        <div className="invalid-feedback">
            {formik.errors.confirmPassword && formik.touched.confirmPassword
            ? formik.errors.confirmPassword : null}
        </div>
    </div>

    </div>
    </div>

    <div className="RegP_title_area">
            <p>{t("additionalInfo",{ns:"tr"})}</p>
            <span>{t("additionalInfo_text",{ns:"tr"})}.</span>
    </div>

    <div className="RegP_Add_info_block">

        <InputField
            title={t("yourHeight",{ns:"translation"})}
            icon="height"
            placeholder={t("heightUnits",{ns:"translation"})}
            name="height"
            type="number"
            inputmode="numeric"
            {...formik.getFieldProps('height')}
            value={formik.values.height}
        />

        <SelectField
            title={t("relations",{ns:"translation"})}
            icon="heart"

            name="relationship"
            {...formik.getFieldProps('relationship')}

            options={[
            { label: t("noAnswer",{ns:"translation"}), value: '' },
            { label: t("dating",{ns:"translation"}), value: 'dating' },
            { label: t("single",{ns:"translation"}), value: 'single' },
            { label: t("married",{ns:"translation"}), value: 'married' },
            { label: t("devorsed",{ns:"translation"}), value: 'devorsed' },
            { label: t("other",{ns:"translation"}), value: 'other' },
            ]}
        />

    <SelectField
        title={t("orientation",{ns:"translation"})}
        icon="sex"

        name="orientation"
        {...formik.getFieldProps('orientation')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("hetero",{ns:"translation"}), value: 'hetero' },
          { label: t("bisexual",{ns:"translation"}), value: 'bisexual' },
          { label: t("gay",{ns:"translation"}), value: 'gay' },
          { label: t("lesbi",{ns:"translation"}), value: 'lesbi' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}
    />

    <SelectField
        title={t("smoking",{ns:"translation"})}
        icon="smoke"

        name="smoking"
        {...formik.getFieldProps('smoking')}

        options={[
            { label: t("noAnswer",{ns:"translation"}), value: '' },
            { label: t("dontSmoke",{ns:"translation"}), value: 'dontSmoke' },
            { label: t("Smoke",{ns:"translation"}), value: 'Smoke' },
            { label: t("Sometimes",{ns:"translation"}), value: 'Sometimes' },
            { label: t("other",{ns:"translation"}), value: 'other' },
        ]}
    />

    <SelectField
        title={t("alkohol",{ns:"translation"})}
        icon="alkohol"

        name="alkohol"
        {...formik.getFieldProps('alkohol')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("dontDrink",{ns:"translation"}), value: 'dontDrink' },
          { label: t("Drink",{ns:"translation"}), value: 'Drink' },
          { label: t("inCompany",{ns:"translation"}), value: 'inCompany' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}
    />

    <SelectField
        title={t("religion",{ns:"translation"})}
        icon="religion"

        name="religion"
        {...formik.getFieldProps('religion')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("notReligious",{ns:"translation"}), value: 'notReligious' },
          { label: t("christianity",{ns:"translation"}), value: 'christianity' },
          { label: t("islam",{ns:"translation"}), value: 'islam' },
          { label: t("buddhism",{ns:"translation"}), value: 'buddhism' },
          { label: t("judaism",{ns:"translation"}), value: 'judaism' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}
    />

    <SelectField
        title={t("character",{ns:"translation"})}
        icon="character"

        name="character"
        {...formik.getFieldProps('character')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("extravert",{ns:"translation"}), value: 'extravert' },
          { label: t("intravert",{ns:"translation"}), value: 'intravert' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}
    />

    <SelectField
        title={t("finances",{ns:"translation"})}
        icon="dollar"

        name="finances"
        {...formik.getFieldProps('finances')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("payForMyself",{ns:"translation"}), value: 'payForMyself' },
          { label: t("canBeSponsor",{ns:"translation"}), value: 'canBeSponsor' },
          { label: t("needSponsor",{ns:"translation"}), value: 'needSponsor' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}
    />

    <SelectField
        title={t("whenReady",{ns:"translation"})}
        icon="whenReady"
        name="whenReady"
        {...formik.getFieldProps('whenReady')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("4_anyMoment",{ns:"translation"}), value: '4_anyMoment' },
          { label: t("3_thisMonth",{ns:"translation"}), value: '3_thisMonth' },
          { label: t("2_thisYear",{ns:"translation"}), value: '2_thisYear' },
          { label: t("1_notReady",{ns:"translation"}), value: '1_notReady' },
        ]}
    />

<div className="RegP_lang">

<AddLang
        title={t("speakLangs",{ns:"translation"})}
        usersLangs={usersLangs}
        setUsersLangs={setUsersLangs}
    />

</div>


<div className="RegP_interest">
<Suspense fallback="Loading...">

    <AddInterest
        title={t("yourInterests",{ns:"translation"})}
        usersList={usersList}
        setUsersList={setUsersList}
    />
</Suspense>
</div>

    </div>


    </div>

    <div className="RegP_send_btn_line">

        {(Object.keys(formik.errors).length > 0) && (Object.keys(formik.touched).length > 0) ?

        <span className="RegP_notValid_error">{t("fillAllFields",{ns:"tr"})}</span>
        : null
        }

        {serverError &&
            <div className="RegP_notValid_error">
            <p>{serverError}</p>
            </div>
        }

        <MainBtn
            color='purp'
            type="submit"
            title={t("ph_createAccount",{ns:"tr"})}
            disabled={!serverError && formik.isSubmitting}
            onClick={()=>{}}
        />

    </div>

    </form>
    </div>
  )
}

export default RegisterPage