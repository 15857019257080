import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputCountry from "../Input/InputCountry";

import "./TourSearchWin.scss";
import InputField from '../Input/InputField';
import RSelectField from '../Input/RSelectField';
import SearchAges from '../Input/SearchAges';
import SearchStartDate from '../Input/SearchStartDate';
import SearchEndDate from '../Input/SearchEndDate';
import SearchDaysAmnt from '../Input/SearchDaysAmnt';
import SearchInt from '../AddInterest/SearchInt';
import SearchLang from '../AddLang/SearchLang';
import SelectField from '../Input/SelectField';
import SearchVerified from '../Input/SearchVerified';

const PostSearchWin = ({ search, close }) => {

  const [depart, setDepart] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState("");
  const [gender, setGender] = useState(null);
  const [startAge, setStartAge] = useState("");
  const [endAge, setEndAge] = useState("");
  const [anyAge, setAnyAge] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [noStartDate, setNoStartDate] = useState();
  const [noEndDate, setNoEndDate] = useState();
  const [daysAmount, setDaysAmount] = useState();
  const [anyDates, setAnyDates] = useState();

  const [orientation, setOrientation] = useState();
  const [character, setCharacter] = useState();
  const [finances, setfinances] = useState();

  const [verified, setVerified] = useState(false);

  const [intValue, setIntValue] = useState(null)
  const [lang, setLang] = useState(null)

  const [filter, setFilter] = useState({})

// console.log(filter);

  useEffect(() => {
    const mapDepart = depart && depart.map(v=>v.value)
    const mapCountry = country && country.map(v=>v.value)
    const mapInterests = intValue && intValue.map(v=>v._id)
    const mapLangs = lang && lang.map(v=>v._id)

    setFilter({
        depart: mapDepart,
        country: mapCountry,
        gender:gender?.value,
        interests:mapInterests,
        langs:mapLangs,
        city,
        startAge,
        endAge,
        anyAge,
        startDate,
        endDate,
        noStartDate,
        noEndDate,
        daysAmount,
        verified,
        anyDates,
        orientation,
        character,
        finances
      })

    }, [depart, country, city, gender, startAge, endAge, anyAge,
      startDate, endDate, noStartDate, noEndDate, daysAmount,anyDates, intValue,verified, lang, orientation, character, finances]);

  const {t} = useTranslation(['tr']);

const handleSearch=()=>{
  if(depart || country|| city||gender||startAge||endAge||(anyAge===true)||
  startDate || endDate|| noStartDate ||verified||noEndDate || daysAmount||anyDates||intValue||lang||orientation||finances||character
  ){
  search(filter)
  close()

  }else{
    close()
  }
}

  return (
    <div className="TourSearchWin_wrapper">
      <div className="TSW_contatiner">
        <div className="TSW_header">
        <h2>{t("PostSearch",{ns:"tr"})}</h2>
          <p>{t("PostSearch_t",{ns:"tr"})}</p>
        </div>

        <div className="TSW_contentBox">

<div className="TSW_line TSW_line1">

        <div className="TSW_SearchBlock">
          <InputCountry
              title={t("fromCountry", { ns: "translation" })}
              isMulti
              icon="depart"
              placeholder={t("fromWhatCountry", { ns: "translation" })}
              tooltip={t("PS_fromCountry_tt",{ns:"tr"})}
              name="depart"
              onChange={(val)=>setDepart(val)}
              value={depart}
            />
          </div>
        <div className="TSW_SearchBlock">
          <InputCountry
              title={t("toCountry", { ns: "translation" })}
              isMulti
              icon="country"
              placeholder={t("toWhatCountry", { ns: "translation" })}
              tooltip={t("TS_toCountry_tt",{ns:"tr"})}
              name="country"
              onChange={(val)=>setCountry(val)}
              value={country}
            />
          </div>

          <div className="TSW_SearchBlock">
          <InputField
            title={t("toCity", { ns: "translation" })}
            icon="country"
            type="text"
            placeholder={t("toWhatCity", { ns: "translation" })}
            tooltip={t("TS_toCity_tt",{ns:"tr"})}
            name="city"
            value={city}
            onChange={(e)=>setCity(e.target.value)}
          />
        </div>
</div>

<div className="TSW_line TSW_line2">
    <div className="TSW_searchInt">
      <SearchInt
        placeholder={t("chooseInt",{ns:"int"})}
        intValue={intValue}
        setIntValue={setIntValue}
        isMulti
      />
  </div>
    <div className="TSW_searchLang">
    <Suspense fallback="Loading...">

      <SearchLang
        placeholder={t("chooseLang",{ns:"langs"})}
        lang={lang}
        setLang={setLang}
        isMulti
      />
      </Suspense>
  </div>

</div>

<div className="TSW_line TSW_line3">

        <div className="TSW_SearchBlock">
          <RSelectField
            title={t("authorGender", { ns: "translation" })}
            icon="sex"
            name="gender"
            tooltip={t("PS_pplGender_tt",{ns:"tr"})}

            defaultValue={{label:t("any",{ns:"translation"}),value:"any"}}
            value={gender}
            onChange={(value)=>setGender(value)}

            options={[
              { label: t("any",{ns:"translation"}), value: "any" },
              { label: t("men",{ns:"translation"}), value: "male" },
              { label: t("women",{ns:"translation"}), value: "female" },
            ]}
          />
        </div>

        <div className="TSW_SearchBlock">

          <SearchAges
            icon="ages"
            title={t("authorAge", { ns: "translation" })}
            startAge={startAge}
            setStartAge={setStartAge}
            endAge={endAge}
            setEndAge={setEndAge}
            anyAge={anyAge}
            setAnyAge={setAnyAge}
            noAnyAge={true}

            tooltip={t("PS_pplAges_tt",{ns:"tr"})}
          />
        </div>

        <div className="TSW_SearchDatesAmount">
          <SearchVerified
            title={t("verification", { ns: "translation" })}
            cb_title={t("cb_verification", { ns: "translation" })}
            tooltip={t("PS_verified_tt",{ns:"tr"})}
            name="verified"
            verified={verified}
            setVerified={setVerified}
          />
        </div>
</div>


<div className="TSW_line TSW_line4">

        <div className="TSW_SearchDates">
          <SearchStartDate
            title={t("startDate", { ns: "translation" })}
            cb_title={t("noStartDate", { ns: "translation" })}
            cb_tooltip={t("PS_cb",{ns:"tr"})}
            icon="dates"
            tooltip={t("PS_startDate_tt",{ns:"tr"})}
            startDate={startDate}
            setStartDate={setStartDate}
            noStartDate={noStartDate}
            setNoStartDate={setNoStartDate}
            anyDates={anyDates}

          />
        </div>

        <div className="TSW_SearchDates">
          <SearchEndDate
            title={t("endDate", { ns: "translation" })}
            cb_title={t("noEndDate", { ns: "translation" })}
            cb_tooltip={t("PS_cb",{ns:"tr"})}
            icon="dates"
            tooltip={t("PS_endDate_tt",{ns:"tr"})}
            endDate={endDate}
            setEndDate={setEndDate}
            noEndDate={noEndDate}
            setNoEndDate={setNoEndDate}
            anyDates={anyDates}
          />
        </div>

        <div className="TSW_SearchDatesAmount">
          <SearchDaysAmnt
            title={t("daysAmount", { ns: "translation" })}
            icon="daysAmount"
            type="number"
            inputmode="numeric"
            upTo={t("upTo", { ns: "translation" })}
            tooltip={t("TS_daysAmount_tt",{ns:"tr"})}
            name="daysAmount"
            value={daysAmount}
            onChange={(e)=>setDaysAmount(e.target.value)}

            cb_title={t("anyDates", { ns: "translation" })}
            cb_tooltip={t("PS_cb",{ns:"tr"})}
            anyDates={anyDates}
            setAnyDates={()=>setAnyDates(!anyDates)}
          />
        </div>
</div>
<div className="TSW_line TSW_line5">

<SelectField
        title={t("authorOrientation", { ns: "translation" })}
        icon="sex"
        name="orientation"
        value={orientation}
        onChange={(e)=>setOrientation(e.target.value)}
        // {...formik.getFieldProps('orientation')}

        options={[
          { label: t("noAnswer", { ns: "translation" }), value: '' },
          { label: t("hetero", { ns: "translation" }), value: 'hetero' },
          { label: t("bisexual", { ns: "translation" }), value: 'bisexual' },
          { label: t("gay", { ns: "translation" }), value: 'gay' },
          { label: t("lesbi", { ns: "translation" }), value: 'lesbi' },
          { label: t("other", { ns: "translation" }), value: 'other' },
        ]}/>

<SelectField
        title={t("authorCharacter", { ns: "translation" })}
        icon="character"

        name="character"
        value={character}
        onChange={(e)=>setCharacter(e.target.value)}
        // {...formik.getFieldProps('character')}

        options={[
          { label: t("noAnswer", { ns: "translation" }), value: '' },
          { label: t("extravert", { ns: "translation" }), value: 'extravert' },
          { label: t("intravert", { ns: "translation" }), value: 'intravert' },
          { label: t("other", { ns: "translation" }), value: 'other' },
        ]}/>

<SelectField
        title={t("authorFinances", { ns: "translation" })}
        icon="dollar"

        name="finances"
        value={finances}
        onChange={(e)=>setfinances(e.target.value)}
        // {...formik.getFieldProps('finances')}

        options={[
          { label: t("noAnswer", { ns: "translation" }), value: '' },
          { label: t("payForMyself", { ns: "translation" }), value: 'pay' },
          { label: t("canBeSponsor", { ns: "translation" }), value: 'sponsor' },
          { label: t("needSponsor", { ns: "translation" }), value: 'need' },
          { label: t("other", { ns: "translation" }), value: 'other' },
        ]}/>

</div>
        </div>

      </div>
      <div className="TSW_Btn_line">
      <div className="TSW_btn" onClick={handleSearch}>
      {t("SearchBtn",{ns:"tr"})}</div>
      </div>
    </div>
  );
};

export default PostSearchWin;
