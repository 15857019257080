import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as api from '../../utils/api/api.utils'

import MsgUser from "./InboxUser";
import { handleError } from "../../utils/handleError";

import "./ShowUsersMenu.scss"
const ShowUsersMenu = ({usersID, tour, menuOpen, setMenuOpen}) => {
  const [users, setUsers] = useState();

  useEffect(() => {
    api.findUsersByIdArray(usersID).then(res=> {
      if(res.status===200){
        setUsers(res.data)
      }else{
        handleError(res)
      }
    }
      )
  }, [tour]);

    const navigate = useNavigate();

    //---- Закрытие на ESC ----
    const escClose = (event) => {
        if (event.key === "Escape") {
        document.removeEventListener("keydown", escClose, false)
        setMenuOpen(false)
        }};

    if(menuOpen){
        document.addEventListener("keydown", escClose, false);
    }
    //------------------------------

    const toProfile=(user)=>{
        setMenuOpen(false)
        navigate(`/user/${user.username}/`)
    }

    const orgIndex = users?.length-1

  return (

    <div className={!menuOpen ? 'SUW_wrapper SUW_hide':'SUW_wrapper'} >


    {users && users.map((user, index) =>(
        <div key={index} onClick={()=>toProfile(user)}>
            <MsgUser org={index===orgIndex} user={user} />
        </div>
    )).reverse()}

    </div>
  )
}

export default ShowUsersMenu