
import './SpinnerSmall.scss'

const SpinnerSmall = () => {
  return (
    <div className="SpinnerSmall_wrapper">
    <div className="lds_small">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  )
}

export default SpinnerSmall