import { useTranslation } from "react-i18next";
import { showDate } from '../../../utils/showDate';
import { showGender } from '../../../utils/showGender';
import { showAges } from '../../../utils/showAges';


import "./../news.scss";
const CreatedPostBlock = ({post}) => {
  const { t } = useTranslation(["tr", "countries"]);

  const text = post?.text;
  const { date, year } = showDate(post);
  const gender = showGender(post, "post");
  const ages = showAges(post);

  return (
    <div className="N_contentBox">
      <p><b>{t("lookingFor", { ns: "tr" })}:</b> {gender}, {ages}</p>

      <div className="N_countryDateLine">
        <p><b>{t("toGoTo", { ns: "tr" })}:</b> <span> <b> {t(post.country, { ns: "countries" })},</b>{post?.city}</span></p>
        <p><b>{date}</b> {year}</p>
      </div>

      <div className="N_TextBox">{text}</div>

    </div>
  )
}

export default CreatedPostBlock