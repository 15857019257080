import SendArrow from '../../assets/sendArrow.svg'

import './MiniBtns.scss'
const SendBtn = ({onClick, disabled}) => {
  return (

    <div className={`Send_Btn ${disabled? "SendBtnDisabled" : null}`}
        onClick={onClick}>
        <img src={SendArrow} alt="" />
    </div>
  )
}

export default SendBtn