import * as Yup from 'yup';
import i18n from '../i18next';
const {t} = i18n

export const validationSchema = Yup.object().shape({

  langs: Yup.array()
  .min(1, t("1langRequired", {ns:"err"}))
  .required(t("1langRequired", {ns:"err"})),

  noStartDate: Yup.boolean(),
  startDate: Yup.date().when('noStartDate', {
    is: false,
    then: (startDate) => startDate.required(t("startDateReq", {ns:"err"})),
    otherwise: (startDate) => startDate,
  }),

  noEndDate: Yup.boolean(),

  endDate: Yup.date()
  .when('noEndDate', {
    is: false,
    then: (endDate) => endDate.required(t("endDateReq", {ns:"err"})),
    otherwise: (endDate) => endDate
  })
  .when(['noEndDate', 'noStartDate'], {
    is: false,
    then: (endDate) => endDate.min( Yup.ref('startDate'), t("endBeforeStart", {ns:"err"})),
    otherwise: (endDate) => endDate
  })
  ,

    anyAge: Yup.boolean(),

    startAge: Yup.number().when('anyAge', {
        is: false,
        then: (startAge) => startAge.required(t("minAgeReq", {ns:"err"})),
        otherwise: (startAge) => startAge,
      })
      .min(18, t("minAge18", {ns:"err"}))
      .max(99, t("maxAge99", {ns:"err"}))
      ,

    endAge: Yup.number().when('anyAge', {
      is: false,
      then: (endAge) => endAge.required(t("maxAgeReq", {ns:"err"})),
      otherwise: (endAge) => endAge,
    })
    .min(18, t("minAge18", {ns:"err"}))
    .max(99, t("maxAge99", {ns:"err"}))
    ,

depart: Yup.string()
  .required(t("countryRequared", {ns:"err"})),

country: Yup.string()
.required(t("countryRequared", {ns:"err"})),

city: Yup.string()
.matches(/^[A-Za-z\s+]+$/, t("onlyEnglish", {ns:"err"}))
.required(t("cityRequared", {ns:"err"})),

text: Yup.string()
.required(t("advTextReq", {ns:"err"})),

    });



