import { useEffect, useState } from 'react';
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';

import InputField from '../../components/Input/InputField'

import './SettingsPage.scss'
import { handleError } from '../../utils/handleError';
import MainBtn from '../../components/Buttons/MainBtn';
const ChangeUsername = ({jwtUser, setJwtUser}) => {
    const [counter, setCounter] = useState(0);
    const [uname, setUname] = useState("");
    const [serverError, setServerError] = useState("");
    const [info, setInfo] = useState("");
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if(jwtUser) setUname(jwtUser?.data?.user?.username)
    }, [jwtUser]);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

        if(counter===0) {
            setServerError("")
            setInfo("")
            setDisabled(false)
        }
      }, [counter]);

    useEffect(() => {
        if(uname===jwtUser?.data?.user?.username){
            setDisabled(true)
        }else{
            setDisabled(false)
        }

    }, [disabled, uname]);

    const {t} = useTranslation(['translation']);

    const changeUsername= async()=>{
        try {
            setDisabled(true)

            const res = await api.changeUsername(jwtUser?.data?.user?._id, uname)

            if(res?.status===200){
              jwtUser.data.user.username = res.data.user.username;
              setJwtUser(jwtUser)
              setCounter(60)
              setInfo(t("newUsernameSaved",{ns:"tr"}))
            }else if(res?.response?.status===403){
                setCounter(60)
                setUname(jwtUser?.data?.user?.username)
                if(res?.response?.data?.msg==="taken"){
                    setServerError(t("usernameTaken",{ns:"tr"}))

                }else if(res?.response?.data?.msg==="noUser"){
                    setServerError(t("UserNotFound",{ns:"tr"}))
                }
            }else{
                throw res
            }
        } catch (error) {
            handleError(error)
        }
    }

  return (

    <div className="SP_content_wrapper">

        <div className="SP_Header">
            <p>{t("ChagingUsername",{ns:"tr"})}</p>
            <span>{t("toChageUsername",{ns:"tr"})}</span>
        </div>

        <div className="SP_contentBox">

    <div className="SP_inputDiv">
        <InputField
            title={t("userName",{ns:"translation"})}
            icon="name"
            type="text"
            placeholder={t("enterUserName",{ns:"translation"})}
            name="username"
            maxLength={20}
            value={uname}
            disabled={counter!==0}
            onChange={(e)=>setUname(e.target.value.replace(/[^A-Za-z0-9-]/ig, ''))}
        />
    </div>

<div className="SP_error_line">
    {info&& <h5><b>{info}</b> {t("nextTryAfter",{ns:"tr"})} {counter} {t("sec",{ns:"tr"})}. </h5>}

    {serverError && <h6><b>{serverError}</b> {t("nextTryAfter",{ns:"tr"})} {counter} {t("sec",{ns:"tr"})}.</h6>}
</div>
        </div>

        <MainBtn
            color='purp'
            title={t("Save",{ns:"tr"})}
            disabled={disabled}
            onClick={changeUsername}
        />

    </div>

    )
}

export default ChangeUsername