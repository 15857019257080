import { useContext, useEffect, useState } from 'react';
import { Navigate, NavLink, Outlet } from 'react-router-dom'
import { UserContext } from '../../context/user.context';
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';


import PageHeader from '../../components/PageHeader/PageHeader'

import './ModerationPage.scss'
import { handleError } from '../../utils/handleError';

const ModerationPage = () => {
    const {jwtUser} = useContext(UserContext);
    const [stats, setStats] = useState();
    const {t} = useTranslation(['tr']);

    useEffect(() => {
        api.getStats().then(res=> {
          if(res?.status===200){
            setStats(res.data)
          }else{
            handleError(res)
          }
        })
      }, []);

    if(!jwtUser?.data?.user?.isAdmin){
        return <Navigate to={`/`} />
    }

  return (

    <div className="ModerationPage_wrapper">

    <PageHeader
    icon="admin"
    title={t("ModerationPage",{ns:"tr"})}
    // tooltip={t("ModerationPage_tt",{ns:"tr"})}
    text={t("ModerationPage_text",{ns:"tr"})}
    btn="none"
    visitors={stats?.visitors?.length}
    />

<div className="ModPage_NavBar">

  <NavLink to={`verify`}
  className={({isActive}) => isActive ? 'Mod_Navlink_active' : ''}>
  <div className="Mod_NavBar_Item">
  {t("MP_Verification",{ns:"tr"})}</div></NavLink>

  <NavLink to={`moderate`}
  className={({isActive}) => isActive ? 'Mod_Navlink_active' : ''}>
  <div className="Mod_NavBar_Item">
  {t("MP_TripsModeration",{ns:"tr"})}</div></NavLink>

  <NavLink to={`feedback`}
  className={({isActive}) => isActive ? 'Mod_Navlink_active' : ''}>
  <div className="Mod_NavBar_Item">
  {t("MP_Feedback",{ns:"tr"})}</div></NavLink>

</div>

    <Outlet />


    </div>

  )
}

export default ModerationPage