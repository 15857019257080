import React, { useRef, useEffect } from "react";

//-- Компонент который реагирует на клик за пределами выбранного объекта.
//-- Объект сюда передается через children

const useOutsideAlerter=(ref, doSmth)=> {

  useEffect(() => {
    const handleClickOutside = (event)=> {
      if (ref.current && !ref.current.contains(event.target)) {
        doSmth();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

//-- Вот основной компонент который экспортируется. 
//-- doSmth - это проп который выполняет действие.
export default function OutsideClick({children, doSmth}) {
    const wrapperRef = useRef(null);
    
  useOutsideAlerter(wrapperRef, doSmth);

  return <div ref={wrapperRef}>{children}</div>;
}