import { useEffect, useState} from "react";
import Lang from "../Lang/Lang";
import OutsideClick from "../../utils/OutsideClick";
import { langs } from "../../data/languages.js";
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';


import arrow_down from '../../assets/arrow_down_grey.svg'
import lang_icon from '../../assets/create_account/lang.svg'

import "./AddLang.scss";
import TooltipPurp from "../Tooltip/TooltipPurp";
import { handleError } from "../../utils/handleError";
import MainBtn from "../Buttons/MainBtn";
const AddLang = ({usersLangs, setUsersLangs, title, tooltip, max=50 }) => {

  const [langMenuOpen, setLangMenuOpen] = useState(false);
   //---- Lang states ---------------
   const [langInput, setLangInput] = useState(''); //-- Lang Input Field
   const [menuLangs, setMenuLangs] = useState([...langs]);//-- Lang menu
   const [allLangs, setAllLangs] = useState([...langs]); //-- All langs

   useEffect(() => {
    api.getLangs().then((res) => {
      if(res.status===200){
        setMenuLangs([...langs, ...res.data])
        setAllLangs([...langs, ...res.data])
      }else{
        handleError(res)
      }
    });
}, []);

const publicMenuLangs = menuLangs.filter(lang=> lang.isPublic===true)
const {t} = useTranslation(['tr']);

const createLang=async()=>{
  try {
    const res = await api.createLang({rus_title: langInput, isPublic: false })

    if(res.status===200){
      setAllLangs([...allLangs,res.data ])
      setUsersLangs([...usersLangs, res.data._id ])
    }else{
      throw res
    }
  } catch (error) {
    handleError(error)
}
}

const addLang=()=>{

  if(langInput.length>=3){
  setLangInput('')
  createLang()
  }
  return
}

  //-- Закрытие на ESC клавишу --
  const onClose = () => {
    document.removeEventListener("keydown", escClose, false);
    setLangMenuOpen(!langMenuOpen);
  };
  const escClose = (event) => {
    if (event.key === "Escape") onClose();
  };
  if (langMenuOpen) {
    document.addEventListener("keydown", escClose, false);
  }
  //------------------------------

  const userLangsList = allLangs.filter(({ _id }) => usersLangs?.includes(_id));

  //-- Добавление в список пользователя и удаление из списка меню

  const selectLang = (lang) => {
    if(usersLangs.length<max){
    const newList = menuLangs.filter((item) => item._id !== lang._id);
    setMenuLangs(newList);
    setUsersLangs([...usersLangs, lang._id]);
    }
  };

  //-- Удаление из списка пользователя и добавление в список меню
  const deleteLang = (lang) => {
    const newUserList = usersLangs.filter((item) => item !== lang._id);
    setUsersLangs(newUserList);
    const addToMenuItem = allLangs.find((item) => item._id === lang._id);
    setMenuLangs([addToMenuItem, ...menuLangs]);
  };


  return (
    <div className="AL_wrapper">
    <div className="AL_Title_line">
        <img src={lang_icon} alt="lang icon" />
        <p>{title}</p>
        {tooltip &&
        <div className="AL_Tooltip">
          <TooltipPurp text={tooltip} />
        </div>
        }

    </div>
    <div className="AL_content_box">
      <div className="AL_Btn_area">

        <div className="AL_BTN" onClick={()=>setLangMenuOpen(!langMenuOpen)}>
        {t("Select",{ns:"tr"})}
        <img src={arrow_down} alt="" />
        </div>

          <OutsideClick doSmth={() => setLangMenuOpen(false)}>
        {langMenuOpen && (
            <div className="AL_Langs_menu">
              {menuLangs && publicMenuLangs.map((lang) => (
                  <Lang
                    key={lang?._id}
                    title={lang?.rus_title}
                    icon={lang?.flag}
                    code={lang.code}
                    pointer={true}
                    onClick={() => selectLang(lang)}
                  />
                ))}
            </div>
        )}
          </OutsideClick>

        {/* <h5>{t("AddIfNo",{ns:"tr"})}</h5> */}

      {/* <div className="AL_addNew">
          <input
          className="AL_input"
          type="text"
          onChange={(e)=>setLangInput(e.target.value)}
          name="createInterest"
          value={langInput}
          placeholder={t("more3Char",{ns:"tr"})}
          />

          <MainBtn
            color='purp'
            title={t("AddLang",{ns:"tr"})}
            onClick={addLang}
          />

    </div> */}


      </div>
      <div className="AL_Lang_box">
      <span> {t("clickToRemove",{ns:"tr"})}.</span>
          <div className="AL_Lang_area">
            {userLangsList && userLangsList.map((lang) => (

                <Lang
                  key={lang._id}
                  title={lang.rus_title}
                  icon={lang.flag}
                  code={lang.code}
                  pointer={true}
                  onClick={() => deleteLang(lang)}
                />
              ))}
          </div>
      </div>
    </div>
    </div>
  );
};

export default AddLang;
