import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import countryObj from '../../utils/countries.json'
import { useMatchMedia } from "../../hooks/use-match-media";

import './SearchCountry.scss'
const SearchCountry = ({isClearable, country, setCountry, isMulti=false }) => {

    const {t} = useTranslation(['translation', 'countries', 'int']);
    const { mobile } = useMatchMedia();

    const countryArr = Object.entries(countryObj).map(([key,value])=>{
        return { 
          value: key,  
          label:t(key, { ns: "countries" }),
          icon: <img style={{width:'30px', height:'20px'}}  
          src={`/assets/flags/small/${key.toLowerCase()}.jpg`} alt="" />
        } })
      //--сортирую по алфавиту в каждом языке
      countryArr.sort(function(a, b) {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });

    return (
        <div className="SearchCountry_wrapper">
      <Select 
      options={countryArr} 
      value={country} 
      onChange={(v)=>setCountry(v)}
      isMulti={isMulti}
      placeholder={t("chooseCountry", { ns: "translation" })}
      components={{ IndicatorSeparator: () => null }}
      isClearable={isClearable}
      isSearchable={ mobile? false: true }
      getOptionValue={o => o.label}
      getOptionLabel={e=>(
      <div className='SC_MenuItem'> {e.icon} 
      <span className='SC_MenuText'>{e.label}</span> </div>)}

      theme={(theme) => ({...theme, borderRadius: 8,
      colors: {
        ...theme.colors,
        primary25: '#eee3f4',
        primary: '#87589C',
      },})} />
        </div>
  )
}

export default SearchCountry