import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import PageHeader from '../../components/PageHeader/PageHeader'
import UnderConstrPage from '../UnderConstrPage/UnderConstrPage'

import './HelpPage.scss'

const HelpPage = () => {
  const {t, i18n} = useTranslation(['tr']);

  const lang = i18n.language
  const { mobile } = useMatchMedia();

  return (
    <div className="HelpPage_wrapper">

        <PageHeader
        icon="info"
        title={t("help",{ns:"tr"})}
        text={t("help_text",{ns:"tr"})}
      />

<div className="HelpP_content_box">

<div className="HelpP_title_area">
  <p>{t("whatTripicoAbout", { ns: "tr" })}</p>
  <h3>{t("whatTripicoAbout_text", { ns: "tr" })}</h3>
</div>

{lang==="ru"&&
  <iframe width={mobile? "350": "560"} height={mobile? "220" : "315"} src="https://www.youtube.com/embed/g2yhOPsfIlI?si=UH1HFfpnMxJ8uXij" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
}

{lang==="en"&&
  <iframe width={mobile? "350": "560"} height={mobile? "220" : "315"}  src="https://www.youtube.com/embed/5W7hNkhHf4I?si=0nGmP7wKbjGsPo0M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
}

</div>

    </div>
  )
}

export default HelpPage