import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LivejournalShareButton,
    MailruShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LivejournalIcon,
    MailruIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WhatsappIcon,
  } from "react-share";


import './ShareBox.scss'
const ShareBox = ({shareUrl, title, tourImg, description}) => {
  return (

    <div className="ShareBox_wrapper">
        <div className="ShareBox_item">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="ShareBox_Btn"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>

        <div className="ShareBox_item">
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="ShareBox_Btn"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>

        <div className="ShareBox_item">
          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="ShareBox_Btn"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </div>

        <div className="ShareBox_item">
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="ShareBox_Btn"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>

        <div className="ShareBox_item">
          <VKShareButton
            url={shareUrl}
            image={tourImg}
            className="ShareBox_Btn"
          >
            <VKIcon size={32} round />
          </VKShareButton>
        </div>

        <div className="ShareBox_item">
          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="ShareBox_Btn"
          >
            <RedditIcon size={32} round />
          </RedditShareButton>
        </div>

        <div className="ShareBox_item">
          <TumblrShareButton
            url={shareUrl}
            title={title}
            className="ShareBox_Btn"
          >
            <TumblrIcon size={32} round />
          </TumblrShareButton>
        </div>

        <div className="ShareBox_item">
          <LivejournalShareButton
            url={shareUrl}
            title={title}
            description={description}
            className="ShareBox_Btn"
          >
            <LivejournalIcon size={32} round />
          </LivejournalShareButton>
        </div>

        <div className="ShareBox_item">
          <MailruShareButton
            url={shareUrl}
            title={title}
            description={description}
            className="ShareBox_Btn"
          >
            <MailruIcon size={32} round />
          </MailruShareButton>
        </div>

        <div className="ShareBox_item">
          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
            className="ShareBox_Btn"
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>

        <div className="ShareBox_item">
          <ViberShareButton
            url={shareUrl}
            title={title}
            className="ShareBox_Btn"
          >
            <ViberIcon size={32} round />
          </ViberShareButton>
        </div>

        <div className="ShareBox_item">
          <LineShareButton
            url={shareUrl}
            title={title}
            className="ShareBox_Btn"
          >
            <LineIcon size={32} round />
          </LineShareButton>
        </div>

        <div className="ShareBox_item">
          <PocketShareButton
            url={shareUrl}
            title={title}
            className="ShareBox_Btn"
          >
            <PocketIcon size={32} round />
          </PocketShareButton>
        </div>

        <div className="ShareBox_item">
          <InstapaperShareButton
            url={shareUrl}
            title={title}
            description={description}
            className="ShareBox_Btn"
          >
            <InstapaperIcon size={32} round />
          </InstapaperShareButton>
        </div>

        <div className="ShareBox_item">
          <HatenaShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="ShareBox_Btn"
          >
            <HatenaIcon size={32} round />
          </HatenaShareButton>
        </div>
    </div>

    )
}

export default ShareBox