import { useNavigate } from "react-router-dom";
import { thumb } from "../../utils/thumb";
import { config } from "../../utils/config";

import "./UserSml.scss";
const UserXSml = ({ user, noName }) => {
  const navigate = useNavigate();

  const navigateTo = (user) => {
    navigate(`/user/${user.username}/`);
  };
  return (
    <div className="userXSml_wrapper" onClick={() => navigateTo(user)}>


      <div className="userXSml_imgBG">
        <img src={thumb(user?.photos[0], config.thumb_width)} alt="user" loading="lazy" />
      </div>

      {!noName&&
        <p className="userXSml_name">{user?.fName?.substring(0, 8)}</p>
      }
    </div>
  );
};

export default UserXSml;
