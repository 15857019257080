import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import join_request from '../../assets/events/join_request.svg'
import joined from '../../assets/events/joined.svg'
import confirmed from '../../assets/events/confirmed.svg'

import { config } from '../../utils/config'
import { thumb } from '../../utils/thumb'
import './Event.scss'
import i18next from 'i18next';

const TourEvent = ({e, menuOpen, setMenuOpen}) => {
    const navigate = useNavigate();
    const {t} = useTranslation(['tr']);
    const lang = i18next.language

    const {fName, lName, photos, username} = e?.fromUserId
    const x = e?.action
    const date =  new Date(e?.createdAt).toLocaleString(lang,{day:'numeric', month:'short'});

    const navigateTo =()=>{
        navigate(`/user/${username}`)
        setMenuOpen(!menuOpen)
    }

  return (
    <div className="Event_Wrapper" onClick={navigateTo}>
        <div className="E_imgbox">
            <img className='E_userPhoto' src={thumb(photos[0], config.user_photo_width)} alt=""/>

            {(x==="join_request")&& <img className='E_icon' src={join_request} alt="" />}
            {(x==="joined")&& <img className='E_icon' src={joined} alt="" />}
            {(x==="confirmed")&& <img className='E_icon' src={confirmed} alt="" />}
        </div>

        <div className="Event_infoArea">

            {/*//-- User отправил Join Request в ваш тур */}
            {(x==="join_request")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span className='E_orange'> <b>{t("wantsToJoin",{ns:"tr"})}</b> {t("toYourTrip",{ns:"tr"})}: </span>
                <h4>{e.tour?.title}</h4>
            </>}

            {/*//-- User присоединился в ваш тур */}
            {(x==="joined")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span className='E_green'> <b>{t("joined",{ns:"tr"})}</b> {t("toYourTrip",{ns:"tr"})}: </span>
                <h4>{e.tour?.title}</h4>
            </>}

            {/*//-- User одобрен организатором в ваш тур */}
            {(x==="confirmed")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span className='E_blue'> <b>{t("approvedByOrg",{ns:"tr"})}</b> {t("toYourTrip",{ns:"tr"})}: </span>
                <h4>{e.tour?.title}</h4>
            </>}

        </div>

    </div>
  )
}

export default TourEvent