import { useTranslation } from "react-i18next";

import Logo from "../../assets/tripico_logo.svg";
import Pic_1 from "../../assets/onboarding/ob_1.png";
import right_arrow from "../../assets/arrow_right_white.svg";
import YouTubeBtn from "../../components/Buttons/YouTubeBtn";

import "./OBPScreens.scss";

const OBPScreen1 = ({ close, next }) => {
  const { t, i18n } = useTranslation(["tr"]);

  let link;
  if (i18n.language === "ru") {
    link = "https://youtu.be/g2yhOPsfIlI";
  }else{
    link = "https://youtu.be/5W7hNkhHf4I";
  }


  return (
    <div className="OBP_Screen1">
      <div className="OBP_header">
        <img src={Logo} alt="Tripico Logo" />
        <p><b>1</b> / 3</p>
      </div>

      <div className="OBP_body">
        <div className="OBP_pic_area">
          <img className="OBP_pic" src={Pic_1} alt="Onboarding" />
        </div>

        <div className="OBP_body_right">
          <p>{t("OnBoarding_P1", { ns: "tr" })}</p>
          <span> {t("OnBoarding_P1_text1", { ns: "tr" })}
            <br /> {t("OnBoarding_P1_text2", { ns: "tr" })}
          </span>

          <YouTubeBtn
            title={t("OnBoarding_P1_youtube", { ns: "tr" })}
            link={link}
          />
        </div>
      </div>

      <div className="OBP_bottom">
        <span onClick={close}>{t("Skip", { ns: "tr" })}</span>
        <div className="OBP_Buttom_Btns">
          <div className="OBP_back_btn_area"></div>
          <div className="OBP_next_btn" onClick={next}>
            <p>{t("Next", { ns: "tr" })}</p>
            <img src={right_arrow} alt="arrow" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OBPScreen1;
