import React from 'react'
import TooltipPurp from '../Tooltip/TooltipPurp';

import "./MainBtn.scss"
const MainBtn = ({title, tooltip, disabled, onClick, color="purp", ...props}) => {

const btnStyle = {
  purp: "BtnMain_purp",
  tort: "BtnMain_tort",
  tortSmall: "BtnMain_tortSmall",
  blue: "BtnMain_blue",
  blueVK: "BtnMain_blueVK",
  blueVK_light: "BtnMain_blueVK_light",
  white: "BtnMain_white",
  yellow: "BtnMain_yellow",
};

  return (
    <div className="MainBtn_line">
        <button
          className={`MainBtn ${btnStyle[color]}`}
          disabled={disabled}
          type="button"
          onClick={onClick}
          {...props}
          >
          {title}
        </button>
        {tooltip&& <TooltipPurp text={tooltip}/>}
    </div>
  )
}

export default MainBtn