import { useContext, useState, useEffect, Suspense,} from "react";
import { UserContext } from '../../context/user.context';
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import * as api from '../../utils/api/api.utils'
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { validationSchema } from "../../utils/validations/edituser_Yup";

import InputField from '../../components/Input/InputField'
import PageHeader from '../../components/PageHeader/PageHeader'
import SelectField from "../../components/Input/SelectField";
import TextField from "../../components/Input/TextField";
import AddInterest from "../../components/AddInterest/AddInterest";
import AddLang from "../../components/AddLang/AddLang";

import AddPhoto from "../../components/AddPhoto/AddPhoto";
import RadioBtn from "../../components/Input/RadioBtn";

import InputCountry from "../../components/Input/InputCountry";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import { handleError } from "../../utils/handleError";
import MainBtn from "../../components/Buttons/MainBtn";

import './UserPageEdit.scss'
const UserPageEdit = () => {
    const { jwtUser, setJwtUser, location } = useContext(UserContext);
    const [selectedFiles, setSelectedFiles] = useState([]);//-- Files to upload
    const [serverError, setServerError] = useState();

    const [sending, setSending] = useState(false);

    const [usersList, setUsersList] = useState([...jwtUser?.data.user?.interests]); //-- Users Interests
    const [usersLangs, setUsersLangs] = useState([...jwtUser?.data.user.speak_lang]); //-- Users Langs

    const {t} = useTranslation(['translation']);
    const { ipadSml } = useMatchMedia();

    useEffect(() => {
        formik.setFieldValue("image", selectedFiles);

    if(selectedFiles.length===0) {
        formik.setFieldValue("image", null);
    }
    }, [selectedFiles]);


    const navigate = useNavigate();

    const formik = useFormik({initialValues: {
      fName: jwtUser.data.user.fName,
      lName: jwtUser.data.user.lName,
      gender: jwtUser.data.user.gender,
      about: jwtUser.data.user.about,
      country: jwtUser.data.user.country,
      city: jwtUser.data.user.city,
      birthDate: new Date(jwtUser.data.user.birthDate).toISOString().slice(0, 10),

      relationship: jwtUser.data.user.relationship,
      orientation: jwtUser.data.user.orientation,
      height: jwtUser.data.user.height,
      smoking: jwtUser.data.user.smoking,
      alkohol: jwtUser.data.user.alkohol,
      religion: jwtUser.data.user.religion,
      character: jwtUser.data.user.character,
      finances: jwtUser.data.user.finances,
      whenReady: jwtUser.data.user?.whenReady || "",
      image:[...jwtUser.data.user.photos],

    },
    validationSchema: validationSchema ,
    onSubmit: (values) => {
        // console.log('Formik values', values);
    },
  });

  //--- перенаправляет на логин если он не в системе
  if(!jwtUser){
    return <Navigate to={`/login`} />
}

    const handleSubmit = async (event) => {
      event.preventDefault();
      formik.handleSubmit();

      setServerError(null)

    //   console.log(selectedFiles);


          const formData = new FormData();
          formData.append("fName", formik.values.fName);
          formData.append("lName", formik.values.lName);
          formData.append("birthDate", formik.values.birthDate);
          formData.append("country", formik.values.country);
          formData.append("city", formik.values.city);
          formData.append("gender", formik.values.gender);
          formData.append("about", formik.values.about);

          for (let i=0; i<selectedFiles.length; i++) {

            if(selectedFiles[i].link){
              formData.append("image", JSON.stringify({id:i, path:selectedFiles[i].link}));
            }else{
                formData.append(i, selectedFiles[i].source);
            }
           }

          formData.append("relationship", formik.values.relationship);
          formData.append("orientation", formik.values.orientation);
          formData.append("height", formik.values.height);
          formData.append("smoking", formik.values.smoking);
          formData.append("alkohol", formik.values.alkohol);
          formData.append("religion", formik.values.religion);
          formData.append("character", formik.values.character);
          formData.append("finances", formik.values.finances);
          formData.append("whenReady", formik.values.whenReady);


          for (let i=0; i<usersLangs.length; i++) {
            formData.append("speak_lang", usersLangs[i]);
       }

          for (let i=0; i<usersList.length; i++) {
            formData.append("interests", usersList[i]);
       }

       if(formik.isValid){
         try {
            setSending(true)

            const res = await api.editUser(jwtUser.data.user._id, formData)

            if (res?.status === 200) {
              jwtUser.data.user = res.data
              setJwtUser(jwtUser);
              setSending(false)
              navigate(`/user/${jwtUser?.data?.user?.username}`)
            }else{
              throw res
            }

      } catch (error) {
        handleError(error);
        setSending(false)
      }

    }else{
            console.log("Forms are not correct")
          }

    };


  return (

    <div className="UserEditPage_wrapper">

    {sending && <LoadingScreen/>}

    <PageHeader
      icon="edituser"
      title={t("EditAccount",{ns:"translation"})}
      tooltip={t("EditAccount_tooltip",{ns:"translation"})}
      text={t("EditYourAccount",{ns:"translation"})}
      btn="none"
    />

    <form  onSubmit={handleSubmit}>

    <div className="UEP_content_box">

    <AddPhoto
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        initialFiles={jwtUser.data.user.photos}
        formik_errors={formik.errors}
        formik_touched={formik.touched}
    />

    <div className="UEP_info_block">

    <div className="UEP_title_area">
            <p>{t("mainInfo",{ns:"tr"})}</p>
            <span>{t("mainInfo_text",{ns:"tr"})}.</span>
            {jwtUser?.data?.user?.verified&&
            <h4>{t("mainInfo_text2",{ns:"tr"})}.</h4>
            }

    </div>

    <div className="UEP_info_body">

        <div className="UEP_inputDiv">
            <InputField
                title={t("firstName",{ns:"translation"})}
                icon="name"
                type="text"
                placeholder={t("yourName",{ns:"translation"})}
                name="fName"
                disabled={jwtUser.data.user.verified}
                {...formik.getFieldProps('fName')}
            />
        <div className="invalid-feedback">
          {formik.errors.fName && formik.touched.fName
           ? formik.errors.fName : null}
        </div>
        </div>

        <div className="UEP_inputDiv">
            <InputField
                title={t("lastName",{ns:"translation"})}
                icon="name"
                type="text"
                placeholder={t("yourLastName",{ns:"translation"})}
                name="lName"
                disabled={jwtUser.data.user.verified}

                {...formik.getFieldProps('lName')}
            />
        <div className="invalid-feedback">
            {formik.errors.lName && formik.touched.lName
            ? formik.errors.lName : null}
        </div>
        </div>

        <div className="UEP_inputDiv">
        <Suspense fallback="Loading...">
            <InputCountry
                title={t("country", { ns: "translation" })}
                icon="country"
                placeholder={t("yourCountry", { ns: "translation" })}
                tooltip={t("country_tooltip", { ns: "translation" })}
                name="country"
                isSearchable={!ipadSml}
                onChange={(value)=>formik.setFieldValue("country", value.value)}
                onBlur={()=>formik.setFieldTouched("country")}
                value={formik.values.country}
            />
        </Suspense>
        <div className="invalid-feedback">
            {formik.errors.country && formik.touched.country
            ? formik.errors.country : null}
        </div>
        </div>


            <div className="UEP_inputDiv">
            <InputField
                title={t("birthDate", { ns: "translation" })}
                icon="date"
                type="date"
                placeholder={t("birthDate", { ns: "translation" })}
                name="birthDate"
                disabled={jwtUser.data.user.verified}

                {...formik.getFieldProps('birthDate')}
            />
        <div className="invalid-feedback">
            {formik.errors.birthDate && formik.touched.birthDate
            ? formik.errors.birthDate : null}
        </div>
        </div>


        <div className="UEP_gender">

            <RadioBtn
            title={t("yourGender", { ns: "translation" })}
            icon="name"
            name="gender"
            male={t("Male", { ns: "translation" })}
            female={t("Female", { ns: "translation" })}
            disabled={jwtUser.data.user.verified}

            value={formik.values.gender}
            onChange={formik.handleChange}
            />
            <div className="invalid-feedback">
                {formik.errors.gender && formik.touched.gender
                ? formik.errors.gender : null}
            </div>
        </div>


        <div className="UEP_textarea">

         <TextField
            title={t("aboutYou", { ns: "translation" })}
            icon="doc"
            tooltip={t("aboutYou_tooltip", { ns: "translation" })}
            placeholder={t("writeAboutYou", { ns: "translation" })}
            maxLength="1000"
            name="about"
            {...formik.getFieldProps('about')}
         />
         <div className="invalid-feedback">
            {formik.errors.about && formik.touched.about
            ? formik.errors.about : null}
        </div>

         </div>


    <div className="UEP_inputDiv">
    <InputField
                title={t("city",{ns:"translation"})}
                icon="country"
                type="text"
                tooltip={t("city_tooltip",{ns:"translation"})}
                placeholder={t("yourCity", { ns: "translation" })}
                name="city"
                {...formik.getFieldProps('city')}
        />
        <div className="invalid-feedback">
            {formik.errors.city && formik.touched.city
            ? formik.errors.city : null}
        </div>
    </div>

    </div>
    </div>

    <div className="UEP_title_area">
            <p>{t("additionalInfo",{ns:"tr"})}</p>
            <span>{t("additionalInfo_text",{ns:"tr"})}.</span>
    </div>

    <div className="UEP_Add_info_block">

    <InputField
                title={t("yourHeight",{ns:"translation"})}
                icon="height"
                type="number"
                inputmode="numeric"
                placeholder={t("heightUnits",{ns:"translation"})}
                name="height"
                {...formik.getFieldProps('height')}
            />

        <SelectField
        title={t("relations",{ns:"translation"})}
        icon="heart"

        name="relationship"
        {...formik.getFieldProps('relationship')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("dating",{ns:"translation"}), value: 'dating' },
          { label: t("single",{ns:"translation"}), value: 'single' },
          { label: t("married",{ns:"translation"}), value: 'married' },
          { label: t("devorsed",{ns:"translation"}), value: 'devorsed' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}/>

    <SelectField
        title={t("orientation",{ns:"translation"})}
        icon="sex"

        name="orientation"
        {...formik.getFieldProps('orientation')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("hetero",{ns:"translation"}), value: 'hetero' },
          { label: t("bisexual",{ns:"translation"}), value: 'bisexual' },
          { label: t("gay",{ns:"translation"}), value: 'gay' },
          { label: t("lesbi",{ns:"translation"}), value: 'lesbi' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}/>

<SelectField
        title={t("smoking",{ns:"translation"})}
        icon="smoke"

        name="smoking"
        {...formik.getFieldProps('smoking')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("dontSmoke",{ns:"translation"}), value: 'dontSmoke' },
          { label: t("Smoke",{ns:"translation"}), value: 'Smoke' },
          { label: t("Sometimes",{ns:"translation"}), value: 'Sometimes' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}/>

<SelectField
        title={t("alkohol",{ns:"translation"})}
        icon="alkohol"

        name="alkohol"
        {...formik.getFieldProps('alkohol')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("dontDrink",{ns:"translation"}), value: 'dontDrink' },
          { label: t("Drink",{ns:"translation"}), value: 'Drink' },
          { label: t("inCompany",{ns:"translation"}), value: 'inCompany' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}/>

<SelectField
        title={t("religion",{ns:"translation"})}
        icon="religion"

        name="religion"
        {...formik.getFieldProps('religion')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("notReligious",{ns:"translation"}), value: 'notReligious' },
          { label: t("christianity",{ns:"translation"}), value: 'christianity' },
          { label: t("islam",{ns:"translation"}), value: 'islam' },
          { label: t("buddhism",{ns:"translation"}), value: 'buddhism' },
          { label: t("judaism",{ns:"translation"}), value: 'judaism' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}/>

<SelectField
        title={t("character",{ns:"translation"})}
        icon="character"

        name="character"
        {...formik.getFieldProps('character')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("extravert",{ns:"translation"}), value: 'extravert' },
          { label: t("intravert",{ns:"translation"}), value: 'intravert' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}/>

<SelectField
        title={t("finances",{ns:"translation"})}
        icon="dollar"

        name="finances"
        {...formik.getFieldProps('finances')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("payForMyself",{ns:"translation"}), value: 'payForMyself' },
          { label: t("canBeSponsor",{ns:"translation"}), value: 'canBeSponsor' },
          { label: t("needSponsor",{ns:"translation"}), value: 'needSponsor' },
          { label: t("other",{ns:"translation"}), value: 'other' },
        ]}/>

<SelectField
        title={t("whenReady",{ns:"translation"})}
        icon="whenReady"
        name="whenReady"
        {...formik.getFieldProps('whenReady')}

        options={[
          { label: t("noAnswer",{ns:"translation"}), value: '' },
          { label: t("4_anyMoment",{ns:"translation"}), value: '4_anyMoment' },
          { label: t("3_thisMonth",{ns:"translation"}), value: '3_thisMonth' },
          { label: t("2_thisYear",{ns:"translation"}), value: '2_thisYear' },
          { label: t("1_notReady",{ns:"translation"}), value: '1_notReady' },
        ]}/>

<div className="UEP_lang">

<AddLang
        title={t("speakLangs",{ns:"translation"})}
        usersLangs={usersLangs}
        setUsersLangs={setUsersLangs}
    />

</div>


<div className="UEP_interest">

    <AddInterest
        title={t("yourInterests",{ns:"translation"})}
        usersList={usersList}
        setUsersList={setUsersList}
    />

</div>
    </div>
    </div>

    <div className="UEP_send_btn_line">

    {(Object.keys(formik.errors).length > 0) &&
    (Object.keys(formik.touched).length > 0) ?

    <span className="UEP_notValid_error">
    {t("fillAllFields",{ns:"tr"})}!
    </span>
     : null}

    {serverError &&
        <div className="UEP_notValid_error">
           <p>{serverError}</p>
        </div>
    }

    <MainBtn
        color='purp'
        type="submit"
        title={t("Save",{ns:"tr"})}
        // disabled={!serverError && formik.isSubmitting}
        onClick={()=>{}}
    />

    </div>

    </form>

    </div>
  )
}

export default UserPageEdit