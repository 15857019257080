import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import './TravelPlan.scss'

const TripRules = () => {

  const {tour} = useOutletContext();
  const {t} = useTranslation(['tr']);


  return (
    <div className="TP_Travelplan_container">

        <h4><b>{t("TripRules",{ns:"tr"})}:</b></h4>
        <div className="Travelplan_box">
          <div dangerouslySetInnerHTML={{ __html: tour?.tourRules }} />
        </div>

    </div>
  )
}

export default TripRules