import { Navigate, NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import * as api from '../../utils/api/api.utils'
import { UserContext } from "../../context/user.context";
import { interests as allInterests } from "../../data/interests";
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Navigation, Keyboard, EffectFade, Zoom } from "swiper";

import BackBtn from "../../components/Buttons/BackBtn";
import LeftBtn from "../../components/Buttons/LeftBtn";
import RightBtn from "../../components/Buttons/RightBtn";
import ShareBtn from "../../components/Buttons/ShareBtn";

import 'swiper/scss';
import 'swiper/css/zoom';
import 'swiper/scss/navigation';
import "swiper/scss/effect-fade";
import Modal from "../../components/Modal/Modal";
import Spinner from "../../components/Spinner/Spinner";
import TourFavBtn from "../../components/Buttons/TourFavBtn";
import JoinedChip from "../../components/Chips/JoinedChip";
import ConfirmedChip from "../../components/Chips/ConfirmedChip";
import RejectChip from "../../components/Chips/RejectChip";
import RequestChip from "../../components/Chips/RequestChip";
import TestChip from "../../components/Chips/TestChip";
import FinishChip from "../../components/Chips/FinishChip";
import ShareBox from "../../components/ShareBox/ShareBox";
import FeedBack from "../../components/FeedBack/FeedBack";
import OutsideClick from "../../utils/OutsideClick";
import TourMenu from "../../components/Menu/TourMenu";
import { thumb } from "../../utils/thumb";
import { config } from "../../utils/config";
import { handleError } from "../../utils/handleError";
import { showDate } from "../../utils/showDate";
import FollowTourBtn from "../../components/Buttons/FollowTourBtn";
import EditBtn from "../../components/Buttons/EditBtn";
import MainBtn from "../../components/Buttons/MainBtn";
import ActionsBtnSml from "../../components/Buttons/ActionsBtnSml";
import JoinBtnSml from "../../components/Buttons/JoinBtnSml";


import "./TourPage.scss";
const TourPage = () => {
  const { jwtUser, setJwtUser} = useContext(UserContext);
  const [tour, setTour] = useState({});
  const [loading, setLoading] = useState(true);

  const [shareShow, setShareShow] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const { mobileBig  } = useMatchMedia();

  SwiperCore.use([Keyboard]);

  const { id } = useParams();

  useEffect(() => {
    setTour({});
    api.getTourById(id, visitorID).then(res=> {
      if(res?.status===200){
        setTour(res.data)
        setLoading(false)
      }else{
        handleError(res)
      }
    })
  }, [id]);

  const {t} = useTranslation(['translation','countries','tr']);
  const navigate = useNavigate();

  const {date, year, isFinished} = showDate(tour);

  const myId = jwtUser?.data?.user?._id

  const inJoined = tour?.joined_users?.some(user => user._id === myId)
  const inConfirmed = tour?.confirmed_users?.includes(myId)
  const inRequests = tour?.join_requests?.includes(myId)
  const inBlocked = tour?.blocked_users?.includes(myId)
  const inRejected = tour?.rejected_users?.includes(myId)
  const madeReview = tour?.users_made_review?.includes(myId)
  const isCreator = tour?.creator?._id===myId
  const isMyTour = tour?.creator?._id===myId
  const visitorID = localStorage.getItem('UUID')

  const shareUrl = window.location.href
  const title = `Tripico: Join to our group in this tour:  "${tour?.title}"`;
  const tourImg = tour?.titleImages && tour?.titleImages[0]
  const description = "Collecting people to this tour!"

  const joinPage = window.location.pathname.includes("join")
  const chatPage = window.location.pathname.includes("chat")

  const tour_interests = allInterests.filter(({_id}) => tour?.interests?.includes(_id));

  const handleModal = (index) => {
    setModalOpen(true);
    setPhotoIndex(index);
  };

const exitTour = async()=>{
    await api.removeFromJoin(myId, {tourID: tour?._id})
    await api.removeFromConf(myId, {tourID: tour?._id})
    await api.getTourById(tour?._id).then(res=> {
      if(res?.status===200){
       setTour(res.data)
      }else{
        handleError(res)
      }
    })
}

if(inBlocked){
    return <Navigate to={`/`} />
}

if(loading){
  return <Spinner/>
}

  return (
    <div className="TourPage_wrapper">

      <div className="TourPage_pic_area">

        {tour?.titleImages&&
          <Swiper
            slidesPerView={1}
            modules={[Navigation, EffectFade]}
            effect="fade"
            loop
            navigation={{
              nextEl: '.TP_RightBtn',
              prevEl: '.TP_LeftBtn',
              }}
          >

            {tour?.titleImages && tour?.titleImages.map((pic, index) =>
              <SwiperSlide key={index} onClick={() => handleModal(index)} >
                <div className="tourLowResImg" style={{ backgroundImage: `url("${thumb(pic, config.thumb_width)}")`}}>
                  <img className="tourHighResImg" src={pic} alt="" loading="lazy"/>
                  <div class="swiper-lazy-preloader"></div>
                </div>
              </SwiperSlide>
            )}

          </Swiper>
        }
      </div>

      <div className="TP_BackBtn"> <BackBtn/> </div>

      <div className="TP_LeftBtn"> <LeftBtn /></div>
      <div className="TP_RightBtn"> <RightBtn /></div>

      {isFinished &&
      <div className="TP_Finish_Chip">
        <FinishChip
        title={t("tourFinished",{ns:"tr"})}
        small={false}
        tooltip={t("tourFinished_tooltip",{ns:"tr"})}
        />
      </div>
      }

      {tour?.isTest &&
      <div className="TP_Test_Chip">
        <TestChip
        title={t("testTrip",{ns:"tr"})}
        small={false}
        tooltip={t("testTrip_tooltip",{ns:"tr"})}
        />
      </div>
      }

      {inJoined&&
      <div className="TP_JoinedChip">
          <JoinedChip
          title={t("youJoined",{ns:"tr"})}
          tooltip={t("youJoined_tooltip",{ns:"tr"})}
          />
      </div>
      }

      {inConfirmed&&
        <div className="TP_JoinedChip">
        <ConfirmedChip
          title={t("youConfirmed",{ns:"tr"})}
          tooltip={t("youConfirmed_tooltip",{ns:"tr"})}
        />
        </div>
      }

      {inRequests &&
        <div className="TP_JoinedChip">
          <RequestChip
          title={t("youRequested",{ns:"tr"})}
          tooltip={t("youRequested_tooltip",{ns:"tr"})}
          />
        </div>
      }

      {inRejected &&
      <div className="TP_JoinedChip">
        <RejectChip
          title={t("youRejected",{ns:"tr"})}
          tooltip={t("youRejected_tooltip",{ns:"tr"})}
        />
      </div>
      }

      <div className="TP_FavBtnWht">
      <TourFavBtn
          jwtUser={jwtUser}
          setJwtUser={setJwtUser}
          setLike={()=>{}}
          tour={tour}
      />
      </div>
      <div className="TP_ShareBtn" onClick={()=>setShareShow(!shareShow)}> <ShareBtn /></div>

<div className={`TourPage_ShareBox ${shareShow? null:"TP_ShareShow"}`}>
    <ShareBox
    shareUrl={shareUrl}
    title={title}
    tourImg={tourImg}
    description={description}
    />
</div>

      <div className="TourPage_title_block">
        <div className="TP_title_block_left">

          <div className="TB_left_country">
            <img className="TB_flag" src={`/assets/flags/small/${tour?.country?.toLowerCase()}.jpg`} alt="" />
            <p><b>{t(tour?.country, { ns: "countries" })},</b></p>
            <span>{tour?.city}</span>
          </div>

          <div className="TB_left_date">
            <span><b>{date}</b> {year}</span>
          </div>
        </div>

        <div className="TP_title_block_mid">
          <div className="TP_mid_Left">
            <p>$ {tour?.tourPrice}</p>

            {tour?.daysAmount >0 &&
              <span>{tour?.daysAmount} {t("days",{ns:"tr"})} </span>
            }
          </div>

          {(isMyTour && !isFinished) &&
            <EditBtn
              tooltip={t("Edit",{ns:"tr"})}
              onClick={()=>navigate(`/edittour/${tour?._id}`)}
            />
          }
        </div>

        <div className="TP_title_block_right">

          {!(inRejected || isFinished) && !isMyTour &&
            <NavLink to={`/tour/${id}/join`}>
              {(inJoined || inConfirmed || inRequests)?
                (mobileBig?
                  <ActionsBtnSml/>
                  :
                  <MainBtn color="purp" title={t("MyActions",{ns:"tr"})}/>
                )
                :
                (mobileBig?
                  <JoinBtnSml/>
                  :
                  <MainBtn color="tort" title={t("Join",{ns:"tr"})}/>
                )
              }
            </NavLink>
          }

          {(isFinished && (inJoined || isCreator) && !madeReview )&&
            <NavLink to={`/tour/${id}/makereviews`} >
              <MainBtn color="purp" title={t("WriteReview",{ns:"tr"})}/>
            </NavLink>
          }
        </div>

      </div>


      <div className="TourPage_NavBar">

        <NavLink to={`/tour/${id}/`}
        className={({isActive}) => isActive ? 'TP_Navlink_active' : ''}>
        <div className="TP_NavBar_Item">
        {t("TourOverview",{ns:"tr"})}</div></NavLink>


        {isFinished?
          <div className="TPNBI_reviews">
            <NavLink to={`/tour/${id}/reviews`}
            className={({isActive}) => isActive ? 'TP_Navlink_active' : ''}>
            <div className="TP_NavBar_Item">
            {t("TourReviews",{ns:"tr"})}</div></NavLink>
          </div>
        :
          <NavLink to={`/tour/${id}/people`}
          className={({isActive}) => isActive ? 'TP_Navlink_active' : ''}>
          <div className="TP_NavBar_Item">
          {t("TourPeople",{ns:"tr"})}</div>
          </NavLink>
        }

      {!isFinished &&
        <div className="TPNBI_plan">
          <NavLink to={`/tour/${id}/travelplan`}
          className={({isActive}) => isActive ? 'TP_Navlink_active' : ''}>
          <div className="TP_NavBar_Item">
          {t("TravelPlan",{ns:"tr"})}</div></NavLink>
        </div>
      }
        <div className="TPNBI_gallery">
          <NavLink to={`/tour/${id}/gallery`}
          className={({isActive}) => isActive ? 'TP_Navlink_active' : ''}>
          <div className="TP_NavBar_Item">
          {t("TourGallery",{ns:"tr"})}</div></NavLink>
        </div>

        <div className="TPNBI_chat">
          <NavLink to={`/tour/${id}/chat`}
          className={({isActive}) => isActive ? 'TP_Navlink_active' : ''}>
          <div className="TP_NavBar_Item">
          {t("TourChatRoom",{ns:"tr"})}</div></NavLink>
        </div>

        <div className="TPNBI_other">
          <OutsideClick doSmth={() => setMenuOpen(false)}>
            <div className="TP_NavBar_Item" onClick={() => setMenuOpen(!menuOpen)} >
              <p>{t("More_info",{ns:"tr"})}</p>
              <TourMenu isFinished={isFinished} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            </div>
          </OutsideClick>
        </div>

      </div>

      <div className="TP_Outlet_container">
      <div className="TP_Outlet">

  <Outlet context={{tour, inJoined, inConfirmed, isCreator, isFinished, setTour, isMyTour, tour_interests}} />
        </div>

{!joinPage&&!chatPage&&
  <div className="TP_Mob_action_block">
    {!(inRejected || isFinished)&&
      <>
        {!isMyTour &&
          <NavLink to={`/tour/${id}/join`}>
            {!(inJoined || inConfirmed || inRequests)?
              <div className="TP_Mob_joinBtn">{t("Join",{ns:"tr"})}</div>
              :
              <h2>{t("MyActions",{ns:"tr"})}</h2>
            }
          </NavLink>
        }

        {(isMyTour && !isFinished) &&
          <h4 onClick={()=>navigate(`/edittour/${tour?._id}`)}>{t("EditTrip",{ns:"tr"})}</h4>
        }
      </>
    }

    {(isFinished && (inJoined || isCreator) && !madeReview )&&
      <NavLink to={`/tour/${id}/makereviews`} >
        <div className="TP_makeReviewBtn">{t("WriteReview",{ns:"tr"})}</div>
      </NavLink>
    }

  </div>
}

          <div className="TP_footer">
            {isCreator?
              <div></div>
              :
              <FollowTourBtn tour={tour} jwtUser={jwtUser} setJwtUser={setJwtUser}/>
            }

            {(inJoined || inConfirmed) && !isFinished &&
              <p onClick={exitTour} >{t("ExitFromTour",{ns:"tr"})}</p>
            }
            <p onClick={()=> setFeedbackOpen(true)}>
              {t("sendFeedback", { ns: "translation" })}
            </p>
          </div>
    </div>

<Modal open={modalOpen} close={() => setModalOpen(false)}>

  <div className="ModalPhoto">
    <Swiper
      slidesPerView={1}
      spaceBetween={50}
      modules={[Navigation, Zoom]}
      zoom={true}
      initialSlide={photoIndex}
      keyboard={{ enabled: true }}
      loop
      // zoom
      navigation
      style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
      >

      {tour?.titleImages && tour?.titleImages.map((pic) => (
            <div key={pic}>
              <SwiperSlide>
                <div className="swiper-zoom-container">
                  <img className="tourHighResImgView" src={pic} alt="" loading="lazy" />
                  <div class="swiper-lazy-preloader"></div>
                </div>
              </SwiperSlide>
            </div>
          ))
        }

    </Swiper>
  </div>
</Modal>

<Modal
  open={feedbackOpen}
  close={() => setFeedbackOpen(false)}
  NoCloseBtn={true}
>
    <FeedBack
      close={()=> {
        document.body.classList.remove("active-modal");
        setFeedbackOpen(false)}}
    />

</Modal>

    </div>
  );
};

export default TourPage;
