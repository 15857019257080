import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as api from '../../utils/api/api.utils'
import { handleError } from '../../utils/handleError';

import './Buttons.scss'
const FollowUserBtn = ({jwtUser, setJwtUser, user }) => {

  const doIFollow = jwtUser?.data?.user?.i_follow?.includes(user?._id)
  const [pressed, setPressed] = useState(doIFollow);
  const [clickable, setClickable] = useState(true);

  const {t} = useTranslation(['tr']);

  const navigate = useNavigate();
  const myId = jwtUser?.data?.user?._id

  const followUser= async()=>{
    if(!jwtUser) navigate('/login')

    try {
      setPressed(true);
      setClickable(false);
      const res = await api.followUserById(myId, {followID: user._id})

      if(res.status===200){
        jwtUser.data.user.i_follow = res.data.user.i_follow;
        setJwtUser(jwtUser)
        setClickable(true);
        console.log("Follow button pressed")
      }else{
        setClickable(true);
        throw res;
      }
    } catch (error) {
      handleError(error)
    }
  }

  const unFollowUser = async()=>{
    if(!jwtUser) navigate('/login')

    try {
      setPressed(false)
      setClickable(false);
      const res = await api.unFollowUserById(myId, {unFollowID: user._id})

      if(res.status===200){
        jwtUser.data.user.i_follow = res.data.i_follow;
        setJwtUser(jwtUser)
        setClickable(true);
        console.log("UnFollow button pressed")
      }else{
        setClickable(true);
        throw res;
      }
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <div className={clickable? 'FollowUserBtn_link': "FollowUserBtn_text"}
        onClick={clickable? (pressed? unFollowUser: followUser): ()=>{}}>
        {t(pressed? "up_unFollow" : "up_follow",{ns:"tr"})}
    </div>
  )
}

export default FollowUserBtn