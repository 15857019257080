import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';


import dates_icon from "../../assets/create_account/calendar.svg";

import TooltipPurp from "../Tooltip/TooltipPurp";

import "react-datepicker/dist/react-datepicker.css";
import "./DatesField.scss";

const DatesField = ({ title, icon, tooltip, formik }) => {
  const {t} = useTranslation(['translation']);

  let img;
  if (icon === "dates") {
    img = dates_icon;
  }


  return (
    <div className="DatesField_container">
      <div className="DF_title_line">
        <div className="DF_title_sign">
          <img src={img} alt="" />
          <p>{title}</p>
        </div>
        {tooltip && <TooltipPurp text={tooltip} />}
      </div>

      <div className="DatesField_box">

        <DatePicker
          className="DatesField_input"
          selected={formik?.values?.startDate}
          onChange={(date) => formik.setFieldValue("startDate", date)}
          selectsStart
          startDate={formik?.values?.startDate}
          minDate={new Date()}
          endDate={formik?.values?.endDate}
          disabled={formik.values.noStartDate===true}
          monthsShown={2}
          isClearable={formik.values.startDate}
          placeholderText={t("startDate", {ns:"translation"})}
          dateFormat="MMMM d, yyyy"
          locale="ru"
        />
        <div className="invalid-feedback">
        {formik.errors.startDate && formik.touched.startDate
          ? formik.errors.startDate : null}
        </div>

        <div className="CT_Dates_checkbox">

        <label className="DF_checkbox_label">  
        <b>{t("noStartDate", {ns:"translation"})}</b>
          <input
            type="checkbox"
            name="noStartDate"
            checked={formik.values.noStartDate}
            {...formik.getFieldProps("noStartDate")}
          />
          <span className="DF_checkmark"></span>
        </label>
        <TooltipPurp 
        text={t("noStartDate_tt", {ns:"translation"})}
        />
        </div>

        <DatePicker
          className="DatesField_input"
          selected={formik?.values?.endDate}
          onChange={(date) => formik.setFieldValue("endDate", date)}
          selectsEnd
          startDate={formik?.values?.startDate}
          endDate={formik?.values?.endDate}
          minDate={formik?.values?.startDate}
          disabled={formik?.values?.noEndDate===true}
          monthsShown={2}
          isClearable={formik?.values?.endDate}
          placeholderText={t("endDate", {ns:"translation"})}
          dateFormat="MMMM d, yyyy"
          locale="ru"
          // value={formik.values.endDate}
          // onBlur={formik.handleBlur}
        />
        <div className="invalid-feedback">
        {formik.errors.endDate && formik.touched.endDate
          ? formik.errors.endDate : null}
        </div>

        <div className="CT_Dates_checkbox">
        <label className="DF_checkbox_label"> 
        <b>{t("noEndDate", {ns:"translation"})}</b>
          <input
            type="checkbox"
            name="noEndDate"
            checked={formik.values.noEndDate}
            {...formik.getFieldProps("noEndDate")}
          />
          <span className="DF_checkmark"></span>
        </label>
        <TooltipPurp 
        text={t("noEndDate_tt", {ns:"translation"})}
        />
        </div>

        {!(formik.values.noStartDate===true || formik.values.noEndDate===true)&&

        <p>{t("duration", {ns:"translation"})}: <b> {formik.values.daysAmount}</b> {t("days", {ns:"tr"})}</p>
        }

      </div>
    </div>
  );
};

export default DatesField;
