import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import * as api from '../../utils/api/api.utils'

import GalleryNoEdit from "../../components/Gallery/GalleryNoEdit";
import EditTourGallery from "../../components/Gallery/EditTourGallery";

import "./TourGallery.scss";
import { handleError } from "../../utils/handleError";
import Spinner from "../../components/Spinner/Spinner";
const TourGallery = () => {
  const { tour, setTour, isMyTour } = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [userAlbums, setUserAlbums] = useState([]);

  // console.log(userAlbums)

  useEffect(() => {
    api.getUserAlbums(tour._id).then(res=> {

      if(res?.status===200){
        setUserAlbums(res.data)
        setLoading(false)
      }else{
        handleError(res)
      }
    })
  }, []);

  if(loading){
  return <Spinner/>
}

  return (
    <div className="TourGallery_wrapper">

      <GalleryNoEdit
        tour={tour}
        type="tour" //tour, user, userTour
      />

      {isMyTour ?
        <div className="MyGallery_Block">
          <EditTourGallery tour={tour} setTour={setTour} />
        </div>
      :
        tour?.tourGallery?.length > 0 &&
          <div className="MyGallery_Block">
            <GalleryNoEdit
              tour={tour}
              type="tourGallery" //tour, user, userTour, tourGallery
            />
          </div>
      }

      {userAlbums && userAlbums.map((album, idx) => (
        <GalleryNoEdit
          key={idx}
          album={album}
          type="userAlbum" //tour, user, userTour, tourGallery, userAlbum
        />
      ))}

    </div>
  );
};

export default TourGallery;
