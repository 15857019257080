import Select from 'react-select'
import { useTranslation } from 'react-i18next';

import name_icon from '../../assets/create_account/man.svg'
import date_icon from '../../assets/create_account/calendar.svg'
import planet_icon from '../../assets/create_account/planet.svg'
import email_icon from '../../assets/create_account/email.svg'
import pass_icon from '../../assets/create_account/password.svg'
import conf_icon from '../../assets/create_account/confirm.svg'

import alkohol_icon from '../../assets/create_account/alkohol.svg'
import character_icon from '../../assets/create_account/character.svg'
import dollar_icon from '../../assets/create_account/dollar.svg'
import heart_icon from '../../assets/create_account/heart.svg'
import height_icon from '../../assets/create_account/height.svg'
import lang_icon from '../../assets/create_account/lang.svg'
import interest_icon from '../../assets/create_account/interest.svg'
import religion_icon from '../../assets/create_account/religion.svg'
import sex_icon from '../../assets/create_account/sex.svg'
import smoke_icon from '../../assets/create_account/smoke.svg'
import zodiak_icon from '../../assets/create_account/zodiak.svg'

import TooltipPurp from "../Tooltip/TooltipPurp";
import "./SelectField.scss";

const RSelectField = ({ title, icon, tooltip, 
  options, value, onChange, onBlur, ...otherProps }) => {

  const {t} = useTranslation(['translation']);

    let img;
    if(icon==="date") {img = date_icon};
    if(icon==="name") {img = name_icon};
    if(icon==="email") {img = email_icon};
    if(icon==="pass") {img = pass_icon};
    if(icon==="conf") {img = conf_icon};
    if(icon==="country") {img = planet_icon};

    if(icon==="alkohol") {img = alkohol_icon};
    if(icon==="character") {img = character_icon};
    if(icon==="dollar") {img = dollar_icon};
    if(icon==="heart") {img = heart_icon};
    if(icon==="height") {img = height_icon};
    if(icon==="lang") {img = lang_icon};
    if(icon==="interest") {img = interest_icon};
    if(icon==="religion") {img = religion_icon};
    if(icon==="sex") {img = sex_icon};
    if(icon==="smoke") {img = smoke_icon};
    if(icon==="zodiak") {img = zodiak_icon};

    const selectValue = (options, value)=>{
      return options? options.find(o=>o.value===value):""
    }

    const options18n = options.map(({value,label})=>{
      return { 
        value: value,  
        label:t(value, { ns: "translation" }),
      } })

      // console.log(options18n);

  return (
    <div className="SelectField_container">
      <div className="SF_title_line">
      <div className="SF_title_sign">
        <img src={img} alt="" />
        <p>{title}</p>
      </div>
      {tooltip && <TooltipPurp text={tooltip}/> }

      </div>
        <div className="SF_select">
          <Select 
          options={options18n} 
          value={selectValue(options18n, value)} 
          onChange={onChange}
          onBlur={onBlur}
          theme={(theme) => ({...theme, borderRadius: 8,
          colors: {
            ...theme.colors,
            primary25: '#eee3f4',
            primary: '#87589C', },})} 
          {...otherProps} 

          />
      </div>
    </div>
  );
};

export default RSelectField;
