import { useEffect, useRef, useState } from "react";
import * as api from "../../utils/api/api.utils";

import { handleError } from "../../utils/handleError";

import "./LikesMenu.scss";
import UserXSml from "../User/UserXSml";
const LikesMenu = ({ users, menuOpen, setMenuOpen, leftSide=false }) => {
  const [userBadges, setUserBadges] = useState();

  const scrollRef = useRef()

  useEffect(() => {
    scrollRef.current?.scrollTo({top: 0, left: 0, behavior: 'auto'})
    if(menuOpen){
      api.findUsersBadges(users).then((res) => {
        if(res.status===200){
          setUserBadges(res?.data);
        }else{
          handleError(res)
        }
      });
    }
  }, [menuOpen]);

  const show_amount = 50;

  //---- Закрытие на ESC ----
  const escClose = (event) => {
    if (event.key === "Escape") {
      document.removeEventListener("keydown", escClose, false);
      setMenuOpen(false);
    }
  };

  if (menuOpen) {
    document.addEventListener("keydown", escClose, false);
  }

  return (
    <div ref={scrollRef}
    className={`LikesMenu_wrapper ${menuOpen ? null: "LM_hide"} ${leftSide? "LM_leftside": null}`}>

        <div className="LM_Box">
          {userBadges && userBadges.map((badge, idx) =>
            idx < show_amount &&
              <UserXSml key={idx} user={badge}/>
             )}
        </div>

    </div>
  );
};

export default LikesMenu;
