import { useTranslation } from 'react-i18next';

import ages_icon from '../../assets/ages.svg'
import TooltipPurp from "../Tooltip/TooltipPurp";

import "./AgesField.scss";

const SearchAges = ({icon, title, tooltip,startAge, noAnyAge,  setStartAge,endAge, setEndAge, anyAge, setAnyAge }) => {

  const {t} = useTranslation(['translation']);


    let img;
    if(icon==="ages") {img = ages_icon};

const handleReset=()=>{
  setStartAge("");
  setEndAge("");
  setAnyAge(false);

}

  return (
    <div className="AgesField_container">
      <div className="AF_title_line">
        <div className="AF_title_sign">
          <img src={img} alt="" />
          <p>{title}</p>
        </div>
        {tooltip && <TooltipPurp text={tooltip}/> }
      </div>

         <div className="SearchAges_box">

         <p>{t("from", { ns: "translation" })}</p>

     <input
     className="AgesField_input"
     type="number"
     inputmode="numeric"
     min="18"
     max="99"
     name="startAge"
     disabled={anyAge}
     value={startAge}
     onChange={(e)=>setStartAge(e.target.value)}
     />

     <p> - </p>

     <input
     className="AgesField_input"
     type="number"
     inputmode="numeric"
     min="18"
     max="99"
     name="endAge"
     disabled={anyAge}
     value={endAge}
     onChange={(e)=>setEndAge(e.target.value)}
     />

    <p>{t("years", { ns: "translation" })}</p>


    {(startAge || endAge ) &&
    <div onClick={handleReset} className="SA_closeBtn">&#x2715;</div> }

         </div>

{!noAnyAge &&
         <label className="AF_checkbox_label">{t("anyAge",{ns:"translation"})}
          <input
          type="checkbox"
          id="anyAge"
          name="anyAge"
          checked={anyAge}
          onChange={()=>setAnyAge(!anyAge)}

          />
          <span className="AF_checkmark"></span>
      </label>
}
    </div>
  );
};

export default SearchAges;
