import { useEffect, useState} from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as api from '../../utils/api/api.utils'

import InputField from "../../components/Input/InputField";
import PageHeader from "../../components/PageHeader/PageHeader";

import "./ResetPassPage.scss";
import Spinner from "../../components/Spinner/Spinner";
import { handleError } from "../../utils/handleError";
import MainBtn from "../../components/Buttons/MainBtn";

const ResetPassPage = () => {
   const [serverError, setServerError] = useState();
   const [info, setInfo] = useState("");
   const [pass, setPass] = useState("");
   const [isPassOK, setIsPassOK] = useState(false);
   const [isTokenOK, setIsTokenOK] = useState(false);
   const [loading, setLoading] = useState(true);
   const [confirmPass, setConfirmPass] = useState("");

   const [searchParams] = useSearchParams();
   const navigate = useNavigate();

   const token = searchParams.get('token')
   const id = searchParams.get('id')
   const {t} = useTranslation(['translation']);

   useEffect(() => {
    api.verifyResetToken(id, token).then((res)=>{
        if(res?.status===200){
          setLoading(false)
          setIsTokenOK(true)
        }else if(res?.response?.status===403){
          setLoading(false)
          setIsTokenOK(false)
          setServerError(res?.response?.data?.message)
        }else{
          handleError(res)
        }
      })
    }, []);


   useEffect(() => {
    if(pass.length===0){
      setServerError("");
      setIsPassOK(false)
    }else if(pass.length < 6){
      setServerError(t("err_pass6",{ns:"tr"}));
      setIsPassOK(false)
      }else if(pass.length > 20) {
        setServerError(t("err_pass20",{ns:"tr"}));
      setIsPassOK(false)
      } else if(pass!==confirmPass) {
        setServerError(t("err_passNotMatch",{ns:"tr"}));
        setIsPassOK(false)
      }else{
        setServerError("")
        setIsPassOK(true)
      }
}, [pass, confirmPass]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setServerError(null)
    try {
      if(isPassOK){
        const res = await api.setNewPassword(id, token, pass)

          if(res?.status===200){
            setInfo(res?.data?.message)
            setPass("")
            setConfirmPass("")
            navigate('/login');
          }else if(res?.response?.status===403){
            setPass("")
            setConfirmPass("")
            setServerError(res?.response?.data?.message)
          }else{
            throw res
          }
        }
    } catch (error) {
      handleError(error)
      setServerError(error.response.data.message)
    }

  }

  if(!isTokenOK){
    return <p className="ResP_Main_Error">{serverError}</p>
  }

  if(loading){
    return <Spinner/>
  }


  return (
    <div className="ResP_wrapper">
       <PageHeader
        icon="info"
        title={t("NewPass",{ns:"tr"})}
        // tooltip="Введите ваш логин и пароль "
        text={t("enterNewPass",{ns:"tr"})}
        btn="none"
      />

          <div className="ResP_content_box">

        <form  onSubmit={handleSubmit}>
        <div className="ResP_mid_content_box">

        <p className="ResP_title">{t("enterNewPass",{ns:"tr"})}</p>
        <span>{t("saveNewPass",{ns:"tr"})}</span>

        <div className="ResP_inputDiv">
          <InputField
            title={t("newPassword",{ns:"translation"})}
            icon="pass"
            type="password"
            placeholder={t("enterNewPassword",{ns:"translation"})}
            name="password"
            value={pass}
            onChange={(e)=>setPass(e.target.value)}
          />
        </div>

        <div className="ResP_inputDiv">
          <InputField
            title={t("confirmPassword",{ns:"translation"})}
            icon="conf"
            type="password"
            placeholder={t("confirmPassword",{ns:"translation"})}
            name="confirmPassword"
            value={confirmPass}
            onChange={(e)=>setConfirmPass(e.target.value)}
          />
        </div>

        <MainBtn
          color='purp'
          type="submit"
          title={t("resetPassword",{ns:"tr"})}
          disabled={!isPassOK}
          onClick={()=>{}}
        />

        {serverError &&
          <div className="ResP_invalid_feedback">
            <p>{serverError}</p>
          </div>
        }

        {info &&
          <div className="ResP_info">
            <p>{info}</p>
          </div>
        }
      </div>
    </form>

      </div>
    </div>
  );
};

export default ResetPassPage;
