import { useEffect, useState } from 'react';
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';

import './SettingsPage.scss'
import { handleError } from '../../utils/handleError';
import MainBtn from '../../components/Buttons/MainBtn';
const ResetPassBlock = ({jwtUser}) => {
    const [counter, setCounter] = useState(0);
    const [info, setInfo] = useState("");
    const [serverError, setServerError] = useState();
    const {t} = useTranslation(['tr']);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

        if(counter===0) {
          setInfo("")
          setServerError("")
        }
      }, [counter]);

const resetPwd = async()=>{
    api.resetPassword({email: jwtUser?.data?.user?.email}).then((res)=>{
      if(res?.status===200){
        setCounter(60)
        setInfo(t("resetSent",{ns:"tr"}))
      }else if(res?.response?.status===403){
        setCounter(60)
        setServerError(res?.response?.data?.message)
      }else{
        handleError(res)
      }
    })
}


  return (

    <div className="SP_content_wrapper">

        <div className="SP_Header">
            <p>{t("resetPassword",{ns:"tr"})}</p>
            <span>{t("resetPassword_text",{ns:"tr"})}</span>
            <h4>{t("resetPassword_text2",{ns:"tr"})}
            {t("resetPassword_text3",{ns:"tr"})}</h4>
        </div>

        <div className="SP_contentBox">
            <div className="SP_EmailBox">
                <span>{t("yourEmailIs",{ns:"tr"})}:</span>
                <h4>{jwtUser?.data?.user?.email}</h4>

            </div>

<div className="SP_error_line">
    {info&&
    <h5><b>{info}</b> {t("nextTryAfter",{ns:"tr"})} {counter} {t("sec",{ns:"tr"})}. </h5>
    }

    {serverError&&
    <h6><b>{serverError}</b> {t("nextTryAfter",{ns:"tr"})} {counter} {t("sec",{ns:"tr"})}.</h6>
    }
</div>
        </div>

        <MainBtn
            color='purp'
            title={t("resetPassword",{ns:"tr"})}
            disabled={counter!==0}
            onClick={resetPwd}
        />

    </div>

    )
}

export default ResetPassBlock