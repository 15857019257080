import help_grey from "../../assets/help.svg";


import './TooltipGrey.scss'

const TooltipGrey = ({text}) => {
  return (
    <div className='TTGrey_wrapper'>

        <img src={help_grey} alt="tooltip" /> 

        <div className="TT_grey_tip" dangerouslySetInnerHTML={{ __html: text }} />

    </div>
  )
}

export default TooltipGrey