import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user.context";
import * as api from "../../utils/api/api.utils";
import { useNavigate } from "react-router-dom";

import { config } from "../../utils/config";
import { thumb } from "../../utils/thumb";

import i18n from '../../utils/i18next';
import { useTranslation } from 'react-i18next';

import TripicoLogo from "../../assets/tripico_logo.svg";
import LocationIcon from "../../assets/location.svg";
import Bell from "../../assets/bell.svg";


import OutsideClick from "../../utils/OutsideClick";
import AccountMenu from "../Menu/AccountMenu";

import SelectLang from "../Input/SelectLang";

import "./Header.scss";
import { handleError } from "../../utils/handleError";
import EventsMenu from "../Menu/EventsMenu";
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [eventsMenuOpen, setEventsMenuOpen] = useState(false);
  const [eventsNum, setEventsNum] = useState();
  const [lang, setLang] = useState();

  const { jwtUser, setJwtUser, socket, location, setLocation } = useContext(UserContext);

  const myId = jwtUser?.data?.user?._id

  const {t} = useTranslation(['tr']);

  const handleScroll=()=>{
    setMenuOpen(false)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, false)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])


    useEffect(() => {
      i18n.changeLanguage() // detection
      if(lang==="ru"){ i18n.changeLanguage("ru")}
      if(lang==="en"){ i18n.changeLanguage("en")}
    }, [lang]);

    useEffect(() => {
      if(jwtUser){
        api.getUnreadEventsAnmt().then((res) => {
          if(res.status===200){
            setEventsNum(res.data)
          }else{
            handleError(res)
          }
        });
      }
    }, [jwtUser]);

    useEffect(() => {
    socket && socket.on("event", e=>{
      // console.log(e.userId===myId);
      if(e.userId===myId){
        setEventsNum(prev => prev + 1)
      }

      })
  }, [socket])


    const loggedUser = jwtUser?.data?.user;
    const navigate = useNavigate();

    return (
    <div className="header">
      <div className="header_container">
        <div className="header_logo">
          <img className="H_TripicoLogo" onClick={()=>navigate('/')} src={TripicoLogo} alt="logo" />
        </div>

        <div className="header_r_side">

          <div className="header_r_lang">
              <SelectLang
                lang={lang}
                setLang={setLang}
              />
          </div>

  <div className="header_notifications_box" >
        {jwtUser&&
          <div className="HN_box">
            {eventsNum>0 &&  <div className="HN_number">{eventsNum}</div>}

            <OutsideClick doSmth={() => setEventsMenuOpen(false)}>
              <div className="header_notifications" onClick={() => {
                setEventsMenuOpen(!eventsMenuOpen)
                setEventsNum(null)
                }}>
                  <img src={Bell} alt="" />
              </div>

                <EventsMenu
                  menuOpen={eventsMenuOpen}
                  setMenuOpen={setEventsMenuOpen}
                />
            </OutsideClick>

            </div>
          }
    </div>

          <div className="header_r_location">
            {location &&
              <>
                <img src={LocationIcon} alt="" />
                <p>{location?.city}</p>
              </>
            }
          </div>

          <div className="header_r_user">

          <OutsideClick doSmth={() => setMenuOpen(false)}>
          {jwtUser?
            <img className="Header_user_img"
            onClick={() => setMenuOpen(!menuOpen)}
            src={thumb(loggedUser?.photos[0],
            config.loggedUser_photo_width)} alt="logged in user" />
            :
            <p onClick={()=>navigate('/login')}>
            {t("ph_login",{ns:"tr"})}</p>
          }

              <AccountMenu
                loggedUser={loggedUser}
                setJwtUser={setJwtUser}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
              />
          </OutsideClick>

          </div>
        </div>

      </div>
    </div>
  );
};

export default Header;
