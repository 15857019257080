import { useState } from 'react';
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

// import searchIcon from '../../assets/searchFilter.svg'
import { IoSearch } from "react-icons/io5";
import closeW from '../../assets/close_white.svg'


import "./SearchBar.scss";
import SearchCountry from '../Input/SearchCountry';
import SearchGender from '../Input/SearchGender';
import SearchBarAges from '../Input/SearchBarAges';
import PostSort from '../Sort/PostSort';
import PostSearchWin from '../Window/PostSearchWin';
import ModalWin from '../Modal/ModalWin';

const PostSearchBar = ({country, setCountry, gender, setGender,
  startDate, setStartDate, sortSearch, filterSearch, query, setQuery, sorting, resetSearch, startAge, setStartAge, endAge, setEndAge}) => {

    const [modalOpen, setModalOpen] = useState(false);
    const { mobileBig, mobile, isTablet, isDesktop } = useMatchMedia();

  const {t} = useTranslation(['translation', 'countries', 'int']);

  return (
    <div className="search_bar_container">
    <div className="SB_top_line">

      <div className="PSB_country">
          <SearchCountry
            country={country}
            setCountry={setCountry}
            isClearable={false}
            isMulti={false}
          />
       </div>

{!mobile&&  <>
    <div className="SB_date">
      <DatePicker
          className="SB_DateInput"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          minDate={new Date()}
          // isClearable
          placeholderText={t("afterDate", { ns: "translation" })}
          dateFormat="MMMM d, yyyy"
        />
    </div>


       <div className="PSB_Gender">
        <SearchGender
          gender={gender}
          setGender={setGender}
        />
       </div>

        <div className="SB_ages">
          <SearchBarAges
            startAge={startAge}
            setStartAge={setStartAge}
            endAge={endAge}
            setEndAge={setEndAge}
          />
        </div>
</>}
  <div className="SB_rightSide">

      <div className="PSB_resetBtn">
        {(Object.keys(query).length!==0||sorting||gender||startAge||endAge||startDate||country) &&
        <div className="search_filters SB_reset" onClick={resetSearch}>
          <img src={closeW} alt="" />
        </div>
        }
      </div>

      <div className="search_filters" onClick={()=>setModalOpen(true)}>
        {/* <img src={searchIcon} alt="" /> */}
        <IoSearch size={20} color='#87589C'/>
      </div>

      <PostSort
        search={sortSearch}
        sorting={sorting}
      />
  </div>



      <ModalWin noEsc={false} open={modalOpen} close={() => {
        document.body.classList.remove("active-modal");
        resetSearch();
        setModalOpen(false)
        }}>

      <PostSearchWin
        close={()=> {
            document.body.classList.remove("active-modal");
            setModalOpen(false);
            }}

        search={filterSearch}
      />

      </ModalWin>

    </div>
    <div className="SB_bottom_line">
    <div className="SB_date">
      <DatePicker
          className="SB_DateInput"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          minDate={new Date()}
          // isClearable
          placeholderText={t("afterDate", { ns: "translation" })}
          dateFormat="MMMM d, yyyy"
        />
    </div>

       <div className="PSB_Gender">
        <SearchGender
          gender={gender}
          setGender={setGender}
        />
       </div>

        <div className="SB_ages">
          <SearchBarAges
            startAge={startAge}
            setStartAge={setStartAge}
            endAge={endAge}
            setEndAge={setEndAge}
          />
        </div>

    </div>
    </div>
  );
};

export default PostSearchBar;
