import { useTranslation } from 'react-i18next';
import arrow from '../../assets/arrow_left_grey.svg'
import { useMatchMedia } from "../../hooks/use-match-media";

import './Pagination.scss'
const Pagination = ({found, pages, page, setPage, showAmnt, setAmnt, 
    startShowAmount:SSA, amountStep }) => {
    
    page=parseInt(page);
    const startValue = Math.floor((page-1)/5)*5;
    const lastPage = Math.floor((pages-1)/5)*5;
    const {t} = useTranslation(['tr']);
    const { mobile } = useMatchMedia();

    return (

    <div className="Pagination_wrapper">
        {mobile&& 
        <div className="PGN_top_line">
          <div className="PGN_found">
              <p><b>{t("Found",{ns:"tr"})}:</b> {found}</p>
          </div>

          <div className="PGN_showAmnt">
            <label> 
            {t("ShowOnPage",{ns:"tr"})}:
                 <select 
                value={showAmnt}
                onChange={e => setAmnt(e.target.value)}
                name="amount" 
                >
                    <option value={SSA}>{SSA}</option>
                    <option value={SSA+amountStep*1}>{SSA+amountStep*1}</option>
                    <option value={SSA+amountStep*2}>{SSA+amountStep*2}</option>
                    <option value={SSA+amountStep*3}>{SSA+amountStep*3}</option>
                    <option value={SSA+amountStep*4}>{SSA+amountStep*4}</option>
                </select>
            </label>
        </div>

        </div>
    }

      <div className="PGN_bottom_line">

      {!mobile&& 
        <div className="PGN_found">
            <p><b>{t("Found",{ns:"tr"})}:</b> {found}</p>
        </div>
      }

        <div className="PGN_pages">
        {(found>showAmnt)&&  <>  
            <button className="PGN_Left_arrow" disabled={page===1}
            onClick={()=>setPage(page=> page-1)}>
                <img src={arrow} alt="arrow" />
            </button>

            {(startValue!==0)&& <div onClick={()=>setPage(1)}
            className={page===1 ? "PGN_page PGN_active":"PGN_page"}>1</div>}

            {(startValue!==0)&&<p>...</p>}

            {[...Array(5)].map((_, idx)=>(

              <div key={startValue+idx+1} onClick={()=>setPage(startValue+idx+1)}
              style={pages< startValue+idx+1 ? {display:"none"}:null}
              className={page===startValue+idx+1 ? "PGN_page PGN_active":"PGN_page"}
              >{startValue+idx+1}</div>

            )
            )}
            {(startValue!==lastPage)&&<p>...</p>}

            {(startValue!==lastPage)&& <div onClick={()=>setPage(pages)}
              className={page===pages ? "PGN_page PGN_active":"PGN_page"}
              >{pages}</div>}

            <button className="PGN_Left_arrow PGN_Right_arrow" 
            disabled={page===pages} onClick={()=>setPage(page=>page+1)}>
                <img src={arrow} alt="arrow" />
            </button>
        </>}
        </div>
    {!mobile&& 
        <div className="PGN_showAmnt">
            <label> 
            {t("ShowOnPage",{ns:"tr"})}:
                 <select 
                value={showAmnt}
                onChange={e => setAmnt(e.target.value)}
                name="amount" 
                >
                    <option value={SSA}>{SSA}</option>
                    <option value={SSA+amountStep*1}>{SSA+amountStep*1}</option>
                    <option value={SSA+amountStep*2}>{SSA+amountStep*2}</option>
                    <option value={SSA+amountStep*3}>{SSA+amountStep*3}</option>
                    <option value={SSA+amountStep*4}>{SSA+amountStep*4}</option>
                </select>
            </label>
        </div>
    }

        </div>

    </div>

  )
}

export default Pagination