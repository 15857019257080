import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from "../../context/user.context";
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useMatchMedia } from "../../hooks/use-match-media";

import PageHeader from "../../components/PageHeader/PageHeader";
import SearchBar from "../../components/SearchBar/SearchBar";
import Tour from "../../components/Tour/Tour";
import Spinner from '../../components/Spinner/Spinner';
import OnboardingPage from '../OnboardingPage/OnboardingPage';

import Pagination from '../../components/Pagination/Pagination';
import MTour from '../../components/Tour/MTour';
import { handleError } from '../../utils/handleError';

import './ToursPage.scss'
const ToursPage = () => {
  const { jwtUser, setJwtUser, location } = useContext(UserContext);
  const [showOB, setShowOB] = useState(false);
  const [tours, setTours] = useState();
  const { mobile, mobileBig } = useMatchMedia();

  const { pageNumber } = useParams();

  const pageNum = pageNumber || 1;
  const startShowAmnt = 25

  const [page, setPage] = useState(pageNum);
  const [amount, setAmount] = useState(startShowAmnt);
  const [pages, setPages] = useState();
  const [total, setTotal] = useState();

  const [loading, setLoading] = useState(true);

  const [country, setCountry] = useState(null)
  const [intValue, setIntValue] = useState(null)
  const [startDate, setStartDate] = useState(null);

  const [search, setSearch] = useState({})
  const [sorting, setSorting] = useState("")

  const visited = localStorage.getItem('visited')

  const navigate = useNavigate();
  const searchDate = new Date(startDate).toISOString()
  const {t} = useTranslation(['tr']);

  const makeSearch=()=>{

    api.getTours(page, amount,
      search.country ? search.country: (country? country?.value: null),
      search.interests ? search.interests: (intValue? intValue?._id: null),
      startDate? searchDate: null,
      search.depart ? search.depart: null,
      search.city ? search.city: null,
      search.ppl ? search.ppl: null,
      search.gender ? search.gender: null,
      search.startAge ? search.startAge: null,
      search.endAge ? search.endAge: null,
      search.anyAge ? search.anyAge: null,
      search.startDate ? search.startDate: null,
      search.endDate ? search.endDate: null,
      search.noStartDate ? search.noStartDate: null,
      search.noEndDate ? search.noEndDate: null,
      search.daysAmount ? search.daysAmount: null,
      search.anyDates ? search.anyDates: null,
      search.tourPrice ? search.tourPrice: null,
      search.tourFee ? search.tourFee: null,
      search.pocketMoney ? search.pocketMoney: null,
      search.langs ? search.langs: null,
      sorting ? sorting: null,
      ).then((res) => {

        if(res.status===200){
          setTours(res.data.data)
          setPages(res.data.pages)
          // в "Найдено:" отсеиваем туры у которых удален организатор
          const deletedCreatorToursAmnt = res.data.data?.filter(tour=> tour?.creator?.deleted === true)?.length
          setTotal(res.data.total - deletedCreatorToursAmnt)
          setLoading(false)
          navigate(`/tours/${page}`)
        }else if(res?.response?.status===403){
          setLoading(false)
          setPage(1)
        }else{
          handleError(res)
        }
      });
  }

  useEffect(() => {
    if(!visited) {
      setShowOB(true)
    }
    }, [visited]);

  // useEffect(() => {
  //     api.getLangs().then((res) => {
  //       if(res.status===200){
  //         setAllLangs([...langs, ...res.data])
  //       }else{
  //         handleError(res)
  //       }
  //     })
  //   }, []);

  useEffect(() => {
      makeSearch();
  }, [page, amount, country,intValue, startDate, sorting, search]);

  const myId = jwtUser?.data?.user?._id
  const allowed_tours = tours?.filter(tour=>!tour?.blocked_users?.includes(myId))
  const toursWithOrg = allowed_tours?.filter(tour=> tour?.creator?.deleted !== true)

const resetSearch=()=>{
    setSearch({});
    setSorting("");
    setCountry(null)
    setIntValue(null)
    setStartDate(null)
}

const sortSearch= (sorting)=>{
  setPage(1)
  setSorting(sorting);
}
const filterSearch= (query)=>{
  setPage(1)
  setSearch(query)
}

if(loading){
    return <Spinner/>
}

  const fromCountry = location?.country;
  const fromCity= location?.city;


if (!visited) {
    localStorage.setItem('visited', 1)
    const newID = uuidv4();
    localStorage.setItem('UUID',newID)
    //-- Сохраняю нового посетителя
    api.setNewVisitorID(newID, fromCountry, fromCity )
  }

// console.log(uuidv4());

  return (
    <div className="ToursPage_wrapper">

      <PageHeader
      icon="tours"
      title={t("groupTours",{ns:"tr"})}
      text={t("groupTours_subtext",{ns:"tr"})}
      tooltip={t("groupTours_tooltip",{ns:"tr"})}
      />

      <SearchBar
        country={country}
        setCountry={setCountry}
        intValue={intValue}
        setIntValue={setIntValue}
        startDate={startDate}
        setStartDate={setStartDate}
        query={search}
        sorting={sorting}
        sortSearch={sortSearch}
        filterSearch={filterSearch}
        resetSearch={resetSearch}
      />

      <div className="TP_tours_box">
        {toursWithOrg?.map((tour, index) =>
          index < startShowAmnt &&(
            <div key={tour?._id}  >
              {mobile?
                <MTour tour={tour} />
              :
                <Tour
                  tour={tour}
                  jwtUser={jwtUser}
                  setJwtUser={setJwtUser}
                />
              }
            </div>
          ))
          }
        </div>

      <Pagination
        found={total}
        pages={pages}
        page={page}
        setPage={setPage}
        showAmnt={amount}
        setAmnt={setAmount}
        startShowAmount={startShowAmnt}
        amountStep={5}
      />

       {showOB && ( <OnboardingPage/> )}

    </div>
  );
};

export default ToursPage;
