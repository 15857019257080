import { useNavigate } from "react-router-dom";
import { thumb } from "../../utils/thumb";
import { age } from "../../utils/age";
import { config } from "../../utils/config";
import { useTranslation } from 'react-i18next';

import { ReactComponent as Verified } from "../../assets/verified.svg";

import './User.scss';
const User = ({user, online}) => {

  const navigate = useNavigate();
  const {t} = useTranslation(['translation', 'countries']);

  const navigateTo = (user)=>{
    navigate(`/user/${user?.username}/`)
  };

  let whenReady ='';
  if(user?.whenReady==="4_anyMoment") whenReady = "userReady_anyTime"
  if(user?.whenReady==="3_thisMonth") whenReady = "userReady_thisMonth"
  if(user?.whenReady==="2_thisYear") whenReady = "userReady_thisYear"

  return (

    <div className="user_wrapper" onClick={() => navigateTo(user)}>
      <div className="user_imgBG">
        <img src={thumb(user?.photos[0], config.thumb_width)} alt="user" loading="lazy"/>
      </div>
        {user?.verified&&<Verified className='user_verified'/>}
        {(user?.rating>0)&& <div className="user_rating">{user?.rating.toFixed(1)}</div> }

        <div className="user_text">
          <div className={`user_text_up ${whenReady}`}>
              <p><b>{user?.fName},</b> {age(user?.birthDate)} </p>
          </div>
          <span className="user_country">{t(user?.country, { ns: "countries" })}, {user?.city}</span>

        </div>

        {online && <div className="user_online"></div>}
    </div>

  )
}

export default User