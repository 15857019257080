import { useEffect, useState } from "react";
import * as api from '../../utils/api/api.utils'

import Spinner from "../Spinner/Spinner";
import ShowReview from "./ShowReview";
import { handleError } from "../../utils/handleError";
import TourForReview from "../Tour/TourForReview";

import './BigReview.scss'
const BigReview = ({tour, user}) => {
    const [reviews, setReviews] = useState();
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.getUserReviewsFromTour(tour?._id, user._id).then(res=> {
        if(res.status===200){
            setReviews(res.data)
            setLoading(false)
        }else{
            handleError(res)
        }
    })
  }, []);

  if(loading){
    return <Spinner/>
  }

  return (
    <div className="BigReview_wrapper">

        <div className="BR_top">
            <TourForReview tour={tour} />
        </div>

        <div className="BR_bottom">
            {reviews && reviews?.map((review, index)=>(
                <ShowReview key={index} review={review} />
                ))
            }
        </div>
    </div>
  )
}

export default BigReview