import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../context/user.context';
import { useTranslation } from 'react-i18next';
import * as api from "../../utils/api/api.utils";
import { handleError } from '../../utils/handleError';

import PageHeader from '../../components/PageHeader/PageHeader'
import News from '../../components/News/News';
import Spinner from '../../components/Spinner/Spinner';
import SystemNews from '../../components/News/SystemNews';

import './NewsPage.scss'
const NewsPage = () => {
  const { jwtUser} = useContext(UserContext);

  const [news, setNews] = useState();
  const [loading, setLoading] = useState(true);

  const {t} = useTranslation(['tr']);
  const show_amount = 30;

  const hash = useLocation().hash;
  const clean_uri = hash.split('#')[1];

  useEffect(() => {
    const el = document.getElementById(clean_uri);
    if (el && news) {
      el.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }, [hash, news, clean_uri]);

  useEffect(() => {
    if(jwtUser){
      api.getAllNews().then((res) => {
        if(res.status===200){
          setLoading(false)
          setNews(res.data);
        }else{
          handleError(res)
        }
      });
    }else{
      setLoading(false)
    }
  }, []);

  if(loading){
    return <Spinner/>
}

  return (
    <div className="NewsPage_wrapper">

        <PageHeader
        icon="news"
        title={t("news",{ns:"tr"})}
        // title="Лента новостей"
        text={t("news_text",{ns:"tr"})}
        // text="На которые вы подписались"
        btn="none"
      />

    <div className="News_contentBox">

      {news && news.map((n, index) =>
        index < show_amount &&  (
          <div key={n._id} id={n._id}>
            {n.type==="user" && <News news={n} /> }
            {n.type==="system" && <SystemNews news={n} /> }
          </div>
        ))
      }

      {!jwtUser && <SystemNews welcome/>}

    </div>

    </div>
  )
}

export default NewsPage