import React, { useState } from "react";
import { FaStar } from "react-icons/fa";

import "./Rating.scss";

const Rating = ({rating, setRating, size=20, readonly}) => {
  const [hover, setHover] = useState(null);

  if(readonly){
    return(
        <div className="ratingBox">
      {[...Array(5)].map((_, idx) => {
        const ratingValue = idx + 1;
        return (
            <FaStar
              size={size}
              color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
            />
        );
      })}
      {rating&&
      <h4> {rating}.0 </h4>
      }
    </div>
    )
  }


  return (
    <div className="ratingBox">
      {[...Array(5)].map((star, idx) => {
        const ratingValue = idx + 1;
        return (
          <label>
            <input type="radio" name="rating" value={ratingValue} onClick={() => setRating(ratingValue)} />
            <FaStar className="star" size={size}
              color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
              onMouseEnter={()=>setHover(ratingValue)}
              onMouseLeave={()=>setHover(null)}
            />
          </label>
        );
      })}
      <h5 className="ratingNum" style={{fontSize: size-8}}> {rating}.0 </h5>
    </div>
  );
};

export default Rating;
