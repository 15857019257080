import { useNavigate } from "react-router-dom";
import { config } from "../../utils/config";
import { thumb } from "../../utils/thumb";

import { ReactComponent as Good } from "../../assets/reviews/good.svg";
import { ReactComponent as Neutral } from "../../assets/reviews/neutral.svg";
import { ReactComponent as Bad } from "../../assets/reviews/bad.svg";

import RatingSml from "./RatingSml";

import "./ShowReview.scss";
const ShowReview = ({ review }) => {
  const navigate = useNavigate();

  return (
    <div className="ShowReview_wrapper">
      <div className="SR_top">
        <img onClick={() => navigate(`/user/${review?.fromUser?.username}/`)}
          src={thumb(review?.fromUser?.photos[0], config.user_photo_width)}
          alt="reviewer"
        />

        <div className="SR_userInfo">
          <p> {review?.fromUser?.fName}</p>

          {review.rating && <RatingSml rating={review.rating} />}

          {review?.mark && (
            <div className="SR_smileBox">
              {review?.mark === "good" && <Good className="SR_smile" />}
              {review?.mark === "neutral" && <Neutral className="SR_smile" />}
              {review?.mark === "bad" && <Bad className="SR_smile" />}
            </div>
          )}
        </div>
      </div>

    <div className="SR_bottom">
        <h3><q>{review.text}</q></h3>
    </div>

    </div>
  );
};

export default ShowReview;
