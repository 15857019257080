import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


import Logo from '../../assets/tripico_logo.svg'
import Pic_3 from '../../assets/onboarding/ob_3.png'
import left_arrow from '../../assets/arrow_left_grey.svg'


import './OBPScreens.scss'

const OBPScreen3 = ({close, back, next}) => {
    const navigate = useNavigate();
    const {t} = useTranslation(['tr']);

    const toLogin =()=>{
        navigate('/login')
        close()
    }

  return (
    <div className="OBP_Screen1">

    <div className="OBP_header">
        <img src={Logo} alt="Tripico Logo" />
        <p><b>3</b> / 3</p>
    </div>

    <div className="OBP_body">
        <div className="OBP_pic_area">
        <img className='OBP_pic' src={Pic_3} alt="Onboarding" />
        </div>

        <div className="OBP_body_right">
            <p>{t("OnB_P3",{ns:"tr"})} <br/> {t("OnB_P3_text_1",{ns:"tr"})}</p>
            <span>{t("OnB_P3_text_2",{ns:"tr"})}
            <br/>
            <br/>{t("OnB_P3_text_3",{ns:"tr"})}</span>

            <div className="OBP_Login" onClick={toLogin}>
                <h5>{t("Login",{ns:"tr"})}</h5>
            </div>

        </div>
    </div>

    <div className="OBP_bottom">
        <span onClick={close}>{t("Skip",{ns:"tr"})}</span>
        <div className="OBP_Buttom_Btns">
        <div className="OBP_back_btn_area">

        <div className="OBP_back_btn" onClick={back}>
            <img src={left_arrow} alt="arrow" />
            <p>{t("Back",{ns:"tr"})}</p>
        </div>

        </div>
        <div className="OBP_next_btn CTA_btn" onClick={next}>
            <p>{t("ph_createAccount",{ns:"tr"})}</p>
        </div>
        </div>
    </div>
  </div>
  )
}

export default OBPScreen3