import { PiArrowFatLineRightFill } from "react-icons/pi";

import './MsgBtnSml.scss'
const ActionsBtnSml = ({onClick}) => {
  return (
    <div className="MsgBtnUP ActionsBtn" onClick={onClick}>
        <PiArrowFatLineRightFill size={22} color="#fff" />
    </div>
  )
}

export default ActionsBtnSml