import { Suspense, useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';

import * as api from '../../utils/api/api.utils'

import InputField from "../../components/Input/InputField";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useFormik } from "formik";
import { validationSchema } from "../../utils/validations/verification_Yup.js";

import RadioBtn from "../../components/Input/RadioBtn";
import idCard from '../../assets/verification/id_card.jpg'

import "./VerificationPage.scss";
import AddImage from "../../components/AddImage/AddImage";
import Modal from "../../components/Modal/Modal";
import InfoWindow from "../../components/Window/InfoWindow";
import InputCountry from "../../components/Input/InputCountry";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import { handleError } from "../../utils/handleError";
import MainBtn from "../../components/Buttons/MainBtn";

const VerificationPage = () => {
  const { jwtUser } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [serverError, setServerError] = useState();
  const [sending, setSending] = useState(false);

    const navigate = useNavigate();
    const {t} = useTranslation(['tr']);


    const formik = useFormik({initialValues: {
        fName: "",
        lName: "",
        birthDate: "",
        gender: "",
        country: '',
        city: '',
        address: '',
        passport: '',
        phone: '',
        doc_photo_1:[],
        doc_photo_2:[],
        doc_photo_3:[],

      },
      validationSchema: validationSchema ,
      onSubmit: (values) => {
          // console.log('Formik values', values);
      },
    });

    useEffect(() => {
        if(Object.keys(formik.errors).length === 0){
          setServerError("")
        }
    }, [formik.errors]);


    const handleSubmit = async(e)=>{
        e.preventDefault();
        formik.handleSubmit();
        setServerError(null)

        try {
          const formData = new FormData();
          formData.append("v_fName", formik.values.fName);
          formData.append("v_lName", formik.values.lName);
          formData.append("v_birthDate", formik.values.birthDate);
          formData.append("v_gender", formik.values.gender);

          formData.append("v_country", formik.values.country);
          formData.append("v_city", formik.values.city);
          formData.append("v_address", formik.values.address);

          formData.append("v_passport", formik.values.passport);
          formData.append("v_phone", formik.values.phone);
          formData.append("v_data", Date.now());

        formData.append("doc_img", formik.values.doc_photo_1?.[0].source);
        formData.append("doc_img", formik.values.doc_photo_2?.[0].source);
        formData.append("doc_img", formik.values.doc_photo_3?.[0].source);

          if(formik.isValid){
            setSending(true)

            const res = await api.verifyUser(jwtUser.data.user._id, formData)
            if (res?.status === 200) {
              setModalOpen(true);
            }else{
              throw res
            }
          }else{
            console.log("Forms are not correct")
            setServerError(t("fillAllFields",{ns:"tr"}))
          }
      } catch (error) {
        handleError(error)
        setServerError(error.response.message)
      }
    }
//---- Элемент Info Window ----------------
      const onModalClose=()=>{
        setModalOpen(false)
        document.body.classList.remove("active-modal");
        navigate('/');
      }
//------------------------------------------

      if(!jwtUser){
        return <Navigate to={`/login`} />
    }

      if(jwtUser.data.user.verified){
        return (

            <div className="VerificationPage_wrapper">
            <PageHeader
              icon="verification"
              title={t("VerificationPage",{ns:"tr"})}
              text={t("VerificationPage_text",{ns:"tr"})}
            />

            <div className="VP_content_box">

            <h4>{t("youAreVerified",{ns:"tr"})}</h4>

            </div>
            </div>

        )
    }


  return (
    <div className="VerificationPage_wrapper">

    {sending && <LoadingScreen/>}

      <PageHeader
        icon="verification"
        title={t("VerificationPage",{ns:"tr"})}
        text={t("VerificationPage_text",{ns:"tr"})}
      />

        <form onSubmit={handleSubmit}>

      <div className="VP_content_box">



            <div className="VP_title_area">
            {jwtUser?.data?.user?.v_deny_reason&&
            <h6>{t("lastVerificationFailed",{ns:"tr"})} <b> {t("reason",{ns:"tr"})}:</b> "{jwtUser?.data?.user?.v_deny_reason}"</h6>
            }
              <p>{t("personalData",{ns:"tr"})}</p>
              <h3>{t("ID_info",{ns:"tr"})}
              <br/>{t("ID_info2",{ns:"tr"})}</h3>
              <h5>{t("ID_info3",{ns:"tr"})}</h5>
            </div>

          <div className="VP_top_content_box">


            <div className="VP_IdCard_box">
                <img src={idCard} alt="" />
            </div>


            <div className="VP_inputDiv_Left">
            <InputField
                title={t("firstName",{ns:"translation"})}
                icon="name"
                type="text"
                placeholder={t("yourName",{ns:"translation"})}
                name="fName"
                {...formik.getFieldProps('fName')}
            />
        <div className="invalid-feedback">
          {formik.errors.fName && formik.touched.fName
           ? formik.errors.fName : null}
        </div>
        </div>

        <div className="VP_inputDiv_Left">
            <InputField
                title={t("lastName",{ns:"translation"})}
                icon="name"
                type="text"
                placeholder={t("yourLastName",{ns:"translation"})}
                name="lName"
                {...formik.getFieldProps('lName')}
            />
        <div className="invalid-feedback">
            {formik.errors.lName && formik.touched.lName
            ? formik.errors.lName : null}
        </div>
        </div>

        <div className="VP_inputDiv_Left">
            <InputField
                title={t("birthDate", { ns: "translation" })}
                icon="date"
                type="date"
                placeholder={t("birthDate", { ns: "translation" })}
                name="birthDate"
                {...formik.getFieldProps('birthDate')}
            />
        <div className="invalid-feedback">
            {formik.errors.birthDate && formik.touched.birthDate
            ? formik.errors.birthDate : null}
        </div>
        </div>


        <div className="VP_inputDiv">
            <RadioBtn
            title={t("yourGender", { ns: "translation" })}
            male={t("Male", { ns: "translation" })}
            female={t("Female", { ns: "translation" })}
            icon="gender"
            name="gender"
            onChange={formik.handleChange}
            />
            <div className="invalid-feedback">
                {formik.errors.gender && formik.touched.gender
                ? formik.errors.gender : null}
            </div>
        </div>

        <div className="VP_inputDiv">
        <Suspense fallback="Loading...">
            <InputCountry
                title={t("country", { ns: "translation" })}
                icon="country"
                placeholder={t("yourCountry", { ns: "translation" })}
                tooltip={t("country_tooltip", { ns: "translation" })}
                name="country"
                onChange={(value)=>formik.setFieldValue("country", value.value)}
                onBlur={()=>formik.setFieldTouched("country")}
                value={formik.values.country}
            />
        </Suspense>

        <div className="invalid-feedback">
            {formik.errors.country && formik.touched.country
            ? formik.errors.country : null}
        </div>
        </div>

        <div className="VP_inputDiv">
              <InputField
                title={t("city",{ns:"translation"})}
                icon="country"
                type="text"
                tooltip={t("city_tooltip",{ns:"translation"})}
                placeholder={t("yourCity",{ns:"translation"})}
                name="city"
                {...formik.getFieldProps('city')}
               />
        <div className="invalid-feedback">
            {formik.errors.city && formik.touched.city
            ? formik.errors.city : null}
        </div>
    </div>

        <div className="VP_inputDiv_long">
              <InputField
                title={t("yourAdress",{ns:"tr"})}
                icon="country"
                type="text"
                tooltip={t("yourAdress_tt",{ns:"tr"})}
                placeholder={t("yourAdress_ph",{ns:"tr"})}
                name="address"
                {...formik.getFieldProps('address')}
               />
        <div className="invalid-feedback">
            {formik.errors.address && formik.touched.address
            ? formik.errors.address : null}
        </div>
    </div>

        <div className="VP_inputDiv">
              <InputField
                title={t("idNum",{ns:"tr"})}
                icon="passport"
                type="text"
                tooltip={t("idNum_tt",{ns:"tr"})}
                placeholder={t("idNum_ph",{ns:"tr"})}
                name="passport"
                {...formik.getFieldProps('passport')}
               />
        <div className="invalid-feedback">
            {formik.errors.passport && formik.touched.passport
            ? formik.errors.passport : null}
        </div>
    </div>

        <div className="VP_inputDiv">
              <InputField
                title={t("mobileNum",{ns:"tr"})}
                icon="phone"
                type="tel"
                tooltip={t("mobileNum_tt",{ns:"tr"})}
                placeholder={t("mobileNum_ph",{ns:"tr"})}
                name="phone"
                {...formik.getFieldProps('phone')}
               />
        <div className="invalid-feedback">
            {formik.errors.phone && formik.touched.phone
            ? formik.errors.phone : null}
        </div>
    </div>


          </div>

          <div className="VP_title_area">
              <p>{t("ID_photo",{ns:"tr"})}</p>
              <h3>{t("ID_photo_text1",{ns:"tr"})}
              <br/>{t("ID_photo_text2",{ns:"tr"})}</h3>
            </div>

          <div className="VP_bottom_content_box">

          <div className="VP_doc_photo">
            <AddImage
            title={t("addID_t1",{ns:"tr"})}
            title2={t("addID_t2",{ns:"tr"})}
            subtitle={t("addID_st",{ns:"tr"})}
            tooltip={t("addID_tt",{ns:"tr"})}
            formik={formik}
            name="doc_photo_1"
            />

            <div className="invalid-feedback">
                {formik.errors.doc_photo_1 && formik.touched.doc_photo_1
                ? formik.errors.doc_photo_1 : null}
            </div>

         </div>

          <div className="VP_doc_photo">
            <AddImage
            title={t("addID2_t1",{ns:"tr"})}
            title2={t("addID2_t2",{ns:"tr"})}
            subtitle={t("addID2_st",{ns:"tr"})}
            tooltip={t("addID2_tt",{ns:"tr"})}
            formik={formik}
            name="doc_photo_2"
            />

            <div className="invalid-feedback">
                {formik.errors.doc_photo_2 && formik.touched.doc_photo_2
                ? formik.errors.doc_photo_2 : null}
            </div>
         </div>

          <div className="VP_doc_photo">
            <AddImage
            title={t("addID3_t1",{ns:"tr"})}
            subtitle={t("addID3_st",{ns:"tr"})}
            tooltip={t("addID3_tt",{ns:"tr"})}
            formik={formik}
            name="doc_photo_3"
            />
            <div className="invalid-feedback">
                {formik.errors.doc_photo_3 && formik.touched.doc_photo_3
                ? formik.errors.doc_photo_3 : null}
            </div>
         </div>

          </div>

      </div>

          <div className="VP_send_btn_line">

            {serverError &&
                <div className="VP_notValid_error">
                <p>{serverError}</p>
                </div>
            }

            <MainBtn
                color='purp'
                type="submit"
                title={t("Send",{ns:"tr"})}
                disabled={!serverError && formik.isSubmitting}
                onClick={()=>{}}
            />

            </div>

        </form>

        <Modal open={modalOpen} close={() => setModalOpen(false)} NoCloseBtn={true} >

            <InfoWindow
            close={onModalClose}
            title={t("Information",{ns:"tr"})}
            info={t("itCanTake24hours",{ns:"tr"})}
            />

    </Modal>


    </div>
  );
};

export default VerificationPage;
