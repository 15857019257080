import { useState } from 'react';
import { ReactComponent as FavHeart} from '../../assets/heart_2.svg'

import './FavButton.scss'

const TourFavBtnEmpty = () => {
    const [pressed, setPressed] = useState(false);

    const likeAction = async()=>{
      if(pressed){
      //-- Unlike
        setPressed(false)
      }else{
      //-- Like
      setPressed(true);
      }
    }

  return (
    <div onClick={likeAction}
    className={pressed ? 
    "FavButton TourFavButton  TourFavButton_clicked" :
     "FavButton TourFavButton"}>

        <FavHeart className="FavHeart" />
    </div>
  )
}

export default TourFavBtnEmpty