import { Outlet } from 'react-router-dom';

import Header from './components/Header/Header';
import Menu from './components/Menu/Menu';
import Advice from './components/Advices/Advice';
import NewUsers from './components/User/NewUsers';
import NewTours from './components/Tour/NewTours';
import Footer from './components/Footer/Footer';

import MobFooter from './components/Footer/MobFooter';

import "./App.scss";
function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <Header />

        <div className="under_header"></div>

      <div className="app_container">

        <div className="menubar">
          <Menu/>
          <Advice/>
        </div>

        <div className="content">
        <Outlet />

        </div>

        <div className="sidebar">
          <NewUsers/>
          <NewTours/>
        </div>

      </div>

      <div className="MobFooter">
        <MobFooter/>
      </div>

      <div className="footer">
        <Footer/>
      </div>

      </div>
    </div>
  );
}

export default App;
