
import MsgIcon from '../../assets/msg_purp.svg'

import './MsgBtnSml.scss'

const MsgBtnSml = ({onClick}) => {
  return (
    <div className="MsgBtnSml" onClick={onClick}>
        <img src={MsgIcon} alt="" />
    </div>
  )
}

export default MsgBtnSml