import { useState } from 'react';
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

// import searchIcon from '../../assets/searchFilter.svg'
import { IoSearch } from "react-icons/io5";

import closeW from '../../assets/close_white.svg'

import "./SearchBar.scss";
import TourSearchWin from '../Window/TourSearchWin';
import SearchInt from '../AddInterest/SearchInt';
import SearchCountry from '../Input/SearchCountry';
import TourSort from '../Sort/TourSort';
import ModalWin from '../Modal/ModalWin';

const SearchBar = ({country, setCountry, intValue, setIntValue,
  startDate, setStartDate, sortSearch, filterSearch, query, setQuery, sorting, resetSearch}) => {

    const [modalOpen, setModalOpen] = useState(false);

    const { mobileBig, mobile, isTablet, isDesktop } = useMatchMedia();

    const {t} = useTranslation(['translation', 'countries', 'int']);

  return (
    <div className="search_bar_container">
    <div className="SB_top_line">
      <div className="SB_country">
          <SearchCountry
            country={country}
            setCountry={setCountry}
            isClearable={false}
            isMulti={false}
          />
       </div>

{!mobile&&
      <div className="SB_int">
          <SearchInt
            intValue={intValue}
            setIntValue={setIntValue}
            placeholder={t("chooseInt", { ns: "int" })}
            isClearable={false}
            isMulti={false}
          />
      </div>
}

{!mobileBig&&
      <div className="SB_date">
      <DatePicker
          className="SB_DateInput"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          minDate={new Date()}
          // isClearable
          placeholderText={t("afterDate", { ns: "translation" })}
          dateFormat="MMMM d, yyyy"
        />
    </div>
}


  <div className="SB_rightSide">
    <div className="PSB_resetBtn">
        {(Object.keys(query).length!==0|| sorting||startDate||country||intValue) &&
          <div className="search_filters SB_reset" onClick={resetSearch}>
            <img src={closeW} alt="" />
          </div>
        }
      </div>

      <div className="search_filters" onClick={()=>setModalOpen(true)}>
        {/* <img src={searchIcon} alt="" /> */}
        <IoSearch size={20} color='#87589C'/>
      </div>

      <TourSort
        search={sortSearch}
        sorting={sorting}
      />
  </div>

      <ModalWin noEsc={true} open={modalOpen} close={() => {
        document.body.classList.remove("active-modal");
        resetSearch();
        setModalOpen(false)
        }}>

      <TourSearchWin
        close={()=> {
            document.body.classList.remove("active-modal");
            setModalOpen(false);
            }}

        search={filterSearch}
      />

      </ModalWin>


      </div>
    <div className="SB_bottom_line">
      <div className="SB_bottom_Int">
            <SearchInt
              intValue={intValue}
              setIntValue={setIntValue}
              placeholder={t("chooseInt", { ns: "int" })}
              isClearable={false}
              isMulti={false}
            />
        </div>
        <div className="SB_date">
          <DatePicker
              className="SB_DateInput"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              minDate={new Date()}
              // isClearable
              placeholderText={t("afterDate", { ns: "translation" })}
              dateFormat="MMMM d, yyyy"
        />
    </div>
    </div>

    </div>
  );
};

export default SearchBar;
