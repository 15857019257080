import { Navigate } from 'react-router-dom'
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/user.context";
import * as api from '../../utils/api/api.utils'
import { langs } from "../../data/languages.js";
import { interests } from "../../data/interests";
import { useTranslation } from 'react-i18next';

import PageHeader from '../../components/PageHeader/PageHeader'
import Spinner from "../../components/Spinner/Spinner";

import './FavoritesPage.scss'
import FavPostsPage from '../FavoritesPages/FavPostsPage';
import FavPeoplePage from '../FavoritesPages/FavPeoplePage';
import FavTripsPage from '../FavoritesPages/FavTripsPage';
import { handleError } from '../../utils/handleError';

const FavoritesPage = () => {
  const { jwtUser, setJwtUser } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [allLangs, setAllLangs] = useState([...langs]); //-- All langs
  const [allInterests, setAllInterests] = useState([...interests]);

  const [posts, setPosts] = useState();
  const [tours, setTours] = useState();
  const [users, setUsers] = useState();

  const [loading, setLoading] = useState(true);
  const {t} = useTranslation(['tr']);

  useEffect(() => {

    api.findUsersByIdArray(jwtUser?.data?.user?.i_liked_user).then(res=> {
      if (res?.status === 200) {
        setLoading(false)
        setUsers(res.data)
      }else{
        handleError(res)
      }
    })

    api.findPostsByIdArray(jwtUser?.data?.user?.i_liked_post).then(res=> {
      if (res?.status === 200) {
        setPosts(res.data)
      }else{
        handleError(res)
      }
    })

    api.findToursByIdArray(jwtUser?.data?.user?.i_liked_tour).then(res=> {
      if (res?.status === 200) {
        setTours(res.data)
      }else{
        handleError(res)
      }
    })

    api.getLangs().then((res) => {
      if (res?.status === 200) {
        setAllLangs([...langs, ...res.data])
      }else{
        handleError(res)
      }
    });

    api.getInterests().then((res) => {
      if (res?.status === 200) {
        setAllInterests([...interests, ...res.data])
      }else{
        handleError(res)
      }
    });

  }, []);



  if(!jwtUser){
    return <Navigate to={`/login`} />
}

  if(loading){
    return <Spinner/>
  }

  return (
    <div className="FavoritesPage_wrapper">

        <PageHeader
        icon="fav"
        title={t("favorites",{ns:"tr"})}
        // title="Избранное"
        text={t("fav_text",{ns:"tr"})}
        // text="Нажали на сердечко? Это тут!"
        btn="none"
      />

<div className="FavPage_NavBar">

  <div
  onClick={()=>setPage(0)}
  className={page===0 ? "Fav_Item F_active_l":"Fav_Item"}>
  {t("people",{ns:"tr"})}: {users?.length}</div>

  <div
  onClick={()=>setPage(1)}
  className={page===1 ? "Fav_Item F_active_l":"Fav_Item"}>
  {t("trips",{ns:"tr"})}: {tours?.length} </div>

  <div
  onClick={()=>setPage(2)}
  className={page===2 ? "Fav_Item F_active_l":"Fav_Item"}>
  {t("adverts",{ns:"tr"})}:{posts?.length} </div>

</div>

{page===0 && <FavPeoplePage users={users} />}

{page===1 &&
<FavTripsPage
  tours={tours}
  allLangs={allLangs}
  jwtUser={jwtUser}
  setJwtUser={setJwtUser}
  />}

{page===2 &&
<FavPostsPage
  posts={posts}
  allLangs={allLangs}
  allInterests={allInterests}
  jwtUser={jwtUser}
  setJwtUser={setJwtUser}
  />}

    </div>
  )
}

export default FavoritesPage