import { useTranslation } from 'react-i18next';

import User from "../User/User";
import { ReactComponent as Good } from "../../assets/reviews/good.svg";
import { ReactComponent as Neutral } from "../../assets/reviews/neutral.svg";
import { ReactComponent as Bad } from "../../assets/reviews/bad.svg";

import "./UserReview.scss";

const UserReview = ({review, index, usersReviews, setUsersReviews}) => {
  const {t} = useTranslation(['tr']);


    const handleMark=(index, mark)=>{
        const updatedReviews = [...usersReviews];
        updatedReviews[index].mark=mark;
        setUsersReviews(updatedReviews);
      }
      
      const handleChangeInput=(index, e)=>{
        const updatedReviews = [...usersReviews];
        updatedReviews[index][e.target.name]=e.target.value;
        setUsersReviews(updatedReviews);
      }

  return (
    <div className="UserReview_wrapper">
      <div className="URP_Review">
        <User user={review.user} />

        <div className="URP_userReview_right">
          <div className="URP_smiles_block">
            <p>{t("Mark",{ns:"tr"})}:</p>
            <div
              onClick={() => handleMark(index, "bad")}
              className={
                usersReviews[index]?.mark === "bad"
                  ? "URP_smile URP_smileIcon_active"
                  : "URP_smile"
              }
            >
              <Bad className="URP_smileIcon" />
            </div>
            <div
              onClick={() => handleMark(index, "neutral")}
              className={
                usersReviews[index]?.mark === "neutral"
                  ? "URP_smile URP_smileIcon_active"
                  : "URP_smile"
              }
            >
              <Neutral className="URP_smileIcon" />
            </div>
            <div
              onClick={() => handleMark(index, "good")}
              className={
                usersReviews[index]?.mark === "good"
                  ? "URP_smile URP_smileIcon_active"
                  : "URP_smile"
              }
            >
              <Good className="URP_smileIcon" />
            </div>
          </div>

          <textarea
            className="URP_input"
            type="text"
            name="text"
            value={review.text}
            onChange={(e) => handleChangeInput(index, e)}
          />
        </div>
      </div>
    </div>
  );
};

export default UserReview;
