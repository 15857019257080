import { useTranslation } from 'react-i18next';

import ages_icon from '../../assets/ages.svg'
import TooltipPurp from "../Tooltip/TooltipPurp";

import "./AgesField.scss";
const AgesField = ({ title, icon, tooltip, formik, }) => {
  const {t} = useTranslation(['translation']);

    let img;
    if(icon==="ages") {img = ages_icon};

  return (
    <div className="AgesField_container">
      <div className="AF_title_line">
        <div className="AF_title_sign">
          <img src={img} alt="" />
          <p>{title}</p>
        </div>
        {tooltip && <TooltipPurp text={tooltip}/> }
      </div>

         <div className="AgesField_box">

         <p>{t("from", {ns:"translation"})}</p>

     <input
      className="AgesField_input"
      type="number"
      inputmode="numeric"
      min="18"
      max="99"
      name="startAge"
      disabled={formik.values.anyAge===true}
      {...formik.getFieldProps('startAge')}
     />

     <p> - </p>

     <input
      className="AgesField_input"
      type="number"
      inputmode="numeric"
      min="18"
      max="99"
      name="endAge"
      disabled={formik.values.anyAge===true}
      {...formik.getFieldProps('endAge')}
     />

    <p>{t("years", {ns:"translation"})}</p>

         </div>


         <label className="AF_checkbox_label" >
         {t("anyAge", {ns:"translation"})}
          <input
          type="checkbox"
          id="anyAge"
          name="anyAge"
          checked={formik.values.anyAge}
          {...formik.getFieldProps('anyAge')}
          />
          <span className="AF_checkmark"></span>
      </label>

    </div>
  );
};

export default AgesField;
