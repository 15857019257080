import { UserContext } from "../../context/user.context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";


import { ReactComponent as CreatePost } from "../../assets/Menu/createpost.svg";
import { ReactComponent as Tours } from "../../assets/Menu/tours.svg";
import { ReactComponent as Posts } from "../../assets/Menu/posts.svg";
import { ReactComponent as People } from "../../assets/Menu/people.svg";
import { ReactComponent as Society } from "../../assets/Menu/community.svg";
import { ReactComponent as Msg } from "../../assets/Menu/msg.svg";
import { ReactComponent as Fav } from "../../assets/Menu/heart.svg";
import { ReactComponent as News } from "../../assets/Menu/news.svg";
import { ReactComponent as Info } from "../../assets/Menu/info.svg";
import { ReactComponent as Create } from "../../assets/create_account/create_account_logo.svg";
import { ReactComponent as Login } from "../../assets/Menu/login.svg";
import { ReactComponent as Balance } from "../../assets/Menu/big_dollar.svg";
import { ReactComponent as Edit } from "../../assets/Menu/edit.svg";
import { ReactComponent as Admin } from "../../assets/Menu/admin.svg";
import { ReactComponent as Verification } from "../../assets/Menu/verification.svg";
import { ReactComponent as Control } from "../../assets/Menu/control.svg";
import { ReactComponent as Reviews } from "../../assets/Menu/reviews.svg";
import { ReactComponent as Bell } from "../../assets/Menu/Bell_big.svg";
import { ReactComponent as Settings } from "../../assets/user_menu/settings_icon.svg";

import { ReactComponent as Plus } from "../../assets/plus.svg";

import TooltipGrey from "../Tooltip/TooltipGrey";
import YouTubeBtn from "../Buttons/YouTubeBtn";
import { useContext, useState } from "react";
import Modal from "../Modal/Modal";
import FeedBack from "../FeedBack/FeedBack";

import "./PageHeader.scss";
const PageHeader = ({icon, title, text, tooltip, visitors}) => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const navigate = useNavigate();
  const {t} = useTranslation(['translation']);
  const { jwtUser, onlineUsers} = useContext(UserContext);

  const {mobileBig } = useMatchMedia();

  const isAdmin = jwtUser?.data?.user?.isAdmin;

  return (
    <div className="page_header">
      <div className="header_title_section">

        {(icon==="createtour")&& <Tours className="big_icon" />}
        {(icon==="tours")&& <Tours className="big_icon" />}
        {(icon==="posts")&& <Posts className="big_icon" />}
        {(icon==="people")&& <People className="big_icon" />}
        {(icon==="society")&& <Society className="big_icon" />}
        {(icon==="msg")&& <Msg className="big_icon" />}
        {(icon==="fav")&& <Fav className="big_icon" />}
        {(icon==="news")&& <News className="big_icon" />}
        {(icon==="info")&& <Info className="big_icon" />}
        {(icon==="create")&& <Create className="purple_icon" />}
        {(icon==="login")&& <Login className="purple_icon" />}
        {(icon==="balance")&& <Balance className="purple_icon" />}
        {(icon==="edituser")&& <Edit className="purple_icon" />}
        {(icon==="edittour")&& <Edit className="purple_icon" />}
        {(icon==="verification")&& <Verification className="purple_icon" />}
        {(icon==="admin")&& <Admin className="purple_icon" />}
        {(icon==="control")&& <Control className="purple_icon" />}
        {(icon==="reviews")&& <Reviews className="purple_icon" />}
        {(icon==="createpost")&& <CreatePost className="purple_icon" />}
        {(icon==="notifications")&& <Bell className="purple_icon" />}
        {(icon==="settings")&& <Settings className="purple_icon" />}

        <div className="header_title">
          <p>{title}</p>
          <span>{text}</span>
        </div>
      </div>

      {tooltip && <TooltipGrey text={tooltip}/>}

      {!mobileBig&&
      <div className="header_action">

      {(icon==="create")&&
      <div className="Register_action_block">
        <p>{t("ph_alreadyHaveAcc",{ns:"tr"})}</p>
        <h5 onClick={()=>navigate('/login')}>{t("ph_login",{ns:"tr"})}</h5>
      </div>
      }

      {(icon==="login")&&
      <div className="Login_action_block">
        <button onClick={()=>navigate('/register')}>
        <h3>{t("ph_createAccount",{ns:"tr"})}</h3>
        {/* <h3>Создать аккаунт</h3> */}
        </button>
      </div>
      }

      {(icon==="balance")&&
      <div className="Login_action_block">
        <button onClick={()=>navigate('/topup')}>{t("ph_topUp",{ns:"tr"})}</button>
      </div>
      }

      {(icon==="posts")&&
      <div className="Login_action_block">
        <button onClick={()=>navigate('/createpost')}>
        <Plus/>
        <p>{t("ph_createPost",{ns:"tr"})}</p>
        </button>
      </div>
      }

      {(icon==="tours")&&
      <div className="Login_action_block">
        <button onClick={()=>navigate('/createtour')}>
        <Plus/>
        <p>{t("ph_createTour",{ns:"tr"})}</p>
        </button>
      </div>
      }

      {(icon==="info")&&
      <div className="Login_action_block LAB_help">
        <button onClick={()=> setFeedbackOpen(true)}>
        <p>{t("sendFeedback",{ns:"translation"})}</p>
        </button>
      </div>
      }

      {(icon==="createtour")&&
      <div className="Login_action_block">
        <YouTubeBtn
        title={t("ph_videoInstructions",{ns:"tr"})}
        link='https://youtu.be/g2yhOPsfIlI'
        />
      </div>
      }

      </div>

    }

    {icon==="people" && isAdmin &&
      <div className="Moderation_block">
        <p><b>Online:</b> {onlineUsers?.length}</p>
        {/* <p><b>{t("ph_uniqueVisitors",{ns:"tr"})}:</b> {visitors}</p> */}
      </div>
    }

    {(icon==="admin")&&
      <div className="Moderation_block">
        <p><b>Online:</b> {onlineUsers?.length}</p>
        <p><b>{t("ph_uniqueVisitors",{ns:"tr"})}:</b> {visitors}</p>
      </div>
    }

<Modal
  open={feedbackOpen}
  close={() => setFeedbackOpen(false)}
  NoCloseBtn={true}
  >
  <FeedBack
    close={()=> {
      document.body.classList.remove("active-modal");
      setFeedbackOpen(false)}}
  />
</Modal>

    </div>
  );
};

export default PageHeader;
