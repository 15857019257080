import { useOutletContext } from "react-router-dom";
import { useState, useEffect} from "react";
import { useTranslation } from 'react-i18next';

import * as api from '../../utils/api/api.utils'

import User from "../../components/User/User";
import './Followers.scss'
import { handleError } from "../../utils/handleError";

const Followers = () => {
  const [iFollow, setIFollow] = useState([]);
  const [myFollowers, setMyFollowers] = useState([]);

  const { user, setUser } = useOutletContext();
  const {t} = useTranslation(['tr']);

  useEffect(() => {
    api.findUsersByIdArray(user?.i_follow).then(res=> {
      if (res?.status === 200) {
        setIFollow(res.data)
      }else{
        handleError(res)
      }
    })

    api.findUsersByIdArray(user?.my_followers).then(res=> {
      if (res?.status === 200) {
        setMyFollowers(res.data)
      }else{
        handleError(res)
      }
    })
  }, []);

  useEffect(() => {
    api.findUsersByIdArray(user?.i_follow).then(res=> {
      if (res?.status === 200) {
        setIFollow(res.data)
      }else{
        handleError(res)
      }
    })
    api.findUsersByIdArray(user?.my_followers).then(res=> {
      if (res?.status === 200) {
        setMyFollowers(res.data)
      }else{
        handleError(res)
      }
    })

  }, [user]);

  if(iFollow.length===0 && myFollowers.length===0){
    return (
      <div className="UP_noPageInfo">
        <h4>{t("emptyHere",{ns:"tr"})}!</h4>
        <h3>{t("upF_emptyText",{ns:"tr"})}!</h3>
      </div>
    )
  }


  return (
    <div className="UP_Followers_container">
    {/* {window.scrollTo({top:0, behavior: 'smooth'})} */}

    {iFollow.length!==0 &&
    <div className="UP_I_Follow">
    <div className="UP_Followers_header">
    <p><b>{t("upF_IFollow",{ns:"tr"})}:</b> {iFollow?.length}</p>
    </div>

      <div className="UP_Followers_users ">
    {
    iFollow.map((user) => (
      <div key={user._id}>
        <User user={user} />
      </div>
    ))
    }
     </div>
    </div>
    }

    {myFollowers.length!==0 &&
    <div className="UP_MyFollowers">
    <div className="UP_Followers_header">

          <p><b>{t("upF_MyFollowers",{ns:"tr"})}:</b> {myFollowers?.length}</p>
      </div>
      <div className="UP_Followers_users ">
    {myFollowers.length!==0 &&
      myFollowers.map((user) => (
      <div key={user._id}>
        <User user={user} />
      </div>
    ))
    }
      </div>

    </div>
    }




    </div>
  )
}

export default Followers


