import { useContext } from 'react';
import { UserContext } from '../../context/user.context';
import { useNavigate } from 'react-router-dom'
import follow from '../../assets/events/follow.svg'
import like from '../../assets/events/like.svg'
import { useTranslation } from 'react-i18next';

import { thumb } from '../../utils/thumb'
import { config } from '../../utils/config'
import i18next from 'i18next';

import './Event.scss'
const UserEvent = ({e, menuOpen, setMenuOpen}) => {
  const { jwtUser } = useContext(UserContext);

  const myUsername = jwtUser?.data?.user?.username

    const navigate = useNavigate();
    const {t} = useTranslation(['tr', 'countries']);

    const {fName, lName, photos, username} = e?.fromUserId
    const x = e?.action
    const lang = i18next.language
    const date =  new Date(e?.createdAt).toLocaleString(lang,{day:'numeric', month:'short'});

    const navigateTo =()=>{
        if(x==="answ_Comment"|| x==="like_comment"||x==="like_news"){
            navigate(`/news/#${e?.news?._id}`)
            setMenuOpen(!menuOpen)
        }else if(x==="createdReviewAboutYou"){
            navigate(`/user/${myUsername}/reviews`)
            setMenuOpen(!menuOpen)
        }else{
            navigate(`/user/${username}`)
            setMenuOpen(!menuOpen)
        }
    }

  return (
    <div className="Event_Wrapper" onClick={navigateTo}>
        <div className="E_imgbox">
            <img className='E_userPhoto' src={thumb(photos[0], config.user_photo_width)} alt=""/>

            {(x==="follow"||x==="follow_tour") &&
                <img className='E_icon' src={follow} alt="" />
            }

            {(x==="like"||x==="like_post"||x==="like_tour" ||x==="like_news" || x==="like_comment") &&
                <img className='E_icon' src={like} alt="" />
            }

        </div>

        <div className="Event_infoArea">

            {/*//-- User написал обо мне Отзыв после Тура */}
            {(x==="createdReviewAboutYou")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span className='E_tort'> <b>{t("createdReviewAboutYou",{ns:"tr"})}</b></span>
            </>}

            {/*//-- User подписался на мою АНКЕТУ */}
            {(x==="follow")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span className='E_tort'> <b>{t("followToYourAccount",{ns:"tr"})}</b></span>
            </>}

            {/*//--User лайкнул мою АНКЕТУ */}
            {(x==="like")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span> <b>{t("likedYourAccount",{ns:"tr"})}</b></span>
            </>}

            {/*//--User лайкнул мое ОБЪЯВЛЕНИЕ */}
            {(x==="like_post")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span> <b>{t("likedYourPost",{ns:"tr"})}:</b></span>
                <h4>{t(e.post.country, { ns: "countries" })}, {e.post.city}</h4>
            </>}

            {/*//--User лайкнул мою НОВОСТЬ */}
            {(x==="like_news")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span> <b>{t("likedYourNews",{ns:"tr"})}</b></span>
            </>}

            {/*//--User лайкнул мой КОММЕНТ */}
            {(x==="like_comment")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span> <b>{t("likedYourComment",{ns:"tr"})}:</b></span>
                <p>{e.news?.comments[e?.commentIdx]?.text.substring(0, 100) + "..."}</p>
            </>}

            {/*//--User ответил на мой КОММЕНТ */}
            {(x==="answ_Comment")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span> <b>{t("answered2YourComment",{ns:"tr"})}:</b></span>
                <p>{e?.text.substring(0, 100) + "..."}</p>
            </>}

            {/*//--User лайкнул мой ТУР */}
            {(x==="like_tour")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span><b>{t("likedYourTrip",{ns:"tr"})}:</b></span>
                <h4>{e.tour?.title}</h4>
            </>}

            {/*//--User подписался на мой ТУР */}
            {(x==="follow_tour")&& <>
                <div className="E_name_date">
                    <h3>{fName} {lName}</h3> <h5>{date}</h5>
                </div>
                <span className='E_tort'><b>{t("followedYourTrip",{ns:"tr"})}:</b></span>
                <h4>{e.tour?.title}</h4>
            </>}



        </div>

    </div>
  )
}

export default UserEvent