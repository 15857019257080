import LiftUpIcon from "../../assets/liftUp.svg";

import "./MiniBtns.scss";

const LiftUpBtn = ({tooltip, small}) => {
  return (
    <div className="Btns_Tooltip_wrapper">
      <div className={`CloseIcon LiftUpIcon ${small? "Btns_small": null}`}>
        <img src={LiftUpIcon} alt="close" />
      </div>
      <div
        className="Btns_TT_tip"
        dangerouslySetInnerHTML={{ __html: tooltip }}
      />
    </div>
  );
};

export default LiftUpBtn;
