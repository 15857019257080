import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as api from "../../utils/api/api.utils";
import { useTranslation } from 'react-i18next';

import UserEvent from "../Event/UserEvent";

import SysEvent from "../Event/SysEvent";
import AdminEvent from "../Event/AdminEvent";
import TourEvent from "../Event/TourEvent";
import { handleError } from "../../utils/handleError";

import "./EventsMenu.scss";
const EventsMenu = ({ menuOpen, setMenuOpen }) => {
  const [events, setEvents] = useState();

  const scrollRef = useRef()
  const {t} = useTranslation(['tr']);


  useEffect(() => {
    scrollRef.current?.scrollTo({top: 0, left: 0, behavior: 'auto'})
    if(menuOpen){
      api.getEvents().then((res) => {
        if(res.status===200){
          setEvents(res?.data?.data?.events);
        }else{
          handleError(res)
        }
      });
    }
  }, [menuOpen]);

  const navigate = useNavigate();
  const show_amount = 6;

  //---- Закрытие на ESC ----
  const escClose = (event) => {
    if (event.key === "Escape") {
      document.removeEventListener("keydown", escClose, false);
      setMenuOpen(false);
    }
  };

  if (menuOpen) {
    document.addEventListener("keydown", escClose, false);
  }

  return (
    <div ref={scrollRef}
    className={`EventsMenu_wrapper ${menuOpen ? null: "EM_hide"}`}>
      <div className="EM_content_box">
        <div className="EM_eventsBox">
          {events && events.map((e, index) =>
            index < show_amount &&  (
              <div key={index}>

                {e.eventType==="user" &&
                  <UserEvent e={e}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                  />
                }

                {e.eventType==="tour" &&
                  <TourEvent e={e}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                  />
                }

                {e.eventType==="system" &&
                  <SysEvent e={e}
                      menuOpen={menuOpen}
                      setMenuOpen={setMenuOpen}
                  />
                }

                {e.eventType==="admin" &&
                  <AdminEvent e={e}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                  />
                }

              </div> ))}
        </div>


      </div>
        <div className="EM_footer">
        <p onClick={() => {
            navigate("/notifications")
            setMenuOpen(!menuOpen)}}> {t("seeAllEvents", { ns: "tr" })} </p>
        </div>
    </div>
  );
};

export default EventsMenu;
