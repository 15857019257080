import Select from 'react-select'
import {interests as int} from '../../data/interests'
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import './SearchInt.scss'

const SearchInt = ({isClearable, placeholder, intValue, setIntValue, isMulti=false}) => {
    const {t} = useTranslation(['translation', 'countries', 'int']);
    const { mobile } = useMatchMedia();

    const intArr = int.map((i) =>{
        return {
          _id: i._id,
          value: i.code,
          label:t(i.code, { ns: "int" }),
          icon: <img className='SI_img' 
          src={`/assets/interests/${i.code}.png`} alt="" />
        }
      })

  return (
    <div className="SearchInt_wrapper">

      <Select 
      options={intArr} 
      value={intValue} 
      onChange={(v)=>setIntValue(v)}
      components={{ IndicatorSeparator: () => null }}

      isMulti={isMulti}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={ mobile? false: true }
      getOptionValue={o => o.label}
      getOptionLabel={e=>(
      <div className='SI_MenuItem'> {e.icon} 
      <span className='SI_MenuText'>{e.label}</span> 
      </div>)}

      theme={(theme) => ({...theme, borderRadius: 8,
      colors: {
        ...theme.colors,
        primary25: '#eee3f4',
        primary: '#87589C',
      },})} />

    </div>
  )
}

export default SearchInt