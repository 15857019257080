import Spinner from "./Spinner";

import "./Loading.scss";

const LoadingItem = () => {

  return (
    <div className="LoadingItem_container">
      <Spinner />
    </div>
  );
};

export default LoadingItem;
