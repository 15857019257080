import youtube from '../../assets/social/youtube.svg'

import './YouTubeBtn.scss'

const YouTubeBtn = ({title, link}) => {
  return (
    <div className="Youtube_btn_wrapper" 
    onClick={() => window.open(`${link}`, '_blank')}  
    // onClick={() => window.open(`https://youtu.be/g2yhOPsfIlI`, '_blank')}  
    >
        <img src={youtube} alt="youtube" />
        <h5>{title}</h5>
    </div>
  )
}

export default YouTubeBtn