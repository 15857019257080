import { Suspense } from "react";
import { useTranslation } from 'react-i18next';
import Tour from "../../components/Tour/Tour";
import { useMatchMedia } from "../../hooks/use-match-media";

import "./FavoritesPages.scss";
import MTour from "../../components/Tour/MTour";

const FavTripsPage = ({jwtUser, setJwtUser, allLangs, tours}) => {

  const {t} = useTranslation(['tr']);
  const { mobile } = useMatchMedia();

  return (

    <div className="FavPage_wrapper">

      <h1>{t("fav_trips",{ns:"tr"})}</h1>
<Suspense fallback="Loading...">

      {tours && tours.map((tour) => (
          <div key={tour?._id}>
          {mobile?
            <MTour tour={tour} />
            :
            <Tour
            tour={tour}
            allLangs={allLangs}
            jwtUser={jwtUser}
            setJwtUser={setJwtUser}
            />
          }
          </div>
        ))}
</Suspense>
    </div>

  );
};

export default FavTripsPage;
