import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { thumb } from '../../utils/thumb'
import { config } from '../../utils/config'
import i18next from 'i18next';

import ReactTimeAgo from 'react-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import TimeAgo from 'javascript-time-ago'
import CommentDelBtn from "../Buttons/CommentDelBtn";
import CommentFavBtn from "../Buttons/CommentFavBtn";
import { RiEditLine } from "react-icons/ri";

import './news.scss'
import DeletedComment from "./DeletedComment";
import EditComment from "./EditComment";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

const Comment = ({newsId, com, idx, setAnswer, deleteComment, jwtUser, creatorId}) => {
  const {photos, fName, lName, username} = com?.fromUser;
  const fromUserId = com?.fromUser?._id;

  const [haveLikes, setHaveLikes] = useState(com?.user_liked_me?.length > 0);
  // const [deleted, setDeleted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [comText, setComText] = useState(com?.text);

  const navigate = useNavigate();
  const { t } = useTranslation(["tr"]);

  const myId = jwtUser?.data?.user?._id
  const myComment = com?.fromUser?._id === myId;
  const lang = i18next.language

  const navigateTo = (username) => {
    navigate(`/user/${username}/`);
  };


  // if(deleted){
  //   return <DeletedComment/>
  // }

  return (
    <div className="Comment_wrapper">


      {myComment && !editMode &&
        <div className="Com_edit" onClick={()=>setEditMode(true)}>
          <RiEditLine size={18} color='#aaa'/>
        </div>
      }

        {(myComment || creatorId )&&
          <div className="Com_delete">
            <CommentDelBtn
              newsId={newsId}
              com={com}
              idx={idx}
              delComment={deleteComment}
            />
          </div>
        }

      {!editMode &&
        <div className={haveLikes ? "Com_haveLike" : "Com_Like"}>
          <CommentFavBtn
            newsId={newsId}
            idx={idx}
            com={com}
            jwtUser={jwtUser}
            setHaveLikes={setHaveLikes}
          />
        </div>
      }

      <div className="CommentUser_imgBG" onClick={() => navigateTo(username)}>
        <img src={thumb(photos?.[0], config.thumb_width)} alt="user" loading="lazy" />
      </div>

      <div className="Comment_textArea">
        <div className="Comment_nameLine">
          <p onClick={() => navigateTo(username)}>{fName} {lName}</p>
          {editMode && <h3>{t("editingComment", { ns: "tr" })}:</h3>}
          {!editMode && com?.answeredToId && <h3>{t("answered", { ns: "tr" })} - {com?.answeredToName}</h3>}
        </div>

        {editMode?
          <EditComment
            idx={idx}
            newsId={newsId}
            commentText={comText}
            setEditMode={setEditMode}
            setComText={setComText}
          />
          :
          <div className="Comment_textBox">
            <span>{comText}</span>
            <div className="Comment_timeLine">
              <h3><ReactTimeAgo date={Date.parse(com?.createdAt)} locale={lang}/></h3>
              <h4 onClick={()=>setAnswer(fName, fromUserId)}>Ответить</h4>
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default Comment