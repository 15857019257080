import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from '../../utils/api/api.utils'
import { ReactComponent as FavHeart} from '../../assets/heart_2.svg'
import { handleError } from '../../utils/handleError';



import './FavButton.scss'
const PostFavBtn = ({post, mobile, jwtUser, setLike, setJwtUser }) => {

    const doILike = jwtUser?.data?.user?.i_liked_post?.includes(post?._id)
    const [pressed, setPressed] = useState(doILike);
    const [clickable, setClickable] = useState(true);

    const navigate = useNavigate();
    const myId = jwtUser?.data?.user?._id


    const likeAction = async()=>{
      if(pressed){
      //-- Unlike
        try {
          setLike(0)
          setPressed(false)
          setClickable(false)
          const res = await api.unLikePost(myId, {unLikeID: post?._id})

          if(res.status===200){
            jwtUser.data.user.i_liked_post = res.data.user.i_liked_post;
            setJwtUser(jwtUser)
            setPressed(false)
            setClickable(true)

          }else{
            setLike(1)
            setPressed(true)
            setClickable(true)
            throw res;
          }

        } catch (error) {
          handleError(error)
        }
      }else{
      //-- Like
        if(!jwtUser){ navigate('/login') }

        try {
          setLike(1)
          setPressed(true);
          setClickable(false)
          const res = await api.likePost(myId, {likeID: post?._id})

          if(res.status===200){
            jwtUser.data.user.i_liked_post = res.data.user.i_liked_post;
            setJwtUser(jwtUser)
            setPressed(true);
            setClickable(true)
          }else{
            setLike(0)
            setPressed(false);
            setClickable(true)
            throw res;
          }

        } catch (error) {
          handleError(error)
        }
      }
    }

  return (
    <>
    {!mobile ?
      <div onClick={clickable? likeAction : ()=>{}} className={pressed ? "FavButton TourFavButton  TourFavButton_clicked" : "FavButton TourFavButton"} >
        <FavHeart className="FavHeart" />
      </div>

    :

      <div onClick={clickable? likeAction : ()=>{}} className={pressed ? "Mobile_Fav MobButton_clicked" : "Mobile_Fav "} >
        <FavHeart className="MobHeart" />
      </div>
    }
    </>
  )
}

export default PostFavBtn