import Resizer from "react-image-file-resizer";

export const resize = async (file, maxWidth=2048, maxHeight=1024, 
    format="webp", quality="85" )=>{

    try {
        
    const result = new Promise((resolve) => {
    Resizer.imageFileResizer(file, maxWidth,maxHeight,format,quality, 0,
      (uri) => {
         resolve(uri)
            }, "file")
      })
               
      return result
        } catch (error) {
        }

}