import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import * as api from "../../utils/api/api.utils";
import { useTranslation } from 'react-i18next';

import Spinner from "../../components/Spinner/Spinner";
import MTour from "../../components/Tour/MTour";
import { handleError } from "../../utils/handleError";
import TabsMenu from "../../components/Menu/TabsMenu";
import EmptyHere from "../../components/PageItems/EmptyHere";

import "./MyTours.scss";
const MyTours = () => {
  const { user } = useOutletContext();

  const [joinedTours, setJoinedTours] = useState();
  const [createdTours, setCreatedTours] = useState();
  const [loading, setLoading] = useState(true);
  const [menuActive, setMenuActive] = useState(1)


  useEffect(() => {
    api.findToursByIdArray(user?.joined_to_tour).then((res) => {
      if (res?.status === 200) {
        const realTrips = res.data.filter(trip => trip.isTest!==true)
        setJoinedTours(realTrips)
      }else{
        handleError(res)
      }
    });

    api.findToursByIdArray(user?.created_tours).then((res) => {
      if (res?.status === 200) {
        const realTrips = res.data.filter(trip => trip.isTest!==true)
        setCreatedTours(realTrips)
      }else{
        handleError(res)
      }
    });

    setLoading(false);
  }, []);

  const {t} = useTranslation(['tr']);

  const joinedAmnt = joinedTours ? joinedTours?.length : "" ;
  const createdAmnt = createdTours ? createdTours?.length : "" ;

  useEffect(() => {
      if(joinedAmnt > createdAmnt) {
        setMenuActive(1)
      }else{
        setMenuActive(2)
      }
    }, [joinedAmnt, createdAmnt])

  if(createdTours?.length===0 && joinedTours?.length===0){
    return (
      <EmptyHere
        text={t("emptyHere",{ns:"tr"})}
        subText={t("upTr_emptyText",{ns:"tr"})}
      />
    )
  }

  return (
    <div className="MyTours_wrapper">

      <TabsMenu
        menuActive={menuActive}
        setMenuActive={setMenuActive}
        title1={`${t("upRw_iTookPart",{ns:"tr"})}: ${joinedAmnt}`}
        title2={`${t("upRw_iOrganized",{ns:"tr"})}: ${createdAmnt}`}
      />

      {createdTours && menuActive===2 &&
        <div className="MTs_container">
          {createdTours && createdTours.map((tour) => (
              <div key={tour?._id}>
                <MTour tour={tour} />
              </div>
            ))}
        </div>
        }

      {joinedTours && menuActive===1 &&
        <div className="MTs_container">
          {joinedTours && joinedTours.map((tour) => (
              <div key={tour?._id}>
                <MTour tour={tour} />
              </div>
            ))}
        </div>
      }

      {loading && <Spinner />}
    </div>
  );
};

export default MyTours;
