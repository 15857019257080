import { useContext, useEffect, useState } from 'react';
import { UserContext } from "../../context/user.context";
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';

import Spinner from '../../components/Spinner/Spinner'

import './ConTours.scss'
import ConPost from '../../components/Post/ConPost';
import { handleError } from '../../utils/handleError';

const ConAdverts = () => {
  const { jwtUser, setJwtUser} = useContext(UserContext);

  const [page, setPage] = useState(0);
  const [actualPosts, setActualPosts] = useState();
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.findPostsByIdArray(jwtUser?.data?.user?.created_posts).then(res=> {
      if (res?.status === 200) {
        setActualPosts(res.data)
        setLoading(false)
      }else{
        handleError(res)
      }
    }
    )

  }, [updated]);

  const {t} = useTranslation(['tr']);

  const notFinishedPosts = actualPosts?.filter(post=>{
    if(!post.noEndDate && (new Date() > new Date(post.endDate))){
      return false
    }else{
      return true
    }
  })

  const finishedPosts = actualPosts?.filter(post=>{
    if(!post.noEndDate && (new Date() > new Date(post.endDate))){
      return true
    }else{
      return false
    }
  })

  if(loading){
    return <Spinner/>
  }

return (
  <div className="ConTours_wrapper">

  <div className="ConTours_title_area">
      <p>{t("CP_myAdverts",{ns:"tr"})}</p>
      <h4>{t("CP_myAdverts_text",{ns:"tr"})}</h4>
  </div>

  <div className="ConTPage_NavBar">
      <div onClick={()=>setPage(0)} className={page===0 ? "ConT_Item active_l":"ConT_Item"}>
        {t("All",{ns:"tr"})}: {actualPosts?.length}
      </div>

      <div onClick={()=>setPage(1)} className={page===1 ? "ConT_Item active_l":"ConT_Item"}>
        {t("Actual",{ns:"tr"})}: {notFinishedPosts?.length}
      </div>

      <div onClick={()=>setPage(2)} className={page===2 ? "ConT_Item active_l":"ConT_Item"}>
        {t("Finished",{ns:"tr"})}: {finishedPosts?.length}
      </div>

  </div>


  {page===0 &&
    <>
    {actualPosts && actualPosts.map((post) => (
        <ConPost
          key={post._id}
          post={post}
          jwtUser={jwtUser}
          setJwtUser={setJwtUser}
          setUpdated={setUpdated}
        />
      ))}
    </>
  }

  {page===1 &&
    <>
    {notFinishedPosts && notFinishedPosts.map((post) => (
        <ConPost
          key={post._id}
          post={post}
          jwtUser={jwtUser}
          setJwtUser={setJwtUser}
          setUpdated={setUpdated}
        />
      ))}
    </>
  }

  {page===2 &&
    <>
      {finishedPosts && finishedPosts.map((post) => (
        <ConPost
          key={post._id}
          post={post}
          jwtUser={jwtUser}
          setJwtUser={setJwtUser}
          setUpdated={setUpdated}
        />
      ))}
    </>
  }

  </div>
)
}

export default ConAdverts