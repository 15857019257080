import { useEffect, useState } from 'react'
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';
import MTour from './MTour'

import './NewTours.scss'
const NewTours = () => {
  const [new_tours, setNewTours] = useState();

  useEffect(() => {
    api.getTours().then((res) => {
      setNewTours(res.data.data)
    });
  }, []);

  const show_amount = 3;
  const {t} = useTranslation(['tr']);

  const toursWithOrg = new_tours?.filter(tour=> tour?.creator?.deleted !== true)

  return (
    <div className="NewTours_wrapper">
      <div className="NewTours_title">
        <p>{t("newTrips",{ns:"tr"})}:</p>
      </div>

      <div className="NewTours_container">
        {toursWithOrg?.map((tour, index) =>
          index < show_amount && (
            <div key={tour?._id}>
              <MTour tour={tour} />
            </div>
          ))}
      </div>


    </div>
  )
}

export default NewTours