import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Keyboard, Zoom} from "swiper";
import Modal from "../../Modal/Modal";

import { thumb } from "../../../utils/thumb";
import { config } from "../../../utils/config";

import 'swiper/scss';
import 'swiper/scss/navigation';
import "swiper/scss/effect-fade";

import "./newsBlocks.scss";
const UserTourPhotoBlock = ({tour, photoArr}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  SwiperCore.use([Keyboard]);
  const { t } = useTranslation(["tr", "countries"]);
  const navigate = useNavigate();

  const navigateTo = (tour)=>{
    navigate(`/tour/${tour?._id}/`)
  };

  const handleModal = (index) => {
    setModalOpen(true);
    setPhotoIndex(index);
  };

  return (
    <div className="N_UTPB_ContentBox">

      {tour &&
          <div className="N_UTPB_Top">
            <div className="N_UTPB_ibg" style={{ backgroundImage: `url("${thumb(tour?.titleImages[0], config.thumb_width)}")`}} onClick={() => navigateTo(tour)}>
              <img className="N_UTPB_ibg" src={thumb(tour?.titleImages[0], config.image_width)} loading="lazy" alt="country"/>
            </div>

            <div className="N_UTPB_rightSide">
            <h2 onClick={() => navigateTo(tour)}>{tour?.title}</h2>
              <p><b>{t("Country", { ns: "tr" })}:</b> <span> <b> {t(tour.country, { ns: "countries" })}, </b> {tour?.city}</span></p>
            </div>
          </div>
      }

      <div className="N_UTPB_Bottom">
        {photoArr && photoArr.map((img, idx) =>
            idx < 20 &&
            <img key={idx} className="N_UTPB_addedPhoto" src={thumb(img, config.thumb_width)}
            onClick={() => handleModal(idx)}
              loading="lazy" alt=""/>
          )
        }
      </div>


      <Modal open={modalOpen} close={() => setModalOpen(false)}>

<div className="ModalPhoto">
  <Swiper
    slidesPerView={1}
    spaceBetween={50}
    modules={[Navigation, Zoom]}
    initialSlide={photoIndex}
    zoom={true}
    keyboard={{ enabled: true }}
    navigation
    style={{
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      }}
    >

    {photoArr && photoArr.map((pic) => (

        <div key={pic} className="slider_img_box">

        <SwiperSlide>
        <div className="swiper-zoom-container" >
          <img className="slider_img" src={pic} alt="" />
        </div>
        </SwiperSlide>

        </div>
      ))}

  </Swiper>
</div>

</Modal>

    </div>
  )
}

export default UserTourPhotoBlock