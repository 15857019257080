import { useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { UserContext } from "../../context/user.context";

import TripicoLogo from "../../assets/tripico_logo.svg";
import {ReactComponent as Facebook} from '../../assets/social/facebook.svg'
import {ReactComponent as Instagram} from '../../assets/social/instagram.svg'
import {ReactComponent as Twitter} from '../../assets/social/twitter.svg'
import {ReactComponent as Youtube} from '../../assets/social/youtube.svg'
import {ReactComponent as Discord} from '../../assets/social/discord.svg'

import Modal from "../Modal/Modal";
import FeedBack from "../FeedBack/FeedBack";

import './Footer.scss'
const Footer = () => {
  const { jwtUser, setJwtUser } = useContext(UserContext);

  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const {t} = useTranslation(['translation', 'tr']);


  return (
    <div className="footer_container">
  
        <div className="footer_up">
        <div className="footer_box">
            <div className="footer_logo_side">
                <img src={TripicoLogo} alt="" />
                <p>{t("footer_logoText1", { ns: "tr" })}.<br/>
                {t("footer_logoText2", { ns: "tr" })}.</p>
                <div className="footer_logobox">
                    <Facebook className="social_icon"/>
                    <Instagram className="social_icon"/>
                    <Twitter className="social_icon"/>
                    <Youtube className="social_icon"/>
                    <Discord className="social_icon"/>
                </div>
            </div>

        <div className="footer_text_side">

            <div className="footer_text">
                <p>{t("fb_aboutUs",{ns:"tr"})}</p>
                <span>{t("fb_tripicoTeam",{ns:"tr"})}</span>
                <span>{t("fb_ourIdea",{ns:"tr"})}</span>
                <span>{t("fb_forInvestors",{ns:"tr"})}</span>
                <span onClick={()=> setFeedbackOpen(true)}>
            {t("sendFeedback", { ns: "translation" })}</span>
                <span>{t("fb_contactUs",{ns:"tr"})}</span>
            </div>

            <div className="footer_text">
            <p>{t("fb_howTo",{ns:"tr"})}.</p>
                <span>{t("fb_useTripico",{ns:"tr"})}</span>
                <span>{t("fb_createTour",{ns:"tr"})}</span>
                <span>{t("fb_findTour",{ns:"tr"})}</span>
                <span>{t("fb_beSafe",{ns:"tr"})}</span>
                <span>{t("fb_earnOnTours",{ns:"tr"})}</span>
            </div>

            <div className="footer_text">
            <p>{t("fb_customerService",{ns:"tr"})}</p>
                <span>{t("fb_myAccount",{ns:"tr"})}</span>
                <span>{t("fb_inviteFriend",{ns:"tr"})}</span>
                <span>{t("fb_covid",{ns:"tr"})}</span>
                <span>{t("fb_ReportBug",{ns:"tr"})}</span>
                <span>{t("fb_helpCenter",{ns:"tr"})}</span>
            </div>
        </div>

        </div>
        </div>
        <div className="footer_down">
            <div className="footer_trademark">© 2023 SimStar Productions LLC. {t("fb_allRights",{ns:"tr"})}.</div>
        </div>
     


<Modal 
  open={feedbackOpen} 
  close={() => setFeedbackOpen(false)} 
  NoCloseBtn={true}
  >
    <FeedBack 
      close={()=> {
        document.body.classList.remove("active-modal");
        setFeedbackOpen(false)}}
    />

</Modal>

    </div>
  )
}

export default Footer