import { config } from "../../utils/config";
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from "../../hooks/use-match-media";

import AddInterest from "../../components/AddInterest/AddInterest";
import AddLang from "../../components/AddLang/AddLang";

import AddTourPhoto from "../../components/AddPhoto/AddTourPhoto";
import AgesField from "../../components/Input/AgesField";
import DatesField from "../../components/Input/DatesField";
import InputField from "../../components/Input/InputField";
import TextField from "../../components/Input/TextField";
import "./CreateTourPages.scss";
import InputCountry from "../../components/Input/InputCountry";
import RSelectField from "../../components/Input/RSelectField";

const CreateTourPage1 = ({
    selectedFiles, setSelectedFiles, formik, jwtUser,
    usersList,setUsersList,
    usersLangs, setUsersLangs
}) => {

  const {t} = useTranslation(['translation', 'int']);
  const { ipadSml } = useMatchMedia();

  return (
    <div className="CreateTour_wrapper">
    <div className="CT_content_box">
      <div className="CT_title_area">
        <p>{t("mainInfo",{ns:"tr"})}</p>
        <h3>{t("ct_mi_text",{ns:"tr"})}</h3>
      </div>

      <AddTourPhoto
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        formik_errors={formik.errors}
        formik_touched={formik.touched}
        tooltip={t("addTourPhoto_tooltip",{ns:"tr"})}
      />

      <div className="CT_info_body">

        <div className="CT_inputDiv CT_tour_title">
          <InputField
            title={t("tourTitle",{ns:"tr"})}
            icon="title"
            maxLength={config.titleCharAmount}
            type="text"
            placeholder={t("tourTitle_ph",{ns:"tr"})}
            tooltip={t("tourTitle_tt",{ns:"tr"})}
            name="title"
            {...formik.getFieldProps("title")}
          />
          <div className="invalid-feedback">
            {formik.errors.title && formik.touched.title
              ? formik.errors.title
              : null}
          </div>
          </div>

        <div className="CT_empty_div"></div>

        <div className="CT_inputDiv">
        <InputCountry
            icon="depart"
            title={t("fromWhatCountry", { ns: "translation" })}
            placeholder={t("SelectCountry", { ns: "translation" })}
            tooltip={t("fromWhatCountry_tt", { ns: "translation" })}
            isSearchable={!ipadSml}
            onChange={(val)=> val?
                formik.setFieldValue("depart", val.value):
                formik.setFieldValue("depart", null)
              }
            onBlur={()=>formik.setFieldTouched("depart")}
            value={formik.values.depart}
          />

          <div className="invalid-feedback">
            {formik.errors.depart && formik.touched.depart
              ? formik.errors.depart
              : null}
          </div>
        </div>

        <div className="CT_inputDiv">
          <InputCountry
            icon="country"
            title={t("toWhatCountry", { ns: "translation" })}
            placeholder={t("SelectCountry", { ns: "translation" })}
            tooltip={t("toWhatCountry_tt", { ns: "translation" })}
            name="country"
            isSearchable={!ipadSml}
            onChange={(val)=> val?
            formik.setFieldValue("country", val.value):
            formik.setFieldValue("country", null)
            }
            onBlur={()=>formik.setFieldTouched("country")}
            value={formik.values.country}
          />
          <div className="invalid-feedback">
            {formik.errors.country && formik.touched.country
              ? formik.errors.country
              : null}
          </div>
        </div>

        <div className="CT_inputDiv">
          <InputField
            icon="country"
            type="text"
            title={t("toWhatCity", { ns: "translation" })}
            placeholder={t("writeCity", { ns: "translation" })}
            tooltip={t("toWhatCity_tt", { ns: "translation" })}
            name="city"
            {...formik.getFieldProps("city")}
          />
          <div className="invalid-feedback">
            {formik.errors.city && formik.touched.city
              ? formik.errors.city
              : null}
          </div>
        </div>

        <div className="CT_inputDiv">
          <InputField
            title={t("pplAmount",{ns:"tr"})}
            icon="amount"
            type="number"
            inputmode="numeric"
            tooltip={t("pplAmount_tt",{ns:"tr"})}
            name="amount"
            {...formik.getFieldProps("amount")}
          />
          <div className="invalid-feedback">
            {formik.errors.amount && formik.touched.amount
              ? formik.errors.amount
              : null}
          </div>
        </div>

        <div className="CT_inputDiv">
          <RSelectField
            title={t("pplGender",{ns:"tr"})}
            icon="sex"
            name="gender"
            {...formik.getFieldProps("gender")}
            tooltip={t("pplGender_tt",{ns:"tr"})}
            onChange={(value)=>formik.setFieldValue("gender", value.value)}
            defaultValue={{label:t("any",{ns:"translation"}),value: "any" }}
            value={formik.values.gender}
            options={[
              { label: t("any",{ns:"translation"}), value: "any" },
              { label: t("men",{ns:"translation"}), value: "men" },
              { label: t("women",{ns:"translation"}), value: "women" },
            ]}
          />
        </div>

        <div className="CT_inputDiv">
          <AgesField
            title={t("pplAges",{ns:"translation"})}
            icon="ages"
            tooltip={t("pplAges_tt",{ns:"translation"})}
            formik={formik}
          />
          <div className="invalid-feedback">
            {formik.errors.startAge && formik.touched.startAge
              ? formik.errors.startAge
              : null}
            <br />
            {formik.errors.endAge && formik.touched.endAge
              ? formik.errors.endAge
              : null}
          </div>
        </div>

        <div className="CT_textarea">
          <TextField
            title={t("TripText",{ns:"tr"})}
            icon="doc"
            tooltip={t("TripText_tt",{ns:"tr"})}
            placeholder={t("TripText_ph",{ns:"tr"})}
            maxLength="1000"
            name="text"
            {...formik.getFieldProps("text")}
          />
          <div className="invalid-feedback">
            {formik.errors.text && formik.touched.text
              ? formik.errors.text
              : null}
          </div>
        </div>

        <div className="CT_Dates_inputDiv">
          <DatesField
            title={t("TripDates",{ns:"tr"})}
            icon="dates"
            tooltip={t("TripDates_tt",{ns:"tr"})}
            formik={formik}
          />

        </div>

        <div className="CT_lang">
          <AddLang
            usersLangs={usersLangs}
            setUsersLangs={setUsersLangs}
            max={2}
            title={t("TripLangs",{ns:"tr"})}
            tooltip={t("TripLangs_tt",{ns:"tr"})}
            {...formik.getFieldProps("langs")}
          />
        </div>
        <div className="invalid-feedback">
            {formik.errors.langs}
          </div>

        <div className="CT_interest">
          <AddInterest
            usersList={usersList}
            setUsersList={setUsersList}
            title={t("chooseInt", { ns: "int" })}
            tooltip={t("TripInts_tt",{ns:"tr"})}
          />
        </div>
      </div>

    </div>
    </div>
  );
};

export default CreateTourPage1;
