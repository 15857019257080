import CloseIcon from "../../assets/close.svg";

import "./MiniBtns.scss";
const CloseBtn = () => {
  return (
      <div className="CloseIcon">
        <img src={CloseIcon} alt="close" />
      </div>
  );
};

export default CloseBtn;
