import ShareIcon from '../../assets/share.svg'
import { useMatchMedia } from "../../hooks/use-match-media";

import './ShareBtn.scss'
const ShareBtn = () => {
  const { mobile } = useMatchMedia();

  return (
    <div className="ShareBtn">
    {mobile? <img src={ShareIcon} alt="share" />
    :
    <div className='Tooltip_wrapper'>
      <img src={ShareIcon} alt="share" />
      <div className="TT_tip" dangerouslySetInnerHTML={{ __html: "<b>Поделиться о туре в соц-сетях!</b>" }} />
    </div>
    }
</div>
  )
}

export default ShareBtn