import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useMatchMedia } from "../../hooks/use-match-media";

import { useFormik } from "formik";
import { validationSchema } from "../../utils/validations/createPost_Yup";
import { UserContext } from "../../context/user.context";
import * as api from '../../utils/api/api.utils'
import { useTranslation } from 'react-i18next';

import { age } from "../../utils/age";

import AddLang from '../../components/AddLang/AddLang'
import AgesField from '../../components/Input/AgesField'
import DatesField from '../../components/Input/DatesField'
import InputField from '../../components/Input/InputField'
import TextField from '../../components/Input/TextField'
import PageHeader from '../../components/PageHeader/PageHeader'

import InputCountry from "../../components/Input/InputCountry";
import RSelectField from "../../components/Input/RSelectField";
import AddInterest from "../../components/AddInterest/AddInterest";

import './CreatePostPage.scss'
import { handleError } from "../../utils/handleError";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import MainBtn from "../../components/Buttons/MainBtn";

const CreatePostPage = () => {
  const { jwtUser, setJwtUser, location } = useContext(UserContext);
  const [usersList, setUsersList] = useState([]); //-- Users Interests
  const [usersLangs, setUsersLangs] = useState([]); //-- Users Langs

  const [serverError, setServerError] = useState("");
  const [sending, setSending] = useState(false);

  const {t} = useTranslation(['translation']);
  const { ipadSml } = useMatchMedia();

  useEffect(() => {
    formik.setFieldValue("langs", usersLangs);
    if(usersLangs.length===0) {
      formik.setFieldValue("langs", "");
    }
  }, [usersLangs]);

  useEffect(() => {
    formik.setFieldValue("interests", usersList);
    }, [usersList]);

  const navigate = useNavigate();

  const formik = useFormik({initialValues: {
        depart: location && location.country,
        creator: jwtUser?.data?.user?._id,
        startAge: 18,
        endAge: '',
        anyAge: false,
        text: "",
        country: "",
        city: "",
        interests: [],
        langs: [],
        gender: "any",

        startDate:"",
        endDate:"",
        noStartDate:false,
        noEndDate:false,
        anyDates:false,
        daysAmount:0,

      },
      validationSchema: validationSchema ,
      onSubmit: (values) => {
          // console.log('Formik values', values);
      },
    });

    useEffect(() => {
        if(!formik.values.endDate || !formik.values.startDate || formik.values.noStartDate ||
            formik.values.noEndDate || (formik.values.endDate - formik.values.startDate)<0){
            formik.setFieldValue("daysAmount", 0);
          }else{
            const daysAmount = (Math.ceil((formik.values.endDate - formik.values.startDate) / (1000 * 3600 * 24)))+1;
            formik.setFieldValue("daysAmount", daysAmount);
            }
        }, [formik.values.endDate, formik.values.startDate, formik.values.noStartDate, formik.values.noEndDate,]);

        if(!jwtUser){
            return <Navigate to={`/login`} />
        }


        const handleSubmit = async(e)=>{
          e.preventDefault();
          formik.handleSubmit();

          try {
            const formData = new FormData();
            formData.append("text", formik.values.text);
            formData.append("gender", formik.values.gender);

            formData.append("creator", jwtUser.data.user._id);
            formData.append("org_gender", jwtUser?.data?.user?.gender);
            formData.append("org_age", age(jwtUser?.data?.user?.birthDate));
            formData.append("org_verified", jwtUser?.data?.user?.verified);
            formData.append("org_orientation", jwtUser?.data?.user?.orientation);
            formData.append("org_finances", jwtUser?.data?.user?.finances);
            formData.append("org_character", jwtUser?.data?.user?.character);
            formData.append("org_rating", jwtUser?.data?.user?.rating||0);

            formData.append("startAge", formik.values.startAge);
            formData.append("endAge", formik.values.endAge);
            formData.append("anyAge", formik.values.anyAge);

            formData.append("depart", formik.values.depart);
            formData.append("country", formik.values.country);
            formData.append("city", formik.values.city);

            for (let i=0; i<usersLangs.length; i++) {
              formData.append("langs", usersLangs[i]);
            }

            for (let i=0; i<usersList.length; i++) {
              formData.append("interests", usersList[i]);
            }

            formData.append("startDate", formik.values.startDate);
            formData.append("endDate", formik.values.endDate);
            formData.append("noStartDate", formik.values.noStartDate);
            formData.append("noEndDate", formik.values.noEndDate);

            if(formik.values.noStartDate && formik.values.noEndDate){
              formData.append("anyDates", true);
            }else{
              formData.append("anyDates", false);
            }

            formData.append("daysAmount", formik.values.daysAmount);
            formData.append("isPublic", true);


            if(formik.isValid){
              setSending(true)

              const res = await api.createPost(formData)

              if (res?.status === 200) {
                jwtUser.data.user.created_posts = res.data.created_posts;
                setJwtUser(jwtUser)
                setSending(false)
                navigate('/posts')
              }else{
                throw res
              }

            }else{
              console.log("Forms are not correct")
              setServerError(t("fillAllFields",{ns:"tr"}))
            }

        } catch (error) {
          handleError(error);
          setSending(false)
          setServerError(error.response.message)
        }
        }



  return (
    <div className="CreatePostPage_wrapper">

    {sending && <LoadingScreen/>}


<PageHeader
      icon="createpost"
      title={t("creatingPost",{ns:"tr"})}
      text={t("creatingPost_text",{ns:"tr"})}
      tooltip={t("creatingPost_tt",{ns:"tr"})}

      />
 <form  onSubmit={handleSubmit}>

<div className="CPP_content_box">
    <div className="CPP_info_body">
        <div className="CPP_inputDiv">
        <InputCountry
            icon="depart"
            name="depart"
            title={t("fromWhatCountry", { ns: "translation" })}
            placeholder={t("SelectCountry", { ns: "translation" })}
            tooltip={t("fromWhatCountry_tt", { ns: "translation" })}
            isSearchable={!ipadSml}
            onChange={(val)=> val?
                formik.setFieldValue("depart", val.value):
                formik.setFieldValue("depart", null)
              }
            onBlur={()=>formik.setFieldTouched("depart")}
            value={formik.values.depart}
          />

          <div className="invalid-feedback">
            {formik.errors.depart && formik.touched.depart
              ? formik.errors.depart
              : null}
          </div>
        </div>


        <div className="CPP_inputDiv">
        <InputCountry
            icon="country"
            name="country"
            title={t("toWhatCountry", { ns: "translation" })}
            placeholder={t("SelectCountry", { ns: "translation" })}
            tooltip={t("toWhatCountry_tt", { ns: "translation" })}
            isSearchable={!ipadSml}
            onChange={(val)=> val?
                formik.setFieldValue("country", val.value):
                formik.setFieldValue("country", null)
              }
            onBlur={()=>formik.setFieldTouched("country")}
            value={formik.values.country}
          />

          <div className="invalid-feedback">
            {formik.errors.country && formik.touched.country
              ? formik.errors.country
              : null}
          </div>
        </div>


        <div className="CPP_inputDiv">
          <InputField
            title={t("toWhatCity", { ns: "translation" })}
            icon="country"
            type="text"
            placeholder={t("writeCity", { ns: "translation" })}
            tooltip={t("toWhatCity_tt", { ns: "translation" })}
            name="city"
            {...formik.getFieldProps("city")}
          />
          <div className="invalid-feedback">
            {formik.errors.city && formik.touched.city
              ? formik.errors.city
              : null}
          </div>
        </div>


        <div className="CPP_inputDiv">
        <RSelectField
            title={t("post_gender", { ns: "translation" })}
            icon="sex"
            name="gender"
            {...formik.getFieldProps("gender")}
            tooltip={t("pplGender_tt",{ns:"tr"})}
            onChange={(value)=>formik.setFieldValue("gender", value.value)}
            defaultValue={{label:t("any",{ns:"translation"}),value: "any" }}
            value={formik.values.gender}
            options={[
              { label: t("any",{ns:"translation"}), value: "any" },
              { label: t("men",{ns:"translation"}), value: "men" },
              { label: t("women",{ns:"translation"}), value: "women" },
            ]}
          />

        </div>

{/*
        <div className="CPP_inputDiv">
          <AlkoChild
            formik={formik}
          />
        </div> */}
        <div className="EPP_Empty_Div"></div>


        <div className="CPP_inputDiv">
          <AgesField
            title={t("companionAge",{ns:"tr"})}
            icon="ages"
            tooltip={t("companionAge_tt",{ns:"tr"})}
            formik={formik}
          />
          <div className="invalid-feedback">
            {formik.errors.startAge && formik.touched.startAge
              ? formik.errors.startAge
              : null}
            <br />
            {formik.errors.endAge && formik.touched.endAge
              ? formik.errors.endAge
              : null}
          </div>
        </div>


        <div className="CPP_textarea">
          <TextField
            title={t("postText",{ns:"tr"})}
            icon="doc"
            tooltip={t("postText_tt",{ns:"tr"})}
            placeholder={t("postText_ph",{ns:"tr"})}
            maxLength="350"
            name="text"
            {...formik.getFieldProps("text")}
          />
          <div className="invalid-feedback">
            {formik.errors.text && formik.touched.text
              ? formik.errors.text
              : null}
          </div>
        </div>

        <div className="CPP_Dates_inputDiv">
          <DatesField
            title={t("postDates",{ns:"tr"})}
            icon="dates"
            tooltip={t("postDates_tt",{ns:"tr"})}
            formik={formik}
          />
        </div>


        <div className="CPP_lang">
          <AddLang
            usersLangs={usersLangs}
            setUsersLangs={setUsersLangs}
            max={2}
            title={t("postLangs",{ns:"tr"})}
            tooltip={t("postLangs_tt",{ns:"tr"})}
            {...formik.getFieldProps("langs")}
          />
        </div>
        <div className="invalid-feedback">
            {formik.errors.langs}
          </div>

        <div className="CPP_interest">
          <AddInterest
            usersList={usersList}
            setUsersList={setUsersList}
            max={6}
            title={t("postInts",{ns:"tr"})}
            tooltip={t("postInts_tt",{ns:"tr"})}
          />
        </div>
    </div>

</div>

    <div className="CPP_CreateBtn_line">

        <div className="CPP_notValid_error">
           <p>{serverError}</p>
        </div>

        <MainBtn
          color='purp'
          type="submit"
          title={t("createPost",{ns:"tr"})}
          onClick={()=>{}}
        />

    </div>

</form>

    </div>
  )
}

export default CreatePostPage