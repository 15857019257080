import { NavLink, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import PageHeader from '../../components/PageHeader/PageHeader'

import './ControlPage.scss'
const ControlPage = () => {

  const {t} = useTranslation(['tr']);

  return (
<div className="ControlPage_wrapper">

<PageHeader 
      icon="control"
      title={t("ContropPage",{ns:"tr"})} 
      text={t("ContropPage_text",{ns:"tr"})} 
      tooltip={t("ContropPage_tt",{ns:"tr"})} 
        
      />

<div className="ConPage_NavBar">

<NavLink to={`tours/`} 
className={({isActive}) => isActive ? 'Con_Navlink_active' : ''}>
  <div className="Con_NavBar_Item">
  {t("CP_myTours",{ns:"tr"})}</div></NavLink>

  <NavLink to={`adverts`} 
  className={({isActive}) => isActive ? 'Con_Navlink_active' : ''}>
  <div className="Con_NavBar_Item">
  {t("CP_myAdverts",{ns:"tr"})}</div></NavLink>


  <NavLink to={`news`} 
  className={({isActive}) => isActive ? 'Con_Navlink_active' : ''}>
  <div className="Con_NavBar_Item">
  {t("CP_myNews",{ns:"tr"})}</div></NavLink>

</div>

    <Outlet />

</div>

    )
}

export default ControlPage