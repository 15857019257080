import { useTranslation } from 'react-i18next';

import PageHeader from '../../components/PageHeader/PageHeader'
import UnderConstrPage from '../UnderConstrPage/UnderConstrPage'

import './SocietiesPage.scss'

const SocietiesPage = () => {
  const {t} = useTranslation(['tr']);

  return (
    <div className="SocietiesPage_wrapper">

        <PageHeader
        icon="society"
        title={t("societies",{ns:"tr"})}
        // title="Сообщества"
        text={t("societies_text",{ns:"tr"})}
        // text="На темы путешествий"
      />

      <UnderConstrPage/>
    </div>
  )
}

export default SocietiesPage