import React from 'react'

import './PageItems.scss'
const EmptyHere = ({text, subText }) => {
  return (
    <div className="EmptyHere">
        <h4>{text}!</h4>
        <h3>{subText}</h3>
    </div>
  )
}

export default EmptyHere