import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';
import * as api from '../../utils/api/api.utils'

import { thumb } from "../../utils/thumb";

import Verified from "../../assets/verified.svg";

import Lang from "../../assets/lang.svg";
import daysAmnt from "../../assets/sortMenu/amount.svg";
import MsgBtnSml from "../Buttons/MsgBtnSml";

import { config } from "../../utils/config";
import PostFavBtn from "../Buttons/PostFavBtn";

import Modal from "../Modal/Modal";
import SendMsgWin from "../Window/SendMsgWin";
import { showDate } from "../../utils/showDate";
import { FaSearch } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";

import { showLangs } from "../../utils/showLangs";
import { showGender } from "../../utils/showGender";
import { showAges } from "../../utils/showAges";
import TimeStatusText from "../Chips/TimeStatusText";
import LikesChip from "../Chips/LikesChip";
import EditBtn from "../Buttons/EditBtn";
import DeleteBtn from "../Buttons/DeleteBtn";
import { handleError } from "../../utils/handleError";


import "./Post.scss";
const Post = ({ post, setUpdated, allLangs, allInterests }) => {
  const { jwtUser, setJwtUser } = useContext(UserContext);
  const [like, setLike] = useState(0);

  const [msgModalOpen, setMsgModalOpen] = useState(false);
  const {t} = useTranslation(['translation', 'countries', 'int', 'langs']);
  const navigate = useNavigate();

  const myId = jwtUser?.data?.user?._id;
  const isMy = post?.creator?._id === myId;

  const {date, year} = showDate(post);
  const langs = showLangs(post)
  const gender = showGender(post, "post")
  const ages = showAges(post)


  const confirmDelete = () => {
    if(window.confirm(t("AreYouSureToDeletePost",{ns:"tr"}))){
      api.deletePost(post._id).then(res=> {
        if(res.status===200){
          setUpdated("noMaterWhat")
        }else{
          handleError(res)
        }
      })
    }
  }

  const sendMessage =()=>{
    if(!jwtUser) navigate('/login')
    setMsgModalOpen(true)
  }

  const post_ints = allInterests?.filter(({_id}) => post?.interests?.includes(_id));

  return (

    <div className="Post_Big_wrapper">

      <div className="Post_countryLine">
        <p><b>{t(post.country,{ns:"countries"})},</b> {post?.city}</p>
        <span><b>{date}</b> {year}</span>
      </div>

      <div className="Post_wrapper">

      <div className="post_top">
        <div className="post_left_side">

          <div onClick={() => navigate(`/user/${post?.creator?.username}`)}
            className="post_pic_area"  >
            <img className="post_ibg"
              src={thumb(post?.creator?.photos[0], config.image_width)} alt="" loading="lazy" />
          </div>

        </div>

        <div className="post_right_side">
          <div className="post_right_top">

          <div className="post_rt_left">

            <div className="post_line">
              {post?.creator?.verified ?
                <img src={Verified} alt="" />
                :
                <FaRegUserCircle size={16} color="#87589C" />
              }
              <p><b>{post?.creator?.fName}</b> </p>
              <p> {post?.org_age} </p>
              <img className="Mpost_flag" src={`/assets/flags/small/${post?.depart?.toLowerCase()}.jpg`} alt="" />
            </div>

            <div className="post_line">
              <FaSearch size={14} color="#87589C" />
              <p><b>{gender}</b></p>
              <p>{ages}</p>
            </div>

            <div className="post_line">
              {post?.daysAmount > 0 && <>
                <img src={daysAmnt} alt="travel dates" />
                  <p> <b>{t("Duration", { ns: "tr" })}:</b> </p>
                  <p>{post?.daysAmount}</p>
                  <p>{t("days", { ns: "tr" })}</p>
              </>}
            </div>

            <div className="post_line">
              <img src={Lang} alt="" />
              <p>{langs}</p>
            </div>

          </div>

            <div className="post_intBox">
            {post_ints && post_ints.map((int, index) => (
              <div className='Tooltip_wrapper' key={index}>
                  <img className="post_int_img" src={int.icon} alt="" />
                <div className="TT_tip TT_center TT_fw500"
                    dangerouslySetInnerHTML={{ __html: t(int?.code, { ns: "int" }) }} />
              </div>
                ))}
            </div>


              {isMy ?
                <div className="post_rt_right">
                  <EditBtn small
                    tooltip={t("Edit",{ns:"tr"})}
                    onClick={()=>navigate(`/editpost/${post._id}`)}
                  />

                  <DeleteBtn small
                    tooltip={t("Delete",{ns:"tr"})}
                    onClick={()=> confirmDelete()}
                  />
                </div>

                :
                <div className="post_rt_right">
                  <PostFavBtn
                      post={post}
                      jwtUser={jwtUser}
                      setLike={setLike}
                      setJwtUser={setJwtUser}
                  />

                  <MsgBtnSml onClick={sendMessage} />
                </div>
              }


          </div>
          <div className={`post_right_btm ${post?.org_gender==='male'? 'post_M': 'post_F'}`}>
            {/* <div dangerouslySetInnerHTML={{ __html: post.text }} /> */}
            <span>{post.text}</span>
          </div>
        </div>
        </div>

        <div className="Post_bottomLine">
          <TimeStatusText item={post}/>
          <LikesChip item={post} like={like} size={15}/>
        </div>

        <Modal open={msgModalOpen} close={()=>setMsgModalOpen(false)}>

        <SendMsgWin
        jwtUser={jwtUser}
        setJwtUser={setJwtUser}
        user={post?.creator}
        close={()=>{
            document.body.classList.remove("active-modal");
            setMsgModalOpen(false)
          }}
        />

        </Modal>

      </div>
    </div>
  );
};

export default Post;
