export const langs = [
    {
        isPublic: true,
        _id: "rus",
        code: "rus",
        code2: "ru",
        rus_title:'Русский',
        eng_title: "Russian",
        flag:'/assets/flags/rus.jpg',

    },
    {
        isPublic: true,
        _id: "ukr",
        code: "ukr",
        code2: "ui",
        rus_title:'Украинский',
        eng_title: "Ukrainian",
        flag:'/assets/flags/ukr.jpg',

    },
    {
        isPublic: true,
        _id: "eng",
        code: "eng",
        code2: "en",
        rus_title:'Английский',
        eng_title: "English",
        flag:'/assets/flags/eng.jpg',

    },
    {
        isPublic: true,
        _id: "arm",
        code: "arm",
        code2: "am",
        rus_title:'Армянский',
        eng_title: "Armenian",
        flag:'/assets/flags/arm.jpg',

    },
    {
        isPublic: true,
        _id: "aze",
        code: "aze",
        code2: "az",
        rus_title:'Азербайджани',
        eng_title: "Azerbajani",
        flag:'/assets/flags/aze.jpg',

    },
    {
        isPublic: true,
        _id: "chn",
        code: "chn",
        code2: "cn",
        rus_title:'Китайский',
        eng_title: "Chinese",
        flag:'/assets/flags/chn.jpg',

    },
    {
        isPublic: true,
        _id: "ger",
        code: "ger",
        code2: "de",
        rus_title:'Немецкий',
        eng_title: "German",
        flag:'/assets/flags/ger.jpg',

    },
    {
        isPublic: true,
        _id: "esp",
        code: "esp",
        code2: "es",
        rus_title:'Испанский',
        eng_title: "Spanish",
        flag:'/assets/flags/esp.jpg',

    },
    {
        isPublic: true,
        _id: "fra",
        code: "fra",
        code2: "fr",
        rus_title:'Французский',
        eng_title: "Franch",
        flag:'/assets/flags/fra.jpg',

    },
    {
        isPublic: true,
        _id: "geo",
        code: "geo",
        code2: "ge",
        rus_title:'Грузинский',
        eng_title: "Georgian",
        flag:'/assets/flags/geo.jpg',

    },
    {
        isPublic: true,
        _id: "gre",
        code: "gre",
        code2: "gr",
        rus_title:'Греческий',
        eng_title: "Greece",
        flag:'/assets/flags/gre.jpg',

    },
    {
        isPublic: true,
        _id: "jew",
        code: "jew",
        code2: "il",
        rus_title:'Иврит',
        eng_title: "Jewish",
        flag:'/assets/flags/jew.jpg',

    },
    {
        isPublic: true,
        _id: "ind",
        code: "ind",
        code2: "in",
        rus_title:'Индийский',
        eng_title: "Indian",
        flag:'/assets/flags/ind.jpg',

    },
    {
        isPublic: true,
        _id: "ita",
        code: "ita",
        code2: "it",
        rus_title:'Итальянский',
        eng_title: "Italian",
        flag:'/assets/flags/ita.jpg',

    },
    {
        isPublic: true,
        _id: "jpa",
        code: "jpa",
        code2: "jp",
        rus_title:'Японский',
        eng_title: "Japan",
        flag:'/assets/flags/jpa.jpg',

    },
    {
        isPublic: true,
        _id: "pol",
        code: "pol",
        code2: "pl",
        rus_title:'Польский',
        eng_title: "Polish",
        flag:'/assets/flags/pol.jpg',

    },
    {
        isPublic: true,
        _id: "swe",
        code: "swe",
        code2: "se",
        rus_title:'Шведский',
        eng_title: "Swedish",
        flag:'/assets/flags/swe.jpg',

    },
    {
        isPublic: true,
        _id: "trk",
        code: "trk",
        code2: "tr",
        rus_title:'Турецкий',
        eng_title: "Turkish",
        flag:'/assets/flags/trk.jpg',

    },
    {
        isPublic: true,
        _id: "arb",
        code: "arb",
        code2: "sa",
        rus_title:'Арабский',
        eng_title: "Arabic",
        flag:'/assets/flags/arb.jpg',

    },

]