import SentIcon from '../../assets/join/sent.svg'


import './JoinedChip.scss'

const RequestChip = ({title, tooltip, small=false, mobile=false}) => {
  return (

    <div className='Chip_Tooltip_wrapper'>
     {!mobile ?
      <div className={small? "TP_Chip_wrapper Chip_small": "TP_Chip_wrapper"}>
        <img src={SentIcon} alt="" />
        {!small &&
        <h3>{title}</h3>}
      </div>
      :
      <div className="TP_Chip_mob">
        <img src={SentIcon} alt="" />
      </div>
     }
      <div className="Chip_tip" dangerouslySetInnerHTML={{ __html: tooltip }} />
      </div>

    )
}

export default RequestChip