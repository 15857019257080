import { useContext, useEffect, useState } from 'react'
import { UserContext } from "../../context/user.context";
import { useTranslation } from 'react-i18next';
import i18n from '../../utils/i18next';
import * as api from '../../utils/api/api.utils'

import done from '../../assets/FeedBack_done.svg'
import lamp from '../../assets/images/lightbulb.png'
import problem from '../../assets/images/issue.png'
import cloud from '../../assets/images/cloud.png'

import './FeedBack.scss'
import { handleError } from '../../utils/handleError';
const FeedBack = ({close}) => {
    const {jwtUser, location } = useContext(UserContext);
  const [page, setPage] = useState(1)
  const [type, setType] = useState("")
  const [text, setText] = useState("")
  const [email, setEmail] = useState("")
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false)

  const userEmail = jwtUser?.data?.user?.email;
  const lang = i18n.language
  const fromUrl = window.location.href

  useEffect(() => {
    if(userEmail) setEmail(userEmail)
  }, [userEmail]);

  const {t} = useTranslation(['translation']);

  const FirstSelect1=()=>{
    setPage(2)
    setType("issue")
  }
  const FirstSelect2=()=>{
    setPage(2)
    setType("idea")
  }
  const FirstSelect3=()=>{
    setPage(2)
    setType("other")
  }

  const sendFeedback=()=>{
    if(!text){
        setServerError(t("fb_noText_error", { ns: "translation" }))
    }else{
    setLoading(true)
    api.sendFeedback({lang, fromUrl, text, email, type,
        userId: jwtUser?.data?.user?._id,
        username: jwtUser?.data?.user?.username,
        gender: jwtUser?.data?.user?.gender,
        country: location?.country,
        city: location?.city
    }).then((res)=>{
        if(res?.status===200){
            setLoading(false)
            setPage(3)
        }else{
            setLoading(false)
            setServerError(t("fb_server_error", { ns: "translation" }))
            handleError(res)
        }
      })
    }
}

  return (

    <div className="FeedBack_wrapper">

        <div className="FeedB_TitleLine">
            <div className="FeedB_emptyDIV"></div>
            {page!==3&&  <div className="FeedB_Title">
            {t("sendFeedback", { ns: "translation" })}
            </div>}
            <div className="FeedB_CloseBtn" onClick={close}>&#x2715;</div>
        </div>

        <div className="FeedB_ContentBox">

    {page===1&&
        <div className="FBC_Page_1">
            <div className="FBC_P1_BigBtn" onClick={FirstSelect1}>
                <img src={problem} alt="problem" />
                <p>{t("issue", { ns: "translation" })}</p>
            </div>
            <div className="FBC_P1_BigBtn" onClick={FirstSelect2}>
                <img src={lamp} alt="lamp" />
                <p>{t("idea", { ns: "translation" })}</p>
            </div>
            <div className="FBC_P1_BigBtn" onClick={FirstSelect3}>
                <img src={cloud} alt="cloud" />
                <p>{t("other", { ns: "translation" })}</p>
            </div>
        </div>
    }
    {page===2&&
        <div className="FBC_Page_2">
        <div className="FBC_SmallBtns_Container">
            <div className={`FBC_P2_SmlBtn ${type==="issue"?"FBC_act":null}`} onClick={()=>setType("issue")}>
                <img src={problem} alt="problem" />
                <p>{t("issue", { ns: "translation" })}</p>
            </div>
            <div className={`FBC_P2_SmlBtn ${type==="idea"?"FBC_act":null}`} onClick={()=>setType("idea")}>
                <img src={lamp} alt="lamp" />
                <p>{t("idea", { ns: "translation" })}</p>
            </div>
            <div className={`FBC_P2_SmlBtn ${type==="other"?"FBC_act":null}`} onClick={()=>setType("other")}>
                <img src={cloud} alt="cloud" />
                <p>{t("other", { ns: "translation" })}</p>
            </div>
        </div>

        <div className="FBC_Text">
            <textarea
                value={text}
                onChange={(e)=>setText(e.target.value)}
                placeholder={t("fb_placeholder", { ns: "translation" })}
                />
        </div>

        {serverError&& <span>{serverError}</span> }

        <div className="FBC_Email">
            <input
                type='email'
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                placeholder={t("yourEmail", { ns: "translation" })}
                />
        </div>
        <button
        className="FBC_Send"
        onClick={sendFeedback}
        disabled={loading}
        > {loading? t("sending", { ns: "translation" })
        : t("send", { ns: "translation" })}
        </button>
        </div>
    }

    {page===3&&
        <div className="FBC_Page_3">

            <img src={done} alt="" />
            <p>{t("fb_Thanks", { ns: "translation" })}</p>

        </div>
    }

        </div>

    </div>

    )
}

export default FeedBack