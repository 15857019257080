import { useTranslation } from 'react-i18next';

import Logo from '../../assets/tripico_logo.svg'
import Pic_2 from '../../assets/onboarding/ob_2.png'
import right_arrow from '../../assets/arrow_right_white.svg'
import left_arrow from '../../assets/arrow_left_grey.svg'


import './OBPScreens.scss'

const OBPScreen2 = ({close, back, next}) => {
    const {t} = useTranslation(['tr']);

  return (
    <div className="OBP_Screen1">

    <div className="OBP_header">
        <img src={Logo} alt="Tripico Logo" />
        <p><b>2</b> / 3</p>
    </div>

    <div className="OBP_body">
        <div className="OBP_pic_area">
        <img className='OBP_pic' src={Pic_2} alt="Onboarding" />
        </div>

        <div className="OBP_body_right">
            <p>{t("OnBoarding_P2",{ns:"tr"})}</p>
            <span>{t("OnBoarding_P2_text1",{ns:"tr"})} <b> Tripico</b>
            {t("OnBoarding_P2_text2",{ns:"tr"})}
            <br/> {t("OnBoarding_P2_text3",{ns:"tr"})}</span>


        </div>
    </div>

    <div className="OBP_bottom">
        <span onClick={close}>{t("Skip",{ns:"tr"})}</span>
        <div className="OBP_Buttom_Btns">
        <div className="OBP_back_btn_area">

        <div className="OBP_back_btn" onClick={back}>
            <img src={left_arrow} alt="arrow" />
            <p>{t("Back",{ns:"tr"})}</p>
        </div>

        </div>
        <div className="OBP_next_btn" onClick={next}>
            <p>{t("Next",{ns:"tr"})}</p>
            <img src={right_arrow} alt="arrow" />
        </div>
        </div>
    </div>
  </div>
  )
}

export default OBPScreen2